<div class="sfondo">
  <div class="box">
    <div class="center">
      <mat-icon svgIcon="mat:warning"></mat-icon>
    </div>
    <h1>Ops!</h1>
    <h1>{{"PAYMENT_REJ.PAYMENT" | translate}}</h1>
    <p>{{"PAYMENT_REJ.WORRY" | translate}}</p>
    <div class="center"><button (click)="goToLogin()">{{"PAYMENT_REJ.LOGIN" | translate}}</button></div>
  </div>
</div>
