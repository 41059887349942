import { GuiService } from './gui.service';
import { RestService } from 'src/app/services/rest.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map, Observable, take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(public restService: RestService, private router: Router, public guiService: GuiService){

  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.restService.token$.pipe(
      take(1), map((token)=>{
        if(token || sessionStorage.getItem('tkn')){
          console.debug('authguard usata')
          this.guiService.selectMenuItems(sessionStorage.getItem('menu'))
          this.restService.getService('current_user', '').subscribe((ris)=>{
            this.guiService.userLogged = ris
            this.guiService.notificationsList = ris.notifications
            if(this.guiService.userLogged && this.guiService.userLogged.userPaymentInfo && this.guiService.userLogged.userPaymentInfo.subscriptionStatus == null || this.guiService.userLogged && this.guiService.userLogged.userPaymentInfo && this.guiService.userLogged.userPaymentInfo.subscriptionStatus != 'ACTIVE'){
              if(this.guiService.userLogged.user.roles[0] === 'USER'){
                console.debug('SUBSCRIPTION PAUSED OR SUSPENDED')
                this.router.navigate(['main/user/sub'])
              }
          }
          })

          return true;
        }
        return this.router.createUrlTree(['/'])
      })
    );
  }

}
