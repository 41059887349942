<div class="container mt-4">
  <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">
    <h2 class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l hidden sm:block flex-none">
      <span *ngIf="selection.isEmpty()">{{"CHALLENGES.CHALL" | translate}}</span>
    </h2>
    <div
      class="rounded-full border px-4 max-w-[300px] flex-auto flex items-center border border-gray-300">
      <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon>
      <input [formControl]="searchCtrl"  class="px-4 py-2 border-0 outline-none w-full bg-transparent" placeholder="{{'GLOBAL.SEARCH' | translate}}" type="search" />
    </div>
    <button class="ml-4 flex-none ltr:mr-4 ltr:border-r" color="primary" mat-mini-fab type="button" (click)="search();resetPage()">
      <mat-icon svgIcon="mat:search"></mat-icon>
    </button>
    <button (click)="reload()" class="ml-4 flex-none blue-bottone" color="primary" mat-mini-fab matTooltip="reload" type="button" >
      <mat-icon svgIcon="mat:autorenew"></mat-icon>
    </button>
    <button (click)="createChallenge()" class="ml-4 flex-none blue-bottone" color="primary" mat-mini-fab matTooltip="Add Challenge" type="button" >
      <mat-icon svgIcon="mat:add"></mat-icon>
    </button>
  </div>
  <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center bg2">
    <div class="flex items-center filter-bar">
      <nav class="vex-tabs border-0 flex-auto cerca" mat-tab-nav-bar>
        <mat-form-field class="nut-form">
          <mat-label class="label-light">{{"CHALLENGES.PUB_DATE" | translate}}</mat-label>
          <input matInput [formControl]="searchCtrlPublication" type="date">
        </mat-form-field>
      </nav>

    </div>
  </div>
  <table mat-table [dataSource]="dataSource" *ngIf="challenges" matSort>

    <ng-container matColumnDef="vuota">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef=""></td>
    </ng-container>

    <ng-container matColumnDef="nome">
      <th mat-header-cell *matHeaderCellDef mat-sort-header (click)="sortParam('name', asc == true ? false : true)">{{"CHALLENGES.NAME" | translate}}</th>
      <td mat-cell *matCellDef="let challenge">{{ challenge.name }}</td>
    </ng-container>
    <ng-container matColumnDef="data-pubblicazione">
      <th mat-header-cell *matHeaderCellDef mat-sort-header (click)="sortParam('publishDate', asc == true ? false : true)">{{"CHALLENGES.PUB_DATE" | translate}}</th>
      <td mat-cell *matCellDef="let challenge">{{ challenge.publishDate | date: 'shortDate'}}</td>
    </ng-container>
    <ng-container matColumnDef="descrizione">
      <th mat-header-cell *matHeaderCellDef>{{"CHALLENGES.DESC" | translate}}</th>
      <td mat-cell *matCellDef="let challenge">{{challenge.description}}</td>
    </ng-container>

    <!-- Action Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row" class="w-10 text-secondary" >
        <button (click)="$event.stopPropagation()"
                [matMenuTriggerData]="{ customer: row }"
                [matMenuTriggerFor]="actionsMenu"
                mat-icon-button
                type="button">
          <mat-icon svgIcon="mat:more_horiz"></mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>


  </table>
  <mat-paginator [pageSize]="pageSize" [length]="lunghezza" (page)="onPageChanged($event)" class="sticky left-0"></mat-paginator>

</div>

<mat-menu #actionsMenu="matMenu" xPosition="before" yPosition="below">
  <ng-template let-customer="customer" matMenuContent>
    <button  mat-menu-item (click)="editChallenge(customer)">
      <mat-icon svgIcon="mat:edit"></mat-icon>
      <span>{{'GLOBAL.EDIT' | translate}}</span>
    </button>
    <button  mat-menu-item (click)="deleteChallenge(customer.id)">
      <mat-icon svgIcon="mat:delete"></mat-icon>
      <span>{{'GLOBAL.DELETE' | translate}}</span>
    </button>
  </ng-template>
</mat-menu>
