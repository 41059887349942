<div class="container" *ngIf="user">
  <div class="info w-50">
    <h3>{{user.masterData.name}} {{user.masterData.lastName}}</h3>
    <p>{{"USER-NUTRITION.REGISTER" | translate}} {{user.masterData.registrationDate | date: 'M/d/yyyy'}}</p>
    <p *ngIf="user.userPaymentInfo && user.userPaymentInfo.subscriptionStatus != null && user.userPaymentInfo.subscriptionStatus != 'SUSPENDED'">
      <fa-icon [icon]="icon.faCircle" class="icon payed"></fa-icon>
      {{"USER-NUTRITION.PAYED" | translate}}
    </p>
    <p *ngIf="user.userPaymentInfo && user.userPaymentInfo.subscriptionStatus == 'SUSPENDED'|| user.userPaymentInfo && user.userPaymentInfo.subscriptionStatus == null || user.userPaymentInfo == null">
      <fa-icon [icon]="icon.faCircle" class="icon not-payed"></fa-icon>
      {{"USER-NUTRITION.NOT_PAYED" | translate}}
    </p>
    <span *ngIf="guiService.userLogged.userPaymentInfo.subscriptionType == 'DIAMOND'">
      <p *ngIf="user.masterData.lastDietUploaded != null">
        <fa-icon [icon]="icon.faCheck" class="icon charged"></fa-icon>
        {{"USER-NUTRITION.DIET" | translate}}
      </p>
      <p *ngIf="user.masterData.lastDietUploaded == null">
        <fa-icon [icon]="icon.faCheck" class="icon not-charged"></fa-icon>
        {{"USER-NUTRITION.NOT_DIET" | translate}}
      </p>
    </span>
    <p *ngIf="user.userPaymentInfo && user.userPaymentInfo.subscriptionExpiration == null || user.userPaymentInfo == null">{{"USER-NUTRITION.SUB_EXPIRE" | translate}} N/A</p>
    <p *ngIf="user.userPaymentInfo && user.userPaymentInfo.subscriptionExpiration != null">{{"USER-NUTRITION.SUB_EXPIRE" | translate}} {{user.userPaymentInfo.subscriptionExpiration | date: 'M/d/yyyy'}}</p>
  </div>
  <div class="guida w-50">
    <div class="block" >
      <div class="blocco " [class.incucina]="translate.currentLang == 'it'" [class.incucina-en]="translate.currentLang == 'en'" (click)="downloadGuidePdf(1)"></div>
      <div class="blocco " [class.alimentazione]="translate.currentLang == 'it'" [class.alimentazione-en]="translate.currentLang == 'en'"  (click)="downloadGuidePdf(2)"></div>
    </div>
    <div class="bottoni" *ngIf="guiService.userLogged.userPaymentInfo && guiService.userLogged.userPaymentInfo.subscriptionType == 'DIAMOND'">
      <button class="survey compiled" *ngIf="user.masterData.lastSurvey == null || check30Days() == true" (click)="showSurvey = true">{{"USER-NUTRITION.SURVEY" | translate}}</button>
      <button class="survey compiled" *ngIf="user.masterData.lastSurvey != null && check30Days() == false" (click)="getMySurvey()">{{"USER-NUTRITION.MY_SURVEY" | translate}}</button>
      <button class="survey compiled" *ngIf="user.masterData.lastDietUploaded != null && user.masterData.lastSurveyWithDietUploadedId" (click)="downloadDietPdf(user.masterData.lastSurveyWithDietUploadedId,'diet '+user.masterData.name +' '+user.masterData.lastName)">{{"USER-NUTRITION.MY_DIET" | translate}}</button>
      <button class="survey not-compiled" *ngIf="user.masterData.lastDietUploaded == null && user.masterData.lastSurveyWithDietUploadedId == null">{{"USER-NUTRITION.MY_DIET" | translate}}</button>
    </div>
    <div class="bottoni" *ngIf="guiService.userLogged.userPaymentInfo && guiService.userLogged.userPaymentInfo.subscriptionType == 'GOLD'">
      <button class="discoverBtn" (click)="discover()">{{"USER-NUTRITION.DISCOVER" | translate}}</button>
    </div>
  </div>

  <div class="surveys" *ngIf="showSurvey == true && user.masterData.lastDietUploaded == null">
    <mat-horizontal-stepper #stepper="matHorizontalStepper" [linear]="true">
      <ng-template matStepperIcon="edit">
        <mat-icon svgIcon="mat:done_all"></mat-icon>
      </ng-template>

      <ng-template matStepperIcon="done">
        <mat-icon svgIcon="mat:done_all"></mat-icon>
      </ng-template>

      <mat-step [stepControl]="systemFormGroup">
        <form [formGroup]="systemFormGroup">

            <mat-form-field appearance="fill">
              <mat-label>{{"USER-NUTRITION.SURVEY_C.UNIT" | translate}}</mat-label>
              <mat-select formControlName="system" required (selectionChange)="changeSystemUnit()">
                <mat-option [value]="option.val" *ngFor="let option of system">{{ option.label }} </mat-option>
              </mat-select>
            </mat-form-field>
          <div class="bottoni">
            <button mat-button matStepperNext [disabled]="systemFormGroup.invalid">{{"USER-NUTRITION.SURVEY_C.NEXT" | translate}}</button>
          </div>
        </form>
      </mat-step>

      <mat-step [stepControl]="firstFormGroup">
        <form [formGroup]="firstFormGroup">

            <mat-form-field appearance="fill">
              <mat-label>{{"USER-NUTRITION.SURVEY_C.NAME" | translate}}</mat-label>
              <input matInput formControlName="nome" required>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>{{"USER-NUTRITION.SURVEY_C.SURNAME" | translate}}</mat-label>
              <input matInput formControlName="cognome" required>
            </mat-form-field>
            <div class="flex flex-col sm:flex-row gap-2 sm:gap-6">
            <mat-form-field appearance="fill">
              <mat-label>{{"USER-NUTRITION.SURVEY_C.AGE" | translate}}</mat-label>
              <input matInput formControlName="eta" type="number" required>
            </mat-form-field>
            <mat-form-field appearance="fill" class="flex-auto">
              <mat-label>{{"USER-NUTRITION.SURVEY_C."+unit+".HEIGHT" | translate}}</mat-label>
              <input matInput  formControlName="altezza" type="number" required>
            </mat-form-field>
            <mat-form-field appearance="fill" class="flex-auto">
              <mat-label>{{"USER-NUTRITION.SURVEY_C."+unit+".WEIGHT" | translate}}</mat-label>
              <input matInput  formControlName="peso" type="number" required>
            </mat-form-field>
            </div>
          <div class="bottoni">
            <button mat-button matStepperNext [disabled]="firstFormGroup.invalid">{{"USER-NUTRITION.SURVEY_C.NEXT" | translate}}</button>
          </div>
        </form>
      </mat-step>

      <mat-step [stepControl]="secondFormGroup">
        <form [formGroup]="secondFormGroup">
          <div class="flex flex-col sm:flex-row gap-2 sm:gap-6">
            <mat-form-field appearance="fill" class="flex-auto">
              <mat-label>{{"USER-NUTRITION.SURVEY_C."+unit+".WAIST" | translate}}</mat-label>
              <input matInput  formControlName="vita" type="number" required>
            </mat-form-field>
            <mat-form-field appearance="fill" class="flex-auto">
              <mat-label>{{"USER-NUTRITION.SURVEY_C."+unit+".FLANKS" | translate}}</mat-label>
              <input matInput  formControlName="fianchi" type="number" required>
            </mat-form-field>
          </div>
          <div class="flex flex-col sm:flex-row gap-2 sm:gap-6">
            <mat-form-field appearance="fill" class="flex-auto">
              <mat-label>{{"USER-NUTRITION.SURVEY_C."+unit+".THIGH_DX" | translate}}</mat-label>
              <input matInput formControlName="coscia_dx" type="number" required>
            </mat-form-field>
            <mat-form-field appearance="fill" class="flex-auto">
              <mat-label>{{"USER-NUTRITION.SURVEY_C."+unit+".THIGH_SX" | translate}}</mat-label>
              <input matInput formControlName="coscia_sx" type="number" required>
            </mat-form-field>
          </div>
          <div class="flex flex-col sm:flex-row gap-2 sm:gap-6">
            <mat-form-field appearance="fill" class="flex-auto">
              <mat-label>{{"USER-NUTRITION.SURVEY_C."+unit+".ARM_DX" | translate}}</mat-label>
              <input matInput formControlName="braccio_dx" type="number" required>
            </mat-form-field>
            <mat-form-field appearance="fill" class="flex-auto">
              <mat-label>{{"USER-NUTRITION.SURVEY_C."+unit+".ARM_SX" | translate}}</mat-label>
              <input matInput formControlName="braccio_sx" type="number" required>
            </mat-form-field>
          </div>
          <div class="bottoni">
            <button mat-button matStepperPrevious>{{"USER-NUTRITION.SURVEY_C.BACK" | translate}}</button>
            <button mat-button matStepperNext [disabled]="secondFormGroup.invalid">{{"USER-NUTRITION.SURVEY_C.NEXT" | translate}}</button>
          </div>
        </form>
      </mat-step>

      <mat-step [stepControl]="thirdFormGroup">
        <form [formGroup]="thirdFormGroup">

            <mat-form-field appearance="fill">
              <mat-label>{{"USER-NUTRITION.SURVEY_C.JOB" | translate}}</mat-label>
              <input matInput formControlName="attivita" required>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>{{"USER-NUTRITION.SURVEY_C.LIFE" | translate}}</mat-label>
              <mat-select formControlName="stile">
                <mat-option [value]="option" *ngFor="let option of stile_vita">{{ option }}</mat-option>
              </mat-select>
            </mat-form-field>
            <div class="flex flex-col sm:flex-row gap-2 sm:gap-6">
              <mat-form-field appearance="fill" class="flex-auto">
                <mat-label>{{"USER-NUTRITION.SURVEY_C.CYCLE" | translate}}</mat-label>
                <mat-select formControlName="ciclo">
                  <mat-option [value]="option" *ngFor="let option of ciclo">{{ option }}</mat-option>
                </mat-select>
              </mat-form-field>
            <mat-form-field appearance="fill" *ngIf="thirdFormGroup.value.ciclo == 'irregolare'" class="flex-auto">
              <mat-label>{{"USER-NUTRITION.SURVEY_C.CYCLE_FREQ" | translate}}</mat-label>
              <input matInput formControlName="frequenza_ciclo">
            </mat-form-field>
            </div>


          <div class="bottoni">
            <button mat-button matStepperPrevious>{{"USER-NUTRITION.SURVEY_C.BACK" | translate}}</button>
            <button mat-button matStepperNext [disabled]="thirdFormGroup.invalid">{{"USER-NUTRITION.SURVEY_C.NEXT" | translate}}</button>
          </div>
        </form>
      </mat-step>

      <mat-step [stepControl]="fourthFormGroup">
        <form [formGroup]="fourthFormGroup">

            <mat-form-field appearance="fill">
              <mat-label>{{"USER-NUTRITION.SURVEY_C.PATOLOGY" | translate}}</mat-label>
              <input matInput formControlName="patologie">
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>{{"USER-NUTRITION.SURVEY_C.TERAPY" | translate}}</mat-label>
              <input matInput formControlName="terapia">
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>{{"USER-NUTRITION.SURVEY_C.ALLERGY" | translate}}</mat-label>
              <input matInput formControlName="allergie">
            </mat-form-field>

          <div class="bottoni">
            <button mat-button matStepperPrevious>{{"USER-NUTRITION.SURVEY_C.BACK" | translate}}</button>
            <button mat-button matStepperNext [disabled]="fourthFormGroup.invalid">{{"USER-NUTRITION.SURVEY_C.NEXT" | translate}}</button>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="foodFormGroup">
        <form [formGroup]="foodFormGroup">

              <mat-form-field appearance="fill">
                <mat-label>{{"USER-NUTRITION.SURVEY_C.FOOD_LOVE" | translate}}</mat-label>
                <mat-select formControlName="cibo_amo" multiple>
                  <mat-option [value]="option" *ngFor="let option of cibi">{{ option }}</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>{{"USER-NUTRITION.SURVEY_C.FOOD_HATE" | translate}}</mat-label>
                <mat-select formControlName="cibo_odio" multiple>
                  <mat-option [value]="option" *ngFor="let option of cibi">{{ option }}</mat-option>
                </mat-select>
              </mat-form-field>
              <div class="bottoni">
                <button mat-button matStepperPrevious>{{"USER-NUTRITION.SURVEY_C.BACK" | translate}}</button>
                <button mat-button matStepperNext [disabled]="foodFormGroup.invalid">{{"USER-NUTRITION.SURVEY_C.NEXT" | translate}}</button>
              </div>
        </form>
      </mat-step>

      <mat-step [stepControl]="questnote">
        <form [formGroup]="questnote">

              <mat-form-field appearance="fill">
                <mat-label>Note</mat-label>
                <textarea cols="30" rows="4" matInput formControlName="consideration"></textarea>
              </mat-form-field>
              <div class="bottoni">
                <button mat-button matStepperPrevious>{{"USER-NUTRITION.SURVEY_C.BACK" | translate}}</button>
                <button mat-button matStepperNext [disabled]="questnote.invalid">{{"USER-NUTRITION.SURVEY_C.NEXT" | translate}}</button>
              </div>
        </form>
      </mat-step>

      <mat-step [stepControl]="fifthFormGroup">
        <form [formGroup]="fifthFormGroup">

              <div class="mt-4 flex flex-col gap-2">
                <mat-label>{{"USER-NUTRITION.SURVEY_C.TERMS" | translate}}</mat-label>
                <mat-checkbox class="checkbox" formControlName="termini" required></mat-checkbox>
              </div>
              <div class="mt-4 flex flex-col gap-2">
                <mat-label *ngIf="translate.currentLang == 'it'">
                  Dichiaro di avere compiuto 18 anni (o di essere altresì maggiorenne secondo le leggi dello Stato in cui risiedo). Diversamente dichiaro, sotto la mia esclusiva responsabilità, di avere ottenuto il consenso del genitore/tutore/esercente responsabilità genitoriale, per partecipare al piano Diamond.

                  Dichiaro inoltre di non avere problemi di salute e di aver ottenuto il consenso dal mio medico o operatore sanitario per iniziare il programma nutrizionale/piano alimentare offerto dal piano Diamond.
                  </mat-label>
                <mat-label *ngIf="translate.currentLang == 'en'">
                  I certify that I am 18 years of age or older (or of legal age in my jurisdiction required to form a binding contract if that age is over 18 or if I am younger than 18 years of age, that I have obtained my parent or guardian’s consent to participate in the Diamond plan).

                  I also certify that I have no known health problems and that I have obtained consent from my doctor, physician or health care provider to begin the nutrition program/meal plan offered by the Diamond plan.
                  </mat-label>
                <mat-checkbox class="checkbox" formControlName="termini2" required></mat-checkbox>
              </div>

          <div class="bottoni">
            <button mat-button matStepperPrevious>{{"USER-NUTRITION.SURVEY_C.BACK" | translate}}</button>
            <button mat-button matStepperNext [disabled]="fifthFormGroup.invalid || disabledSend == true" (click)="submit()">{{"USER-NUTRITION.SURVEY_C.SEND" | translate}}</button>
          </div>
        </form>
      </mat-step>

    </mat-horizontal-stepper>
  </div>
  <div *ngIf="showSurvey == true && user.masterData.lastDietUploaded != null">
    <mat-horizontal-stepper [linear]="true" >
      <ng-template matStepperIcon="edit">
        <mat-icon svgIcon="mat:done_all"></mat-icon>
      </ng-template>

      <ng-template matStepperIcon="done">
        <mat-icon svgIcon="mat:done_all"></mat-icon>
      </ng-template>

      <mat-step [stepControl]="systemFormGroup">
        <form [formGroup]="systemFormGroup">
          <mat-form-field appearance="fill">
            <mat-label>{{"USER-NUTRITION.SURVEY_C.UNIT" | translate}}</mat-label>
            <mat-select formControlName="system" required (selectionChange)="changeSystemUnit()">
              <mat-option [value]="option.val" *ngFor="let option of system" >{{ option.label }} </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="bottoni">
            <button mat-button matStepperNext [disabled]="systemFormGroup.invalid">{{"USER-NUTRITION.SURVEY_C.NEXT" | translate}}</button>
          </div>
        </form>
      </mat-step>


      <mat-step [stepControl]="surveyFormGroup">
        <form [formGroup]="surveyFormGroup">

            <div class="flex flex-col sm:flex-row gap-2 sm:gap-6">
              <mat-form-field appearance="fill" class="flex-auto">
                <mat-label>{{"USER-NUTRITION.SURVEY_C."+unit+".WEIGHT" | translate}}</mat-label>
                <input matInput formControlName="peso" type="number">
              </mat-form-field>
              <mat-form-field appearance="fill" class="flex-auto">
                <mat-label>{{"USER-NUTRITION.SURVEY_C."+unit+".WAIST" | translate}}</mat-label>
                <input matInput formControlName="vita" type="number">
              </mat-form-field>
              <mat-form-field appearance="fill" class="flex-auto">
                <mat-label>{{"USER-NUTRITION.SURVEY_C."+unit+".FLANKS" | translate}}</mat-label>
                <input matInput formControlName="fianchi" type="number">
              </mat-form-field>
            </div>
            <div class="flex flex-col sm:flex-row gap-2 sm:gap-6" >
              <mat-form-field appearance="fill" class="flex-auto">
                <mat-label>{{"USER-NUTRITION.SURVEY_C."+unit+".THIGH_DX" | translate}}</mat-label>
                <input matInput formControlName="coscia_dx" type="number">
              </mat-form-field>
              <mat-form-field appearance="fill" class="flex-auto">
                <mat-label>{{"USER-NUTRITION.SURVEY_C."+unit+".THIGH_SX" | translate}}</mat-label>
                <input matInput formControlName="coscia_sx" type="number">
              </mat-form-field>
            </div>
            <div class="flex flex-col sm:flex-row gap-2 sm:gap-6">
              <mat-form-field appearance="fill" class="flex-auto">
                <mat-label>{{"USER-NUTRITION.SURVEY_C."+unit+".ARM_DX" | translate}}</mat-label>
                <input matInput formControlName="braccio_dx" type="number">
              </mat-form-field>
              <mat-form-field appearance="fill" class="flex-auto">
                <mat-label>{{"USER-NUTRITION.SURVEY_C."+unit+".ARM_SX" | translate}}</mat-label>
                <input matInput formControlName="braccio_sx" type="number">
              </mat-form-field>
            </div>

          <div class="bottoni">
            <button mat-button matStepperPrevious>{{"USER-NUTRITION.SURVEY_C.BACK" | translate}}</button>
            <button mat-button matStepperNext [disabled]="surveyFormGroup.invalid">{{"USER-NUTRITION.SURVEY_C.NEXT" | translate}}</button>
          </div>
        </form>
      </mat-step>

      <mat-step [stepControl]="surveyTwoFormGroup">
        <form [formGroup]="surveyTwoFormGroup">

              <mat-form-field appearance="fill" class="flex-auto">
                <mat-label>{{"USER-NUTRITION.SURVEY_C.LIKE" | translate}}</mat-label>
                <input matInput formControlName="like" type="text">
              </mat-form-field>
              <mat-form-field appearance="fill" class="flex-auto">
                <mat-label>{{"USER-NUTRITION.SURVEY_C.DISLIKE" | translate}}</mat-label>
                <input matInput formControlName="dislike" type="text">
              </mat-form-field>

            <div class="flex flex-col sm:flex-row gap-2 sm:gap-6">
              <mat-form-field appearance="fill" class="flex-auto">
                <mat-label>{{"USER-NUTRITION.SURVEY_C.NEW_FOOD" | translate}}</mat-label>
                <input matInput formControlName="would" type="text">
              </mat-form-field>
              <mat-form-field appearance="fill" class="flex-auto">
                <mat-label>{{"USER-NUTRITION.SURVEY_C.CONSIDERATION" | translate}}</mat-label>
                <input matInput formControlName="consideration" type="text">
              </mat-form-field>
            </div>
          <div class="bottoni">
            <button mat-button matStepperPrevious>{{"USER-NUTRITION.SURVEY_C.BACK" | translate}}</button>
            <button mat-button matStepperNext [disabled]="surveyTwoFormGroup.invalid">{{"USER-NUTRITION.SURVEY_C.NEXT" | translate}}</button>
          </div>
        </form>
      </mat-step>

      <mat-step [stepControl]="termsFormGroup">
        <form [formGroup]="termsFormGroup">
            <span>
              <div class="mt-4 flex flex-col gap-2">
                <mat-label>{{"USER-NUTRITION.SURVEY_C.TERMS" | translate}}</mat-label>
                <mat-checkbox class="checkbox" formControlName="termini" required></mat-checkbox>
              </div>
              <div class="mt-4 flex flex-col gap-2">
                <mat-label *ngIf="translate.currentLang == 'it'">
                  Dichiaro di avere almeno 18 anni (o l'età legale richiesta per stipulare un contratto vincolante nella mia giurisdizione, se tale età è superiore a 18 anni; o se ho meno di 18 anni, che ho ottenuto il consenso di un genitore o del tutore legale per partecipare al piano Diamond).

                  Dichiaro inoltre di non avere problemi di salute e di aver ottenuto il consenso dal mio medico o operatore sanitario per iniziare il programma nutrizionale/piano alimentare offerto dal piano Diamond.
                  </mat-label>
                <mat-label *ngIf="translate.currentLang == 'en'">
                  I certify that I am 18 years of age or older (or of legal age in my jurisdiction required to form a binding contract if that age is over 18 or if I am younger than 18 years of age, that I have obtained my parent or guardian’s consent to participate in the Diamond plan).

                  I also certify that I have no known health problems and that I have obtained consent from my doctor, physician or health care provider to begin the nutrition program/meal plan offered by the Diamond plan.
                  </mat-label>
                <mat-checkbox class="checkbox" formControlName="termini" required></mat-checkbox>
              </div>

            </span>
          <div class="bottoni">
            <button mat-button matStepperPrevious>{{"USER-NUTRITION.SURVEY_C.BACK" | translate}}</button>
            <button mat-button matStepperNext [disabled]="termsFormGroup.value.termini == false || disabledSend == true" (click)="submit()">{{"USER-NUTRITION.SURVEY_C.SEND" | translate}}</button>
          </div>
        </form>
      </mat-step>

    </mat-horizontal-stepper>
  </div>

</div>
