import { MatIconModule } from '@angular/material/icon';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { ProgramInfoComponent } from './program-info/program-info.component';
import { HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PostpartumPopupComponent } from './program-info/postpartum-popup/postpartum-popup.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader (http, '../../assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    ProgramInfoComponent,
    PostpartumPopupComponent
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    FontAwesomeModule,
    MatIconModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient],

      }
  })
  ]
})
export class DashboardModule { }
