<form [formGroup]="form">
  <mat-form-field>
    <mat-label>{{"USER_NEW.NAME" | translate}}</mat-label>
    <input matInput required formControlName="name"/>
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{"USER_NEW.LAST" | translate}}</mat-label>
    <input matInput required formControlName="lastName"/>
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{"USER_NEW.EMAIL" | translate}}</mat-label>
    <input matInput required formControlName="email"/>
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{"USER_NEW.BIRTHDATE" | translate}}</mat-label>
    <input type="date" matInput formControlName="birthdate" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{"USER_NEW.ROLE" | translate}}</mat-label>
    <mat-select matInput required formControlName="roles">
      <mat-option value="ADMIN">ADMIN</mat-option>
      <mat-option value="AMBASSADOR">AMBASSADOR</mat-option>
      <mat-option value="NUTRITIONIST">NUTRITIONIST</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field *ngIf="form.value.roles == 'AMBASSADOR'">
    <mat-label>{{"USER_NEW.TYPE" | translate}}</mat-label>
    <mat-select matInput formControlName="subscriptionType">
      <mat-option value="GOLD">GOLD</mat-option>
      <mat-option value="DIAMOND">DIAMOND</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{"USER_NEW.PSW" | translate}}</mat-label>
    <input matInput required formControlName="password"/>
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{"USER_NEW.PSW_CONFIRM" | translate}}</mat-label>
    <input matInput required formControlName="confirmPassword"/>
  </mat-form-field>
  <div class="btn-group">
    <button mat-button-base (click)="close()">{{"USER_NEW.CLOSE" | translate}}</button>
    <button mat-raised-button (click)="create()" class="m-4 bottone-viola" [disabled]="form.invalid">{{"USER_NEW.CREATE" | translate}}</button>
  </div>
</form>
