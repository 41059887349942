import { CommonModule } from '@angular/common';
import { Component, Inject, type OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { GuiService } from 'src/app/services/gui.service';
import { RestService } from 'src/app/services/rest.service';
import { CloseConfirmComponent } from '../../close-confirm/close-confirm.component';
import { CategorieComponent } from '../categorie.component';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'vex-new-category',
  templateUrl: './new-category.component.html',
  styleUrls: ['./new-category.component.scss'],
})
export class NewCategoryComponent implements OnInit {
  form: UntypedFormGroup;
  formLang: any;
  iconFormFlag: any;
  flags = {
    en: 'flag:united-states',
    it: 'flag:italy'
  };
  categories = [];
  allCategories = [];

  constructor(private fb: UntypedFormBuilder, private translate: TranslateService, public restService: RestService, public guiService: GuiService,private dialogRef: MatDialogRef<CategorieComponent>,private dialog: MatDialog,@Inject(MAT_DIALOG_DATA) private item: any['item']){

  }

  ngOnInit(): void {
    this.search()
    console.debug(this.item)
    this.changeFormLanguage(this.translate.currentLang);
    this.form = this.fb.group({
      nome_it:[null, Validators.required],
      nome_en:[null, Validators.required],
      desc_it: [null],
      desc_en: [null],
      parentId: [this.item ? this.item : null]
    })
  }

  changeFormLanguage(lang) {
    this.iconFormFlag = this.flags[lang];
    this.formLang = lang;
}

submit(){
  let category = {
    name: this.form.value.nome_it,
    description: this.form.value.desc_it,
    i18nDescription: {
      EN: this.form.value.desc_en,
      IT: this.form.value.desc_it
    },
    i18nName: {
      EN: this.form.value.nome_en,
      IT: this.form.value.nome_it
    },
    parentId: this.form.value.parentId
  }

  this.restService.postService('category', '?ALL_LANGUAGES=true', category).subscribe((ris)=>{
    this.guiService.openSnackBar(this.translate.instant('GLOBAL.NEW_SUCCESS'), "OK!")
    this.dialogRef.close('done')
  })
}

search() {
  this.restService.postService('category', '/search?ALL_LANGUAGES=true',{}).subscribe((ris) => {
    let tmp = []
    console.debug(ris)
    for (let item of ris.items) {
      if (!item.parentId) {
        tmp.push(item)
      }
    }
    this.categories = tmp
    this.allCategories = tmp;
    this.guiService.showSpinner = false;
  })
}

confirmClose(){
  this.dialog.open(CloseConfirmComponent,{
    disableClose: true,
    hasBackdrop: true,
    panelClass: 'close-dialog-modalbox'
  }).afterClosed().subscribe((ris)=>{
    if(ris == 'true'){
      this.dialogRef.close()
    }
  })
}

searchCat(){
  if(this.form.value.parentId && this.form.value.parentId.length > 3){
    this.categories = this.allCategories.filter(element => element.name.toLowerCase().includes(this.form.value.parentId.toLowerCase()))
  }else{
    this.categories = this.allCategories;
  }
}

displayFn(option: any): string {
  return option&& option.name ? option.name : '';
}

}
