<div class="h-full flex flex-col">
  <div class="p-6 pb-0 bg-foreground shadow-b flex-none border-b border-gray-200">
    <div class="container px-0">
      <h1 class="display-1 font-bold mt-0 mb-4 flex items-center">
        <span @scaleIn
          class="w-12 h-12 rounded-full text-primary ltr:mr-4 rtl:ml-4 flex items-center justify-center bg-primary/10">
          <mat-icon svgIcon="mat:playlist_play"></mat-icon>
        </span>
        <span @fadeInRight class="block">{{"EXERCISE-LIST.LIST" | translate}}</span>
      </h1>

      <div class="flex items-center filter-bar">
        <nav class="vex-tabs border-0 flex-auto cerca" mat-tab-nav-bar>

      <mat-form-field>
        <mat-label>{{'EXERCISE-LIST.NAME' | translate}}</mat-label>
        <input [formControl]="searchCtrlName" matInput/>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{'EXERCISE-LIST.SEARCH_MUSC' | translate}}</mat-label>
        <mat-select [formControl]="searchCtrlMuscle">
          <mat-option></mat-option>
          <mat-option [value]="option.key" *ngFor="let option of muscle">{{"EXERCISE-LIST."+option.key | translate}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{'EXERCISE-LIST.SEARCH_TYPE' | translate}}</mat-label>
        <mat-select [formControl]="searchCtrlType">
          <mat-option></mat-option>
          <mat-option [value]="option.key" *ngFor="let option of typs">{{"EXERCISE-LIST."+option.key | translate}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{'EXERCISE-LIST.SEARCH_LEVEL' | translate}}</mat-label>
        <mat-select [formControl]="searchCtrlLevel">
          <mat-option></mat-option>
          <mat-option [value]="option.key" *ngFor="let option of level">{{"EXERCISE-LIST."+option.key | translate}}</mat-option>
        </mat-select>
      </mat-form-field>
        </nav>

        <button  class="ltr:ml-1 rtl:mr-1 flex-none sm:hidden" color="primary" mat-mini-fab
          matTooltip="filtra" type="button">
          <mat-icon svgIcon="mat:search"></mat-icon>
        </button>

        <button class="flex-none hidden sm:inline-block bottone-rosa" color="primary" mat-raised-button
          type="button" (click)="resetPage()">

          <mat-icon svgIcon="mat:search"></mat-icon>
        </button>
        <button class="ml-4 flex-none hidden sm:inline-block bottone-rosa" color="primary" mat-raised-button
          type="button" (click)="reload()">

          <mat-icon svgIcon="mat:autorenew"></mat-icon>
        </button>

        <button (click)="createExercise()" class="ltr:ml-1 rtl:mr-1 flex-none sm:hidden" color="primary" mat-mini-fab
          matTooltip="Add Contact" type="button">
          <mat-icon svgIcon="mat:person_add"></mat-icon>
        </button>

        <button (click)="createExercise()" class="ltr:ml-4 rtl:mr-4 flex-none hidden sm:inline-block bottone-rosa" color="primary" mat-raised-button
          type="button">

          <mat-icon svgIcon="mat:add"></mat-icon>
        </button>
      </div>
    </div>
  </div>

</div>
<div>
  <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize" [length]="lunghezza" (page)="onPageChanged($event)" class="sticky left-0"></mat-paginator>
  <div class="p-gutter grid grid-cols-1 sm:grid-cols-3 md:grid-cols-5 gap-3 container" >
    <div *ngFor="let ex of exercises" class="p-4 text-center relative card carta-flexata exercise-card" matRipple >
      <img [src]="imgURL+'/'+ex.id+'/images/main/'" class="mx-auto exercise-img">

      <h2 class="title mb-1 mt-3">{{ex.name}}</h2>
      <!--div class="body-2 text-secondary flex items-left justify-start">
        <p> {{ex.description}}</p>
      </div-->

      <div class="exercise-divider">
      </div>

      <div class="body-2 text-secondary items-center justify-center exercise-type">
        <small class="">{{"EXERCISE-LIST."+ex.difficulty | translate}}</small>
        <small class="">{{"EXERCISE-LIST."+ex.type | translate}}</small>
      </div>

      <div class="bg-app-bar p-2 flex items-center justify-around barra-rosa mt-2">
        <button class="text-white" mat-icon-button type="button" (click)="deleteExercise(ex.id)">
          <mat-icon svgIcon="mat:delete"></mat-icon>
        </button>

        <button class="text-white" mat-icon-button type="button" (click)="editExercise(ex)">
          <mat-icon svgIcon="mat:edit"></mat-icon>
        </button>

      </div>
    </div>


  </div>
</div>

