import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'vex-payment-rejected',
  templateUrl: './payment-rejected.component.html',
  styleUrls: ['./payment-rejected.component.scss']
})
export class PaymentRejectedComponent implements OnInit {

  constructor(public router: Router) { }

  ngOnInit(): void {
  }

  goToLogin(){
    this.router.navigate(['auth/login']);
  }

}
