import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'vex-tmp-upgrade-paymentmethod',
  templateUrl: './tmp-upgrade-paymentmethod.component.html',
  styleUrls: ['./tmp-upgrade-paymentmethod.component.scss']
})
export class TmpUpgradePaymentmethodComponent implements OnInit {

  constructor(public translate: TranslateService, private restService: RestService) { }

  ngOnInit(): void {
  }
  upgradeInfos(){
    /* ANDRA' INSERITA L'API PER L'AGGIORNAMENTO DEL METODO DI PAGAMENTO PER GLI UTENTI PAYPAL */

    this.restService.getService('chargebee','/manage_payment_sources').subscribe((res)=>{
      window.open(res.chargeBeePortalSession,'_blank')
    })
  }

}
