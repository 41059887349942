import { AcceptChallengeDialogComponent } from './accept-challenge-dialog/accept-challenge-dialog.component';
import { RestService } from 'src/app/services/rest.service';
import { GuiService } from 'src/app/services/gui.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'vex-user-challenge',
  templateUrl: './user-challenge.component.html',
  styleUrls: ['./user-challenge.component.scss']
})
export class UserChallengeComponent implements OnInit {
  challSelected = []
  challenges: any;
  index: number
  showButtonAccept = true;
  colors = ['#BED3FF','#8444FC','#FE0B81','#FE4AA1','#FE8CC3']
  @ViewChild('myVideo') videoplayer: ElementRef;
  VIDEO_ENV = environment.baseURL.video;

  constructor(public guiService: GuiService, public restService: RestService, private dialog: MatDialog) { }


  ngOnInit(): void {
    setTimeout(()=>this.guiService.showSpinner = true,0)
    this.getChallenges()
  }

  getChallenges(){
    let param = {
      "filterAnd": [
        {
          "field": "notificationEnable",
          "operator": "eq",
          "value": false
        }
      ],
      orders: [
        {
          field: "publishDate",
          ascending : true
        }
      ],
      "page": 0,
      "size": 20
    }

    this.restService.postService('challenges', '/search', param).subscribe((ris)=>{
      this.challenges = ris.items;
      this.guiService.showSpinner = false;
    })
  }

  openChallenge(challenge, index){
    this.challSelected = []
    this.index = index
    this.challSelected.push(challenge)
    console.debug(this.challSelected)
  }

  modalAcceptChallenge(chall){
    let challenges = localStorage.getItem('challAccepted') != '' && localStorage.getItem('challAccepted') ? JSON.parse(localStorage.getItem('challAccepted')) : []
    if(challenges.some(i => i.id.includes(chall.id)) != true){
      this.showButtonAccept = true;
    this.dialog.open(AcceptChallengeDialogComponent,{
      disableClose: true,
      hasBackdrop: true,
      panelClass: 'change-program-dialog'
    }).afterClosed().subscribe((challenge) => {
      if(challenge == 'true'){
        this.getChallenges()
      }else{
        this.showButtonAccept = false;
        challenges.push(chall)
        let challengesStrings = JSON.stringify(challenges)
        localStorage.setItem('challAccepted',challengesStrings)
        this.guiService.openSnackBar('sfida '+chall.name+' accettata!')
        this.playVideo()
      }
    })
    }else{
      this.showButtonAccept = false;
    }
  }

  evaluateExpire(chall){
    let challDate = moment(chall.expirationDate)
    let now = moment()
    return moment(challDate).isBefore(now)
  }

  evaluatePublish(chall){
    let challPublishDate = moment(chall.publishDate)
    let now = moment()
    return now.isSameOrAfter(challPublishDate)
  }

  evaluateBgColor(index){
    let x = index%5;
    return this.colors[x]
  }

  playVideo(){
    this.videoplayer.nativeElement.play();
  }

}
