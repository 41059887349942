<div class="container">
  <form [formGroup]="form">
    <div class="card flex-auto">
      <div class="px-6 py-4 border-b flex items-center header-title">
        <h2 class="title m-0">{{"NEW-WORKOUT-PROG.NEW" | translate}}</h2>
        <div class="flex-end">
          <button [matMenuTriggerFor]="languageFormMenu" mat-icon-button type="button" align="end">
            <mat-icon svgIcon="{{iconFormFlag}}"></mat-icon>
          </button>
          <mat-menu #languageFormMenu="matMenu" overlapTrigger="false" >
            <button mat-menu-item (click)="changeFormLanguage('it')">
              <mat-icon svgIcon="flag:italy"></mat-icon>
              <span>Italiano</span>
            </button>
            <button mat-menu-item (click)="changeFormLanguage('en')">
              <mat-icon svgIcon="flag:united-states"></mat-icon>
              <span>English</span>
            </button>
          </mat-menu>
        </div>
      </div>


      <div class="px-6 py-4 flex flex-col">
        <div class="card px-6 py-4 flex flex-col subCards" *ngIf="formLang=='it'">
          <div class="flex flex-col sm:flex-row sm:gap-4">
            <mat-form-field class="flex-auto">
              <mat-label>{{"NEW-WORKOUT-PROG.NAME" | translate}} {{"GLOBAL.ITA" | translate}}</mat-label>
              <input formControlName="nome_it" matInput />
            </mat-form-field>
            <mat-form-field class="flex-auto bozza">
              <mat-label>{{"NEW-WORKOUT-PROG.PUBLISH" | translate}}</mat-label>
              <mat-select formControlName="bozza">
                <mat-option value="bozza">{{"NEW-WORKOUT-PROG.DRAFT" | translate}}</mat-option>
                <mat-option value="pubblica">{{"NEW-WORKOUT-PROG.PUBLISH" | translate}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="flex flex-col sm:flex-row sm:gap-4">
            <mat-form-field class="flex-auto">
            <mat-label>{{"NEW-WORKOUT-PROG.EQUIP" | translate}}</mat-label>
            <mat-select formControlName="attrezzatura" multiple>
              <mat-option [value]="option.id" *ngFor="let option of attrezzatura">{{ option.name }}</mat-option>
            </mat-select>
          </mat-form-field>
          </div>

          <div class="flex flex-col sm:flex-row sm:gap-4">

            <mat-form-field class="flex-auto">
              <mat-label>{{"NEW-WORKOUT-PROG.WEEKS" | translate}}</mat-label>
              <input formControlName="week_n" matInput type="number">

            </mat-form-field>

            <mat-form-field class="flex-auto">
              <mat-label>{{"NEW-WORKOUT-PROG.W_PERWEEK" | translate}}</mat-label>
              <input  matInput type="number" formControlName="workout_settimanali">

            </mat-form-field>
            <mat-form-field class="flex-auto">
              <mat-label>{{"NEW-WORKOUT-PROG.W_DURATION" | translate}}</mat-label>
              <input  matInput type="number" formControlName="workout_durata">
            </mat-form-field>
          </div>



          <mat-form-field class="description">
            <mat-label>{{"NEW-WORKOUT-PROG.DESC" | translate}} {{"GLOBAL.ITA" | translate}}</mat-label>
            <textarea cols="30" rows="8" matInput formControlName="desc_it"></textarea>
          </mat-form-field>

        </div>

        <div class="card px-6 py-4 flex flex-col subCards" *ngIf="formLang=='en'">
          <div class="flex flex-col sm:flex-row sm:gap-4">
            <mat-form-field class="flex-auto">
              <mat-label>{{"NEW-WORKOUT-PROG.NAME" | translate}} {{"GLOBAL.ENG" | translate}}</mat-label>
              <input formControlName="nome_en" matInput />
            </mat-form-field>
            <mat-form-field class="flex-auto bozza">
              <mat-label>{{"NEW-WORKOUT-PROG.PUBLISH" | translate}}</mat-label>
              <mat-select formControlName="bozza">
                <mat-option value="bozza">{{"NEW-WORKOUT-PROG.DRAFT" | translate}}</mat-option>
                <mat-option value="pubblica">{{"NEW-WORKOUT-PROG.PUBLISH" | translate}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="flex flex-col sm:flex-row sm:gap-4">
            <mat-form-field class="flex-auto">
            <mat-label>{{"NEW-WORKOUT-PROG.EQUIP" | translate}}</mat-label>
            <mat-select formControlName="attrezzatura" multiple>
              <mat-option [value]="option.id" *ngFor="let option of attrezzatura">{{ option.name }}</mat-option>
            </mat-select>
          </mat-form-field>
          </div>

          <div class="flex flex-col sm:flex-row sm:gap-4">

            <mat-form-field class="flex-auto">
              <mat-label>{{"NEW-WORKOUT-PROG.WEEKS" | translate}}</mat-label>
              <input formControlName="week_n" matInput type="number">

            </mat-form-field>

            <mat-form-field class="flex-auto">
              <mat-label>{{"NEW-WORKOUT-PROG.W_PERWEEK" | translate}}</mat-label>
              <input  matInput type="number" formControlName="workout_settimanali">

            </mat-form-field>
            <mat-form-field class="flex-auto">
              <mat-label>{{"NEW-WORKOUT-PROG.W_DURATION" | translate}}</mat-label>
              <input  matInput type="number" formControlName="workout_durata">
            </mat-form-field>
          </div>



          <mat-form-field class="description">
            <mat-label>{{"NEW-WORKOUT-PROG.DESC" | translate}} {{"GLOBAL.ENG" | translate}}</mat-label>
            <textarea cols="30" rows="8" matInput formControlName="desc_en"></textarea>
          </mat-form-field>

        </div>
        <div class="mt-4 mb-4">
          <h3 class="title mb-3">Upload</h3>
          <input type="file" (change)="onFileSelected($event)" formControlName="file">
        </div>


        <div class="bottoni">
          <button (click)="confirmClose()" mat-button-base class="m-4">{{"GLOBAL.CLOSE" | translate}}</button>
          <button mat-raised-button [disabled]="form.invalid" (click)="submit(form)" class="m-4 bottone-viola">
            {{"GLOBAL.CREATE" | translate}}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
