import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'vex-tmp-msg-contact',
  templateUrl: './tmp-msg-contact.component.html',
  styleUrls: ['./tmp-msg-contact.component.scss']
})
export class TmpMsgContactComponent implements OnInit {

  constructor(public translate: TranslateService) { }

  ngOnInit(): void {
  }

}
