<div class="container mt-5e">
    <div class="notifications-list" >
      <span *ngFor="let chall of challenges; let i = index">
        <div class="notification"  *ngIf="evaluateExpire(chall) == false && evaluatePublish(chall) == true && chall.notificationEnable == false">
          <div class="top-notification">
            <h1>{{"CHALLENGES.CHALL_N" | translate}} {{i+1}}</h1>
            <span class="label icon">
              <h2 (click)="openChallenge(chall, i)" (click)="modalAcceptChallenge(chall)">{{chall.name}}</h2>
            </span>
          </div>
        </div>
      </span>
    </div>
    <div class="read-notification" *ngIf="challSelected.length > 0">
      <h2>{{"CHALLENGES.LIMIT" | translate}}</h2>
      <h3>{{"CHALLENGES.CHALL_N" | translate}} {{index+1}}</h3>
      <h1>{{challSelected[0].name}}</h1>
      <video [src]="VIDEO_ENV+'/'+challSelected[0].name.replaceAll(' ', '_')+'.mp4'" controls #myVideo></video>
      <button (click)="modalAcceptChallenge(challSelected[0])" *ngIf="showButtonAccept">{{"ACCEPT_CHALLENGE.ACCEPT" | translate}}</button>
    </div>
    <div class="icona read-notification" *ngIf="challSelected.length == 0">
      <mat-icon svgIcon="mat:slow_motion_video"></mat-icon>
    </div>
  </div>

