<div class="card mt-6" *ngIf="user">
  <div class="px-gutter py-4 border-b">
    <h2 class="title m-0">{{"PROFILE_MENU.INFO" | translate}}</h2>
  </div>
  <div @stagger class="card mt-2" >

    <div class="px-gutter py-4 grid grid-cols-2 quest">
      <div class="m-0 body-1">{{"USER-EDIT.SUB_STATUS" | translate}}</div>
      <span *ngIf="user.userPaymentInfo && user.userPaymentInfo.subscriptionStatus === 'ACTIVE'">
        <span *ngIf="user.userPaymentInfo.subscriptionExpirationPendingStatus != null && user.userPaymentInfo.subscriptionExpirationPendingStatus != 'PAUSED'">
          <fa-icon [icon]="icon.faCircle" class="icon payed"></fa-icon> {{"USER-EDIT.ACTIVE" | translate}} <br>
          <span *ngIf="user.userPaymentInfo.subscriptionIdRequest == null">{{"USER-EDIT.REQ_SUSP" | translate}}</span>
          <button class="btn-pay" (click)="chargeBeePortal()" *ngIf="user.userPaymentInfo.paymentType == 'CHARGEBEE'">{{"PROFILE_MENU.MANAGE" | translate}}</button>
        </span>
        <span *ngIf="user.userPaymentInfo.subscriptionExpirationPendingStatus == 'PAUSED' || user.userPaymentInfo.subscriptionStatusRequest == 'PAUSED'">
          <fa-icon [icon]="icon.faCircle" class="icon payed"></fa-icon> {{"USER-EDIT.ACTIVE" | translate}} <br>
          {{"USER-EDIT.REQ_PAUSE" | translate}} {{"USER-EDIT."+user.userPaymentInfo.subscriptionSuspensionTypeRequest | translate}}
          <button (click)="changeSubStatus('ACTIVE')" *ngIf="user.userPaymentInfo.paymentType != 'CHARGEBEE'">{{"USER-EDIT.SET_ACTIVE" | translate}}</button>
          <button class="btn-pay" (click)="chargeBeePortal()" *ngIf="user.userPaymentInfo.paymentType == 'CHARGEBEE'">{{"PROFILE_MENU.MANAGE" | translate}}</button>
        </span>
        <span *ngIf="user.userPaymentInfo.subscriptionExpirationPendingStatus == null && user.userPaymentInfo.subscriptionStatusRequest == null">
          <fa-icon [icon]="icon.faCircle" class="icon payed"></fa-icon> {{"USER-EDIT.ACTIVE" | translate}}
          <button (click)="changeSubStatus('PAUSED')" *ngIf="user.userPaymentInfo.paymentType != 'CHARGEBEE'">{{"USER-EDIT.SET_PAUSED" | translate}}</button>
          <button class="btn-pay" (click)="chargeBeePortal()" *ngIf="user.userPaymentInfo.paymentType == 'CHARGEBEE'">{{"PROFILE_MENU.MANAGE" | translate}}</button>
        </span>
      </span>
      <span *ngIf="user.userPaymentInfo && user.userPaymentInfo.subscriptionStatus === 'SUSPENDED' || user.userPaymentInfo && user.userPaymentInfo.subscriptionStatus === 'CUST_CANCELED' || user.userPaymentInfo && user.userPaymentInfo.subscriptionStatus === 'EXPIRED' || user.userPaymentInfo && user.userPaymentInfo.subscriptionStatus == null || user.userPaymentInfo == null">
        <fa-icon [icon]="icon.faCircle" class="icon not-payed"></fa-icon> {{"USER-EDIT.SUSPENDED" | translate}}
        <button class="btn-pay" (click)="chargeBeePortal()" *ngIf="user.userPaymentInfo.paymentType == 'CHARGEBEE'">{{"PROFILE_MENU.MANAGE" | translate}}</button>
      </span>
      <span *ngIf="user.userPaymentInfo && user.userPaymentInfo.subscriptionStatus === 'PAUSED'">
        <fa-icon [icon]="icon.faCircle" class="icon paused"></fa-icon> {{"USER-EDIT.PAUSED" | translate}}
        <button (click)="changeSubStatus('ACTIVE')" *ngIf="user.userPaymentInfo.paymentType != 'CHARGEBEE'">{{"USER-EDIT.SET_ACTIVE" | translate}}</button>
        <button class="btn-pay" (click)="chargeBeePortal()" *ngIf="user.userPaymentInfo.paymentType == 'CHARGEBEE'">{{"PROFILE_MENU.MANAGE" | translate}}</button>
      </span>

    </div>

    <div class="px-gutter py-4 grid grid-cols-2 quest">
        <div class="m-0 body-1">{{"USER-EDIT.PAY_STATUS" | translate}}</div>
        <div>
          <span *ngIf="user.userPaymentInfo && user.userPaymentInfo.subscriptionStatus == 'ACTIVE'">
            <fa-icon [icon]="icon.faCheck" class="icon survey is-survey"></fa-icon>
            {{"PROFILE_MENU.PAYED" | translate}}
            {{user.userPaymentInfo.subscriptionLastActivation | date: 'shortDate'}}
            <button (click)="managePayment()" class="btn-pay" *ngIf="user.userPaymentInfo.paymentType == 'PAYPAL'">{{"PROFILE_MENU.CHANGE_CARD" | translate}}</button>
          </span>
          <span *ngIf="user.userPaymentInfo && user.userPaymentInfo.subscriptionStatus == 'SUSPENDED' || user.userPaymentInfo && user.userPaymentInfo.subscriptionStatus === 'CUST_CANCELED' || user.userPaymentInfo && user.userPaymentInfo.subscriptionStatus === 'EXPIRED'">
            <fa-icon [icon]="icon.faCheck" class="icon survey not-survey"></fa-icon>
            {{"PROFILE_MENU.NOT_PAYED" | translate}}
            <button (click)="managePayment()" class="btn-pay" *ngIf="user.userPaymentInfo.paymentType == 'PAYPAL'">{{"PROFILE_MENU.CHANGE_CARD" | translate}}</button>
          </span>
          <span *ngIf="user.userPaymentInfo && user.userPaymentInfo.subscriptionStatus == null || user.userPaymentInfo == null">
            <fa-icon [icon]="icon.faCheck" class="icon survey not-survey"></fa-icon>
            {{"PROFILE_MENU.NOT_PAYED" | translate}}
             <button (click)="pay()" class="btn-pay" *ngIf="user.userPaymentInfo.paymentType != 'CHARGEBEE'">{{"PROFILE_MENU.PAY" | translate}}</button>
            <button (click)="payChargeBee()" class="btn-pay" *ngIf="user.userPaymentInfo.paymentType == 'CHARGEBEE'">{{"PROFILE_MENU.PAY" | translate}}</button>
          </span>

          <!-- <span *ngIf="user.user.subscriptionPaymentStatus === true">{{user | date: 'shortDate'}}</span> -->
        </div>
    </div>

    <div class="px-gutter py-4 grid grid-cols-2 quest" >
        <div class="m-0 body-1 mr-3">{{"USER-EDIT.SUB_TYPE" | translate}}</div>
        <span *ngIf=" user.userPaymentInfo && user.userPaymentInfo.subscriptionId == null || user.userPaymentInfo == null">N/A</span>
        <span *ngIf=" user.userPaymentInfo && user.userPaymentInfo.subscriptionId != null">
          <span *ngIf="user.userPaymentInfo.subscriptionId && subscriptions[user.userPaymentInfo.subscriptionId]">{{subscriptions[user.userPaymentInfo.subscriptionId].name}} {{"USER-EDIT.REQ_" +subscriptions[user.userPaymentInfo.subscriptionId].duration | translate}}</span>
            <button class="btn-pay" (click)="upgrade()" *ngIf="user.userPaymentInfo.paymentType == 'CHARGEBEE' && user.userPaymentInfo.subscriptionIdRequest == null">{{"USER-EDIT.CHANGE_PLAN" | translate}} <mat-icon svgIcon="mat:open_in_new"></mat-icon></button>
            <button class="btn-pay disabled" *ngIf="user.userPaymentInfo.paymentType == 'CHARGEBEE' && user.userPaymentInfo.subscriptionIdRequest != null">{{"USER-EDIT.CHANGE_PLAN" | translate}} <mat-icon svgIcon="mat:open_in_new"></mat-icon></button>
            <span *ngIf="user.userPaymentInfo.subscriptionIdRequest != null && subscriptions[user.userPaymentInfo.subscriptionIdRequest]"> <br>{{"USER-EDIT.REG_GOLD" | translate}} {{subscriptions[user.userPaymentInfo.subscriptionIdRequest].name}} {{"USER-EDIT.REQ_" +subscriptions[user.userPaymentInfo.subscriptionIdRequest].duration | translate}}</span>
        </span>
    </div>

    <div class="px-gutter py-4 grid grid-cols-2 quest" >
        <div class="m-0 body-1">{{"USER-EDIT.SUB_EXP" | translate}}</div>
        <span *ngIf="user.userPaymentInfo && user.userPaymentInfo.subscriptionExpiration == null || user.userPaymentInfo == null">N/A</span>
        <span *ngIf="user.userPaymentInfo && user.userPaymentInfo.subscriptionExpiration != null">{{user.userPaymentInfo.subscriptionExpiration | date: 'shortDate'}}</span>
    </div>


  <div *ngIf="user.userPaymentInfo && user.userPaymentInfo.subscriptionType == 'DIAMOND'">
    <div class="px-gutter py-4 grid grid-cols-2 quest" >
      <div class="m-0 mr-4 body-1">{{"USER-EDIT.SURVEY" | translate}}</div>
      <div>
        <span *ngIf="user.masterData.lastSurvey != null && mnt().diff(mnt(user.masterData.lastSurvey), 'days') <= 30"><fa-icon [icon]="icon.faCheck" class="icon survey is-survey"></fa-icon> {{"PROFILE_MENU.SURVEY" | translate}}</span>
        <span *ngIf="user.masterData.lastSurvey == null || mnt().diff(mnt(user.masterData.lastSurvey), 'days') > 30"><fa-icon [icon]="icon.faCheck" class="icon survey not-survey"></fa-icon> {{"PROFILE_MENU.NOT_SURVEY" | translate}}</span>
        <span *ngIf="user.masterData.lastSurvey != null && mnt().diff(mnt(user.masterData.lastSurvey), 'days') <= 30" class="ml-3">{{user.masterData.lastSurvey | date: 'shortDate'}}</span>
      </div>
    </div>

    <div class="px-gutter py-4 grid grid-cols-2 quest" >
      <div class="m-0 body-1">{{"USER-EDIT.DIET" | translate}}</div>
      <div>
        <span *ngIf="user.masterData.lastDietUploaded != null && mnt().diff(mnt(user.masterData.lastDietUploaded), 'days') <= 30"><fa-icon [icon]="icon.faCheck" class="icon survey is-survey"></fa-icon> {{"PROFILE_MENU.DIET" | translate}}</span>
        <span *ngIf="user.masterData.lastDietUploaded == null || mnt().diff(mnt(user.masterData.lastDietUploaded), 'days') > 30"><fa-icon [icon]="icon.faCheck" class="icon survey not-survey"></fa-icon> {{"PROFILE_MENU.NOT_DIET" | translate}}</span>
        <span *ngIf="user.masterData.lastDietUploaded != null && mnt().diff(mnt(user.masterData.lastDietUploaded), 'days') <= 30" class="ml-3">{{user.masterData.lastDietUploaded | date: 'shortDate'}}</span>
      </div>
    </div>
  </div>
  </div>
</div>
