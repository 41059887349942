import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WellnessService {
  videoSelected: any;
  categoryName: string;
  macroAreaBack: any;

  constructor() { }

}
