import { RestService } from './../../../services/rest.service';
import { formatDate } from '@angular/common';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { GuiService } from 'src/app/services/gui.service';
import { Component, OnInit } from '@angular/core';
import { toDate } from 'date-fns';
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { scaleIn400ms } from 'src/@vex/animations/scale-in.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { faBirthdayCake } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'vex-admin-profile',
  templateUrl: './admin-profile.component.html',
  styleUrls: ['./admin-profile.component.scss'],
  animations: [
    fadeInUp400ms,
    fadeInRight400ms,
    scaleIn400ms,
    stagger40ms
  ]
})
export class AdminProfileComponent implements OnInit {
  admin: any
  form: UntypedFormGroup;
  icon = {
    "faBirthday": faBirthdayCake
  }

  constructor(public guiService: GuiService, public translate: TranslateService, private fb: UntypedFormBuilder, public restService: RestService) { }

  ngOnInit(): void {
    setTimeout(()=>this.guiService.showSpinner = false,0)
    this.admin = this.guiService.userLogged
    console.debug(this.admin)

    this.form = this.fb.group({
      phonenumber:[null],
      registration: [null],
      email: [null, Validators.required],
      birthday: [null, Validators.required]
    })
    this.form.controls['phonenumber'].setValue(this.admin.masterData.phone);
    this.form.controls['registration'].setValue('');
    this.form.controls['email'].setValue(this.admin.masterData.email);
    this.form.controls['birthday'].setValue(formatDate(this.admin.masterData.birthDate,'yyyy-MM-dd','en'));
  }

  submit(){
    let edit = {
      email: this.form.value.email,
      birthDate: this.form.value.birthday,
      phone: this.form.value.phonenumber
    }
    console.debug(edit)
    this.restService.putService('users', '/'+this.admin.user.id, edit).subscribe((ris)=>{
      this.guiService.openSnackBar(this.translate.instant('GLOBAL.EDIT_SUCCESS'), 'ok!')
    })
  }

}
