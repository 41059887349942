<div class="contenitore-generale bg-app-bar">
  <div class="title">
    <h1 *ngIf="translate.currentLang == 'it'">Avviso</h1>
    <h1 *ngIf="translate.currentLang == 'en'">Notice</h1>
  </div>
  <div class="container">
    <p *ngIf="translate.currentLang == 'it'">Abbiamo aggiornato il nostro sistema di sicurezza. Per continuare ad allenarti, clicca nel bottone sottostante per confermare i tuoi dati di pagamento.</p>
    <p *ngIf="translate.currentLang == 'en'">We updated our security system. Please click below and confirm your method of payment to continue your fitness journey!</p>

    <div class="bottoni">
      <button mat-dialog-close *ngIf="translate.currentLang == 'it'" (click)="upgradeInfos()">CONFERMA</button>
      <button mat-dialog-close *ngIf="translate.currentLang == 'en'" (click)="upgradeInfos()">CONFIRM</button>
    </div>
  </div>
</div>
