import { PostpartumPopupComponent } from './postpartum-popup/postpartum-popup.component';
import { GuiService } from './../../../services/gui.service';
import { RestService } from './../../../services/rest.service';
import { DashboardComponent } from './../dashboard.component';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faCalendarWeek } from '@fortawesome/free-solid-svg-icons';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'vex-program-info',
  templateUrl: './program-info.component.html',
  styleUrls: ['./program-info.component.scss']
})
export class ProgramInfoComponent implements OnInit {
  icon = {
    "faCalendar": faCalendarWeek,

  }
  program
  filteredIds = {}
  environment = environment.baseURL.workout_programs

  constructor(private dialogRef: MatDialogRef<DashboardComponent>,
    @Inject(MAT_DIALOG_DATA) private item: any['item'],
    public restService: RestService,
    public guiService: GuiService,
    private rotta: Router,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    console.debug(this.item)
    this.program = this.item

    this.loadEquipmentNames(this.program.equipmentIds)
    this.guiService.getEquipImgs(this.program.equipmentIds)
    console.debug(this.filteredIds)
  }

  loadEquipmentNames(baseIndex) {

    this.restService.postService('equipments', '', {}).subscribe((ris)=>{
      for(let eqId of baseIndex){
        console.debug(eqId)
        this.filteredIds[eqId]= ris.items.find(x => x.id === eqId).name
      }
      this.guiService.showSpinner = false
    })
}

closeDialog(){
  this.dialogRef.close()
}

evaluateSelectedProgram(program){
  if(program.name === 'Stronger postpartum'){
    this.dialog.open(PostpartumPopupComponent,{
      disableClose: true,
      hasBackdrop: true,
      panelClass: 'postpartum-popup'
    }).afterClosed().subscribe((resp)=>{
      if(resp != 'true'){
        this.selectProgram(program)
      }else{
        this.dialogRef.close()
      }
    })
  }else if(program.name !== 'Stronger postpartum'){
    this.selectProgram(program)
  }
}

selectProgram(program){
  /*METODO PROVVISORIO LOCAL STORAGE */
  let newProgram = {
    day: 0,
    week: 0,
    workoutProgramId: program.id,
    exerciseId: "string",
    id: "string"
  }
  this.restService.postService('user_workout_programs','', newProgram).subscribe((ris)=>{
    console.debug(this.guiService.userLogged.lastWorkoutProgram)
  sessionStorage.setItem("selectedProgram", program.name)
  sessionStorage.setItem("selectedProgramId", program.id)
  this.guiService.selectedProgram = program.name
  console.debug(this.guiService.selectedProgram)
  this.guiService.displaySelectInToolbar = true
  this.rotta.navigate(['/main/home'])
  this.closeDialog()
  })

}

}
