import { GuiService } from 'src/app/services/gui.service';
import { RestService } from 'src/app/services/rest.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NgIf } from '@angular/common';
import {ImageResize} from "../../../class/image-resize";
declare var $: any;

@Component({
  selector: 'vex-workout-info-dialog',
  templateUrl: './workout-info-dialog.component.html',
  styleUrls: ['./workout-info-dialog.component.scss']
})
export class WorkoutInfoDialogComponent implements OnInit {
  exercise:any;
  alternative:any;
  imgURL: string;

  constructor(@Inject(MAT_DIALOG_DATA) private item: any['item'],public restService: RestService, public guiService: GuiService) { }

  ngOnInit(): void {
    this.imgURL = environment.baseURL.exercises;
    if(this.item.exerciseAlternativeId != null){
      this.restService.getService('exercises', this.item.exerciseAlternativeId).subscribe((ris)=>{
        this.alternative = ris
        console.debug(ris)
      })
    }
    this.item.muscleGroups = this.item.muscleGroups.sort()
    this.exercise = this.item
    console.debug(this.item)
    if(this.item.equipmentIds){
      this.guiService.loadEquipmentNames(this.item.equipmentIds)
      this.guiService.getEquipImgs(this.item.equipmentIds)
    }


    const resizeImg = new ImageResize({
      width: 857,
      height: 906,
      element : '#sagoma'
    });

    let ref = this;
    setTimeout(function () {
      ref.muscle();
    }, 500)
  }

  muscle(){
    $('.map').maphilight({
      fade: false,
      fillColor: "db3687",
      fillOpacity: '1',
      strokeColor: '6826D0'
  });
    let muscleGroup = this.item.muscleGroups;
    for (var k in muscleGroup) {
      $('.'+muscleGroup[k]).mouseover();
    }
  }

}
