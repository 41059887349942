import { TranslateService } from '@ngx-translate/core';
import { RestService } from './../../../services/rest.service';
import { GuiService } from 'src/app/services/gui.service';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { scaleIn400ms } from 'src/@vex/animations/scale-in.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';

export function ConfirmPasswordValidator(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    let control = formGroup.controls[controlName];
    let matchingControl = formGroup.controls[matchingControlName]
    if (
      matchingControl.errors &&
      !matchingControl.errors.confirmPasswordValidator
    ) {
      return;
    }
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ confirmPasswordValidator: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}

@Component({
  selector: 'vex-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  animations: [
    fadeInUp400ms,
    fadeInRight400ms,
    scaleIn400ms
  ]
})

export class ResetPasswordComponent implements OnInit {
  form: FormGroup
  visible = false;
  inputType = 'password';

  constructor(private fb: FormBuilder, public guiService: GuiService, public restService: RestService,  private cd: ChangeDetectorRef, public translate: TranslateService) { }

  ngOnInit(): void {

    this.form = this.fb.group({
      password:['', [Validators.required]],
      confirmPassword: ['', [Validators.required]]
    },{
      validator: ConfirmPasswordValidator("password", "confirmPassword")
    })
  }


  send(){
    let editPassword = {
      password: this.form.value.password
    }
    this.restService.putService('users','', editPassword).subscribe((ris)=>{
      this.guiService.openSnackBar(this.translate.instant('GLOBAL.EDIT_SUCCESS'), 'ok!')
      })
  }

  toggleVisibility() {
    if (this.visible) {
      this.inputType = 'password';
      this.visible = false;
      this.cd.markForCheck();
    } else {
      this.inputType = 'text';
      this.visible = true;
      this.cd.markForCheck();
    }
  }

}
