<div [class.container]="isVerticalLayout$ | async" class="toolbar w-full px-gutter flex items-center">
  <button (click)="openSidenav()" [class.hidden]="!mobileQuery" mat-icon-button type="button">
    <mat-icon svgIcon="mat:menu"></mat-icon>
  </button>

  <a *ngIf="isVerticalLayout$ | async"
     [routerLink]="['/']"
     class="ltr:mr-4 rtl:ml-4 block flex items-center">
    <img alt="Logo" class="w-8 select-none logo-white" src="../../../assets/img/demo/logo-white.png"/>
    <h1 [class.hidden]="!mobileQuery" class="title ltr:pl-4 rtl:pr-4 m-0 select-none"></h1>
  </a>




  <div *ngIf="(isVerticalLayout$ | async) && isNavbarInToolbar$ | async"
       [class.hidden]="mobileQuery"
       class="px-gutter flex-none flex items-center">
    <vex-navigation-item *ngFor="let item of navigationItems" [item]="item">{{item.label | translate}}</vex-navigation-item>
  </div>

  <div *ngIf="GuiService.userLogged && GuiService.userLogged.user.roles[0] == 'USER'" class="w-full text-center scelta">
    <form [formGroup]="form" *ngIf="programs">
      <mat-form-field appearance="fill" id="homeMat">
        <mat-select formControlName="programControl" (selectionChange)="changeProgram()">
          <mat-option *ngFor="let program of programs" [value]="program.id">
            {{program.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      </form>
  </div>

  <span class="flex-1"></span>

  <div class="-mx-1 flex items-center">


    <div class="px-1">
      <vex-toolbar-notifications></vex-toolbar-notifications>
      <span class="badge badge-warning" id="lblNoticationCount" *ngIf="notifications && notifications.length != 0">{{notifications.length}}</span>
    </div>

    <div class="px-1">
      <button [matMenuTriggerFor]="languageMenu" mat-icon-button type="button">
        <mat-icon svgIcon="{{changeFlag}}"></mat-icon>
      </button>
    </div>

    <div *ngIf="userVisible$ | async" class="px-1">
      <vex-toolbar-user></vex-toolbar-user>
    </div>

    <mat-menu #languageMenu="matMenu" overlapTrigger="false" xPosition="before" yPosition="below" >
      <button mat-menu-item (click)="changeLanguage('it')">
        <mat-icon svgIcon="flag:italy"></mat-icon>
        <span>Italiano</span>
      </button>
      <button mat-menu-item (click)="changeLanguage('en')">
        <mat-icon svgIcon="flag:united-states"></mat-icon>
        <span>English</span>
      </button>

    </mat-menu>

  </div>
</div>

<vex-navigation *ngIf="(isVerticalLayout$ | async) && isNavbarBelowToolbar$ | async"
                [class.hidden]="mobileQuery"></vex-navigation>
