import { RestService } from 'src/app/services/rest.service';
import { TranslateService } from '@ngx-translate/core';
import { Notification } from './../../../@vex/layout/toolbar/toolbar-notifications/interfaces/notification.interface';
import { GuiService } from 'src/app/services/gui.service';
import { Component, Inject, OnInit } from '@angular/core';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'vex-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  icon = {
    "faTrash": faTrash
  };

  selectedNotification = []

  constructor(public restService: RestService ,public guiService: GuiService, public translate: TranslateService, @Inject(MAT_DIALOG_DATA) private item: any['item'], private dialogRef: MatDialogRef<any>) { }

  ngOnInit(): void {
    setTimeout(()=>this.guiService.showSpinner = false,0)
    this.getNotif(this.item.notificationId)
    console.debug(this.selectedNotification)
  }

  getNotif(idNotifica){
    setTimeout(()=>this.guiService.showSpinner = true,0)
    this.restService.getService('notifications','/'+idNotifica+ '?ALL_LANGUAGES=true').subscribe((ris)=>{
      console.debug(ris)
      this.selectedNotification.push(ris)
      this.guiService.showSpinner = false
    })
  }


  archiveNotification(idNotifica){
    this.restService.postService('user_notification_status','/' + idNotifica + '/read', {}).subscribe((ris)=>{
      console.debug('notifica letta', ris)
      this.restService.getService('current_user', '').subscribe((risposta)=>{
        this.guiService.notificationsList = risposta.notifications
        console.debug(risposta)
      })
      this.dialogRef.close()
    })

  }

  acceptChall(idNotifica){
    this.restService.postService('user_notification_status','/' + idNotifica + '/accept', {}).subscribe((ris)=>{
      console.debug('Notification Chall Accepted', ris)
      this.restService.getService('current_user', '').subscribe((risposta)=>{
        this.guiService.notificationsList = risposta.notifications
      })
      this.dialogRef.close()
    })
  }

  rejectChall(idNotifica){
    this.restService.postService('user_notification_status','/' + idNotifica + '/reject', {}).subscribe((ris)=>{
      console.debug('Notification Chall Rejected', ris)
      this.restService.getService('current_user', '').subscribe((risposta)=>{
        this.guiService.notificationsList = risposta.notifications
      })
      this.dialogRef.close()
    })
  }

}
