<div class="container">
  <div class="px-6 py-4 border-b flex items-center header-title">
    <h2 class="title m-0">{{"USER-NUTRITION.MY_SURVEY" | translate}}</h2>
  </div>

  <div class="content">
    <pdf-viewer [src]="pdfBase64" [zoom]="1"></pdf-viewer>
    <div class="bottoni">
      <button class="m-4 bottone-viola" mat-dialog-close mat-button-base>{{"GLOBAL.CLOSE" | translate}}</button>
    </div>
  </div>
</div>
