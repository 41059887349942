import { ProgramNewComponent } from './program-new/program-new.component';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { RestService } from 'src/app/services/rest.service';
import { TranslateService } from '@ngx-translate/core';
import { ProgramEditComponent } from './program-edit/program-edit.component';
import { DialogConfirmComponent } from '../dialog-confirm/dialog-confirm.component';
import { GuiService } from 'src/app/services/gui.service';
import { faCheck, faCircle, faHourglassHalf } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';

@Component({
  selector: 'vex-program',
  templateUrl: './program.component.html',
  styleUrls: ['./program.component.scss']
})
export class ProgramComponent implements OnInit {
  icon = {
    "faCircle": faCircle,
    "faCheck": faCheck,
    "faHourGlass": faHourglassHalf
  };
  programs: any
  dataSource : MatTableDataSource<any> | null
  displayedColumns: string[] = ['vuota', 'nome', 'numero-settimane','pubblicato', 'actions'];
  selection = new SelectionModel<any>(true, []);
  searchCtrl = new UntypedFormControl();
  pageSize = 10;
  pageNumber = 0;
  lunghezza: any;
  pageSizeOptions: number[] = [5, 10, 15, 50];

  /* sort var */
  asc: any;
  fieldParam: string;
  searchAltFlag: boolean;
  sortFlag: boolean;
  /*ctrls */
  searchCtrlWeeks = new UntypedFormControl();
  searchCtrlCreation = new UntypedFormControl();
  searchCtrlStatus = new UntypedFormControl();

  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private restService: RestService, private dialog: MatDialog,  public translate: TranslateService, public guiService: GuiService) { }

  ngOnInit(): void {
    this.getPrograms()
    this.asc = true;
    this.searchAltFlag = false;
    this.sortFlag = false;
  }

  ngAfterViewInit(): void {
    //this.dataSource.paginator = this.paginator;
    //this.dataSource.sort = this.sort;
  }


  getPrograms(){
    setTimeout(()=>this.guiService.showSpinner = true,0)
    this.dataSource = new MatTableDataSource();
    this.restService.postService('workout_programs', '/search', {page: this.pageNumber, size: this.pageSize}).subscribe((ris)=>{
      this.lunghezza = ris.totalItems
      this.programs = ris["items"];
      console.debug(this.programs)
      this.dataSource.data = this.programs;
      this.table ? this.table.renderRows() : null;
      this.guiService.showSpinner = false
    })
  }

  onPageChanged(pageEvent: PageEvent) {
    this.pageNumber = pageEvent.pageIndex+1;
    this.pageSize = pageEvent.pageSize;
    if(this.searchAltFlag == false && this.sortFlag == false){
      this.getPrograms()
    }else if(this.sortFlag == true){
      // this.sortParam(this.fieldParam, this.asc)
    }else if(this.searchAltFlag == true){
      this.search()
    }
  }

  createProgram(){
    this.dialog.open(ProgramNewComponent,{
      disableClose: true,
      hasBackdrop: true
    }).afterClosed().subscribe((ex) => {
      if(ex == 'done'){
        this.getPrograms()
      }
    })
  }

  editProgram(item?: any['item']){
    this.restService.getService('workout_programs', item.id + '?ALL_LANGUAGES=true').subscribe((ris)=>{
    this.dialog.open(ProgramEditComponent, {
      data: ris || null,
      width: '100vw',
      maxWidth: '90vw',
      height: '95vh',
      panelClass: 'editProgram-modalbox',
      disableClose: true,
      hasBackdrop: true
    }).afterClosed().subscribe((program) => {
      if(program == 'done'){
        this.getPrograms()
      }
    })
  })
  }

  deleteProgram(id): void {
    this.dialog.open(DialogConfirmComponent, {
      width: '250px',
      enterAnimationDuration: '0ms',
      exitAnimationDuration: '1ms',
      panelClass: 'dialog-modalbox',
      data: {
        type: 'workout_programs',
        id: id
      }
    }).afterClosed().subscribe((ris)=>{
      if(ris != 'true'){
        this.getPrograms()
      }
    })
  }

  searchAltExercise() {
    if(this.searchCtrl.value.length > 3){
    console.debug(this.searchCtrl.value)
    let param = {
      "filterAnd": [
        {
        "field": "i18nName."+this.translate.currentLang.toUpperCase(),
        "operator": "like",
        "value": this.searchCtrl.value.replace(/^\s+|\s+$/gm,'')
        }
        ],
      "page": 0,
      "size": 0
    }
    this.restService.postService('workout_programs','/search'+'?ALL_LANGUAGES=true', param).subscribe((ris)=>{
      this.programs = ris["items"]
      this.dataSource.data = this.programs;
      console.debug(this.programs)
    })
  }else if(this.searchCtrl.value.length == 0){
    this.getPrograms()
  }
  }

  search(){
    this.searchAltFlag = true
    let param = {
      "filterAnd": [],
      "page": this.pageNumber,
      "size": this.pageSize
    }

    if(this.searchCtrl.value != null){
      param["filterAnd"].push({
        "field": "i18nName."+this.translate.currentLang.toUpperCase(),
        "operator": "like",
        "value": this.searchCtrl.value.replace(/^\s+|\s+$/gm,'')
      })
    }
    if(this.searchCtrlWeeks.value != null){
      param["filterAnd"].push({
        "field": "numberOfWeeks",
        "operator": "eq",
        "value": this.searchCtrlWeeks.value
      })
    }
    if(this.searchCtrlStatus.value != null){
      param["filterAnd"].push({
        "field": "workoutProgramStatus",
        "operator": "eq",
        "value": this.searchCtrlStatus.value
      })
    }
    // NON PRESENTE ANCORA CREATION DATE
    // if(this.searchCtrlCreation.value != null){
    //   param["filterAnd"].push(
    //     {
    //       "field": "endDate",
    //       "operator": "gte",
    //       "dateValue": moment(this.searchCtrlCreation.value + " 00:00:00","YYYY-MM-DD HH:mm:ss").format('x')
    //     }
    //   ),
    //   param["filterAnd"].push(
    //     {
    //       "field": "endDate",
    //       "operator": "lte",
    //       "dateValue": moment(this.searchCtrlCreation.value + " 23:59:59","YYYY-MM-DD HH:mm:ss").format('x')
    //     }
    //   )
    // }

    this.restService.postService('workout_programs', '/search', param).subscribe((ris)=>{
      this.lunghezza = ris.totalItems
      this.programs = ris["items"];
      console.debug(this.programs)
      this.dataSource.data = this.programs;
      this.table ? this.table.renderRows() : null;
      this.guiService.showSpinner = false
    })
  }

  reload(){
    this.searchCtrl.reset()
    this.searchCtrlWeeks.reset()
    this.searchCtrlCreation.reset()
    this.searchCtrlStatus.reset()

    this.searchAltFlag = false;
    this.sortFlag = false;
    this.pageNumber = 0;
    this.pageSize = 10;
    this.getPrograms()
  }

  resetPage(){
    this.pageNumber = 0;
  }
}
