<div class="card mt-6">
  <div class="px-gutter py-4 border-b">
    <h2 class="title m-0">password</h2>
  </div>
  <div class="card mt-2">
    <form [formGroup]="form">
      <div class="info-field">
      <div class="py-3 flex items-center">

        <div @scaleIn
             class="w-10 h-10 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
          <mat-icon class="icon-sm" svgIcon="mat:vpn_key"></mat-icon>
        </div>

        <div @fadeInRight>

          <mat-form-field>
            <mat-label>password</mat-label>
            <input matInput required formControlName="password" [type]="inputType"/>
            <button (click)="toggleVisibility()" mat-icon-button matSuffix type="button">
              <mat-icon *ngIf="visible" svgIcon="mat:visibility"></mat-icon>
              <mat-icon *ngIf="!visible" svgIcon="mat:visibility_off"></mat-icon>
            </button>
          </mat-form-field>
          <mat-error *ngIf="form.get('password').errors && form.value.password.length == 0" class="alert alert-danger">
              <div *ngIf="form.get('password').errors.required">Password is required</div>
          </mat-error>
          <mat-error *ngIf="form.value.password.length < 5 && form.value.password.length != 0">
            password must have atleast 6 characters
          </mat-error>

        </div>
      </div>
      </div>
      <div class="info-field">
      <div class="py-3 flex items-center">
        <div @scaleIn
             class="w-10 h-10 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
          <mat-icon class="icon-sm" svgIcon="mat:vpn_key"></mat-icon>
        </div>

        <div @fadeInRight>

          <mat-form-field>
            <mat-label>confirm password</mat-label>
            <input matInput required formControlName="confirmPassword" [type]="inputType"/>
            <button (click)="toggleVisibility()" mat-icon-button matSuffix type="button">
              <mat-icon *ngIf="visible" svgIcon="mat:visibility"></mat-icon>
              <mat-icon *ngIf="!visible" svgIcon="mat:visibility_off"></mat-icon>
            </button>
          </mat-form-field>
            <mat-error *ngIf="form.get('confirmPassword').errors" class="alert alert-danger">
              <div *ngIf="form.get('confirmPassword').errors.required">Confirm Password is required</div>

              <div *ngIf="form.get('confirmPassword').errors.confirmPasswordValidator">Passsword and
                Confirm Password didn't match. </div>
            </mat-error>


        </div>
      </div>
      </div>
      <div class="bottoni">
        <button mat-raised-button  class="m-4 bottone-viola" (click)="send()" [disabled]="form.invalid">{{"GLOBAL.EDIT" | translate}}</button>
      </div>
    </form>
  </div>
</div>
