import { ProgramComponent } from './../program.component';
import { GuiService } from 'src/app/services/gui.service';
import { TranslateService } from '@ngx-translate/core';
import { RestService } from 'src/app/services/rest.service';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {faAdd, faClose} from "@fortawesome/free-solid-svg-icons";
import { MatAccordion } from '@angular/material/expansion';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CloseConfirmComponent } from '../../close-confirm/close-confirm.component';

@Component({
  selector: 'vex-program-edit',
  templateUrl: './program-edit.component.html',
  styleUrls: ['./program-edit.component.scss']
})
export class ProgramEditComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  icon = {
    "faAdd": faAdd,
    "faClose": faClose
  };
  form: UntypedFormGroup
  workouts: any
  selectedItem: any = [];
  selectedViewItem: any = [];
  selectedWeek: any = [];
  search = this.translate.instant("GLOBAL.SEARCH")
  delete = this.translate.instant("GLOBAL.DELETE")
  formLang: any;
  iconFormFlag: any;
  flags = {
    en: 'flag:united-states',
    it: 'flag:italy'
  };
  searchCtrl = new UntypedFormControl();

  base_week = {
    week: [
      {id:null},
      {id:null},
      {id:null},
      {id:null},
      {id:null},
      {id:null},
      {id:null}
      ]
  };

  showDiv = {
    current: false
  }

  days: any[] = [
    {value: this.translate.instant("WORKOUT-PROG.DAY1")},
    {value: this.translate.instant("WORKOUT-PROG.DAY2")},
    {value: this.translate.instant("WORKOUT-PROG.DAY3")},
    {value: this.translate.instant("WORKOUT-PROG.DAY4")},
    {value: this.translate.instant("WORKOUT-PROG.DAY5")},
    {value: this.translate.instant("WORKOUT-PROG.DAY6")},
    {value: this.translate.instant("WORKOUT-PROG.DAY7")}

  ]

  weeks: any = [{
    week: [null,null,null,null,null,null,null]
  }]

  attrezzatura: any[];
  level: any[];
  program: any
  filteredWeeks = []
  filteredIds = {}

  JSON: any = JSON;
  imgSelected: string;

  constructor(@Inject(MAT_DIALOG_DATA) private item: any['item'], private restService: RestService, public translate: TranslateService,private fb: UntypedFormBuilder, public guiService: GuiService, private dialogRef: MatDialogRef<ProgramComponent>, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.populateSelects()
    this.program = this.item
    this.changeFormLanguage(this.translate.currentLang);

    this.form = this.fb.group({
      nome_it:[null, Validators.required],
      nome_en:[null, Validators.required],
      bozza:[null, Validators.required],
      week_n: [null, Validators.required],
      desc_it: [null],
      desc_en: [null],
      workout_settimanali: [null, Validators.required],
      workout_durata: [null, Validators.required],
      attrezzatura: [null, Validators.required],
      file: [null]
    })

    console.debug(this.program)

    this.form.controls['desc_it'].setValue(this.program.i18nDescription['IT']);
    this.form.controls['desc_en'].setValue(this.program.i18nDescription['EN']);
    this.form.controls['week_n'].setValue(this.program.numberOfWeeks);
    this.form.controls['workout_settimanali'].setValue(this.program.weeklyWorkouts);
    this.form.controls['workout_durata'].setValue(this.program.workoutDurationInMinutes);
    this.form.controls['attrezzatura'].setValue(this.program.equipmentIds);
    this.form.controls['bozza'].setValue(this.program.workoutProgramStatus);
    this.form.controls['nome_it'].setValue(this.program.i18nName['IT']);
    this.form.controls['nome_en'].setValue(this.program.i18nName['EN']);


    // for (let k in this.program.i18nName) {
    //   for(let [key,value] of Object.entries(this.program.i18nName[k])){
    //     this.form.controls['nome_'+key].setValue(value);
    //   }
    //   for(let [key,value] of Object.entries(this.program.i18nDescription[k])){
    //     this.form.controls['desc_'+key].setValue(value);
    //   }
    // }

    this.weeks = [];
    for(let week of this.program.weeks){
      let tmpWeek = [null,null,null,null,null,null,null];
      for(let k = 0; k <= 7; k++){
        tmpWeek[k] = {id: week['workoutIdDay'+(k+1)]};
        if(week['workoutIdDay'+k] && this.filteredWeeks.indexOf(week['workoutIdDay'+k])< 0){
          this.filteredWeeks.push(week['workoutIdDay'+k])
          console.debug('riga 116',week['workoutIdDay'+k],this.filteredWeeks)
        }
      }
      console.debug(tmpWeek)
      this.weeks.push({
        week: tmpWeek
      })
    }
    this.loadWeekNames(this.filteredWeeks)
  }

  workoutSelection(week,i){
    let is = this.selectedViewItem.indexOf(week+'_'+i);
    is<0 ? this.selectedViewItem.push(week+'_'+i) : this.selectedViewItem.splice(is,1);
    is<0 ? this.selectedItem.push({week: week, day: i}) : this.selectedItem.splice(is,1);
  }

  addWorkout(w){
    for(let k in this.selectedItem) {
      let item = this.selectedItem[k];
      let week = this.weeks[item.week];
      week.week[item.day] = w;
    }
    this.selectedItem = [];
    this.selectedViewItem = [];
  }

  removeWorkout(w,i){
    let week = this.weeks[w];
    week.week.splice(i,1,{id: null});
  }

  addWeek() {
    this.weeks.push(JSON.parse(JSON.stringify(this.base_week)));
  }

  removeWeek(w) {
    this.weeks.splice(w,1);
  }

  submit(){
    console.debug('wwww', this.weeks)
    let tmpWeeks = []
    for(let week of this.weeks){
      console.debug('week0',week.week)
      let tmp = {
      "workoutIdDay1": week.week[0] != null ? week.week[0].id : null,
      "workoutIdDay2": week.week[1] != null ? week.week[1].id : null,
      "workoutIdDay3": week.week[2] != null ? week.week[2].id : null,
      "workoutIdDay4": week.week[3] != null ? week.week[3].id : null,
      "workoutIdDay5": week.week[4] != null ? week.week[4].id : null,
      "workoutIdDay6": week.week[5] != null ? week.week[5].id : null,
      "workoutIdDay7": week.week[6] != null ? week.week[6].id : null
      }
      tmpWeeks.push(tmp)
    }
    let editProgram = {
      description: this.form.value.desc_en,
      difficulty: 'EASY',
      equipmentIds: this.form.value.attrezzatura,
      i18nDescription: {
        "EN": this.form.value.desc_en,
        "IT": this.form.value.desc_it
       },
      i18nName: {
        "EN": this.form.value.nome_en,
        "IT": this.form.value.nome_it
      },
      name: this.form.value.nome_en,
      numberOfWeeks: this.form.value.week_n,
      weeklyWorkouts: this.form.value.workout_settimanali,
      weeks: tmpWeeks,
      workoutDurationInMinutes: this.form.value.workout_durata,
      image: this.imgSelected,
      imageFileName: this.form.value.nome_en,
      workoutProgramStatus: this.form.value.bozza
    }
    console.debug(editProgram)

      this.restService.putService('workout_programs', '/'+this.item.id + '?ALL_LANGUAGES=true', editProgram).subscribe((ris) => {
        this.dialogRef.close('done')
        this.guiService.openSnackBar(ris.name +' '+this.translate.instant('GLOBAL.EDIT_SUCCESS'), this.translate.instant('GLOBAL.CLOSE'))
      })
  }

  changeFormLanguage(lang) {
    this.iconFormFlag = this.flags[lang];
    this.formLang = lang;
}

populateSelects(){
  this.restService.getService('exercise_training', '').subscribe((ris)=>{
    this.level = ris
  })
  this.restService.postService('equipments', '', {page: 0, size: 0}).subscribe((ris)=>{
    this.attrezzatura = ris.items
  })
}
loadWeekNames(baseIndex) {
  let param = {
    "filterAnd": [
      {
      "field": "id",
      "operator": "in",
      "value": baseIndex.toString()
      }
      ],
    "page": 0,
    "size": 400
  }
  this.restService.postService('workouts', '/search', param).subscribe((ris)=>{
    for(let wId of baseIndex){
      console.debug(baseIndex)
      this.filteredIds[wId]= ris.items.find(x => x.id === wId).name
      console.debug("FilterIDS",this.filteredIds);
    }
    this.guiService.showSpinner = false
  })
}
onFileSelected(event) {
  this.guiService.convertFile(event.target.files[0]).subscribe(base64 => {
    console.debug("BASE64",base64);
    this.imgSelected = base64;
  });
}

searchWorkout() {

  console.debug(this.searchCtrl.value)
  let param = {
    "filterAnd": [
      {
      "field": "tags",
      "operator": "eq",
      "value": this.searchCtrl.value
      },
      {
        "field": "workoutProgramId",
        "operator": "eq",
        "value": this.program.id
      }
      ],
    "page": 0,
    "size": 0
  }
  this.restService.postService('workouts_search', '?ALL_LANGUAGES=true', param).subscribe((ris)=>{
    this.workouts = ris["items"]
    for(let k in ris.items){
      ris.items[k].name = ris.items[k].name.split("::").pop();
    }
    console.debug(this.workouts)
  })

}

confirmClose(){
  this.dialog.open(CloseConfirmComponent,{
    disableClose: true,
    hasBackdrop: true,
    panelClass: 'close-dialog-modalbox'
  }).afterClosed().subscribe((ris)=>{
    if(ris == 'true'){
      this.dialogRef.close()
    }
  })
}

}
