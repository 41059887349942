import { RestService } from 'src/app/services/rest.service';
import { MenuItem } from './../../@vex/layout/toolbar/toolbar-user/interfaces/menu-item.interface';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from './../../@vex/services/navigation.service';
import { Injectable } from '@angular/core';
import { NavigationItem } from 'src/@vex/interfaces/navigation-item.interface';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {Observable, ReplaySubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class GuiService {

  // diamondSub = ['63395f8bc8480850ac7645de']
  userLogged: any;
  exercise: any;
  showSpinner = true;
  languages = this.translate.getLangs();

  notificationTypes = {
    subscription: {colorClass: 'text-primary', icon: 'mat:account_circle'},
    info: {colorClass: 'text-orange', icon: 'mat:insert_chart'},
    default: {colorClass: 'text-green', icon: 'mat:check_circle'}
  };

  displaySelectInToolbar = true
  selectedProgram = sessionStorage.getItem("selectedProgram")
  selectedWorkout: any
  isCurrentProgram = true;
  currentDayWorkout: any;
  weekSelectedNumber: number;
  resetWeek = false;
  showDownloadSurveyButton = false;
  filteredIds = {}
  EquipNameIds = {}
  selectedProgramTotalWeeks = 0;

  constructor(private NavigationService: NavigationService, private translate: TranslateService, private _snackBar: MatSnackBar, private router: Router, public restSrv: RestService) { }

  userMenuItems: NavigationItem[] = [
    {
      type: 'link',
      label: 'HOME',
      route: 'home',
      icon: 'mat:home',
      routerLinkActiveOptions: { exact: true }
    },
    {
          type: 'link',
          label: 'INFO',
          route: 'info',
          icon: 'mat:contact_support',
          routerLinkActiveOptions: { exact: true }
        },
        {
          type: 'link',
          label: 'I-PROGRAM',
          route: 'user-program',
          icon: 'mat:info',
          routerLinkActiveOptions: { exact: true }
        },
        {
          type: 'link',
          label: 'NUTRI-CARD',
          route: 'user-nutrition',
          icon: 'mat:restaurant',
          routerLinkActiveOptions: { exact: true }
        },
        {
          type: 'link',
          label: 'Facebook',
          route: 'facebook',
          icon: 'mat:group',
          routerLinkActiveOptions: { exact: true }
        },
        {
          type: 'link',
          label: 'WELLNESS.ON_DEMAND',
          route: 'ondemand',
          icon: 'mat:flash_on',
          routerLinkActiveOptions: { exact: true }
        }
  ]

  adminMenuItems: NavigationItem[]  = [
    {
      type: 'link',
      label: 'HOME',
      route: 'console',
      icon: 'mat:home',
      routerLinkActiveOptions: { exact: true },
      badge: {
        value: '',
        bgClass: '',
        textClass: ''
      }
    },
    {
      type: 'link',
      label: 'USERS',
      route: 'user',
      icon: 'mat:format_list_bulleted',
      routerLinkActiveOptions: { exact: true }
    },
    {
      type: 'link',
      label: 'SUBS',
      route: 'subs',
      icon: 'mat:view_list',
      routerLinkActiveOptions: { exact: true }
    },
    {
          type: 'link',
          label: 'EX',
          route: 'exercise',
          icon: 'mat:fitness_center',
          routerLinkActiveOptions: { exact: true }
        },
        {
          type: 'link',
          label: 'WORKS',
          route: 'workout',
          icon: 'mat:assignment',
          routerLinkActiveOptions: { exact: true }
        },
        {
          type: 'link',
          label: 'WORK-PROG',
          route: 'program',
          faicon: 'faCalendar',
          routerLinkActiveOptions: { exact: true }
        },
    {
      type: 'link',
      label: 'NUTRI',
      route: 'nutrition',
      icon: 'mat:restaurant',
      routerLinkActiveOptions: { exact: true }
    },
    {
      type: 'link',
      label: 'PROMO',
      route: 'promotion',
      icon: 'mat:card_giftcard',
      routerLinkActiveOptions: { exact: true }
    },
    {
      type: 'link',
      label: 'NOTIFICATION',
      route: 'admin-notifications',
      icon: 'mat:notifications',
      routerLinkActiveOptions: { exact: true }
    },
    {
      type: 'dropdown',
      label: 'CHALL',
      icon: 'mat:flash_on',
      children: [
        {
          type: 'link',
          label: 'CHALL',
          route: 'challenge'
        },
        {
          type: 'link',
          label: 'TIME-CHALL',
          route: 'time-challenge'
        }
      ]
    },
    {
      type: 'dropdown',
      label: 'WELLNESS_ZONE',
      icon: 'mat:fitness_center',
      children: [
        {
          type: 'link',
          label: 'CATEGORY',
          route: 'categories'
        },
        {
          type: 'link',
          label: 'ASSIGN_ON_DEMAND',
          route: 'assign-ondemand'
        }
      ]
    },
    {
          type: 'link',
          label: 'STATS',
          route: 'reports',
          icon: 'mat:show_chart',
          routerLinkActiveOptions: { exact: true }

    }
  ]

  collabMenuItems: NavigationItem[]  = [
    {
      type: 'link',
      label: 'HOME',
      route: 'console',
      icon: 'mat:home',
      routerLinkActiveOptions: { exact: true },
      badge: {
        value: '',
        bgClass: '',
        textClass: ''
      }
    },

        {
          type: 'link',
          label: 'USERS-L',
          route: 'user',
          icon: 'mat:format_list_bulleted',
          routerLinkActiveOptions: { exact: true }
        },

        {
          type: 'link',
          label: 'SUBS-L',
          route: 'subs',
          icon: 'mat:view_list',
          routerLinkActiveOptions: { exact: true }
        },

        {
          type: 'link',
          label: 'WORK-PROG',
          route: 'program',
          faicon: 'faCalendar',
          routerLinkActiveOptions: { exact: true }
        },
        {
          type: 'link',
          label: 'WORKS',
          route: 'workout',
          icon: 'mat:assignment',
          routerLinkActiveOptions: { exact: true }
        },
        {
          type: 'link',
          label: 'EX',
          route: 'exercise',
          icon: 'mat:fitness_center',
          routerLinkActiveOptions: { exact: true }
        },
        {
          type: 'link',
          label: 'NUTRI-CARD',
          route: 'nutrition',
          icon: 'mat:restaurant',
          routerLinkActiveOptions: { exact: true }
        },
        {
          type: 'link',
          label: 'PROMO-MAN',
          route: '/',
          icon: 'mat:card_giftcard',
          routerLinkActiveOptions: { exact: true }
        },
        {
          type: 'link',
          label: 'CHALL',
          route: '/',
          icon: 'mat:flash_on',
          routerLinkActiveOptions: { exact: true }
        },
    {
          type: 'link',
          label: 'STATS',
          route: '/',
          icon: 'mat:show_chart',
          routerLinkActiveOptions: { exact: true }

    }
  ]

  nutMenuItems: NavigationItem[]  = [
    {
      type: 'subheading',
      label: 'NUTRI',
      children: [
        {
          type: 'link',
          label: 'NUTRI-CARD',
          route: 'customer',
          icon: 'mat:restaurant',
          routerLinkActiveOptions: { exact: true }
        }
      ]
    }
  ]

  selectMenuItems(user){
    console.debug(user)
    if(user == 'user'){
      this.NavigationService.items = this.userMenuItems
      this.NavigationService.itemsMenu = this.itemsMenuUser
    }
    if(user == 'admin'){
      this.NavigationService.items = this.adminMenuItems
      this.NavigationService.itemsMenu = this.itemsMenuAdmin
    }
    if(user == 'collaboratore'){
      this.NavigationService.items = this.collabMenuItems
    }
    if(user == 'nutritionist'){
      this.NavigationService.items = this.nutMenuItems
      this.NavigationService.itemsMenu = this.nutriMenuAdmin
    }

  }

  changeLang(lang){
    console.debug(lang)
    this.translate.use(lang)
  }

  setExercise(ex){
    this.exercise = ex
  }

  openSnackBar(message: string, action?: any, typ?: string, time?:number) {
    let config: MatSnackBarConfig = {}
    config.panelClass = typ ? typ : 'classic';
    config.duration = time ? time : 3000;
    this._snackBar.open(message, action, config);
  }

  itemsMenuUser: MenuItem[] = [
    {
      id: '1',
      icon: 'mat:account_circle',
      label: this.translate.instant('PROFILE'),
      description: 'INFO-P',
      colorClass: 'text-teal',
      route: '/main/user/profile'
    },
    {
      id: '4',
      icon: 'mat:table_chart',
      label: 'SUB-P',
      description: 'PLAN-P',
      colorClass: 'text-purple',
      route: '/main/user/sub'
    },
    {
      id: '2',
      icon: 'mat:vpn_key',
      label: 'password',
      description: 'reset password',
      colorClass: 'text-blue',
      route: '/main/user/reset-password'
    }
  ];

  itemsMenuAdmin: MenuItem[] = [
    {
      id: '1',
      icon: 'mat:account_circle',
      label: this.translate.instant('PROFILE'),
      description: 'INFO-P',
      colorClass: 'text-teal',
      route: '/admin/administrator/admin-profile'
    },
    {
      id: '2',
      icon: 'mat:vpn_key',
      label: 'password',
      description: 'reset password',
      colorClass: 'text-blue',
      route: '/admin/administrator/admin-reset-password'
    }
  ];

  nutriMenuAdmin: MenuItem[] = [
    {
      id: '1',
      icon: 'mat:account_circle',
      label: this.translate.instant('PROFILE'),
      description: 'INFO-P',
      colorClass: 'text-teal',
      route: '/nutritionist/nutri/nutri-profile'
    },
    {
      id: '2',
      icon: 'mat:vpn_key',
      label: 'password',
      description: 'reset password',
      colorClass: 'text-blue',
      route: '/nutritionist/nutri/nutri-reset-password'
    }
  ];

  notificationsList: any = [];

  convertFile(file : File) : Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) => result.next(btoa(event.target.result.toString()));
    return result;
  }

  getEquipImgs(baseIndex){
    this.restSrv.getService('equipment', 'actives').subscribe((ris)=>{
      for(let eqId of baseIndex){
        this.filteredIds[eqId]= ris.find(x => x.id === eqId).name.replace(/[^A-Z0-9]+/ig, "_")
      }
      console.debug(this.filteredIds)
    })
   }

   loadEquipmentNames(baseIndex) {

    this.restSrv.postService('equipments', '', {}).subscribe((ris)=>{
      for(let eqId of baseIndex){
        console.debug(eqId)
        this.EquipNameIds[eqId]= ris.items.find(x => x.id === eqId).name
      }
    })
}

}
