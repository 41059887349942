<div class="contenitore">
  <div class="general">
    <div class="general-box">
      <h1>Active Members</h1>
      <h3 class="number">{{topDatas ? topDatas.activeMembers : 0}}</h3>
    </div>
    <div class="general-box">
      <h1>Inactive Members</h1>
      <h3 class="number">{{topDatas ? topDatas.inactiveMembers : 0}}</h3>
    </div>
    <div class="general-box">
      <h1>Total Members</h1>
      <h3 class="number">{{topDatas ? topDatas.totalMembers : 0}}</h3>
    </div>
    <div class="general-box">
      <h1>Active Free Members</h1>
      <h3 class="number">{{topDatas ? topDatas.activeFreeMembers : 0}}</h3>
    </div>
    <div class="general-box">
      <h1>Active Paid Members</h1>
      <h3 class="number">{{topDatas ? topDatas.activePaidMembers : 0}}</h3>
    </div>
    <div class="general-box">
      <h1>Avg Mbr Lifetime Val</h1>
      <h3 class="number">{{topDatas ? '$' + topDatas.avgMemberLifetimeValue : '$ 0'}}</h3>
    </div>
  </div>
  <div class="period">
    <a (click)="periodSelected = 'mensile';getDatas()" [class.selected]="periodSelected == 'mensile'">Monthly</a>
    <a (click)="periodSelected = 'annuale';getDatas()" [class.selected]="periodSelected == 'annuale'">Yearly</a>
    <a (click)="periodSelected = 'sempre';getDatas()" [class.selected]="periodSelected == 'sempre'">All-Time</a>
  </div>
  <div class="main">

    <div class="datas">
      <div class="filters">
        <div class="filt" *ngIf="periodSelected == 'mensile'">
          <mat-form-field>
            <mat-label>Month</mat-label>
            <mat-select [formControl]="monthCtrl" >
              <mat-option *ngFor="let mese of mesi" [value]="mese.value">{{mese.label}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="filt" *ngIf="periodSelected != 'sempre'">
          <mat-form-field>
            <mat-label>Year</mat-label>
            <mat-select [formControl]="yearCtrl">
              <mat-option value="2018">2018</mat-option>
              <mat-option value="2019">2019</mat-option>
              <mat-option value="2020">2020</mat-option>
              <mat-option value="2021">2021</mat-option>
              <mat-option value="2022">2022</mat-option>
              <mat-option value="2023">2023</mat-option>
              <mat-option value="2024">2024</mat-option>
              <mat-option value="2025">2025</mat-option>
              <mat-option value="2026">2026</mat-option>
              <mat-option value="2027">2027</mat-option>
              <mat-option value="2028">2028</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="filt">
          <mat-form-field>
            <mat-label>Subscription</mat-label>
            <mat-select [formControl]="subCtrl" >
              <mat-option value="ALL">All</mat-option>
              <mat-option value="GOLD">Gold</mat-option>
              <mat-option value="DIAMOND">Diamond</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <button class="ml-4 flex-none" color="primary" *ngIf="searchSpinner == false" mat-mini-fab type="button" (click)="getDatas()"><mat-icon svgIcon="mat:search"></mat-icon></button>
        <button mat-mini-fab class="ml-4 flex-none" type="button" color="primary" *ngIf="searchSpinner == true" disabled><mat-spinner diameter="24" color="warn"></mat-spinner></button>
      </div>

      <div class="general div2">
        <div class="general-box">
          <h1>Pending Transactions</h1>
          <h3 class="number">N/A</h3>
        </div>
        <div class="general-box">
          <h1>Failed transactions</h1>
          <h3 class="number">{{failedTransactions}}</h3>
        </div>
        <div class="general-box">
          <h1>Refunded Transactions</h1>
          <h3 class="number">{{refundedTransactions}}</h3>
        </div>
        <div class="general-box">
          <h1>Completed Transactions</h1>
          <h3 class="number">{{completedTransactions}}</h3>
        </div>
        <div class="general-box">
          <h1>Amount colleted</h1>
          <h3 class="number">{{amountCollected}}</h3>
        </div>
        <div class="general-box">
          <h1>Amount Refunded</h1>
          <h3 class="number">{{amountRefunded}}</h3>
        </div>
        <div class="general-box">
          <h1>Taxes Colleted</h1>
          <h3 class="number" >{{taxesCollected}}</h3>
        </div>
        <div class="general-box">
          <h1>Total Income</h1>
          <h3 class="number">{{totalIncome}}</h3>
        </div>
         <div id="divPie" [class.d-none]="subCtrl.value != 'ALL'">
          <canvas id="myPie"></canvas>
        </div>
      </div>

      <div class="amounts-transactions" *ngIf="periodSelected != 'sempre'">
        <a (click)="amountsOrtransactions = 'amounts';getDatas()" [class.selected]="amountsOrtransactions == 'amounts'">Amounts</a>
        <a (click)="amountsOrtransactions = 'transactions';getDatas()" [class.selected]="amountsOrtransactions == 'transactions'">Transactions</a>
      </div>
      <div id="divChart" [class.d-none]="periodSelected != 'annuale'">
        <canvas id="myLineChart"></canvas>
      </div>
      <div id="divChartTwo" [class.d-none]="periodSelected != 'mensile'">
        <canvas id="myLineChartTwo"></canvas>
      </div>


      <div class="tabella" *ngIf="periodSelected != 'sempre'">
        <table mat-table [dataSource]="tableDatas" *ngIf="transactions" class="mat-elevation-z8">

          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef> Date </th>
            <td mat-cell *matCellDef="let transaction"> {{transaction.day}}/{{transaction.month}}/{{transaction.year}}</td>
          </ng-container>

          <ng-container matColumnDef="pending">
            <th mat-header-cell *matHeaderCellDef> Pending </th>
            <td mat-cell *matCellDef="let transaction">N/A</td>
          </ng-container>

          <ng-container matColumnDef="failed">
            <th mat-header-cell *matHeaderCellDef> Failed </th>
            <td mat-cell *matCellDef="let transaction"> {{transaction.failedTransactions}} </td>
          </ng-container>

          <ng-container matColumnDef="complete">
            <th mat-header-cell *matHeaderCellDef> Complete </th>
            <td mat-cell *matCellDef="let transaction"> {{transaction.completedTransactions}} </td>
          </ng-container>

          <ng-container matColumnDef="refunded">
            <th mat-header-cell *matHeaderCellDef> Refunded </th>
            <td mat-cell *matCellDef="let transaction"> {{transaction.refundedTransactions}} </td>
          </ng-container>

          <ng-container matColumnDef="colleted">
            <th mat-header-cell *matHeaderCellDef> Colleted </th>
            <td mat-cell *matCellDef="let transaction"> <span class="colleted">{{transaction.amountCollected | currency}}</span> </td>
          </ng-container>

          <ng-container matColumnDef="refunded-money">
            <th mat-header-cell *matHeaderCellDef> Refunded</th>
            <td mat-cell *matCellDef="let transaction"> <span class="refunded">{{transaction.amountRefunded | currency}}</span> </td>
          </ng-container>

          <ng-container matColumnDef="tax">
            <th mat-header-cell *matHeaderCellDef> Tax </th>
            <td mat-cell *matCellDef="let transaction"> {{transaction.taxesCollected}} </td>
          </ng-container>

          <ng-container matColumnDef="net-total">
            <th mat-header-cell *matHeaderCellDef> Net Total </th>
            <td mat-cell *matCellDef="let transaction"> <span class="net">{{transaction.totalIncome | currency}}</span> </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>
  </div>
</div>
