import { CloseConfirmComponent } from './../../close-confirm/close-confirm.component';
import { AdminNotificationsComponent } from './../admin-notifications.component';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { GuiService } from 'src/app/services/gui.service';
import { RestService } from 'src/app/services/rest.service';
import { formatDate } from '@angular/common';

import moment from 'moment';

@Component({
  selector: 'vex-edit-notification',
  templateUrl: './edit-notification.component.html',
  styleUrls: ['./edit-notification.component.scss']
})
export class EditNotificationComponent implements OnInit {

  form: UntypedFormGroup;
  formLang: any;
  iconFormFlag: any;
  flags = {
    en: 'flag:united-states',
    it: 'flag:italy'
  };
  types: any;
  subTypes: any
  notification:any;

  constructor(private fb: UntypedFormBuilder,
    public translate: TranslateService,
    public guiService: GuiService,
    public restService: RestService,
    @Inject(MAT_DIALOG_DATA) private item: any['item'],
    private dialogRef: MatDialogRef<AdminNotificationsComponent>,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.changeFormLanguage(this.translate.currentLang);
    this.populateSelects()
    this.notification = this.item
    this.form = this.fb.group({
      nome_it:[null, Validators.required],
      nome_en:[null, Validators.required],
      publish: [null],
      tipo: [null],
      desc_it: [null],
      desc_en: [null],
      sub: [null]
    })

    let tmpFrom = moment(this.notification.startDate)
    let offSet = moment().utcOffset()

    if(offSet < 0){
      tmpFrom = tmpFrom.add(Math.abs(offSet),'minutes')
    }else{
      tmpFrom = tmpFrom.subtract(Math.abs(offSet),'minutes')
    }

    this.form.controls['nome_it'].setValue(this.notification.i18nName ? this.notification.i18nName['IT'] : this.notification.i18nTitle['IT']);
    this.form.controls['nome_en'].setValue(this.notification.i18nName ? this.notification.i18nName['EN'] : this.notification.i18nTitle['EN']);
    if(this.notification.startDate != null){
      this.form.controls['publish'].setValue(tmpFrom.format("YYYY")+"-"+tmpFrom.format("MM")+"-"+tmpFrom.format("DD"));
    }
    this.form.controls['tipo'].setValue(this.notification.type);
    this.form.controls['desc_it'].setValue(this.notification.i18nMessage['IT']);
    this.form.controls['desc_en'].setValue(this.notification.i18nMessage['EN']);
    this.form.controls['sub'].setValue(this.notification.subscriptionType);
  }

  submit(form){
    let tmpFrom = moment(this.form.value.publish)

    let editNotification = {
      description: this.form.value.desc_en,
      i18nDescription: {
        EN: this.form.value.desc_en,
        IT: this.form.value.desc_it
      },
      i18nMessage: {
        EN: this.form.value.desc_en,
        IT: this.form.value.desc_it
      },
      i18nName: {
        EN: this.form.value.nome_en,
        IT: this.form.value.nome_it
      },
      i18nSubject: {
        EN: this.form.value.nome_en,
        IT: this.form.value.nome_it
      },
      message: this.form.value.desc_en,
      name: this.form.value.nome_en,
      startDate: tmpFrom.format("YYYY")+"-"+tmpFrom.format("MM")+"-"+tmpFrom.format("DD"),
      subject: this.form.value.nome_en,
      subscriptionType: this.form.value.sub,
      type: this.form.value.tipo,
      userId: null
    }
    console.debug(editNotification)
     this.restService.putService('notifications','/'+this.item.id + '?ALL_LANGUAGES=true', editNotification).subscribe((ris)=>{
      console.debug(ris)
      this.guiService.openSnackBar(this.translate.instant('GLOBAL.EDIT_SUCCESS'), "OK!")
      this.dialogRef.close()
     })

  }

  changeFormLanguage(lang) {
    this.iconFormFlag = this.flags[lang];
    this.formLang = lang;
  }

  populateSelects(){
    this.restService.getService('bos', 'notification-type').subscribe((ris)=>{
      console.debug('notification type', ris);
      this.types = ris
    })

    this.restService.getService('bos', 'subscription-type').subscribe((ris)=>{
      console.debug('tipo sub', ris);
      this.subTypes = ris;
    })
  }

  confirmClose(){
    this.dialog.open(CloseConfirmComponent,{
      disableClose: true,
      hasBackdrop: true,
      panelClass: 'close-dialog-modalbox'
    }).afterClosed().subscribe((ris)=>{
      if(ris == 'true'){
        this.dialogRef.close()
      }
    })
  }

}
