import { PayModalComponent } from './pay-modal/pay-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { ChangeSubstatusModalComponent } from './change-substatus-modal/change-substatus-modal.component';
import { RestService } from './../../../services/rest.service';
import { GuiService } from 'src/app/services/gui.service';
import { Component, OnInit } from '@angular/core';
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { scaleIn400ms } from 'src/@vex/animations/scale-in.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { faCheck, faCircle } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PaypalButtonComponent } from 'src/app/auth/register/paypal-button/paypal-button.component';
import { environment } from 'src/environments/environment';
import { TmpMsgContactComponent } from './tmp-msg-contact/tmp-msg-contact.component';
import { TmpUpgradePaymentmethodComponent } from 'src/app/auth/login/tmp-upgrade-paymentmethod/tmp-upgrade-paymentmethod.component';

@Component({
  selector: 'vex-sub',
  templateUrl: './sub.component.html',
  styleUrls: ['./sub.component.scss'],
  animations: [
    fadeInUp400ms,
    fadeInRight400ms,
    scaleIn400ms,
    stagger40ms
  ]
})
export class SubComponent implements OnInit {
  icon = {
    "faCircle": faCircle,
    "faCheck": faCheck
  };
  user: any;
  filteredIds = {}
  mnt = moment
  requestedSub: any = {
    duration: '',
    name: ''
  };

  subscriptions = {}

  constructor(public guiService: GuiService, public restService: RestService, private dialog: MatDialog, public translate: TranslateService,private router: Router) { }

  ngOnInit(): void {
    setTimeout(()=>this.guiService.showSpinner = false,0)
    this.user = this.guiService.userLogged
    console.debug(this.user)
    // if(this.user.userPaymentInfo && this.user.userPaymentInfo.subscriptionId != null){
    //   this.loadSubsNames(this.user.userPaymentInfo.subscriptionId)
    // }
    this.getSubs()
    // this.user.userPaymentInfo && this.user.userPaymentInfo.subscriptionIdRequest ? this.getRequestedSubscriptionInfo(this.user.userPaymentInfo.subscriptionIdRequest) : null
  }

//   loadSubsNames(baseIndex) {

//     this.restService.postService('subscriptions', '/search', {}).subscribe((ris)=>{
//       console.debug(ris)
//         this.filteredIds[baseIndex]= ris.items.find(x => x.id === baseIndex).name
//       console.debug(this.filteredIds)
//       this.guiService.showSpinner = false
//     })
// }

changeSubStatus(status){
  this.dialog.open(TmpMsgContactComponent,{
    disableClose: true,
    hasBackdrop: true
  })
}

pay(){
  this.dialog.open(TmpMsgContactComponent,{
    disableClose: true,
    hasBackdrop: true
  })
}

upgrade(){
  this.router.navigate(['auth/upgrade-plan'])
}

managePayment(){
  this.dialog.open(TmpUpgradePaymentmethodComponent,{
    disableClose: true,
    hasBackdrop: true
  })
}

chargeBeePortal(){
  let windowRef = window
  this.restService.getService('chargebee','/portal-session').subscribe((res)=>{
    windowRef.location.href = res.chargeBeePortalSession
  })
}

payChargeBee(){
  let checkout = {
    "cancelPageUrl": environment.baseURL.base +"/#/auth/payment-rejected",
    "successPageUrl": environment.baseURL.base +"/#/main/dashboard"
  }
    let tkn = sessionStorage.getItem('tkn')
    this.restService.chargeBeeCheckout('chargebee','/checkout-new',checkout,tkn).subscribe({
      next: (response)=>{
        console.debug(response)
        window.location.href = response.checkoutNewUrl
      }, error: (e)=>{

      }
    })
}

// getRequestedSubscriptionInfo(idSub){
//   this.restService.getService('subscriptions', idSub + '?ALL_LANGUAGES=true').subscribe((ris)=>{
//     console.debug('requestedSubscription',ris)
//     this.requestedSub.duration = ris.duration
//     this.requestedSub.name = ris.name
//   })
// }

getSubs(){
  this.restService.getService('subscriptions', 'actives').subscribe((ris)=>{
    this.subscriptions = {}
    for(let i = 0; i< ris.length; i++){
      let sub = ris[i]
      this.subscriptions[sub.id] = {
        "name": sub.type,
        "duration": sub.duration
      }
    }
    console.debug('AAAAAAAAAAAAAAAA',this.subscriptions)
  })
}

}
