<div class="contenitore">
  <h1 class="titolo">
    {{item.data.i18nName[translate.currentLang.toLocaleUpperCase()]}}
    <span *ngIf="pending">
      - {{"CHALLENGE-TIME.PENDING" | translate}}  <span> ({{pending.totalItems}})</span>
    </span>
    <span *ngIf="accepted">
      - {{"CHALLENGE-TIME.ACCEPTED" | translate}}  <span> ({{accepted.totalItems}})</span>
    </span>
    <span *ngIf="rejected">
      - {{"CHALLENGE-TIME.REJECTED" | translate}}  <span> ({{rejected.totalItems}})</span>
    </span>
  </h1>
  <h2 class="data">{{"CHALLENGE-TIME.FROM" | translate}} {{item.data.publishDate | date : 'shortDate'}} <span *ngIf="item.data.expirationDate">{{"CHALLENGE-TIME.TO" | translate}} {{item.data.expirationDate | date : 'shortDate'}}</span></h2>
  <div class="box" *ngIf="!pending && !accepted && !rejected">

  </div>
  <div class="box" *ngIf="pending">
    <div class="pad">
      <mat-paginator [pageSize]="10" [length]="lunghezzaPending" (page)="onPageChangedPending($event)"></mat-paginator>
      <div class="list" *ngIf="pending">
        <span *ngFor="let item of pending.items">
          <h3>{{item.fullName}}</h3>
          <h1>{{item.email}}</h1>
        </span>
      </div>

    </div>
  </div>
  <div class="box" *ngIf="accepted">

    <div class="pad">
      <mat-paginator [pageSize]="10" [length]="lunghezzaAccepted" (page)="onPageChangedAccepted($event)"></mat-paginator>
      <div class="list" *ngIf="accepted">
        <span *ngFor="let item of accepted.items">
          <h3>{{item.fullName}}</h3>
          <h1>{{item.email}}</h1>
        </span>
      </div>
    </div>
  </div>
  <div class="box" *ngIf="rejected">

    <div class="pad">
      <mat-paginator [pageSize]="10" [length]="lunghezzaRejected" (page)="onPageChangedRejected($event)"></mat-paginator>
      <div class="list" *ngIf="rejected">
        <span *ngFor="let item of rejected.items">
          <h3>{{item.fullName}}</h3>
          <h1>{{item.email}}</h1>
        </span>
      </div>
    </div>
  </div>
  <div class="btn-group">
    <button mat-dialog-close>{{"GLOBAL.CLOSE" | translate}}</button>
    <button (click)="exportAsXLSX(this.item.type)"><mat-icon svgIcon="mat:cloud_download"></mat-icon></button>
  </div>

</div>
