<div class="container mt-4">
  <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">
    <h2 class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l hidden sm:block flex-none">
      <span *ngIf="selection.isEmpty()">{{"PROMOTION-LIST.LIST" | translate}}</span>
    </h2>
    <div
      class="rounded-full border px-4 max-w-[300px] flex-auto flex items-center border border-gray-300">
      <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon>
      <input [formControl]="searchCtrl" (keyup)="searchAltExercise()" (search)="searchAltExercise()" class="px-4 py-2 border-0 outline-none w-full bg-transparent" placeholder="{{'GLOBAL.SEARCH' | translate}}" type="search" />
    </div>

    <span class="flex-1"></span>

    <!-- <button class="ml-4 flex-none" mat-icon-button matTooltip="Filter Columns" type="button">
      <mat-icon svgIcon="mat:filter_list"></mat-icon>
    </button> -->
    <button (click)="reload()" class="ml-4 flex-none blue-bottone" color="primary" mat-mini-fab matTooltip="reload" type="button" >
      <mat-icon svgIcon="mat:autorenew"></mat-icon>
    </button>
    <button (click)="createPromotion()" class="ml-4 flex-none blue-bottone" color="primary" mat-mini-fab matTooltip="Add Promotion" type="button" >
      <mat-icon svgIcon="mat:add"></mat-icon>
    </button>
  </div>
  <table mat-table [dataSource]="dataSource" *ngIf="promos" matSort>

    <ng-container matColumnDef="vuota">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef=""></td>
    </ng-container>

    <ng-container matColumnDef="codice">
      <th mat-header-cell *matHeaderCellDef mat-sort-header (click)="sortParam('code', asc == true ? false : true)">{{"PROMOTION-LIST.CODE" | translate}}</th>
      <td mat-cell *matCellDef="let promo">{{ promo.code }}</td>
    </ng-container>
    <ng-container matColumnDef="valido-da">
      <th mat-header-cell *matHeaderCellDef mat-sort-header (click)="sortParam('startDate', asc == true ? false : true)">{{"PROMOTION-LIST.VALID_FROM" | translate}}</th>
      <td mat-cell *matCellDef="let promo">{{ promo.startDate | date: 'shortDate' : 'UTC'}}</td>
    </ng-container>
    <ng-container matColumnDef="valido-al">
      <th mat-header-cell *matHeaderCellDef mat-sort-header (click)="sortParam('subscriptionTypeRetrictions', asc == true ? false : true)">{{"PROMOTION-LIST.SUB" | translate}}</th>
      <td mat-cell *matCellDef="let promo">
        <span *ngIf="promo.subscriptionTypeRetrictions && promo.subscriptionTypeRetrictions.length === 2">  GOLD & DIAMOND</span>
        <span *ngIf="promo.subscriptionTypeRetrictions && promo.subscriptionTypeRetrictions.length === 1">  {{promo.subscriptionTypeRetrictions}}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="abbonamento">
      <th mat-header-cell *matHeaderCellDef mat-sort-header (click)="sortParam('endDate', asc == true ? false : true)">{{"PROMOTION-LIST.VALID_TO" | translate}}</th>
      <td mat-cell *matCellDef="let promo">
        <span *ngIf="promo.endDate != null">  {{ promo.endDate | date: 'shortDate' : 'UTC'}}</span>
        <span *ngIf="promo.endDate == null">  {{"PROMOTION-LIST.EXP_NEVER" | translate}}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="quantita">
      <th mat-header-cell *matHeaderCellDef mat-sort-header (click)="sortParam('quantity', asc == true ? false : true)">{{"PROMOTION-LIST.QUANTITY" | translate}}</th>
      <td mat-cell *matCellDef="let promo">
        <span *ngIf="promo.quantity != null">{{promo.quantity}}</span>
        <span *ngIf="promo.quantity == null">{{"PROMOTION-LIST.UNLIMITED" | translate}}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="formula">
      <th mat-header-cell *matHeaderCellDef>{{"PROMOTION-LIST.FORMULA" | translate}}</th>
      <td mat-cell *matCellDef="let promo">
        <span *ngIf="promo.percentage > 0">{{promo.percentage}} % </span> <span *ngIf="promo.freeDays > 0">{{promo.freeDays}} free days </span> <span *ngIf="promo.amountOff">{{promo.amountOff.price}} $ </span>
      </td>
    </ng-container>
    <!-- Action Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let promo" class="w-10 text-secondary" >
        <button (click)="$event.stopPropagation()"
                [matMenuTriggerData]="{ customer: promo }"
                [matMenuTriggerFor]="actionsMenu"
                mat-icon-button
                type="button">
          <mat-icon svgIcon="mat:more_horiz"></mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>


  </table>
  <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize" [length]="lunghezza" (page)="onPageChanged($event)" class="sticky left-0"></mat-paginator>

</div>

<mat-menu #actionsMenu="matMenu" xPosition="before" yPosition="below">
  <ng-template let-customer="customer" matMenuContent>
    <button  mat-menu-item (click)="editPromotion(customer)">
      <mat-icon svgIcon="mat:edit"></mat-icon>
      <span>{{'GLOBAL.EDIT' | translate}}</span>
    </button>
    <button  mat-menu-item (click)="deletePromotion(customer.id,customer.code)">
      <mat-icon svgIcon="mat:delete"></mat-icon>
      <span>{{'GLOBAL.DELETE' | translate}}</span>
    </button>
  </ng-template>
</mat-menu>
