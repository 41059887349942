import { GuiService } from 'src/app/services/gui.service';
import {ExerciseComponent} from '../exercise.component';
import {RestService} from 'src/app/services/rest.service';
import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, FormControl} from '@angular/forms';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {TranslateService} from "@ngx-translate/core";
import {Subject, takeUntil} from "rxjs";
import { CloseConfirmComponent } from '../../close-confirm/close-confirm.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'vex-edit-exercise',
  templateUrl: './edit-exercise.component.html',
  styleUrls: ['./edit-exercise.component.scss',
  '../../../../../node_modules/quill/dist/quill.snow.css',
  '../../../../@vex/styles/partials/plugins/quill/_quill.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EditExerciseComponent implements OnInit {
  form: UntypedFormGroup
  formLang: any;
  iconFormFlag: any;
  flags = {
    en: 'flag:united-states',
    it: 'flag:italy'
  };
  typs: any[];
  muscle: any [];
  level: any[] = [
    {key: 'EASY', description: this.translate.instant("EXERCISE-LIST.EASY")},
    {key: 'INTERMEDIATE', description: this.translate.instant("EXERCISE-LIST.INTERMEDIATE")},
    {key: 'EXPERT', description: this.translate.instant("EXERCISE-LIST.EXPERT")}
  ]
  esercizio: any
  imgSelected: string

  exercises: any;
  muscolo = new FormControl('')
  attrezzatura: any[];
  imgURL: string;

  constructor(private router: ActivatedRoute,
              private restService: RestService,
              public guiService: GuiService,
              private fb: UntypedFormBuilder,
              private dialogRef: MatDialogRef<ExerciseComponent>,
              private translate: TranslateService,
              @Inject(MAT_DIALOG_DATA) private item: any['item'],
              private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.populateSelects();
    this.imgURL = environment.baseURL.exercises;
    this.changeFormLanguage(this.translate.currentLang);
    this.form = this.fb.group({
      nome_it:[null, Validators.required],
      nome_en:[null, Validators.required],
      tipo: [null, Validators.required],
      muscolo: [null, Validators.required],
      difficolta: [null, Validators.required],
      alternative: [null],
      desc_it:[null, Validators.required],
      desc_en:[null, Validators.required],
      attrezzatura: [null]
    });

    this.esercizio = this.item
    console.debug(this.item)


    this.form.controls['nome_it'].setValue(this.esercizio.i18nName['IT']);
    this.form.controls['nome_en'].setValue(this.esercizio.i18nName['EN']);
    this.form.controls['tipo'].setValue(this.esercizio.type);
    this.form.controls['muscolo'].setValue(this.esercizio.muscleGroups);
    this.form.controls['difficolta'].setValue(this.esercizio.difficulty);
    this.esercizio.exerciseAlternativeId ? this.retriveExercise(this.esercizio.exerciseAlternativeId) : null
    this.form.controls['desc_it'].setValue(this.esercizio.i18nDescription['IT']);
    this.form.controls['desc_en'].setValue(this.esercizio.i18nDescription['EN']);
    this.form.controls['attrezzatura'].setValue(this.esercizio.equipmentIds);

    for (let k in this.esercizio.name_i18n) {
      for(let [key,value] of Object.entries(this.esercizio.i18nName[k])){
        this.form.controls['nome_'+key.toLocaleLowerCase()].setValue(value);
      }
      for(let [key,value] of Object.entries(this.esercizio.i18nDescription[k])){
        this.form.controls['desc_'+key.toLocaleLowerCase()].setValue(value);
      }
    }


  }

  submit(form) {
    setTimeout(()=>this.guiService.showSpinner = true,0);
    let editEx = {
      i18nName: {
        "EN": this.form.value.nome_en,
        "IT": this.form.value.nome_it
    },
    i18nDescription: {
        "EN": this.form.value.desc_en,
        "IT": this.form.value.desc_it
    },
    difficulty: this.form.value.difficolta,
    type: this.form.value.tipo,
    muscleGroups: this.form.value.muscolo,
    name: this.form.value.nome_en,
    description: this.form.value.desc_en,
    exerciseAlternativeId: this.form.value.alternative ? this.form.value.alternative.id : null,
    image: this.imgSelected,
    imageFileName: this.imgSelected ? this.form.value.nome_en : null,
    equipmentIds: this.form.value.attrezzatura
    }

    this.restService.putService('exercises', '/'+this.item.id + '?ALL_LANGUAGES=true', editEx).subscribe((ris) => {
       this.dialogRef.close('done')
       this.guiService.openSnackBar(ris.name + this.translate.instant('GLOBAL.EDIT_SUCCESS'), this.translate.instant('GLOBAL.CLOSE'))
     })

  }


  searchAltExercise() {
    console.debug('search open')
    if(this.form.value.alternative && this.form.value.alternative.length > 3){

    let param = {
      "filterAnd": [
        {
        "field": "name",
        "operator": "like",
        "value": this.form.value.alternative
        }
        ],
      "page": 0,
      "size": 100
    }
    this.restService.postService('exercises_search', '', param).subscribe((ris)=>{
      this.exercises = ris["items"]
    })
  }else if(this.form.value.alternative && this.form.value.alternative.length < 3){
    this.exercises = null
  }
  }

  retriveExercise(id){
    this.restService.getService('exercises', id + '?ALL_LANGUAGES=true').subscribe((ris)=>{
      this.exercises = [ris]
      this.form.controls['alternative'].setValue(ris)
    })
  }

  changeFormLanguage(lang) {
    this.iconFormFlag = this.flags[lang];
    this.formLang = lang;
}

displayFn(subject) {
  console.debug(subject)
  return subject ? subject.name : undefined;
}

populateSelects(){
  this.restService.getService('exercise_type', '').subscribe((ris)=>{
    this.typs = ris
  })
  this.restService.getService('exercise_muscle', '').subscribe((ris)=>{
    this.muscle = ris
  })
  // this.restService.getService('exercise_training', '').subscribe((ris)=>{
  //   this.level = ris
  // })

  this.restService.postService('equipments', '', {page: 0, size: 0}).subscribe((ris)=>{
    this.attrezzatura = ris.items
  })
}

  onFileSelected(event) {
    this.guiService.convertFile(event.target.files[0]).subscribe(base64 => {
      console.debug("BASE64",base64);
      this.imgSelected = base64;
    });
  }

  confirmClose(){
    this.dialog.open(CloseConfirmComponent,{
      disableClose: true,
      hasBackdrop: true,
      panelClass: 'close-dialog-modalbox'
    }).afterClosed().subscribe((ris)=>{
      if(ris == 'true'){
        this.dialogRef.close()
      }
    })
  }
}
