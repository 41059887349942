import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'vex-reg-sub-info',
  templateUrl: './reg-sub-info.component.html',
  styleUrls: ['./reg-sub-info.component.scss']
})
export class RegSubInfoComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public item: any['item']) { }

  ngOnInit(): void {
  }

}
