import { EditNotificationComponent } from './edit-notification/edit-notification.component';
import { RestService } from 'src/app/services/rest.service';
import { NewNotificationComponent } from './new-notification/new-notification.component';
import { GuiService } from 'src/app/services/gui.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { UntypedFormControl } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { DialogConfirmComponent } from '../dialog-confirm/dialog-confirm.component';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import {Clipboard} from '@angular/cdk/clipboard';

@Component({
  selector: 'vex-admin-notifications',
  templateUrl: './admin-notifications.component.html',
  styleUrls: ['./admin-notifications.component.scss']
})
export class AdminNotificationsComponent implements OnInit {
  dataSource : MatTableDataSource<any> | null;
  searchCtrl = new UntypedFormControl();
  pageSize = 10;
  pageNumber = 0;
  pageSizeOptions: number[] = [5, 10, 15, 50];
  displayedColumns: string[] = ['image', 'name','publish','type', 'sub-type', 'message', 'actions'];
  notifications: any;
  lunghezza: any;
  notificationSelected = {}
  selectedFilter: any
  selectedSubFilter: any
  searchCtrlDate = new UntypedFormControl();
  /* sort var */
  asc: any;
  fieldParam: string;
  fieldParam2: string;
  searchAltFlag: boolean;
  sortFlag: boolean;

  subFilterMap = {
    "BENVENUTA": this.translate.currentLang == 'it' ? 'Benvenuta!' : 'Welcome!',
    "REGISTRAZIONE": this.translate.currentLang == 'it' ? 'Registrazione Utente' : 'User Registration',
    "CANCELLAZIONE": this.translate.currentLang == 'it' ? 'Abbonamento Cancellato' : 'Subscription Canceled',
    "PAUSA": this.translate.currentLang == 'it' ? 'Abbonamento in pausa' : 'Subscription paused',
    "SCADENZA": this.translate.currentLang == 'it' ? 'Abbonamento in scadenza' : 'Subscription Expiring',
    "SURVEY": this.translate.currentLang == 'it' ? 'Nuova Survey' : 'New User Survey',
    "ASSEGNAZIONE": this.translate.currentLang == 'it' ? 'Assegnazione Utente' : 'User Assignement',
    "CARICAMENTO_DIETA": this.translate.currentLang == 'it' ? "E' pronta una nuova dieta" : 'New Diet ready',
  }

  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  environment = environment.baseURL

  constructor(public guiService: GuiService, public translate: TranslateService, private dialog: MatDialog, public restService: RestService,private clipboard: Clipboard) { }

  ngOnInit(): void {
    this.getNotifications()
    this.asc = true;
    this.searchAltFlag = false;
    this.sortFlag = false;
    this.selectedFilter = 'ALL'
  }

  ngAfterViewInit(): void {
    //this.dataSource.paginator = this.paginator;
    //this.dataSource.sort = this.sort;
  }

  getNotifications(){
    setTimeout(()=>this.guiService.showSpinner = true,0)
    this.dataSource = new MatTableDataSource();
    let param = {
      orders: [
        {
         field: 'notificationDate',
         ascending : false
        }
      ],
      page: this.pageNumber,
      size: this.pageSize
    }
    this.restService.postService('notifications', '/search', param).subscribe((ris)=>{
      this.lunghezza = ris.totalItems
      console.debug('notifiche', ris);
      this.notifications = ris.items;
      this.dataSource.data = this.notifications;
      this.table ? this.table.renderRows() : null;
      this.guiService.showSpinner = false
    })
  }

  onPageChanged(pageEvent: PageEvent) {
    this.pageNumber = pageEvent.pageIndex+1;
    this.pageSize = pageEvent.pageSize;
    if(this.searchAltFlag == false && this.sortFlag == false){
      this.getNotifications()
    }else if(this.sortFlag == true){
      this.filter(this.fieldParam)
    }else if(this.searchAltFlag == true){
      this.subFilter(this.fieldParam2)
    }
  }


  createNotification(){
    this.dialog.open(NewNotificationComponent,{
      disableClose: true,
      hasBackdrop: true
    }).afterClosed().subscribe((notification) => {
      if(notification == 'done'){
        this.getNotifications()
      }
    })
  }

  editNotification(item?: any['item']){
    this.restService.getService('notifications', item.id + '?ALL_LANGUAGES=true').subscribe((ris)=>{
      console.debug('edit notification', ris)
    this.dialog.open(EditNotificationComponent, {
      data: ris || null,
      height: '85vh',
      panelClass: 'custom-modalboxx',
      disableClose: true,
      hasBackdrop: true
    }).afterClosed().subscribe((notification) => {
      if(notification == 'done'){
        this.getNotifications()
      }
    })
  })
  }

  deleteNotification(id): void {
    this.dialog.open(DialogConfirmComponent, {
      width: '250px',
      data: {
        type: 'notifications',
        id: id
      },
      panelClass: 'dialog-modalbox'
    }).afterClosed().subscribe((ris)=>{
      if(ris != 'true'){
        this.getNotifications()
      }
    })
  }

  // searchAltExercise() {
  //   if(this.searchCtrl.value.length > 3){
  //   this.searchAltFlag = true
  //   console.debug(this.searchCtrl.value)
  //   let param = {
  //     "filterAnd": [
  //       {
  //       "field": "i18nName."+this.translate.currentLang.toUpperCase(),
  //       "operator": "startwithignorecase",
  //       "value": this.searchCtrl.value
  //       }
  //       ],
  //     "page": this.pageNumber,
  //     "size": this.pageSize
  //   }
  //   this.restService.postService('notifications', '/search'+'?ALL_LANGUAGES=true', param).subscribe((ris)=>{
  //     this.lunghezza = ris.totalItems
  //     console.debug('notifiche', ris);
  //     this.notifications = ris.items;
  //     this.dataSource.data = this.notifications;
  //     this.table ? this.table.renderRows() : null;
  //     this.guiService.showSpinner = false
  //   })
  // }else if(this.searchCtrl.value.length == 0){
  //   this.reload()
  // }
  // }

  // sortParam(field?,asc?){
  //   this.asc = asc
  //   this.sortFlag = true
  //   this.fieldParam = field
  //   let param = {
  //     page: this.pageNumber,
  //     size: this.pageSize,
  //     orders: [
  //       {
  //        field: this.fieldParam,
  //        ascending : this.asc
  //       }
  //     ]
  //   }

  //   this.restService.postService('notifications', '/search', param).subscribe((ris)=>{
  //     this.lunghezza = ris.totalItems
  //     console.debug('notifiche', ris);
  //     this.notifications = ris.items;
  //     this.dataSource.data = this.notifications;
  //     this.table ? this.table.renderRows() : null;
  //     this.guiService.showSpinner = false
  //   })
  // }

  reload(){
    this.searchCtrlDate.reset()
    this.notificationSelected = {}
    this.selectedFilter = null
    this.searchAltFlag = false;
    this.sortFlag = false;
    this.pageNumber = 0;
    this.pageSize = 10;
    this.getNotifications()
  }

  openNotification(notif){
    console.debug(notif)
    this.notificationSelected = {}
    this.notificationSelected = notif
  }

  filter(field?){
    this.sortFlag = true
    this.searchAltFlag = false
    this.selectedSubFilter = null
    this.fieldParam = field
    this.pageNumber = 0
    let param = {}
     param = {
      page: this.pageNumber,
      size: this.pageSize,
      filterAnd: [
        {
          "field": "type",
          "operator": "eq",
          "value": this.fieldParam
        }
      ],
      orders: [
        {
         field: "notificationDate",
         ascending : false
        }
      ]
    }
    if(this.searchCtrlDate.value != null){
      console.debug(this.fieldParam)
      param = {}
      param = {
        page: this.pageNumber,
        size: this.pageSize,
        filterAnd: [

          {
            "field": "notificationDate",
            "operator": "gte",
            "dateValue": moment(this.searchCtrlDate.value + " 00:00:00","YYYY-MM-DD HH:mm:ss").format('x')
          },
          {
            "field": "notificationDate",
            "operator": "lte",
            "dateValue": moment(this.searchCtrlDate.value + " 23:59:59","YYYY-MM-DD HH:mm:ss").format('x')
          }
        ],
        orders: [
          {
           field: "notificationDate",
           ascending : false
          }
        ]
      }
      if(this.fieldParam != 'ALL'){
        param["filterAnd"].push(
          {
            "field": "type",
            "operator": "eq",
            "value": this.fieldParam
          }
        )
      }
    }

    this.restService.postService('notifications', '/search?ALL_LANGUAGES=true', param).subscribe((ris)=>{
      this.lunghezza = ris.totalItems
      this.notifications = ris.items;
      this.dataSource.data = this.notifications;
      this.table ? this.table.renderRows() : null;
      this.guiService.showSpinner = false
    })
  }

  subFilter(field?){
    let param = {}
    this.searchAltFlag = true
    this.sortFlag = false
    this.fieldParam2 = field
    this.pageNumber = 0
    param = {
      "filterAnd": [
        {
          "field": "subject",
          "operator": "eq",
          "value": this.subFilterMap[this.fieldParam2]
        },
        {
          "field": "type",
          "operator": "eq",
          "value": this.fieldParam
        }
      ],
      "orders": [
        {
          "field": "notificationDate",
          "ascending": false
        }
      ],
      page: this.pageNumber,
      size: this.pageSize
    }
    if(this.searchCtrlDate.value != null){
      param = {}
      param = {
        "filterAnd": [
          {
            "field": "subject",
            "operator": "eq",
            "value": this.subFilterMap[this.fieldParam2]
          },
          {
            "field": "type",
            "operator": "eq",
            "value": this.fieldParam
          },
          {
            "field": "notificationDate",
            "operator": "gte",
            "dateValue": moment(this.searchCtrlDate.value + " 00:00:00","YYYY-MM-DD HH:mm:ss").format('x')
          },
          {
            "field": "notificationDate",
            "operator": "lte",
            "dateValue": moment(this.searchCtrlDate.value + " 23:59:59","YYYY-MM-DD HH:mm:ss").format('x')
          }
        ],
        "orders": [
          {
            "field": "notificationDate",
            "ascending": false
          }
        ],
        page: this.pageNumber,
        size: this.pageSize
      }
    }
    this.restService.postService('notifications', '/search?ALL_LANGUAGES=true', param).subscribe((ris)=>{
      this.lunghezza = ris.totalItems
      console.debug('notifiche', ris);
      this.notifications = ris.items;
      this.dataSource.data = this.notifications;
      this.table ? this.table.renderRows() : null;
      this.guiService.showSpinner = false
    })
  }

  copyLink(notificationId,templateRef: any){
    this.clipboard.copy(this.environment.base+'/main/tabs/notification/'+ notificationId)
        let dialogRef = this.dialog.open(templateRef,{
          width:'300px'
        })
        dialogRef.afterOpened().subscribe(_ =>{
          setTimeout(() => {
            dialogRef.close();
          }, 1300);
        })
  }

}
