<div class="contenitore-generale bg-app-bar">
  <div class="title">
    <h1>{{"NOTIF.NOTIFICATION" | translate}}</h1>
  </div>
  <div class="container" *ngIf="selectedNotification.length != 0">
    <div >
      <div class="top-notification">
        <div class="data">{{selectedNotification[0].notificationDate | date: 'short'}}</div>
        <div class="label icon">
          <mat-icon svgIcon="mat:home"></mat-icon>
          <h1>{{selectedNotification[0].subject ? selectedNotification[0].subject : selectedNotification[0].title}}</h1>
        </div>

      </div>
      <p class="read-desc" [innerHTML]="selectedNotification[0].message"></p>
    </div>

    <div class="bottoni">
    <a class="m-4" mat-dialog-close>{{"GLOBAL.CLOSE" | translate}}</a>
    <button *ngIf="!selectedNotification[0].challengeId" mat-raised-button class="m-4 bottone-viola" (click)="archiveNotification(selectedNotification[0].id)">
      {{"ADMIN_NOTIFICATION.ARCHIVE" | translate}}
    </button>
    <button *ngIf="selectedNotification[0].challengeId" mat-raised-button class="m-4 bottone-viola" (click)="rejectChall(selectedNotification[0].id)">
      {{"ADMIN_NOTIFICATION.REJECT" | translate}}
    </button>
    <button *ngIf="selectedNotification[0].challengeId" mat-raised-button class="m-4 bottone-viola" (click)="acceptChall(selectedNotification[0].id)">
      {{"ADMIN_NOTIFICATION.ACCEPT" | translate}}
    </button>
  </div>
  </div>
</div>
