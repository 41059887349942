import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'vex-my-diet',
  templateUrl: './my-diet.component.html',
  styleUrls: ['./my-diet.component.scss']
})
export class MyDietComponent implements OnInit {
  pdfBase64: any;

  constructor(@Inject(MAT_DIALOG_DATA) private item: any['item']) { }

  ngOnInit(): void {
    console.debug(this.item)
    this.pdfBase64 = this.base64ToArrayBuffer(this.item)
  }

  base64ToArrayBuffer(base64) {
	  var binary_string = base64.replace(/\\n/g, '');
    binary_string =  window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array( len );
    for (var i = 0; i < len; i++)        {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
}

}
