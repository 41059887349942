import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { RestService } from 'src/app/services/rest.service';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
declare var paypal;

@Component({
  selector: 'vex-paypal-button',
  templateUrl: './paypal-button.component.html',
  styleUrls: ['./paypal-button.component.scss']
})
export class PaypalButtonComponent implements OnInit {
  @ViewChild('paypal-button-container') paypalElement: ElementRef;

  constructor(private restService: RestService, @Inject(MAT_DIALOG_DATA) private item: any['item'], public translate: TranslateService, public router: Router,private dialogRef: MatDialogRef<PaypalButtonComponent>) { }

  ngOnInit(): void {
    const self = this;
    console.debug('datas paypal received', this.item)

    paypal.Buttons({
      createSubscription: function(data, actions) {
        return actions.subscription.create(self.item.risposta);
      },
      onApprove: function(data, actions) {
        console.log('You have successfully created subscription ' + data.subscriptionID);
        self.alignSubcription(data.subscriptionID);
      },
      onError: function (err) {
        console.log("Transaction error", err);
        self.router.navigate(["/auth/payment-rejected"]);
      }
    }).render('#paypal-button-container');


}

alignSubcription(subId){
  this.restService.putService('paypal', '/user-subscriptions',{subscriptionId: subId},{ headers: {Authorization: 'Bearer ' + this.item.token},responseType: 'text' }).subscribe((ris)=>{
    console.debug('fatto')
    this.dialogRef.close()
    this.item.platform == 'mobile' ? this.router.navigate(['/auth/application']) : this.router.navigate(['/auth/login'])
  })
}


}
