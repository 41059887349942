import { TranslateService } from '@ngx-translate/core';
import { RestService } from './../../services/rest.service';
import { GuiService } from 'src/app/services/gui.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'vex-dialog-confirm',
  templateUrl: './dialog-confirm.component.html',
  styleUrls: ['./dialog-confirm.component.scss']
})
export class DialogConfirmComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    public restService: RestService,
    public guiService: GuiService,
    @Inject(MAT_DIALOG_DATA) private item: any,
    private dialogRef: MatDialogRef<any>,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
    setTimeout(() => this.guiService.showSpinner = false, 0)
    console.debug(this.item)
  }

  delete() {
    console.debug(this.item.type, this.item.id)
    if (this.guiService.userLogged.user.roles.includes('COLLAB') == false) {
      if (this.item.type !== 'deleteVideoCategory') {
        this.restService.deleteService(this.item.type, '/' + this.item.id, {}).subscribe((ris) => {
          console.debug(ris)
          this.dialogRef.close()
        })
      }else{
        this.dialogRef.close()
      }

    } else {
      window.alert("Questa operazione non è permessa, contattare l'amministratore.")
      this.dialogRef.close()
    }

  }

  close() {
    this.dialogRef.close('true')
  }

}
