<div class="contenitore-generale bg-app-bar">
  <div class="title">
    <h1 *ngIf="translate.currentLang == 'it'">Avviso</h1>
    <h1 *ngIf="translate.currentLang == 'en'">Notice</h1>
  </div>
  <div class="container">
    <p *ngIf="translate.currentLang == 'it'">Per apportare modifiche al tuo abbonamento, scrivi ad <a href = "mailto: info@itsdesifit.com">info@itsdesifit.com</a></p>
    <p *ngIf="translate.currentLang == 'en'">To make changes to your subscription, please email <a href = "mailto: info@itsdesifit.com">info@itsdesifit.com</a></p>

    <div class="bottoni">
      <button mat-dialog-close>{{"GLOBAL.CLOSE" | translate}}</button>
    </div>
  </div>
</div>
