<div class="mt-6 flex flex-col md:flex-row md:items-start" *ngIf="admin">

  <div class="flex-auto">
    <div class="card">
      <div class="px-gutter py-4 border-b">
        <h2 class="title m-0">{{"USER-EDIT.INFO" | translate}}</h2>
      </div>

      <div class="px-gutter py-4">
        <form [formGroup]="form">
          <div class="info-field">
            <div class="py-3 flex items-center">
              <div @scaleIn
                   class="w-10 h-10 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
                <mat-icon class="icon-sm" svgIcon="mat:phone"></mat-icon>
              </div>

              <div @fadeInRight>

                <mat-form-field>
                  <mat-label>{{"USER-EDIT.PHONE_NUMBER" | translate}}</mat-label>
                  <input matInput formControlName="phonenumber" type="number"/>
                </mat-form-field>

              </div>
            </div>
          </div>

          <div class="info-field">
            <div class="py-3 flex items-center">
              <div @scaleIn
                   class="w-10 h-10 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
                <mat-icon class="icon-sm" svgIcon="mat:date_range"></mat-icon>
              </div>

              <div @fadeInRight>
                <mat-form-field>
                  <mat-label>{{"USER-EDIT.REG_DATE" | translate}}</mat-label>
                  <input [disabled]="true" [matDatepicker]="datepickerRef" matInput formControlName="registration" />
                  <mat-datepicker-toggle [for]="datepickerRef" matSuffix></mat-datepicker-toggle>
                  <mat-datepicker #datepickerRef></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="info-field">
            <div class="py-3 flex items-center">
              <div @scaleIn
                   class="w-10 h-10 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
                <mat-icon class="icon-sm" svgIcon="mat:mail"></mat-icon>
              </div>

              <div @fadeInRight>
                <mat-form-field>
                  <mat-label>{{"USER-EDIT.EMAIL" | translate}}</mat-label>
                  <input matInput required formControlName="email"/>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="info-field">
            <div class="py-3 flex items-center">
              <div @scaleIn
                   class="w-10 h-10 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
                <fa-icon [icon]="icon.faBirthday" class="icon-sm"></fa-icon>
              </div>

              <div @fadeInRight>
                <mat-form-field>
                  <mat-label>{{"USER-EDIT.BIRT_DATE" | translate}}</mat-label>
                  <input [matDatepicker]="datepickerRef2" matInput formControlName="birthday" />
                  <mat-datepicker-toggle [for]="datepickerRef2" matSuffix></mat-datepicker-toggle>
                  <mat-datepicker #datepickerRef2></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="bottoni">
            <button mat-raised-button  class="m-4 bottone-viola" (click)="submit()">{{"GLOBAL.EDIT" | translate}}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
