import { TranslateService } from '@ngx-translate/core';
import { GuiService } from './../../services/gui.service';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { scaleIn400ms } from 'src/@vex/animations/scale-in.animation';
import { stagger80ms } from 'src/@vex/animations/stagger.animation';
import { RestService } from 'src/app/services/rest.service';
import { MatDialog } from '@angular/material/dialog';
import { PaypalButtonComponent } from '../register/paypal-button/paypal-button.component';
import { environment } from 'src/environments/environment';
import { ErrorUpgradeModalComponent } from './error-upgrade-modal/error-upgrade-modal.component';
import { ConfirmUpgradeModalComponent } from './confirm-upgrade-modal/confirm-upgrade-modal.component';
import * as moment from 'moment';
import { RegSubInfoComponent } from '../register/reg-sub-info/reg-sub-info.component';

@Component({
  selector: 'vex-upgrade-plan',
  templateUrl: './upgrade-plan.component.html',
  styleUrls: ['./upgrade-plan.component.scss'],
  animations: [
    stagger80ms,
    fadeInUp400ms,
    scaleIn400ms,
    fadeInRight400ms
  ]
})
export class UpgradePlanComponent implements OnInit {
  verticalPlanFormGroup: UntypedFormGroup;
  verticalAccountFormGroup: UntypedFormGroup;
  selectedProgram: any;
  subs:any;
  showBtnSpinner: any;
  platform: any;

  subs_M: any = [];
  subs_Q: any = [];
  subs_H: any = [];
  subs_Y: any = [];
  avaiable_duration: any = []
  default_selection: any = {
    duration: 'M',
    type: 'GOLD'
  }

  constructor(
    private fb: UntypedFormBuilder,
    private snackbar: MatSnackBar,
    private restService: RestService,
    private router: Router,
    public guiService: GuiService,
    public translate: TranslateService,
    private dialog: MatDialog,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.platform = this.route.snapshot.paramMap.get('device')
    this.getSubs()
    this.showBtnSpinner = false

    this.verticalAccountFormGroup = this.fb.group({
      email: [null, Validators.required],
      password: [null, Validators.required]
    });
    if(!this.guiService.userLogged){
      this.router.navigate(['auth/login']);
    }
  }

  getSubs(){
    this.restService.getService('subscriptions', 'actives').subscribe((ris)=>{
      this.subs = []
      for(let i = 0; i< ris.length; i++){
        let sub = ris[i]
        if(sub.duration == 'M'){
          let now = moment()
          if(sub.endDate && moment(sub.endDate,'x').isAfter(now)){
            this.subs_M.push(sub)
            this.avaiable_duration.includes('M') ? null : this.avaiable_duration.push('M')
          }
        }else if(sub.duration == 'Q'){
          let now = moment()
          if(sub.endDate && moment(sub.endDate,'x').isAfter(now)){
            this.subs_Q.push(sub)
            this.avaiable_duration.includes('Q') ? null : this.avaiable_duration.push('Q')
          }
        } else if(sub.duration == 'H'){
          let now = moment()
          if(sub.endDate && moment(sub.endDate,'x').isAfter(now)){
            this.subs_H.push(sub)
            this.avaiable_duration.includes('H') ? null : this.avaiable_duration.push('H')
          }
        } else if(sub.duration == 'Y'){
          let now = moment()
          if(sub.endDate && moment(sub.endDate,'x').isAfter(now)){
            this.subs_Y.push(sub)
            this.avaiable_duration.includes('Y') ? null : this.avaiable_duration.push('Y')
          }
        }
      }
      this.subs_M.reverse()
      this.subs_Q.reverse()
      this.subs_H.reverse()
      this.subs_Y.reverse()

      console.debug('M',this.subs_M)
      console.debug('Q',this.subs_Q)
      console.debug('H',this.subs_H)
      console.debug('Y',this.subs_Y)
      console.debug(this.avaiable_duration)
    })
  }

  login(){
    let login = {
      email: this.verticalAccountFormGroup.value.email,
      password: this.verticalAccountFormGroup.value.password
    }
    this.showBtnSpinner = true
    this.restService.doLogin('',login).subscribe({
      next: (ris)=>{
        this.restService.tknSubj.next(ris.body["token"])
        console.debug("LOGIN RES",ris);
        let tkn = ris.body["token"]
        sessionStorage.removeItem("tkn");
        sessionStorage.setItem("tkn", tkn);
        this.getCurrentInfo(tkn)
      },error: (e)=>{
        this.guiService.openSnackBar(this.translate.instant("MSG.LOGIN_FAIL"), this.translate.instant("MSG.CLOSE"), 'error')
        this.showBtnSpinner = false
      }

    })
  }

  getCurrentInfo(tkn){
    this.restService.getService('current_user', '').subscribe((response)=>{

      this.dialog.open(ConfirmUpgradeModalComponent,{
        disableClose: true,
        hasBackdrop: true,
        width: '90vw',
        maxWidth: '900px'
      }).afterClosed().subscribe((confirm) => {
        if(confirm == 'done'){
          console.debug('upgrade confirmed')
          this.upgrade(response.userPaymentInfo.paymentType,tkn)
        }
      })
    })
  }

  upgrade(paymentType, tkn){
    console.debug(paymentType)
    this.findPlanObj()
    if(paymentType == 'CHARGEBEE'){
      let checkout = {
        "cancelPageUrl": environment.baseURL.base +"/#/auth/payment-rejected",
        "successPageUrl": environment.baseURL.base +"/#/main/home",
        "subscriptionId": this.selectedProgram.id
      }

      this.restService.chargeBeeCheckout('chargebee','/upgrade-plan',checkout,tkn).subscribe({
        next: (res)=>{
          if(res.checkoutNewUrl != null){
            window.location.href = res.checkoutNewUrl
          }else{
            this.router.navigate(['main/user/sub'])
          }
        },error: (e)=>{
          this.dialog.open(ErrorUpgradeModalComponent,{
            disableClose: true,
            hasBackdrop: true
          })
          this.showBtnSpinner = false
        }
      })
    }
  }

  selectPlan(type: any){
    this.default_selection.type = type
    console.debug(this.default_selection.type)
  }

  selectDuration(duration:any){
    this.default_selection.duration = duration
    console.debug(this.default_selection.duration)
  }

  findPlanObj(){
    let obj
    if(this.default_selection.duration == 'M'){
      if(this.default_selection.type == 'GOLD'){
        obj = this.subs_M.find(o => o.type === 'GOLD');
      }else if(this.default_selection.type == 'DIAMOND'){
        obj = this.subs_M.find(o => o.type === 'DIAMOND');
      }
    }
    if(this.default_selection.duration == 'Q'){
      if(this.default_selection.type == 'GOLD'){
        obj = this.subs_Q.find(o => o.type === 'GOLD');
      }else if(this.default_selection.type == 'DIAMOND'){
        obj = this.subs_Q.find(o => o.type === 'DIAMOND');
      }
    }
    if(this.default_selection.duration == 'H'){
      if(this.default_selection.type == 'GOLD'){
        obj = this.subs_H.find(o => o.type === 'GOLD');
      }else if(this.default_selection.type == 'DIAMOND'){
        obj = this.subs_H.find(o => o.type === 'DIAMOND');
      }
    }
    if(this.default_selection.duration == 'Y'){
      if(this.default_selection.type == 'GOLD'){
        obj = this.subs_Y.find(o => o.type === 'GOLD');
      }else if(this.default_selection.type == 'DIAMOND'){
        obj = this.subs_Y.find(o => o.type === 'DIAMOND');
      }
    }

    return this.selectedProgram = obj
  }

  showSubDetails(sub:any){
    console.debug('dettaglio',sub)
    this.dialog.open(RegSubInfoComponent,{
      data: sub,
      panelClass: "reg-sub-info-modalbox",
      width: '90vw',
      maxWidth: '90vw'
    })
  }

}
