import { UserProgramInfoComponent } from './user-program-info/user-program-info.component';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GuiService } from 'src/app/services/gui.service';
import { RestService } from 'src/app/services/rest.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'vex-user-program',
  templateUrl: './user-program.component.html',
  styleUrls: ['./user-program.component.scss']
})
export class UserProgramComponent implements OnInit {

  programs = []
  environment = environment.baseURL.workout_programs

  constructor(public guiService: GuiService, private rotta: Router, private dialog: MatDialog, public restService: RestService ) { }

  ngOnInit(): void {
    this.getPrograms()
  }

  getPrograms(){
    setTimeout(()=>this.guiService.showSpinner = true,0)
    this.restService.postService('workout_programs', '/search', {page: 0, size: 0}).subscribe((ris)=>{
      for(let program in ris.items){
        let checkIfDraft = ris.items[program].workoutProgramStatus
        if(checkIfDraft == 'pubblica'){
          this.programs.push(ris.items[program])
        }
      }
      console.debug(this.programs)
      this.guiService.showSpinner = false
    })
  }

  programInfo(item?: any['item']){
    this.restService.getService('workout_programs', item.id).subscribe((ris)=>{
      this.dialog.open(UserProgramInfoComponent, {
      data: ris || null,
      panelClass: 'program-info',
      width: '800px'
    });
    })
  }

}
