<div class="container mt-4">
  <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">
    <h2 class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l hidden sm:block flex-none">
      {{"NUTRI_TABLE.NUTRI" | translate}}
    </h2>
    <div
      class="rounded-full border px-4 max-w-[300px] flex-auto flex items-center border border-gray-300">
      <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon>
      <input [formControl]="searchCtrl" class="px-4 py-2 border-0 outline-none w-full bg-transparent" placeholder="{{'GLOBAL.SEARCH' | translate}}" type="search" />
    </div>

    <!-- <span class="flex-1"></span> -->

    <!-- <button class="ml-4 flex-none" mat-icon-button matTooltip="Filter Columns" type="button">
      <mat-icon svgIcon="mat:filter_list"></mat-icon>
    </button> -->
    <button (click)="reload()" class="ml-4 flex-none" color="primary" mat-mini-fab matTooltip="refresh" type="button" >
      <mat-icon svgIcon="mat:autorenew"></mat-icon>
    </button>
    <button class="ml-4 flex-none" color="primary" mat-mini-fab type="button" (click)="search();resetPage()">
      <mat-icon svgIcon="mat:search"></mat-icon>
    </button>
  </div>
  <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center bg2">
    <div class="flex items-center filter-bar">
      <nav class="vex-tabs border-0 flex-auto cerca" mat-tab-nav-bar>
        <mat-form-field class="nut-form">
          <mat-label class="label-light">{{"SUB_TABLE.REG" | translate}}</mat-label>
          <input matInput [formControl]="searchCtrlReg" type="date">
        </mat-form-field>
        <mat-form-field class="nut-form">
          <mat-label class="label-light">{{"SUB_TABLE.PAY" | translate}}</mat-label>
          <mat-select [formControl]="searchCtrlPay">
            <mat-option [value]="'ACTIVE'">{{"STATUS.PAYED" | translate}}</mat-option>
            <mat-option [value]="'PAUSED'">{{"STATUS.PAUSED" | translate}}</mat-option>
            <mat-option [value]="'SUSPENDED'">{{"STATUS.NOT_PAYED" | translate}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="nut-form">
          <mat-label class="label-light">{{"SUB_TABLE.SURV" | translate}}</mat-label>
          <input matInput [formControl]="searchCtrlSurvey" type="date">
        </mat-form-field>
        <mat-form-field class="nut-form">
          <mat-label class="label-light">{{"SUB_TABLE.DIET" | translate}}</mat-label>
          <input matInput [formControl]="searchCtrlDiet" type="date">
        </mat-form-field>
      </nav>

    </div>
  </div>
  <table mat-table [dataSource]="dataSource" *ngIf="users" matSort>
    <ng-container matColumnDef="utente">
      <th mat-header-cell *matHeaderCellDef>{{"SUB_TABLE.USER" | translate}}</th>
      <td mat-cell *matCellDef="let user" (click)="userDetail(user)">{{ user.user.email }}</td>
    </ng-container>
    <ng-container matColumnDef="data-iscrizione">
      <th mat-header-cell *matHeaderCellDef mat-sort-header (click)="sortParam('registrationDate', asc == true ? false : true)">{{"CUST_TABLE.DATE" | translate}}</th>
      <td mat-cell *matCellDef="let user">{{ user.masterData.registrationDate | date: 'shortDate'}}</td>
    </ng-container>
    <ng-container matColumnDef="stato-pagamento">
      <th mat-header-cell *matHeaderCellDef mat-sort-header (click)="sortParam('subscriptionStatus', asc == true ? false : true)">{{"NUTRI_TABLE.PAY" | translate}}</th>
      <td mat-cell *matCellDef="let user">
        <span *ngIf="user.userPaymentInfo && user.userPaymentInfo.subscriptionStatus == 'SUSPENDED' || user.userPaymentInfo == null">
          <fa-icon [icon]="icon.faCircle" class="icon not-payed"></fa-icon>

        </span>
        <span *ngIf="user.userPaymentInfo && user.userPaymentInfo.subscriptionStatus != 'SUSPENDED'">
            <fa-icon [icon]="icon.faCircle" class="icon payed"></fa-icon>
          </span>
        <!-- {{ user.userPaymentInfo != null && user.userPaymentInfo.subscriptionPaymentStatus != null ? icons[user.userPaymentInfo.subscriptionPaymentStatus]  : 'N/A' }} -->
      </td>
    </ng-container>
    <ng-container matColumnDef="questionario">
      <th mat-header-cell *matHeaderCellDef mat-sort-header (click)="sortParam('lastSurvey', asc == true ? false : true)">{{"NUTRI_TABLE.SURVEY" | translate}}</th>
      <td mat-cell *matCellDef="let user">
        <span *ngIf="user.masterData.lastSurvey != null && check30Days(user.masterData.lastSurvey) == false"><fa-icon [icon]="icon.faCheck" class="icon survey is-survey"></fa-icon>{{user.masterData.lastSurvey | date: 'shortDate'}}</span>
        <span *ngIf="user.masterData.lastSurvey == null || check30Days(user.masterData.lastSurvey) == true"><fa-icon [icon]="icon.faCheck" class="icon survey not-survey"></fa-icon></span>
      </td>
    </ng-container>
    <ng-container matColumnDef="upload-diet">
      <th mat-header-cell *matHeaderCellDef mat-sort-header (click)="sortParam('lastDietUploaded', asc == true ? false : true)">{{"NUTRI_TABLE.UPLOAD" | translate}}</th>
      <td mat-cell *matCellDef="let user">
        <span *ngIf="user.masterData.lastDietUploaded != null && checkDietUpload(user.masterData.lastDietUploaded,user.masterData.lastSurvey)"><fa-icon [icon]="icon.faCheck" class="icon survey is-survey"></fa-icon>{{user.masterData.lastDietUploaded | date: 'shortDate'}}</span>
        <span *ngIf="user.masterData.lastDietUploaded == null || !checkDietUpload(user.masterData.lastDietUploaded,user.masterData.lastSurvey)"><fa-icon [icon]="icon.faCheck" class="icon survey not-survey"></fa-icon></span>
      </td>
    </ng-container>
    <!-- Action Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row" class="w-10 text-secondary" >
        <button (click)="$event.stopPropagation()"
                [matMenuTriggerData]="{ customer: row }"
                [matMenuTriggerFor]="actionsMenu"
                mat-icon-button
                type="button">
          <mat-icon svgIcon="mat:more_horiz"></mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>


  </table>
  <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize" [length]="lunghezza" (page)="onPageChanged($event)" class="sticky left-0"></mat-paginator>

</div>

<mat-menu #actionsMenu="matMenu" xPosition="before" yPosition="below">
  <ng-template let-customer="customer" matMenuContent>
    <button  mat-menu-item (click)="userDetail(customer)">
      <mat-icon svgIcon="mat:description"></mat-icon>
      <span>{{"NUTRI_TABLE.DETAIL" | translate}}</span>
    </button>
  </ng-template>
</mat-menu>
