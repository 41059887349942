<div class="container mt-4">
  <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">
    <h2 class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l hidden sm:block flex-none">
      <span *ngIf="checkedUser.length> 0" class="mr-2 pr-2 border-r flex-none">
        <span *ngIf="checkedUser.length == 1">{{checkedUser.length}} {{"NUTRI_TABLE.USER_SEL" | translate}}</span>
        <span *ngIf="checkedUser.length > 1">{{checkedUser.length}} {{"NUTRI_TABLE.USERS_SEL" | translate}}</span>
      </span>
      <span *ngIf="checkedUser.length == 0">{{"NUTRI_TABLE.NUTRI" | translate}}</span>
    </h2>

    <div *ngIf="checkedUser.length > 0" class="mr-4 pr-4 border-r flex-none">
      <button [matMenuTriggerFor]="assignNutritionist"
              mat-icon-button
              matTooltip="assign to nutritionist"
              type="button">
        <mat-icon svgIcon="mat:person_add"></mat-icon>
      </button>
    </div>

    <div
      class="rounded-full border px-4 max-w-[300px] flex-auto flex items-center border border-gray-300 mr-3">
      <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon>
      <input [formControl]="searchCtrl" class="px-4 py-2 border-0 outline-none w-full bg-transparent" placeholder="{{'SUB_TABLE.S_EMAIL' | translate}}" type="search" />
    </div>
    <div
      class="rounded-full border px-4 max-w-[230px] flex-auto flex items-center border border-gray-300 mr-3">
      <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon>
      <input [formControl]="searchCtrlName" class="px-4 py-2 border-0 outline-none w-full bg-transparent" placeholder="{{'SUB_TABLE.S_NAME' | translate}}" type="search" />
    </div>
    <div
      class="rounded-full border px-4 max-w-[230px] flex-auto flex items-center border border-gray-300 mr-3">
      <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon>
      <input [formControl]="searchCtrlLastName" class="px-4 py-2 border-0 outline-none w-full bg-transparent" placeholder="{{'SUB_TABLE.S_LASTNAME' | translate}}" type="search" />
    </div>
    <button class="ml-4 flex-none" color="primary" mat-mini-fab type="button" (click)="search();resetPage()">
      <mat-icon svgIcon="mat:search"></mat-icon>
    </button>
    <button (click)="reload()" class="ml-4 flex-none blue-bottone" color="primary" mat-mini-fab matTooltip="reload" type="button" >
      <mat-icon svgIcon="mat:autorenew"></mat-icon>
    </button>
    <button class="ml-4 flex-none" color="primary" mat-mini-fab type="button" (click)="report()">
      <mat-icon svgIcon="mat:cloud_download"></mat-icon>
    </button>
    <span class="flex-1"></span>

    <!-- <button class="ml-4 flex-none" mat-icon-button matTooltip="Filter Columns" type="button">
      <mat-icon svgIcon="mat:filter_list"></mat-icon>
    </button> -->

  </div>
  <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center bg2">
    <div class="flex items-center filter-bar">
      <nav class="vex-tabs border-0 flex-auto cerca" mat-tab-nav-bar>
        <mat-form-field class="nut-form">
          <mat-label class="label-light">{{"SUB_TABLE.NUT" | translate}}</mat-label>
          <mat-select [formControl]="searchCtrlNutr">
            <mat-option [value]="n.userId" *ngFor="let n of nutrizioniste">{{ n.name }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="nut-form">
          <mat-label class="label-light">{{"CUST_TABLE.DATE" | translate}}</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input matStartDate placeholder="M/DD/YYYY" [formControl]="searchCtrlReg_from">
            <input matEndDate placeholder="M/DD/YYYY" [formControl]="searchCtrlReg_to">
          </mat-date-range-input>

          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
        <mat-form-field class="nut-form">
          <mat-label class="label-light">{{"SUB_TABLE.PAY" | translate}}</mat-label>
          <mat-select [formControl]="searchCtrlPay">
            <mat-option [value]="'ACTIVE'">{{"STATUS.PAYED" | translate}}</mat-option>
            <mat-option [value]="'PAUSED'">{{"STATUS.PAUSED" | translate}}</mat-option>
            <mat-option [value]="'SUSPENDED'">{{"STATUS.NOT_PAYED" | translate}}</mat-option>
            <mat-option [value]="'CUST_CANCELED'">{{"STATUS.CANCELED" | translate}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="nut-form">
          <mat-label class="label-light">{{"SUB_TABLE.SURV" | translate}}</mat-label>
          <mat-date-range-input [rangePicker]="picker2">
            <input matStartDate placeholder="M/DD/YYYY" [formControl]="searchCtrlSurvey_from">
            <input matEndDate placeholder="M/DD/YYYY" [formControl]="searchCtrlSurvey_to">
          </mat-date-range-input>

          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-date-range-picker #picker2></mat-date-range-picker>
        </mat-form-field>
        <mat-form-field class="nut-form">
          <mat-label class="label-light">{{"NUTRI_TABLE.UPLOAD" | translate}}</mat-label>
          <mat-date-range-input [rangePicker]="picker3">
            <input matStartDate placeholder="M/DD/YYYY" [formControl]="searchCtrlDiet_from">
            <input matEndDate placeholder="M/DD/YYYY" [formControl]="searchCtrlDiet_to">
          </mat-date-range-input>

          <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
          <mat-date-range-picker #picker3></mat-date-range-picker>
        </mat-form-field>
      </nav>

    </div>
  </div>
  <table mat-table [dataSource]="dataSource" *ngIf="users" matSort>
    <ng-container matColumnDef="checkbox">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row; let i = index"><mat-checkbox (click)="selectUser(row.user.id)"></mat-checkbox></td>
    </ng-container>

    <ng-container matColumnDef="utente">
      <th mat-header-cell *matHeaderCellDef  (click)="searchAltFlag == false ?sortParam('email', asc == true ? false : true):search('email', asc == true ? false : true)">{{"SUB_TABLE.USER" | translate}}</th>
      <td mat-cell *matCellDef="let user"><span class="name_lastname">  {{ user.masterData.name }} {{ user.masterData.lastName }}</span><br><span class="mail">{{ user.user.email }}</span></td>
    </ng-container>
    <ng-container matColumnDef="nutrizionista">
      <th mat-header-cell *matHeaderCellDef mat-sort-header (click)="searchAltFlag == false ?sortParam('nutritionistId', asc == true ? false : true):search('nutritionistId', asc == true ? false : true)">{{"SUB_TABLE.NUTRI" | translate}}</th>
      <td mat-cell *matCellDef="let user"><span *ngIf="user.masterData.nutritionistId != null">{{nutIndex[user.masterData.nutritionistId]}}</span></td>
    </ng-container>
    <ng-container matColumnDef="data-iscrizione">
      <th mat-header-cell *matHeaderCellDef mat-sort-header (click)="searchAltFlag == false ?sortParam('registrationDate', asc == true ? false : true):search('registrationDate', asc == true ? false : true)">{{"CUST_TABLE.DATE" | translate}}</th>
      <td mat-cell *matCellDef="let user">{{ user.masterData.registrationDate | date: 'shortDate'}}</td>
    </ng-container>
    <ng-container matColumnDef="stato-pagamento">
      <th mat-header-cell *matHeaderCellDef mat-sort-header (click)="searchAltFlag == false ?sortParam('subscriptionStatus', asc == true ? false : true): search('subscriptionStatus', asc == true ? false : true)">{{"NUTRI_TABLE.PAY" | translate}}</th>
      <td mat-cell *matCellDef="let user">
        <span *ngIf="user.userPaymentInfo && user.userPaymentInfo.subscriptionStatus == 'SUSPENDED' || user.userPaymentInfo == null">
          <fa-icon [icon]="icon.faCircle" class="icon not-payed"></fa-icon>
          {{"STATUS.NOT_PAYED" | translate}}
        </span>
        <span *ngIf="user.userPaymentInfo && user.userPaymentInfo.subscriptionStatus == 'CUST_CANCELED'">
          <fa-icon [icon]="icon.faCircle" class="icon not-payed"></fa-icon>
          {{"STATUS.CANCELED" | translate}}
        </span>
        <span *ngIf="user.userPaymentInfo && user.userPaymentInfo.subscriptionStatus == 'ACTIVE'">
            <fa-icon [icon]="icon.faCircle" class="icon payed"></fa-icon>
            {{"STATUS.PAYED" | translate}}
        </span>
        <span *ngIf="user.userPaymentInfo && user.userPaymentInfo.subscriptionStatus == 'PAUSED'">
          <fa-icon [icon]="icon.faCircle" class="icon paused"></fa-icon>
          {{"STATUS.PAUSED" | translate}}
      </span>
        <!-- {{ user.userPaymentInfo != null && user.userPaymentInfo.subscriptionPaymentStatus != null ? icons[user.userPaymentInfo.subscriptionPaymentStatus]  : 'N/A' }} -->
      </td>
    </ng-container>
    <ng-container matColumnDef="questionario">
      <th mat-header-cell *matHeaderCellDef mat-sort-header (click)="searchAltFlag == false ?sortParam('lastSurvey', asc == true ? false : true) : search('lastSurvey', asc == true ? false : true)">{{"NUTRI_TABLE.SURVEY" | translate}}</th>
      <td mat-cell *matCellDef="let user">
        <span *ngIf="user.masterData.lastSurvey != null && check30Days(user.masterData.lastSurvey) == false"><fa-icon [icon]="icon.faCheck" class="icon survey is-survey"></fa-icon>{{user.masterData.lastSurvey | date: 'shortDate'}}</span>
        <span *ngIf="user.masterData.lastSurvey == null || check30Days(user.masterData.lastSurvey) == true"><fa-icon [icon]="icon.faCheck" class="icon survey not-survey"></fa-icon></span>
      </td>
    </ng-container>
    <ng-container matColumnDef="upload-diet">
      <th mat-header-cell *matHeaderCellDef mat-sort-header (click)="searchAltFlag == false ?sortParam('lastDietUploaded', asc == true ? false : true): search('lastDietUploaded', asc == true ? false : true)">{{"NUTRI_TABLE.UPLOAD" | translate}}</th>
      <td mat-cell *matCellDef="let user">
        <span *ngIf="user.masterData.lastDietUploaded != null && checkDietUpload(user.masterData.lastDietUploaded,user.masterData.lastSurvey)"><fa-icon [icon]="icon.faCheck" class="icon survey is-survey"></fa-icon>{{user.masterData.lastDietUploaded | date: 'shortDate'}}</span>
        <span *ngIf="user.masterData.lastDietUploaded == null || !checkDietUpload(user.masterData.lastDietUploaded,user.masterData.lastSurvey)"><fa-icon [icon]="icon.faCheck" class="icon survey not-survey"></fa-icon></span>
      </td>
    </ng-container>
    <!-- Action Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row" class="w-10 text-secondary" >
        <button (click)="$event.stopPropagation()"
                [matMenuTriggerData]="{ customer: row }"
                [matMenuTriggerFor]="actionsMenu"
                mat-icon-button
                type="button">
          <mat-icon svgIcon="mat:more_horiz"></mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>


  </table>
  <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize" [length]="lunghezza" (page)="onPageChanged($event)" class="sticky left-0"></mat-paginator>

</div>

<mat-menu #actionsMenu="matMenu" xPosition="before" yPosition="below">
  <ng-template let-customer="customer" matMenuContent>
    <button  mat-menu-item (click)="userDetail(customer)">
      <mat-icon svgIcon="mat:description"></mat-icon>
      <span>{{"NUTRI_TABLE.DETAIL" | translate}}</span>
    </button>
  </ng-template>
</mat-menu>

 <mat-menu #assignNutritionist="matMenu" xPosition="before" yPosition="below">
  <span class="mat-menu-item" *ngFor="let n of nutrizioniste; let i = index">
    <p>{{n.name}}</p>
    <!-- <mat-radio-button [value]="n.nome" (click)="sceltaNutrizionista(n,$event)"></mat-radio-button> -->
    <p mat-menu-item class="assign" >
      <fa-icon [icon]="icon.faPlus" (click)="assegna(n)"></fa-icon>
    </p>
  </span>


 </mat-menu>
