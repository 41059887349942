import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'vex-postpartum-popup',
  templateUrl: './postpartum-popup.component.html',
  styleUrls: ['./postpartum-popup.component.scss']
})
export class PostpartumPopupComponent implements OnInit {

  constructor(public translate: TranslateService, private dialogRef: MatDialogRef<any>) { }

  ngOnInit(): void {

  }

  accept(){
    this.dialogRef.close()
  }

  close(){
    this.dialogRef.close('true')
  }

}
