import { RestService } from './../../services/rest.service';
import { ProgramInfoComponent } from './program-info/program-info.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GuiService } from 'src/app/services/gui.service';
import { Component, OnInit } from '@angular/core';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'vex-console',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  icon = {
    "faInfo": faInfo
  }

  programs = []
  environment = environment.baseURL.workout_programs

  constructor(public restService: RestService ,public guiService: GuiService, private rotta: Router, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.getPrograms()
    if(sessionStorage.getItem("selectedProgram") && sessionStorage.getItem("selectedProgram") != '' || this.guiService.userLogged.lastWorkoutProgram.workoutProgramId != 'string' && sessionStorage.getItem('selectedProgramId')){
      this.rotta.navigate(['/main/home'])
      this.guiService.displaySelectInToolbar = true
    }
    else{
    setTimeout(()=>this.guiService.showSpinner = false,0)
    setTimeout(()=>this.guiService.displaySelectInToolbar = false,0)
    }
  }

  getPrograms(){
    setTimeout(()=>this.guiService.showSpinner = true,0)
    this.restService.postService('workout_programs', '/search', {page: 0, size: 0}).subscribe((ris)=>{
      for(let program in ris.items){
        let checkIfDraft = ris.items[program].workoutProgramStatus
        if(checkIfDraft == 'pubblica'){
          this.programs.push(ris.items[program])
        }
      }
      console.debug(this.programs)
      this.guiService.showSpinner = false
    })
  }

  // selectProgram(program){
  //   /*METODO PROVVISORIO LOCAL STORAGE */
  //   let newProgram = {
  //     day: 0,
  //     week: 0,
  //     workoutProgramId: program.id,
  //     exerciseId: "string",
  //     id: "string"
  //   }
  //   this.restService.postService('user_workout_programs','', newProgram).subscribe((ris)=>{
  //     console.debug(this.guiService.userLogged.lastWorkoutProgram)
  //   sessionStorage.setItem("selectedProgram", program.name)
  //   sessionStorage.setItem("selectedProgramId", program.id)
  //   this.guiService.selectedProgram = program.name
  //   console.debug(this.guiService.selectedProgram)
  //   this.guiService.displaySelectInToolbar = true
  //   this.rotta.navigate(['/main/home'])
  //   })

  // }

  programInfo(item?: any['item']) {
    this.restService.getService('workout_programs', item.id).subscribe((ris)=>{
      this.dialog.open(ProgramInfoComponent, {
      data: ris || null,
      panelClass: 'program-info',
      width: '800px'
    });
    })

  }

}
