import { MatDialog } from '@angular/material/dialog';
import {GuiService} from 'src/app/services/gui.service';
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {Component, Inject, OnInit} from "@angular/core";
import {
  FormControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import {MatSnackBar} from "@angular/material/snack-bar";
import {faAdd, faClose} from "@fortawesome/free-solid-svg-icons";
import {TranslateService} from "@ngx-translate/core";
import {fadeInRight400ms} from "src/@vex/animations/fade-in-right.animation";
import {fadeInUp400ms} from "src/@vex/animations/fade-in-up.animation";
import {scaleIn400ms} from "src/@vex/animations/scale-in.animation";
import {stagger80ms} from "src/@vex/animations/stagger.animation";
import {RestService} from "src/app/services/rest.service";
import {environment} from 'src/environments/environment';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {WorkoutComponent} from '../workout.component';
import { CloseConfirmComponent } from '../../close-confirm/close-confirm.component';

@Component({
  selector: "vex-workout-edit",
  templateUrl: "./workout-edit.component.html",
  styleUrls: ["./workout-edit.component.scss"],
  animations: [stagger80ms, fadeInUp400ms, scaleIn400ms, fadeInRight400ms],
})
export class WorkoutEditComponent implements OnInit {
  icon = {
    faAdd: faAdd,
    faClose: faClose,
  };
  formLang: any = 'it';
  iconFormFlag: any;
  flags = {
    en: 'flag:united-states',
    it: 'flag:italy'
  };

  typs: any;

  verticalPlanFormGroup: UntypedFormGroup;
  verticalSelectExerciseFormGroup: UntypedFormGroup;
  verticalSetExerciseFormGroup: any;

  imgURL: string;
  imgURLw: string;
  pre_type;
  selectedValue;
  week_number: any = []
  newSelectedValue;
  programs: any;
  exercises: any;
  selectedExercises: any = [];
  filteredIds = {}

  phases = [
    {
      name: "",
      i18nName: {},
      workoutPhaseTypeId: "",
      exercises: [],
      repetitions: 0,
      restTimeSeconds: 0
    },
  ];
  selectedPhase: any;
  workout;
  imgSelected: string;
  searchCtrl = new UntypedFormControl();
  showMiniSpinner: any;
  reloadExercises: boolean = true;

  constructor(
    private fb: UntypedFormBuilder,
    public restService: RestService,
    public translate: TranslateService,
    public guiService: GuiService,
    @Inject(MAT_DIALOG_DATA) private item: any['item'],
    private dialogRef: MatDialogRef<WorkoutComponent>,
    public dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.imgURLw = environment.baseURL.workouts;
    setTimeout(() => this.guiService.showSpinner = true, 0)
    this.showMiniSpinner = false
    this.workout = this.item
    console.debug('edit workout obj', this.item)
    this.imgURL = environment.baseURL.exercises;
    this.changeFormLanguage(this.translate.currentLang);


    this.verticalPlanFormGroup = this.fb.group({
      nome_it: [null],
      nome_en: [null],
      desc_it: [null],
      desc_en: [null],
      file: [null],
      programs: [null],
      week_number: [null]
    });

    this.verticalSelectExerciseFormGroup = this.fb.group({});

    this.verticalSetExerciseFormGroup = this.fb.group({});

    this.getExercises();
    this.getPrograms()

    this.verticalPlanFormGroup.controls["nome_it"].setValue(this.workout.i18nName['IT']);
    this.verticalPlanFormGroup.controls["nome_en"].setValue(this.workout.i18nName['EN']);
    this.verticalPlanFormGroup.controls["programs"].setValue(this.workout.workoutProgramId);

    this.verticalPlanFormGroup.controls["desc_it"].setValue(this.workout.i18nDescription['IT']);
    this.verticalPlanFormGroup.controls["desc_en"].setValue(this.workout.i18nDescription['EN']);
    if(this.workout.tags){
      for(let n of this.workout.tags){
        this.week_number.push(n)
      }
    }



    this.initController()

  }

  initController(){
    let baseIndex = []
    for (let i in this.workout.phases) {
      let phase = this.workout.phases[i];

      for (let g in this.guiService.languages) {
        let language = this.guiService.languages[g]
        this.verticalSelectExerciseFormGroup.addControl(
          "name_" + language + "_" + i,
          new FormControl(null, Validators.required)
        );
      }

      this.verticalSelectExerciseFormGroup.addControl(
        "type_it_" + i,
        new FormControl(null)
      );

      this.verticalSelectExerciseFormGroup.addControl(
        "type_en_" + i,
        new FormControl(null)
      );

      this.verticalSelectExerciseFormGroup.addControl(
        "series_" + i,
        new FormControl(null)
      );

      this.verticalSelectExerciseFormGroup.addControl(
        "series_rest_" + i,
        new FormControl(null, Validators.required)
      );

      this.verticalSelectExerciseFormGroup.controls["name_it_" + i].setValue(
        phase.i18nName["IT"]
      );
      this.verticalSelectExerciseFormGroup.controls["name_en_" + i].setValue(
        phase.i18nName["EN"]
      );

      let phaseDesc = typeof phase.workoutPhaseTypeId == "string" && phase.workoutPhaseTypeId != '' ? JSON.parse(phase.workoutPhaseTypeId) : {"EN": '', "IT": ''};

      this.verticalSelectExerciseFormGroup.controls["type_it_" + i].setValue(
        phaseDesc['IT']
      );
      this.verticalSelectExerciseFormGroup.controls["type_en_" + i].setValue(
        phaseDesc['EN']
      );

      this.verticalSelectExerciseFormGroup.controls["series_" + i].setValue(
        phase.repetitions
      );
      this.verticalSelectExerciseFormGroup.controls["series_rest_" + i].setValue(
        phase.restTimeSeconds
      );

      for (let e in phase.exercises) {
        let item = phase.exercises[e];

        if (baseIndex.indexOf(item.exerciseId) < 0) {
          baseIndex.push(item.exerciseId)
        }

        this.verticalSetExerciseFormGroup.addControl(
          "reps_" + i + "_" + e,
          new FormControl(null)
        );
        this.verticalSetExerciseFormGroup.addControl(
          "side_" + i + "_" + e,
          new FormControl(null)
        );
        this.verticalSetExerciseFormGroup.addControl(
          "time_" + i + "_" + e,
          new FormControl(null)
        );
        this.verticalSetExerciseFormGroup.addControl(
          "rest_" + i + "_" + e,
          new FormControl(null)
        );

        this.verticalSetExerciseFormGroup.controls[
        "reps_" + i + "_" + e
          ].setValue(item.repetitions);
        this.verticalSetExerciseFormGroup.controls[
        "side_" + i + "_" + e
          ].setValue(item.eachSideFlag);
        this.verticalSetExerciseFormGroup.controls[
        "time_" + i + "_" + e
          ].setValue(item.executionTimeSeconds);
        this.verticalSetExerciseFormGroup.controls[
        "rest_" + i + "_" + e
          ].setValue(item.restTimeSeconds);
      }
    }
    this.loadExercisesNames(baseIndex)
  }

  submit() {
    let editWorkout = {
      name: this.verticalPlanFormGroup.value.nome_en,
      i18nName: {
        "EN": this.verticalPlanFormGroup.value.nome_en,
        "IT": this.verticalPlanFormGroup.value.nome_it
      },
      description: this.verticalPlanFormGroup.value.desc_en,
      i18nDescription: {
        "EN": this.verticalPlanFormGroup.value.desc_en,
        "IT": this.verticalPlanFormGroup.value.desc_it
      },
      phases: [],
      image: this.imgSelected,
      imageFileName: this.verticalPlanFormGroup.value.nome_en,
      tags: this.week_number,
      workoutProgramId: this.verticalPlanFormGroup.value.programs
    };

    for (let k in this.workout.phases) {
      let items = this.workout.phases[k];
      let phaseDesc = {
        "EN": this.verticalSelectExerciseFormGroup.value["type_en_" + k],
        "IT": this.verticalSelectExerciseFormGroup.value["type_it_" + k]
      }

      let phase = {
        i18nName: {
          "IT": this.verticalSelectExerciseFormGroup.value["name_it_" + k],
          "EN": this.verticalSelectExerciseFormGroup.value["name_en_" + k]
        },
        cod: items.cod,
        workoutPhaseTypeId: JSON.stringify(phaseDesc),
        exercises: [],
        repetitions: this.verticalSelectExerciseFormGroup.value["series_" + k],
        restTimeSeconds: this.verticalSelectExerciseFormGroup.value["series_rest_" + k]
      };

      for (let e in items.exercises) {
        let item = JSON.parse(JSON.stringify(items.exercises[e]));
        item.repetitions =
          this.verticalSetExerciseFormGroup.value["reps_" + k + "_" + e];
        item.eachSideFlag =
          this.verticalSetExerciseFormGroup.value["side_" + k + "_" + e];
        item.executionTimeSeconds =
          this.verticalSetExerciseFormGroup.value["time_" + k + "_" + e];
        item.restTimeSeconds =
          this.verticalSetExerciseFormGroup.value["rest_" + k + "_" + e];

        phase.exercises.push(item);
      }

      editWorkout.phases.push(phase);
    }
    console.debug(editWorkout)
    this.showMiniSpinner = true
    this.restService.putService('workouts', '/' + this.item.id + '?ALL_LANGUAGES=true', editWorkout).subscribe((ris) => {
      this.showMiniSpinner = false
      this.dialogRef.close('done')
      this.guiService.openSnackBar(this.translate.instant('GLOBAL.EDIT_SUCCESS'), this.translate.instant('GLOBAL.CLOSE'))
    })
  }

  getExercises() {
    this.restService.postService('exercises_search', '', {page: 0, size: 50}).subscribe((ris) => {
      this.exercises = ris["items"]
    })

  }

  getPrograms(){
    this.restService.postService('workout_programs', '/search?ALL_LANGUAGES=true', {}).subscribe((ris) => {
      for(let k in ris.items){
        ris.items[k].name = ris.items[k].name.replace("DRAFT::", '')
      }
      this.programs = ris["items"]
    })
  }


  // NUOVA GESTIONE CREAZIONE PROGRAMMA
  addPhase() {
    for (let i in this.guiService.languages) {
      let language = this.guiService.languages[i];
      this.verticalSelectExerciseFormGroup.addControl("name_" + language + '_' + this.workout.phases.length, new FormControl(null, Validators.required));
    }

    this.verticalSelectExerciseFormGroup.addControl("type_it_" + this.workout.phases.length, new FormControl(null));
    this.verticalSelectExerciseFormGroup.addControl("type_en_" + this.workout.phases.length, new FormControl(null));
    this.verticalSelectExerciseFormGroup.addControl('series_' + this.workout.phases.length, new FormControl(null));
    this.verticalSelectExerciseFormGroup.addControl('series_rest_' + this.workout.phases.length, new FormControl(null, Validators.required));
    this.workout.phases.push({
      name: "",
      i18nName: [],
      workoutPhaseTypeId: "",
      exercises: [],
      repetitions: 0,
      restTimeSeconds: 0
    });
  }

  addExerciseFromPhase(ex) {
    this.verticalSetExerciseFormGroup.addControl(
      "reps_" +
      this.selectedPhase +
      "_" +
      this.workout.phases[this.selectedPhase].exercises.length,
      new FormControl(null)
    );
    this.verticalSetExerciseFormGroup.addControl(
      "side_" +
      this.selectedPhase +
      "_" +
      this.workout.phases[this.selectedPhase].exercises.length,
      new FormControl(null)
    );
    this.verticalSetExerciseFormGroup.addControl(
      "time_" +
      this.selectedPhase +
      "_" +
      this.workout.phases[this.selectedPhase].exercises.length,
      new FormControl(null)
    );
    this.verticalSetExerciseFormGroup.addControl(
      "rest_" +
      this.selectedPhase +
      "_" +
      this.workout.phases[this.selectedPhase].exercises.length,
      new FormControl(null)
    );

    let exObj = {
      "eachSideFlag": false,
      "executionTimeSeconds": 0,
      "exerciseId": ex.id,
      "repetitions": 0,
      "restTimeSeconds": 0,
      "name": ex.name
    }
    this.filteredIds[ex.id] && this.filteredIds[ex.id] != '' ? null : this.filteredIds[ex.id] = ex.name
    this.workout.phases[this.selectedPhase].exercises.push(exObj);
  }

  removeExerciseFromPhase(phase, exI) {
    if (confirm(this.translate.instant('ALERT.MESSAGE')) == true) {
      console.debug(this.workout.phases)
      for(let f = exI; f < this.workout.phases[phase].exercises.length; f++){
        this.verticalSetExerciseFormGroup.controls["reps_" + phase + "_" + f].setValue(this.verticalSetExerciseFormGroup.value["reps_" + phase + "_" + (f+1)])
        this.verticalSetExerciseFormGroup.controls["side_" + phase + "_" + f].setValue(this.verticalSetExerciseFormGroup.value["side_" + phase + "_" + (f+1)])
        this.verticalSetExerciseFormGroup.controls["time_" + phase + "_" + f].setValue(this.verticalSetExerciseFormGroup.value["time_" + phase + "_" + (f+1)])
        this.verticalSetExerciseFormGroup.controls["rest_" + phase + "_" + f].setValue(this.verticalSetExerciseFormGroup.value["rest_" + phase + "_" + (f+1)])
        if(f == this.workout.phases[phase].exercises.length){
          console.debug('length',this.workout.phases[phase].exercises.length)
          this.verticalSetExerciseFormGroup.removeControl("reps_" + phase + "_" + (this.workout.phases[phase].exercises.length-1))
          this.verticalSetExerciseFormGroup.removeControl("side_" + phase + "_" + (this.workout.phases[phase].exercises.length-1))
          this.verticalSetExerciseFormGroup.removeControl("time_" + phase + "_" + (this.workout.phases[phase].exercises.length-1))
          this.verticalSetExerciseFormGroup.removeControl("rest_" + phase + "_" + (this.workout.phases[phase].exercises.length-1))
        }

      }
      this.workout.phases[phase].exercises.splice(exI, 1);
    }
  }

  selectPhase(i) {
    this.selectedPhase = i;
  }

  setPhasesparam() {
    for (let k in this.phases) {
      let item = this.phases[k];
      let phaseDesc = {
        "EN": this.verticalSelectExerciseFormGroup.value["type_en_" + k],
        "IT": this.verticalSelectExerciseFormGroup.value["type_it_" + k]
      }
      item.name = this.verticalSelectExerciseFormGroup.value["name_it_" + k];
      item.workoutPhaseTypeId = JSON.stringify(phaseDesc);
      item.repetitions = this.verticalSelectExerciseFormGroup.value["series_" + k];
      item.restTimeSeconds = this.verticalSelectExerciseFormGroup.value["series_rest_" + k]
      for (let l in this.guiService.languages) {
        let language = this.guiService.languages[l]
        let tmp = item.i18nName
        tmp[language.toUpperCase()] = this.verticalSelectExerciseFormGroup.value["name_" + language + '_' + k]
        item.i18nName = tmp
      }
    }
  }

  configExerciseParam() {
    for (let k in this.phases) {
      let item = this.phases[k];
      for (let e in item.exercises) {
        let exercise = item.exercises[e];

        exercise.repetitions =
          this.verticalSetExerciseFormGroup.value["reps_" + k + "_" + e];
        exercise.eachSideFlag =
          this.verticalSetExerciseFormGroup.value["side_" + k + "_" + e];
        exercise.executionTimeSeconds =
          this.verticalSetExerciseFormGroup.value["time_" + k + "_" + e];
        exercise.restTimeSeconds =
          this.verticalSetExerciseFormGroup.value["rest_" + k + "_" + e];
      }
    }
  }

    /* GESTIONE DRAG'N DROP */


    drop(event: CdkDragDrop<any[]>, index) {
      this.configExerciseParam()
      moveItemInArray(this.workout.phases[index].exercises, event.previousIndex, event.currentIndex);
      this.dropExercise(index,event.previousIndex, event.currentIndex)
    }

    dropExercise(index,previousIndex,currentIndex){
      // console.debug('prima',this.phases[index].exercises)

      let tmpPrevious = this.workout.phases[index].exercises[previousIndex]
      for(let f = 0; f < this.workout.phases[index].exercises.length; f++){
        this.verticalSetExerciseFormGroup.controls["reps_" + index + "_" + f].setValue(this.workout.phases[index].exercises[f].repetitions)
        this.verticalSetExerciseFormGroup.controls["side_" + index + "_" + f].setValue(this.workout.phases[index].exercises[f].eachSideFlag)
        this.verticalSetExerciseFormGroup.controls["time_" + index + "_" + f].setValue(this.workout.phases[index].exercises[f].executionTimeSeconds)
        this.verticalSetExerciseFormGroup.controls["rest_" + index + "_" + f].setValue(this.workout.phases[index].exercises[f].restTimeSeconds)
      }

      console.debug('dopo',this.verticalSetExerciseFormGroup.value)
    }

  // resetExerciseParam() {
  //   console.debug(this.verticalSetExerciseFormGroup.value);
  //   this.verticalSetExerciseFormGroup.reset();
  //   this.verticalSetExerciseFormGroup = this.fb.group({});

  //   for (let i in this.workout.phases) {
  //     let phase = this.workout.phases[i];

  //     for (let e in phase.exercises) {
  //       this.verticalSetExerciseFormGroup.addControl(
  //         "reps_" + i + "_" + e,
  //         new FormControl(null)
  //       );
  //       this.verticalSetExerciseFormGroup.addControl(
  //         "side_" + i + "_" + e,
  //         new FormControl(null)
  //       );
  //       this.verticalSetExerciseFormGroup.addControl(
  //         "time_" + i + "_" + e,
  //         new FormControl(null)
  //       );
  //       this.verticalSetExerciseFormGroup.addControl(
  //         "rest_" + i + "_" + e,
  //         new FormControl(null)
  //       );
  //     }
  //   }
  //   console.debug("DOPO", this.verticalSetExerciseFormGroup.value);
  // }

  changeFormLanguage(lang) {
    console.debug('lingua form =', lang)
    this.iconFormFlag = this.flags[lang];
    this.formLang = lang;
  }

  loadExercisesNames(baseIndex) {
    let param = {
      "filterAnd": [
        {
          "field": "id",
          "operator": "in",
          "value": baseIndex.toString()
        }
      ],
      "page": 0,
      "size": 200
    }
    this.restService.postService('exercises_search', '', param).subscribe((ris) => {
      for (let exId of baseIndex) {
        this.filteredIds[exId] = ris.items.find(x => x.id === exId).name
        if(this.filteredIds[exId] == null){
          this.filteredIds[exId] = 'N/A'
        }
      }
      this.guiService.showSpinner = false
    })
  }

  onFileSelected(event) {
    this.guiService.convertFile(event.target.files[0]).subscribe(base64 => {
      console.debug("BASE64", base64);
      this.imgSelected = base64;
    });
  }

  removePhase(idx) {
    console.debug('rm phase', idx)
    this.slideController(idx);
  }

  slideController(idx) {
    console.debug('prima',this.verticalSelectExerciseFormGroup.value)
    for (let i = 0; i < this.workout.phases.length-1; i++) {
      let phase = this.workout.phases[i];
      let phaseDesc = typeof phase.workoutPhaseTypeId == "string" && phase.workoutPhaseTypeId != '' ? JSON.parse(phase.workoutPhaseTypeId) : {"EN": '', "IT": ''};

      i >= idx ? phase.i18nName["IT"] = this.verticalSelectExerciseFormGroup.value["name_it_" + (i + 1)]: null
      i >= idx ? phase.i18nName["EN"] = this.verticalSelectExerciseFormGroup.value["name_en_" + (i + 1)]: null
      i >= idx ? phaseDesc['IT'] = this.verticalSelectExerciseFormGroup.value["type_it_" + (i + 1)]: null
      i >= idx ? phaseDesc['EN'] = this.verticalSelectExerciseFormGroup.value["type_en_" + (i + 1)]: null
      i >= idx ? phase.repetitions = this.verticalSelectExerciseFormGroup.value["series_" + (i + 1)]: null
      i >= idx ? phase.restTimeSeconds = this.verticalSelectExerciseFormGroup.value["series_rest_" + (i + 1)]: null

      i >= idx ? this.verticalSelectExerciseFormGroup.controls["name_it_" + i].setValue(this.verticalSelectExerciseFormGroup.value["name_it_" + (i + 1)]) : null;
      i >= idx ? this.verticalSelectExerciseFormGroup.controls["name_en_" + i].setValue(this.verticalSelectExerciseFormGroup.value["name_en_" + (i + 1)]) : null;
      i >= idx ? this.verticalSelectExerciseFormGroup.controls["type_it_" + i].setValue(this.verticalSelectExerciseFormGroup.value["type_it_" + (i + 1)]) : null;
      i >= idx ? this.verticalSelectExerciseFormGroup.controls["type_en_" + i].setValue(this.verticalSelectExerciseFormGroup.value["type_en_" + (i + 1)]) : null;
      i >= idx ? this.verticalSelectExerciseFormGroup.controls["series_" + i].setValue(this.verticalSelectExerciseFormGroup.value["series_" + (i + 1)]) : null;
      i >= idx ? this.verticalSelectExerciseFormGroup.controls["series_rest_" + i].setValue(this.verticalSelectExerciseFormGroup.value["series_rest_" + (i + 1)]) : null;
      i >= idx ? this.workout.phases[i].exercises = this.workout.phases[(i+1)].exercises : null;
      console.debug('i',i,'idx',idx,'length',this.workout.phases.length, 'vr',this.verticalSelectExerciseFormGroup.value)
    }
    this.initController()

     this.verticalSelectExerciseFormGroup.removeControl("name_it_" + (this.workout.phases.length-1));
     this.verticalSelectExerciseFormGroup.removeControl("name_en_" + (this.workout.phases.length-1));
     this.verticalSelectExerciseFormGroup.removeControl("type_it_" + (this.workout.phases.length-1));
     this.verticalSelectExerciseFormGroup.removeControl("type_en_" + (this.workout.phases.length-1));
     this.verticalSelectExerciseFormGroup.removeControl("series_" + (this.workout.phases.length-1));
     this.verticalSelectExerciseFormGroup.removeControl("series_rest_" + (this.workout.phases.length-1));
    this.workout.phases.splice(-1);
    console.debug("Phases", this.workout.phases);
    console.debug("name_it_" + this.workout.phases.length, this.verticalSelectExerciseFormGroup.value);
  }


  searchAltExercise() {
    if(this.searchCtrl.value.length > 3){
    console.debug(this.searchCtrl.value)
    let param = {
      "filterAnd": [
        {
        "field": "i18nName."+this.translate.currentLang.toUpperCase(),
        "operator": "like",
        "value": this.searchCtrl.value
        }
        ],
      "page": 0,
      "size": 50
    }
    this.restService.postService('exercises_search', '?ALL_LANGUAGES=true', param).subscribe((ris)=>{
      this.exercises = ris["items"]
    })
  }else if(this.searchCtrl.value.length == 0){
    this.getExercises()
  }
  }

  addWeekToChip(wNumber){
    if(wNumber != null){
      this.week_number.push(('week '+wNumber))
      this.verticalPlanFormGroup.controls['week_number'].setValue(null)
    }
  }

  removeWeekFromChip(i){
    this.week_number.splice(i, 1)
    console.debug(this.week_number)
  }
  confirmClose(){
    this.dialog.open(CloseConfirmComponent,{
      disableClose: true,
      hasBackdrop: true,
      panelClass: 'close-dialog-modalbox'
    }).afterClosed().subscribe((ris)=>{
      if(ris == 'true'){
        this.dialogRef.close()
      }
    })
  }

  movePhase(phaseIndex,moveTo){
    this.reloadExercises = false;
    let tmpPrevPhase = {
      exercises: this.workout.phases[phaseIndex].exercises,
      i18nName: {
        EN: this.verticalSelectExerciseFormGroup.value["name_en_" + phaseIndex],
        IT: this.verticalSelectExerciseFormGroup.value["name_it_" + phaseIndex]
      },
      repetitions: this.verticalSelectExerciseFormGroup.value["series_"+ phaseIndex],
      restTimeSeconds: this.verticalSelectExerciseFormGroup.value["series_rest_" + phaseIndex],
      workoutPhaseTypeId: {
        EN: this.verticalSelectExerciseFormGroup.value["type_en_"+phaseIndex],
        IT: this.verticalSelectExerciseFormGroup.value["type_it_"+phaseIndex]
    }
    }

    for(let k in this.workout.phases[phaseIndex].exercises){
     this.verticalSetExerciseFormGroup.removeControl('reps_'+phaseIndex+'_'+k)
     this.verticalSetExerciseFormGroup.removeControl('time_'+phaseIndex+'_'+k)
     this.verticalSetExerciseFormGroup.removeControl('side_'+phaseIndex+'_'+k)
     this.verticalSetExerciseFormGroup.removeControl('rest_'+phaseIndex+'_'+k)
    }

    for(let k in this.workout.phases[moveTo].exercises){
      this.verticalSetExerciseFormGroup.removeControl('reps_'+moveTo+'_'+k)
      this.verticalSetExerciseFormGroup.removeControl('time_'+moveTo+'_'+k)
      this.verticalSetExerciseFormGroup.removeControl('side_'+moveTo+'_'+k)
      this.verticalSetExerciseFormGroup.removeControl('rest_'+moveTo+'_'+k)
     }

    this.verticalSelectExerciseFormGroup.controls["name_en_"+phaseIndex].setValue(this.verticalSelectExerciseFormGroup.value["name_en_" + moveTo])
    this.verticalSelectExerciseFormGroup.controls["name_it_"+phaseIndex].setValue(this.verticalSelectExerciseFormGroup.value["name_it_" + moveTo])
    this.verticalSelectExerciseFormGroup.controls["series_"+phaseIndex].setValue(this.verticalSelectExerciseFormGroup.value["series_" + moveTo])
    this.verticalSelectExerciseFormGroup.controls["series_rest_"+phaseIndex].setValue(this.verticalSelectExerciseFormGroup.value["series_rest_" + moveTo])
    this.verticalSelectExerciseFormGroup.controls["type_en_"+phaseIndex].setValue(this.verticalSelectExerciseFormGroup.value["type_en_" + moveTo])
    this.verticalSelectExerciseFormGroup.controls["type_it_"+phaseIndex].setValue(this.verticalSelectExerciseFormGroup.value["type_it_" + moveTo])
    this.workout.phases[phaseIndex].exercises = this.workout.phases[moveTo].exercises


    this.verticalSelectExerciseFormGroup.controls["name_en_"+moveTo].setValue(tmpPrevPhase.i18nName.EN)
    this.verticalSelectExerciseFormGroup.controls["name_it_"+moveTo].setValue(tmpPrevPhase.i18nName.IT)
    this.verticalSelectExerciseFormGroup.controls["series_"+moveTo].setValue(tmpPrevPhase.repetitions)
    this.verticalSelectExerciseFormGroup.controls["series_rest_"+moveTo].setValue(tmpPrevPhase.restTimeSeconds)
    this.verticalSelectExerciseFormGroup.controls["type_en_"+moveTo].setValue(tmpPrevPhase.workoutPhaseTypeId.EN)
    this.verticalSelectExerciseFormGroup.controls["type_it_"+moveTo].setValue(tmpPrevPhase.workoutPhaseTypeId.IT)
    this.workout.phases[moveTo].exercises = tmpPrevPhase.exercises


     for(let k in this.workout.phases[moveTo].exercises){
      let exercise = this.workout.phases[moveTo].exercises[k]
       this.verticalSetExerciseFormGroup.addControl('reps_'+moveTo+'_'+k, new FormControl(exercise.repetitions))
       this.verticalSetExerciseFormGroup.addControl('time_'+moveTo+'_'+k, new FormControl(exercise.executionTimeSeconds))
       this.verticalSetExerciseFormGroup.addControl('side_'+moveTo+'_'+k, new FormControl(exercise.eachSideFlag))
       this.verticalSetExerciseFormGroup.addControl('rest_'+moveTo+'_'+k, new FormControl(exercise.restTimeSeconds))
     }


     for(let k in this.workout.phases[phaseIndex].exercises){
      let exercise = this.workout.phases[phaseIndex].exercises[k]
       this.verticalSetExerciseFormGroup.addControl('reps_'+phaseIndex+'_'+k, new FormControl(exercise.repetitions))
       this.verticalSetExerciseFormGroup.addControl('time_'+phaseIndex+'_'+k, new FormControl(exercise.executionTimeSeconds))
       this.verticalSetExerciseFormGroup.addControl('side_'+phaseIndex+'_'+k, new FormControl(exercise.eachSideFlag))
       this.verticalSetExerciseFormGroup.addControl('rest_'+phaseIndex+'_'+k, new FormControl(exercise.restTimeSeconds))
     }


     this.reloadExercises = true

  }

  validateNo(e): boolean {
    console.debug('control if number')
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false
    }
    return true
  }
}
