<div class="container">
  <h1>
    <span class="back-button" (click)="backButton()">
      <mat-icon svgIcon="mat:arrow_back_ios"></mat-icon>
    </span>
    {{wellnessSrv.categoryName}}
  </h1>
  <div class="sub-video">
    <div class="video-area">
      <img class="vidyard-player-embed" [src]="'https://play.vidyard.com/'+video.i18nvideoId[currentLang]+'.jpg'"
        data-v="4" data-type="inline" />
      <div class="locked" *ngIf="video.videoBlocked">
        <img src="../../../../assets/img/Lucchetto.png" alt="" class="lock-icon">
      </div>
    </div>
    <p *ngIf="video">{{video.i18nName[translate.currentLang.toUpperCase()]}}</p>

  </div>
  <p class="description" *ngIf="video">
    {{video.i18nDescription[translate.currentLang.toUpperCase()]}}
  </p>
  <div class="custom-footer">
    <div *ngIf="!video.videoBlocked">
      <button class="btn-uniform mb-10" *ngIf="parsedPdf[currentLang]" (click)="downloadPdf()">
        {{"WELLNESS.DOWNLOAD_PDF" | translate}}
      </button>
      <button class="btn-uniform" (click)="showVideo()">
        {{"WELLNESS.START_NOW" | translate}}
      </button>
    </div>
    <div *ngIf="video.videoBlocked" class="mb-4">
      <h4>{{"WELLNESS.UNLOCK_AREA" | translate}}</h4>
    </div>
    <button class="btn-uniform" *ngIf="video.videoBlocked" (click)="goToUpgrade()">
      {{"WELLNESS.UPGRADE" | translate}}
    </button>
  </div>
</div>
