import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {Platform} from "@angular/cdk/platform";

@Component({
  selector: 'vex-app-selection-modal',
  templateUrl: './app-selection-modal.component.html',
  styleUrls: ['./app-selection-modal.component.scss']
})
export class AppSelectionModalComponent implements OnInit {
  isAppAvailable: boolean;
  storeLink = {
    ANDROID: "https://play.google.com/store/apps/details?id=com.itsdesifit.app&hl=it&gl=US",
    IOS: "https://apps.apple.com/us/app/getstronger-fitness-for-women/id6443966740"
  }
  constructor(
    private router: Router,
    public platform: Platform
  ) { }

  ngOnInit(): void {

    console.debug("ANDROID",this.platform.ANDROID);
    console.debug("IOS",this.platform.IOS);
    console.debug("navigator.userAgent.toLowerCase()", navigator.userAgent)
    if(!navigator.userAgent.toLowerCase().includes('android') || !navigator.userAgent.toLowerCase().includes('iphone') || !navigator.userAgent.toLowerCase().includes('ipad')){
      this.router.navigate(['auth/login'])
    }
  }

  goToAPP(){
    window.open("getstronger://com.itsdesifit.getstronger","_blank");
  }
  goToStore(){
    if(this.platform.ANDROID) {
      window.open(this.storeLink.ANDROID,"_blank");
    } else if(this.platform.IOS) {
      window.open(this.storeLink.IOS,"_blank");
    }
  }

}
