<div class="contenitore-generale bg-app-bar">
  <div class="title">
    <h1 *ngIf="translate.currentLang == 'it'">Avviso</h1>
    <h1 *ngIf="translate.currentLang == 'en'">Notice</h1>
  </div>
  <div class="container">
    <p *ngIf="translate.currentLang == 'it'">Non è possibile effettuare questa operazione. Contattare il customer support inviando un'email a <a href="mailto:info@itsdesifit.com">info@itsdesifit.com</a> o visitare la pagina <a href="https://itsdesifit.com/it/f-a-q/">FAQ</a></p>
    <p *ngIf="translate.currentLang == 'en'">This request cannot be processed. Please contact customer support at <a href="mailto:info@itsdesifit.com" >info@itsdesifit.com</a> or visit our <a href="https://itsdesifit.com/it/f-a-q/">FAQ</a> page.</p>

    <div class="bottoni">
      <button mat-dialog-close *ngIf="translate.currentLang == 'it'">Chiudi</button>
      <button mat-dialog-close *ngIf="translate.currentLang == 'en'">Close</button>
    </div>
  </div>
</div>
