import { GuiService } from './../../../services/gui.service';
import { RestService } from './../../../services/rest.service';
import { ProgramComponent } from '../program.component';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CloseConfirmComponent } from '../../close-confirm/close-confirm.component';

@Component({
  selector: 'vex-program-new',
  templateUrl: './program-new.component.html',
  styleUrls: ['./program-new.component.scss']
})
export class ProgramNewComponent implements OnInit {

  form: UntypedFormGroup;
  formLang: any;
  iconFormFlag: any;
  flags = {
    en: 'flag:united-states',
    it: 'flag:italy'
  };
  attrezzatura: any[];
  level: any[];
  imgSelected: string;

  constructor(private fb: UntypedFormBuilder, private dialogRef: MatDialogRef<ProgramComponent>, private translate: TranslateService, public restService: RestService, public guiService: GuiService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.populateSelects()
    this.changeFormLanguage(this.translate.currentLang);
    this.form = this.fb.group({
      nome_it:[null, Validators.required],
      nome_en:[null, Validators.required],
      bozza:[null, Validators.required],
      week_n: [null, Validators.required],
      desc_it: [null],
      desc_en: [null],
      workout_settimanali: [null, Validators.required],
      workout_durata: [null, Validators.required],
      attrezzatura: [null, Validators.required],
      file: [null]
    })
  }

  submit(form){
    let newProgram = {
      description: this.form.value.desc_en,
      difficulty: 'EASY',
      equipmentIds: this.form.value.attrezzatura,
      i18nDescription: {
      "EN": this.form.value.desc_en,
      "IT": this.form.value.desc_it
      },
      i18nName: {
        "EN": this.form.value.nome_en,
        "IT": this.form.value.nome_it
      },
      name: this.form.value.nome_en,
      numberOfWeeks: this.form.value.week_n,
      weeklyWorkouts: this.form.value.workout_settimanali,
      weeks: [],
      workoutDurationInMinutes: this.form.value.workout_durata,
      image: this.imgSelected,
      imageFileName: this.form.value.nome_en,
      workoutProgramStatus: this.form.value.bozza
    }
    console.debug(newProgram)
     this.restService.postService('workout_programs', '?ALL_LANGUAGES=true', newProgram).subscribe((ris)=>{
       console.debug(ris)
       this.guiService.openSnackBar(ris.name +' '+ this.translate.instant('GLOBAL.NEW_SUCCESS'), this.translate.instant('GLOBAL.CLOSE'))
       this.dialogRef.close('done')
     })
  }

  changeFormLanguage(lang) {
    this.iconFormFlag = this.flags[lang];
    this.formLang = lang;
}

populateSelects(){
  this.restService.getService('exercise_training', '').subscribe((ris)=>{
    this.level = ris
  })
  this.restService.postService('equipments', '', {page: 0, size: 0}).subscribe((ris)=>{
    this.attrezzatura = ris.items
  })
}

onFileSelected(event) {
  this.guiService.convertFile(event.target.files[0]).subscribe(base64 => {
    console.debug("BASE64",base64);
    this.imgSelected = base64;
  });
}
confirmClose(){
  this.dialog.open(CloseConfirmComponent,{
    disableClose: true,
    hasBackdrop: true,
    panelClass: 'close-dialog-modalbox'
  }).afterClosed().subscribe((ris)=>{
    if(ris == 'true'){
      this.dialogRef.close()
    }
  })
}
}
