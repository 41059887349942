import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'vex-close-confirm',
  templateUrl: './close-confirm.component.html',
  styleUrls: ['./close-confirm.component.scss']
})
export class CloseConfirmComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private item: any,
    private dialogRef: MatDialogRef<any>,
    public translate: TranslateService) { }

  ngOnInit(): void {
  }

  dontClose(){
    this.dialogRef.close('false')
  }

  close(){
    this.dialogRef.close('true')
  }

}
