import { RestService } from './../../../services/rest.service';
import { GuiService } from './../../../services/gui.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'vex-change-week-dialog',
  templateUrl: './change-week-dialog.component.html',
  styleUrls: ['./change-week-dialog.component.scss']
})
export class ChangeWeekDialogComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<any>,@Inject(MAT_DIALOG_DATA) private item: any, public guiService: GuiService, public restService: RestService) { }

  ngOnInit(): void {
    console.debug(this.item)
  }
  changeWeek(){
    this.guiService.userLogged.lastWorkoutProgram.week = this.item
    this.guiService.userLogged.lastWorkoutProgram.day = 0
    this.dialogRef.close()
  }

  close(){
    this.dialogRef.close('true')
  }
}
