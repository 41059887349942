import { GuiService } from 'src/app/services/gui.service';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
declare var require: any;
const htmlToPdfmake = require("html-to-pdfmake");
(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'vex-my-survey',
  templateUrl: './my-survey.component.html',
  styleUrls: ['./my-survey.component.scss']
})
export class MySurveyComponent implements OnInit {
  mySurvey: any;
  questFormGroup: UntypedFormGroup;
  surveyFormGroup: UntypedFormGroup;
  icon = {
    "download": faDownload
  }

  unit: string;

  @ViewChild('pdfTable')
  pdfTable!: ElementRef;

  @ViewChild('pdfTable1')
  pdfTable1!: ElementRef;

  constructor(private dialogRef: MatDialogRef<MySurveyComponent>,@Inject(MAT_DIALOG_DATA) private item: any['item'], private fb: FormBuilder, public guiService: GuiService) { }

  ngOnInit(): void {
    this.mySurvey = this.item
    console.debug(this.mySurvey)
    this.unit = this.mySurvey.unitSystem

    this.questFormGroup = this.fb.group({
      nome: [this.item.name],
      cognome: [this.item.surname],
      eta: [this.item.age],
      altezza: [this.item.height],
      peso: [this.item.weight],
      vita: [this.item.weistline],
      fianchi: [this.item.hip],
      coscia_dx: [this.item.leftThigh],
      coscia_sx: [this.item.rightThigh],
      braccio_dx: [this.item.leftArm],
      braccio_sx: [this.item.rightArm],
      attivita: [this.item.activity],
      stile: [this.item.lifeStyle],
      ciclo: [this.item.period],
      frequenza_ciclo: [this.item.periodFrequency],
      patologie: [this.item.pathologies],
      terapia: [this.item.therapy],
      allergie: [this.item.allergies],
      consideration: [this.item.consideration]
    });

    this.surveyFormGroup = this.fb.group({
      peso: [this.item.weight],
      vita: [this.item.weistline],
      fianchi: [this.item.hip],
      coscia_dx: [this.item.leftThigh],
      coscia_sx: [this.item.rightThigh],
      braccio_dx: [this.item.leftArm],
      braccio_sx: [this.item.rightArm],
      like: [this.item.like],
      dislike: [this.item.dislike],
      would: [this.item.would],
      consideration: [this.item.consideration]
    });
  }

  public downloadSurveyAsPDF() {
    let pdfTable
    if(this.mySurvey.surveyType == 'questionario'){
      pdfTable = this.pdfTable.nativeElement;
    }if(this.mySurvey.surveyType == 'follow-up'){
      pdfTable = this.pdfTable1.nativeElement;
    }

    var html = htmlToPdfmake(pdfTable.innerHTML);
    const documentDefinition = { content: html };
    pdfMake.createPdf(documentDefinition).download();
  }

  closeDialog(){
    this.dialogRef.close('true')
  }

}
