<div class="container mt-4">
  <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">
    <h2 class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l hidden sm:block flex-none">
      <span>{{"CHALLENGE-TIME.HEADER" | translate}}</span>
    </h2>
    <!-- <div
      class="bg-foreground rounded-full border px-4 max-w-[300px] flex-auto flex items-center border border-gray-300">
      <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon>
      <input [formControl]="searchCtrl" (keyup)="searchAltExercise()" (search)="searchAltExercise()" class="px-4 py-2 border-0 outline-none w-full bg-transparent" placeholder="{{'GLOBAL.SEARCH' | translate}}" type="search" />
    </div> -->

    <span class="flex-1"></span>

  </div>
  <table mat-table [dataSource]="dataSource" *ngIf="challenges">

    <ng-container matColumnDef="nome">
      <th mat-header-cell *matHeaderCellDef>{{"CHALLENGE-TIME.NAME" | translate}}</th>
      <td mat-cell *matCellDef="let challenge">{{ challenge.challenge.i18nName[translate.currentLang.toLocaleUpperCase()] }}</td>
    </ng-container>
    <ng-container matColumnDef="data-pubblicazione">
      <th mat-header-cell *matHeaderCellDef >{{"CHALLENGE-TIME.PUBLISH" | translate}}</th>
      <td mat-cell *matCellDef="let challenge">{{ challenge.challenge.publishDate | date: 'shortDate'}}</td>
    </ng-container>
    <ng-container matColumnDef="pending">
      <th mat-header-cell *matHeaderCellDef>{{"CHALLENGE-TIME.PENDING" | translate}}</th>
      <td mat-cell *matCellDef="let challenge" (click)="detail(challenge.challenge,'pending')"><span class="link">{{challenge.nullNumber}}</span></td>
    </ng-container>
    <ng-container matColumnDef="accepted">
      <th mat-header-cell *matHeaderCellDef>{{"CHALLENGE-TIME.ACCEPTED" | translate}}</th>
      <td mat-cell *matCellDef="let challenge" (click)="detail(challenge.challenge,'accepted')"><span class="link">{{challenge.acceptNumber}}</span></td>
    </ng-container>
    <ng-container matColumnDef="rejected">
      <th mat-header-cell *matHeaderCellDef>{{"CHALLENGE-TIME.REJECTED" | translate}}</th>
      <td mat-cell *matCellDef="let challenge" (click)="detail(challenge.challenge,'rejected')"><span class="link">{{challenge.rejectNumber}}</span></td>
    </ng-container>

    <!-- Action Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row" class="w-10 text-secondary" >
        <button (click)="$event.stopPropagation()"
                [matMenuTriggerData]="{ customer: row }"
                [matMenuTriggerFor]="actionsMenu"
                mat-icon-button
                type="button">
          <mat-icon svgIcon="mat:more_horiz"></mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>


  </table>
  <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize" [length]="lunghezza" (page)="onPageChanged($event)" class="sticky left-0"></mat-paginator>

</div>

<mat-menu #actionsMenu="matMenu" xPosition="before" yPosition="below">
  <ng-template let-customer="customer" matMenuContent>
    <button  mat-menu-item (click)="copyLink(customer,templateClip)">
      <mat-icon svgIcon="mat:link"></mat-icon>
      <span>{{"CHALLENGE-TIME.DEEP" | translate}}</span>
    </button>
  </ng-template>
</mat-menu>
<ng-template #templateClip>
  <div class="dialog-msg-div">
    <h1 *ngIf="translate.currentLang == 'it'">Copiato negli appunti</h1>
    <h1 *ngIf="translate.currentLang == 'en'">Copied to clipboard</h1>
  </div>
 </ng-template>
