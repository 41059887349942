import { EditExerciseComponent } from './edit-exercise/edit-exercise.component';
import { NewExerciseComponent } from './new-exercise/new-exercise.component';
import { RestService } from 'src/app/services/rest.service';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { scaleFadeIn400ms } from 'src/@vex/animations/scale-fade-in.animation';
import { scaleIn400ms } from 'src/@vex/animations/scale-in.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { Link } from 'src/@vex/interfaces/link.interface';
import { MatDialog } from '@angular/material/dialog';
import { DialogConfirmComponent } from '../dialog-confirm/dialog-confirm.component';
import { UntypedFormControl } from '@angular/forms';
import { GuiService } from 'src/app/services/gui.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Observable } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';

import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'vex-exercise',
  templateUrl: './exercise.component.html',
  styleUrls: ['./exercise.component.scss'],
  animations: [
    scaleIn400ms,
    fadeInRight400ms,
    stagger40ms,
    fadeInUp400ms,
    scaleFadeIn400ms
  ]
})

export class ExerciseComponent implements OnInit {

  searchCtrlName = new UntypedFormControl();
  searchCtrlMuscle = new UntypedFormControl();
  searchCtrlLevel = new UntypedFormControl();
  searchCtrlType = new UntypedFormControl();
  imgURL: string;
  exercises: any;
  pageNumber = 0;
  pageSize = 10;
  lunghezza: any;
  pageSizeOptions: number[] = [5, 10, 15, 50];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  obs: Observable<any>;
  dataSource: MatTableDataSource<any> = new MatTableDataSource;
  typs: any[];
  muscle: any[];
  level: any[] = [
    { key: 'EASY', description: this.translate.instant("EXERCISE-LIST.EASY") },
    { key: 'INTERMEDIATE', description: this.translate.instant("EXERCISE-LIST.INTERMEDIATE") },
    { key: 'EXPERT', description: this.translate.instant("EXERCISE-LIST.EXPERT") }
  ]



  constructor(public restService: RestService,
    private dialog: MatDialog,
    public translate: TranslateService,
    public guiService: GuiService,
    private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.imgURL = environment.baseURL.exercises;
    this.populateSelects()
    this.getExercises()
    this.changeDetectorRef.detectChanges();
    this.dataSource.paginator = this.paginator;
    console.debug("Paginator", this.dataSource.paginator);
    this.obs = this.dataSource.connect();
  }

  getExercises() {
    let param = {
      "filterAnd": [],
      "page": this.pageNumber,
      "size": this.pageSize
    }
    if (this.searchCtrlLevel.value != null) {
      param["filterAnd"].push(
        {
          "field": "difficulty",
          "operator": "like",
          "value": this.searchCtrlLevel.value
        }
      )
    }
    if (this.searchCtrlMuscle.value != null) {
      param["filterAnd"].push(
        {
          "field": "muscleGroups",
          "operator": "like",
          "value": this.searchCtrlMuscle.value
        }
      )
    }
    if (this.searchCtrlType.value != null) {
      param["filterAnd"].push(
        {
          "field": "type",
          "operator": "like",
          "value": this.searchCtrlType.value
        }
      )
    }
    if (this.searchCtrlName.value != null) {
      param["filterAnd"].push(
        {
          "field": "name",
          "operator": "like",
          "value": this.searchCtrlName.value
        }
      )
    }
    setTimeout(() => this.guiService.showSpinner = true, 0)
    this.restService.postService('exercises_search', '', param).subscribe((ris) => {
      this.lunghezza = ris.totalItems
      this.exercises = ris["items"];
      this.guiService.showSpinner = false
    });

  }
  onPageChanged(pageEvent: PageEvent) {
    this.pageNumber = pageEvent.pageIndex + 1;
    this.pageSize = pageEvent.pageSize;
    this.getExercises();
  }

  createExercise() {
    this.dialog.open(NewExerciseComponent, {
      panelClass: 'exer-modalbox',
      maxHeight: '90vh',
      disableClose: true,
      hasBackdrop: true
    }).afterClosed().subscribe((ex) => {
      if (ex == 'done') {
        this.getExercises()
      }
    })


  }

  deleteExercise(id) {
    this.dialog.open(DialogConfirmComponent, {
      width: '250px',
      enterAnimationDuration: '0ms',
      exitAnimationDuration: '1ms',
      panelClass: 'dialog-modalbox',
      data: {
        type: 'exercises',
        id: id
      }
    }).afterClosed().subscribe((ris) => {
      if (ris != 'true') {
        this.getExercises()
      }
    })
  }

  editExercise(item?: any['item']) {
    this.restService.getService('exercises', item.id + '?ALL_LANGUAGES=true').subscribe((ris) => {
      this.dialog.open(EditExerciseComponent, {
        panelClass: 'exer-modalbox',
        data: ris || null,
        maxHeight: '90vh',
        disableClose: true,
        hasBackdrop: true
      }).afterClosed().subscribe((ex) => {
        if (ex == 'done') {
          this.getExercises()
        }
      })
    })

  }

  populateSelects() {
    this.restService.getService('exercise_type', '').subscribe((ris) => {
      this.typs = ris.sort((a, b) => a.description.localeCompare(b.description))
    })
    this.restService.getService('exercise_muscle', '').subscribe((ris) => {
      this.muscle = ris.sort((a, b) => a.description.localeCompare(b.description))
    })
    // this.restService.getService('exercise_training', '').subscribe((ris)=>{
    //   console.debug(ris)
    //   this.level = ris
    // })
  }

  searchAltExercise() {
    let param = {
      "filterAnd": [],
      "page": 0,
      "size": this.pageSize
    }
    if (this.searchCtrlLevel.value != null) {
      param["filterAnd"].push(
        {
          "field": "difficulty",
          "operator": "like",
          "value": this.searchCtrlLevel.value
        }
      )
    }
    if (this.searchCtrlMuscle.value != null) {
      param["filterAnd"].push(
        {
          "field": "muscleGroups",
          "operator": "like",
          "value": this.searchCtrlMuscle.value
        }
      )
    }
    if (this.searchCtrlType.value != null) {
      param["filterAnd"].push(
        {
          "field": "type",
          "operator": "like",
          "value": this.searchCtrlType.value
        }
      )
    }
    if (this.searchCtrlName.value != null) {
      param["filterAnd"].push(
        {
          "field": "name",
          "operator": "like",
          "value": this.searchCtrlName.value
        }
      )
    }
    console.debug('param prima della chiamata', param)
    this.restService.postService('exercises_search', '?ALL_LANGUAGES=true', param).subscribe((ris) => {
      this.lunghezza = ris.totalItems
      this.exercises = ris["items"]
      console.debug(this.exercises)
    })

  }

  reload() {
    this.searchCtrlLevel.reset()
    this.searchCtrlMuscle.reset()
    this.searchCtrlType.reset()
    this.searchCtrlName.reset()
    this.getExercises()
  }

  resetPage() {
    this.pageNumber = 0
    console.debug(this.dataSource.paginator)
    this.dataSource.paginator.firstPage()
    this.getExercises()
  }
}
