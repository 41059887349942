import { MyDietComponent } from './my-diet/my-diet.component';
import { MySurveyComponent } from './my-survey/my-survey.component';
import { RestService } from './../../services/rest.service';
import { TranslateService } from '@ngx-translate/core';
import { GuiService } from 'src/app/services/gui.service';
import { Component, OnInit } from '@angular/core';
import { faCircle, faCheck } from '@fortawesome/free-solid-svg-icons';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { FormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'vex-user-nutrition',
  templateUrl: './user-nutrition.component.html',
  styleUrls: ['./user-nutrition.component.scss'],
})
export class UserNutritionComponent implements OnInit {
  user: any;

  icon = {
    "faCircle": faCircle,
    "faCheck": faCheck
  }

  systemFormGroup: UntypedFormGroup;
  firstFormGroup: UntypedFormGroup;
  secondFormGroup: UntypedFormGroup;
  thirdFormGroup: UntypedFormGroup;
  fourthFormGroup: UntypedFormGroup;
  fifthFormGroup: UntypedFormGroup;
  questnote: UntypedFormGroup;
  cibo_odio = new FormControl('')
  cibo_amo = new FormControl('')
  surveyFormGroup: UntypedFormGroup;
  surveyTwoFormGroup: UntypedFormGroup;
  termsFormGroup: UntypedFormGroup;
  foodFormGroup: UntypedFormGroup;
  unit: string;

  cibi = [
    this.translate.instant("FOOD.LATTE"),
    this.translate.instant("FOOD.FIOCCHI"),
    this.translate.instant("FOOD.BISCOTTI"),
    this.translate.instant("FOOD.YOGURT_BIANCO"),
    this.translate.instant("FOOD.YOGURT_GRECO"),
    this.translate.instant("FOOD.SKYR"),
    this.translate.instant("FOOD.KEFIR"),
    this.translate.instant("FOOD.FETTE"),
    this.translate.instant("FOOD.PANE"),
    this.translate.instant("FOOD.PANE_INTEGRALE"),
    this.translate.instant("FOOD.PANE_SEGALE"),
    this.translate.instant("FOOD.PANE_CEREALI"),
    this.translate.instant("FOOD.GNOCCHI"),
    this.translate.instant("FOOD.PASTA"),
    this.translate.instant("FOOD.KAMUT"),
    this.translate.instant("FOOD.FARRO"),
    this.translate.instant("FOOD.ORZO"),
    this.translate.instant("FOOD.AVENA"),
    this.translate.instant("FOOD.QUINOA"),
    this.translate.instant("FOOD.PASTA_INTEGRALE"),
    this.translate.instant("FOOD.RISO"),
    this.translate.instant("FOOD.PATATE"),
    this.translate.instant("FOOD.COTTO"),
    this.translate.instant("FOOD.CRUDO"),
    this.translate.instant("FOOD.FESA"),
    this.translate.instant("FOOD.BRESAOLA"),
    this.translate.instant("FOOD.RICOTTA"),
    this.translate.instant("FOOD.FORMAGGIO"),
    this.translate.instant("FOOD.FIOCCHI_LATTE"),
    this.translate.instant("FOOD.CARNE_ROSSA"),
    this.translate.instant("FOOD.CARNE_BIANCA"),
    this.translate.instant("FOOD.TOFU"),
    this.translate.instant("FOOD.SEITAN"),
    this.translate.instant("FOOD.BURGER"),
    this.translate.instant("FOOD.CIOCCOLATO"),
    this.translate.instant("FOOD.PESCE"),
    this.translate.instant("FOOD.SALMONE"),
    this.translate.instant("FOOD.SALMONE_AFFUMICATO"),
    this.translate.instant("FOOD.TONNO"),
    this.translate.instant("FOOD.SGOMBRO"),
    this.translate.instant("FOOD.LEGUMI"),
    this.translate.instant("FOOD.UOVA"),
    this.translate.instant("FOOD.ALBUME"),
    this.translate.instant("FOOD.PARMIGGIANO"),
    this.translate.instant("FOOD.MIELE"),
    this.translate.instant("FOOD.MARMELLATA"),
    this.translate.instant("FOOD.FRUTTA"),
    this.translate.instant("FOOD.SPREMUTA"),
    this.translate.instant("FOOD.SEMI"),
    this.translate.instant("FOOD.OLIVE"),
    this.translate.instant("FOOD.BURRO")
  ];

  system = [
    {
      'val': "SYSTEM_IMP",
      'label': this.translate.instant("USER-NUTRITION.SURVEY_C.SYSTEM_IMP.TEXT")
    },
    {
      'val': "SYSTEM_MET",
      'label': this.translate.instant("USER-NUTRITION.SURVEY_C.SYSTEM_MET.TEXT")
    } ];

    stile_vita = [
      this.translate.instant("USER-NUTRITION.SURVEY_C.ACTIVE"),
      this.translate.instant("USER-NUTRITION.SURVEY_C.SEDENTARY"),
      this.translate.instant("USER-NUTRITION.SURVEY_C.LIGHT")
    ];

    ciclo = [this.translate.instant("USER-NUTRITION.SURVEY_C.REGULAR"), this.translate.instant("USER-NUTRITION.SURVEY_C.IRREGULAR"), this.translate.instant("USER-NUTRITION.SURVEY_C.MENOPAUSE")];

    showSurvey = false;
    disabledSend  = false;


  constructor(public guiService: GuiService, private fb: FormBuilder, public translate: TranslateService, public restService: RestService, private dialog: MatDialog) { }

  ngOnInit(): void {

    setTimeout(() => {
      this.guiService.showSpinner = false
      this.user = this.guiService.userLogged
      this.check30Days()
      console.debug(this.guiService.userLogged)
    }, 1000);


    this.systemFormGroup = this.fb.group({
      system: ['', Validators.required],
    });

    this.firstFormGroup = this.fb.group({
      nome: ['', Validators.required],
      cognome: ['', Validators.required],
      eta: ['', Validators.required],
      altezza: ['', Validators.required],
      peso: ['', Validators.required],
    });

    this.secondFormGroup = this.fb.group({
      vita: ['', Validators.required],
      fianchi: ['', Validators.required],
      coscia_dx: ['', Validators.required],
      coscia_sx: ['', Validators.required],
      braccio_dx: ['', Validators.required],
      braccio_sx: ['', Validators.required]
    });
    this.thirdFormGroup = this.fb.group({
      attivita: ['', Validators.required],
      stile: ['', Validators.required],
      ciclo: [''],
      frequenza_ciclo: [''],
    });
    this.fourthFormGroup = this.fb.group({
      patologie: [''],
      terapia: [''],
      allergie: [''],
    });
    this.foodFormGroup = this.fb.group({
      cibo_amo: ['', Validators.required],
      cibo_odio: ['', Validators.required]
    });
    this.questnote = this.fb.group({
      consideration : ['']
    })
    this.fifthFormGroup = this.fb.group({
      termini: ['', Validators.required],
      termini2: ['', Validators.required]
    });

  /* survey zone */

  this.surveyFormGroup = this.fb.group({
    peso: ['',Validators.required],
    vita: ['', Validators.required],
    fianchi: ['', Validators.required],
    coscia_dx: ['', Validators.required],
    coscia_sx: ['', Validators.required],
    braccio_dx: ['', Validators.required],
    braccio_sx: ['', Validators.required]
  });

  this.surveyTwoFormGroup = this.fb.group({
    like: [''],
    dislike: [''],
    would: [''],
    consideration: ['']
  });

  this.termsFormGroup = this.fb.group({
    termini: [false, Validators.required],
    termini2: ['', Validators.required]
  });
  if(this.guiService.userLogged){
    this.firstFormGroup.controls['nome'].setValue(this.guiService.userLogged.masterData.name);
    this.firstFormGroup.controls['cognome'].setValue(this.guiService.userLogged.masterData.lastName)
    this.firstFormGroup.controls['nome'].disable()
    this.firstFormGroup.controls['cognome'].disable()
    let now = moment()
    let birthdate = this.guiService.userLogged.masterData.birthDate
    // this.firstFormGroup.controls['eta'].setValue(parseInt(''+now.diff(birthdate, 'years')))
    this.firstFormGroup.controls['eta'].setValue(parseInt(''+now.diff(birthdate, 'years')))
    this.firstFormGroup.controls['eta'].disable()
  }

  }

  check30Days(){
    let now = moment()
    let dateSurveyAfter = moment(this.user.masterData.lastSurvey).add(1,'months')

    return now.isSameOrAfter(dateSurveyAfter)
  }

  changeSystemUnit() {
    console.debug("Unit System",this.systemFormGroup.value.system);
    this.unit = this.systemFormGroup.value.system;
  }

  submit(){
    this.disabledSend = true;

    let questionario = {
      surveyType: 'questionario',
      unitSystem: this.systemFormGroup.value.system,
      name: this.firstFormGroup.value.nome,
      surname: this.firstFormGroup.value.cognome,
      age: this.firstFormGroup.value.eta,
      height: this.firstFormGroup.value.altezza,
      weight: this.firstFormGroup.value.peso,
      weistline: this.secondFormGroup.value.vita,
      hip: this.secondFormGroup.value.fianchi,
      rightThigh: this.secondFormGroup.value.coscia_dx,
      leftThigh: this.secondFormGroup.value.coscia_sx,
      rightArm: this.secondFormGroup.value.braccio_dx,
      leftArm: this.secondFormGroup.value.braccio_sx,
      activity: this.thirdFormGroup.value.attivita,
      lifeStyle: this.thirdFormGroup.value.stile,
      period: this.thirdFormGroup.value.ciclo,
      periodFrequency: this.thirdFormGroup.value.frequenza_ciclo,
      pathologies: this.fourthFormGroup.value.patologie,
      therapy: this.fourthFormGroup.value.terapia,
      allergies: this.fourthFormGroup.value.allergie,
      belovedFood: this.foodFormGroup.value.cibo_amo,
      hatedFood: this.foodFormGroup.value.cibo_odio,
      terms: this.fifthFormGroup.value.termini,
      userId: this.guiService.userLogged.user.id,
      consideration: this.questnote.value.consideration
    }
    if(this.guiService.userLogged){
      questionario.name = this.guiService.userLogged.masterData.name
      questionario.surname = this.guiService.userLogged.masterData.lastName
      let now = moment()
      let birthdate = this.guiService.userLogged.masterData.birthDate
      questionario.age = parseInt(''+now.diff(birthdate, 'years'))
    }

    let survey = {
      surveyType: 'follow-up',
      unitSystem: this.systemFormGroup.value.system,
      weight: this.surveyFormGroup.value.peso,
      weistline: this.surveyFormGroup.value.vita,
      hip: this.surveyFormGroup.value.fianchi,
      rightThigh: this.surveyFormGroup.value.coscia_dx,
      leftThigh: this.surveyFormGroup.value.coscia_sx,
      rightArm: this.surveyFormGroup.value.braccio_dx,
      leftArm: this.surveyFormGroup.value.braccio_sx,
      like: this.surveyTwoFormGroup.value.like,
      dislike: this.surveyTwoFormGroup.value.dislike,
      would: this.surveyTwoFormGroup.value.would,
      consideration: this.surveyTwoFormGroup.value.consideration,
      userId: this.guiService.userLogged.user.id,
      terms: this.termsFormGroup.value.termini
    }

    if(this.user.masterData.lastDietUploaded != null){
      console.debug(survey)
      this.restService.postService('nutrition_survey', '', survey).subscribe((ris)=>{
        console.debug('survey',ris)
        this.guiService.openSnackBar('survey inviato con successo', 'chiudi')
        setTimeout(() => {
          location.reload()
        }, 1000);

        this.showSurvey = false;
      })
    }else{
      console.debug(questionario)
      this.restService.postService('nutrition_survey', '', questionario).subscribe((ris)=>{
        console.debug('questionario',ris)
        this.guiService.openSnackBar('questionario inviato con successo', 'chiudi')
        setTimeout(() => {
          location.reload()
        }, 1000);

        this.showSurvey = false;
      })
    }
  }

  getMySurvey(){
    this.restService.getService('nutrition_survey',this.user.masterData.lastSurveyId).subscribe((ris)=>{
      this.dialog.open(MySurveyComponent, {
        data: ris || null
      })
    })
  }

  // getMyDiet(){
  //   this.restService.getService('nutrition_survey',this.user.masterData.lastSurveyId + '/diet/document').subscribe((ris)=>{
  //     console.debug(ris.diet)
  //     this.dialog.open(MyDietComponent,{
  //       data: ris.diet.base64
  //     })
  //     // this.downloadPdf(ris.diet.base64, 'myDiet')
  //   })
  // }

  downloadDietPdf(idNutSurvey, fileName) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    this.restService.getPdf('nutrition_survey', '/'+idNutSurvey + '/diet/document').subscribe((ris)=>{
      const file = new Blob([ris], {type: 'application/pdf'});
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, '_blank', 'width=1000, height=800');
    })

  }

  downloadPdf(base64String, fileName) {
    const source = `data:application/pdf;base64,${base64String}`;
    const link = document.createElement("a");
    link.href = source;
    link.download = `${fileName}.pdf`
    link.click();
  }

  downloadGuidePdf(param){
    let link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    if(param == 1){
      link.href = "assets/44pdf44/44cucina_"+this.translate.currentLang+'.pdf';
      if(this.translate.currentLang == 'it'){
        link.download = 'In cucina con itsDesifit';
      }else{
        link.download = 'In the kitchen with itsDesifit';
      }
    }else if(param == 2){
      link.href = "assets/44pdf44/44guida_"+this.translate.currentLang+'.pdf'
      if(this.translate.currentLang == 'it'){
        link.download = 'Guida Nutrizionale';
      }else{
        link.download = 'Nutritional Guide';
      }
    }
    document.body.appendChild(link);
    link.click();
    link.remove();

  }

  discover(){
    if(this.translate.currentLang == 'it'){
      window.open('https://itsdesifit.com/it/piano-diamond/','_blank');
    }else{
      window.open('https://itsdesifit.com/diamond-plan/','_blank');
    }
  }

}
