import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { faCheck, faCircle, faPlus } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { NutritionDetailComponent } from 'src/app/admin/nutrition/nutrition-detail/nutrition-detail.component';
import { GuiService } from 'src/app/services/gui.service';
import { RestService } from 'src/app/services/rest.service';
import * as moment from 'moment';

@Component({
  selector: 'vex-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.scss']
})
export class CustomerListComponent implements OnInit {
  mnt = moment

  icon = {
    "faCircle": faCircle,
    "faCheck": faCheck,
    "faPlus": faPlus
  };

  icons = {
    "PAID": '<fa-icon [icon]="icon.faCircle" class="icon payed"></fa-icon>',
    "NOT_PAID": '<fa-icon [icon]="icon.faCircle" class="icon not-payed"></fa-icon>'
  }

  users: any;
  dataSource: MatTableDataSource<any> | null;
  displayedColumns: string[] = [
    "utente",
    "data-iscrizione",
    "stato-pagamento",
    "questionario",
    "upload-diet",
    "actions",
  ];
  searchCtrl = new UntypedFormControl();
  pageSize = 10;
  pageNumber = 0;
  pageSizeOptions: number[] = [5, 10, 15, 50];
  lunghezza: any;

  searchCtrlReg = new UntypedFormControl();
  searchCtrlPay = new UntypedFormControl();
  searchCtrlSurvey = new UntypedFormControl();
  searchCtrlDiet = new UntypedFormControl();

  /* sort var */
  asc: any;
  fieldParam: string;
  searchAltFlag: boolean;
  sortFlag: boolean;

  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private restService: RestService,
    public translate: TranslateService,
    private dialog: MatDialog,
    public guiService: GuiService
  ) { }

  ngOnInit(): void {
    setTimeout(() => (this.getUsers()), 1000);
  }

  getUsers(){
    setTimeout(() => (this.guiService.showSpinner = true), 0);
    this.dataSource = new MatTableDataSource();
    this.restService.postService('nutritionists', '/'+this.guiService.userLogged.user.id+'/users/master-data-search', {page: this.pageNumber , size: this.pageSize}).subscribe((ris)=>{
      this.lunghezza = ris.totalItems
      this.users = ris["items"];
      console.debug('utenti', ris.items)
      this.dataSource.data = this.users;
      this.table ? this.table.renderRows() : null;
      this.guiService.showSpinner = false;
    })
  }


  onPageChanged(pageEvent: PageEvent) {
    this.pageNumber = pageEvent.pageIndex+1;
    this.pageSize = pageEvent.pageSize;
    if(this.searchAltFlag == false && this.sortFlag == false){
      this.getUsers()
    }else if(this.sortFlag == true){
      this.sortParam(this.fieldParam, this.asc)
    }else if(this.searchAltFlag == true){
      this.search()
    }
  }

  userDetail(item?: any['item']){
    console.debug(item)
    this.dialog.open(NutritionDetailComponent, {
      width: '65vw',
      maxWidth: '65vw',
      height: '95vh',
      panelClass: 'user-detail',
      data: item || null
    }).afterClosed().subscribe((user) => {
      if(user){
        this.getUsers()
      }
    })
  }

  search() {
    this.searchAltFlag = true
    let param = {
      user: {
        "filterAnd": []
      },
      masterData:{
        "filterAnd": []
      },
      payment:{
        "filterAnd": []
      },
      "page": this.pageNumber,
      "size": this.pageSize
    }

    if(this.searchCtrlReg.value != null){
      param.masterData["filterAnd"].push(
        {
          "field": "registrationDate",
          "operator": "gte",
          "dateValue": moment(this.searchCtrlReg.value + " 00:00:00","YYYY-MM-DD HH:mm:ss").format('x')
        },
      ),
      param.masterData["filterAnd"].push(
        {
          "field": "registrationDate",
          "operator": "lte",
          "dateValue": moment(this.searchCtrlReg.value + " 23:59:59","YYYY-MM-DD HH:mm:ss").format('x')
        }
      ),
      param.masterData["filterAnd"].push(
        {
          "field": "nutritionistId",
          "operator": "eq",
          "value": this.guiService.userLogged.user.id
        }
      )
    }
    if(this.searchCtrl.value != null){
      param.user["filterAnd"].push(
        {
          "field": "email",
          "operator": "like",
          "value": this.searchCtrl.value
        }
      ),
      param.masterData["filterAnd"].push(
        {
          "field": "nutritionistId",
          "operator": "eq",
          "value": this.guiService.userLogged.user.id
        }
      )
    }
    if(this.searchCtrlPay.value != null){
      param.payment["filterAnd"].push(
        {
          "field": "subscriptionStatus",
          "operator": "like",
          "value": this.searchCtrlPay.value
        }
      ),
      param.masterData["filterAnd"].push(
        {
          "field": "nutritionistId",
          "operator": "eq",
          "value": this.guiService.userLogged.user.id
        }
      )
    }
    if(this.searchCtrlSurvey.value != null){
      param.masterData["filterAnd"].push(
        {
          "field": "lastSurvey",
          "operator": "gte",
          "dateValue": moment(this.searchCtrlSurvey.value + " 00:00:00","YYYY-MM-DD HH:mm:ss").format('x')
        }
      ),
      param.masterData["filterAnd"].push(
        {
          "field": "lastSurvey",
          "operator": "lte",
          "dateValue": moment(this.searchCtrlSurvey.value + " 23:59:59","YYYY-MM-DD HH:mm:ss").format('x')
        }
      ),
      param.masterData["filterAnd"].push(
        {
          "field": "nutritionistId",
          "operator": "eq",
          "value": this.guiService.userLogged.user.id
        }
      )
    }
    if(this.searchCtrlDiet.value != null){
      param.masterData["filterAnd"].push(
        {
          "field": "lastDietUploaded",
          "operator": "gte",
          "dateValue": moment(this.searchCtrlDiet.value + " 00:00:00","YYYY-MM-DD HH:mm:ss").format('x')
        }
      ),
      param.masterData["filterAnd"].push(
        {
          "field": "lastDietUploaded",
          "operator": "lte",
          "dateValue": moment(this.searchCtrlDiet.value + " 23:59:59","YYYY-MM-DD HH:mm:ss").format('x')
        }
      ),
      param.masterData["filterAnd"].push(
        {
          "field": "nutritionistId",
          "operator": "eq",
          "value": this.guiService.userLogged.user.id
        }
      )
    }
    console.debug('param prima della chiamata', param)
    this.restService.postService('users', '/full/search?ALL_LANGUAGES=true', param).subscribe({
      next: (ris)=>{
        this.lunghezza = ris.totalItems
        this.users = ris["items"];
        console.debug('utenti', ris.items)
        this.dataSource.data = this.users;
        this.table ? this.table.renderRows() : null;
        this.guiService.showSpinner = false;
      },error: (e)=>{
        setTimeout(()=>this.guiService.showSpinner = false,1500)
      }
    })

  }

  sortParam(field?,asc?){
    this.asc = asc
    this.sortFlag = true
    this.fieldParam = field
    let param: any;
    if(this.fieldParam == 'subscriptionStatus'){
      param = {
        page: this.pageNumber,
        size: this.pageSize,
      payment:{
        orders: [
        {
         field: this.fieldParam,
         ascending : this.asc
        }
      ]
      },
      masterData:{
        filterAnd:[
          {
            "field": "nutritionistId",
            "operator": "eq",
            "value": this.guiService.userLogged.user.id
          }
        ]

      }
      }
    }
    if(this.fieldParam == 'email'){
      param = {
        page: this.pageNumber,
        size: this.pageSize,
        user:{
          orders: [
          {
           field: this.fieldParam,
           ascending : this.asc
          }
        ]
        },
        masterData:{
          filterAnd:[
            {
              "field": "nutritionistId",
              "operator": "eq",
              "value": this.guiService.userLogged.user.id
            }
          ]

        }
      }
    }
    if(this.fieldParam == 'lastDietUploaded' || this.fieldParam == 'lastSurvey' ||this.fieldParam == 'registrationDate'){
      param = {
        page: this.pageNumber,
        size: this.pageSize,
        masterData:{
          filterAnd:[
            {
              "field": "nutritionistId",
              "operator": "eq",
              "value": this.guiService.userLogged.user.id
            }
          ],
          orders: [
          {
           field: this.fieldParam,
           ascending : this.asc
          }
        ]
        }
      }
    }
    setTimeout(()=>this.guiService.showSpinner = true,0)
    this.restService.postService('users', '/full/search', param).subscribe({
      next: (ris)=>{
        this.lunghezza = ris.totalItems
      this.users = ris["items"];
      console.debug('utenti', ris.items)
      this.dataSource.data = this.users;
      this.table ? this.table.renderRows() : null;
      this.guiService.showSpinner = false;
      },error:(e)=>{
        setTimeout(()=>this.guiService.showSpinner = false,1500)
      }

    });
  }

  reload(){
    this.searchCtrlReg.reset()
    this.searchCtrlPay.reset()
    this.searchCtrlSurvey.reset()
    this.searchCtrlDiet.reset()
    this.searchAltFlag = false;
    this.sortFlag = false;
    this.pageNumber = 0;
    this.pageSize = 10;
    this.getUsers()
  }

  resetPage(){
    this.pageNumber = 0;
  }

  checkDietUpload(diet,survey){
    return moment(diet).isSameOrAfter(survey)
  }

  check30Days(lastSurvey){
    let now = moment()
    let dateSurveyAfter = moment(lastSurvey).add(1,'months')

    return now.isSameOrAfter(dateSurveyAfter)
  }
}
