// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

//  export const environment = {
//   production: false,
//   baseURL: {
//     base: "http://itsdev.sigma2solutions.it",
//     login: "https://app.itsdesifit.com/api-test/login",
//     users: "https://app.itsdesifit.com/api-test/users",
//     register: "https://app.itsdesifit.com/api-test/register",
//     current_user: "https://app.itsdesifit.com/api-test/users/current",
//     exercises_search: "https://app.itsdesifit.com/api-test/exercises/search",
//     exercises: "https://app.itsdesifit.com/api-test/exercises",
//     exercise_type: "https://app.itsdesifit.com/api-test/bos/exercise-type",
//     exercise_muscle: "https://app.itsdesifit.com/api-test/bos/muscle-group",
//     exercise_training: "https://app.itsdesifit.com/api-test/bos/training-group",
//     workouts_search: "https://app.itsdesifit.com/api-test/workouts/search",
//     workouts: "https://app.itsdesifit.com/api-test/workouts",
//     equipments: "https://app.itsdesifit.com/api-test/equipments/search",
//     equipment: "https://app.itsdesifit.com/api-test/equipments",
//     workout_programs: "https://app.itsdesifit.com/api-test/workout-programs",
//     user_workout_programs: 'https://app.itsdesifit.com/api-test/user-workout-programs',
//     workout_phase_types: 'https://app.itsdesifit.com/api-test/workout-phase-types',
//     promotions: 'https://app.itsdesifit.com/api-test/promotions',
//     subscriptions: 'https://app.itsdesifit.com/api-test/subscriptions',
//     bos: 'https://app.itsdesifit.com/api-test/bos',
//     notifications: 'https://app.itsdesifit.com/api-test/notifications',
//     user_notification_status: 'https://app.itsdesifit.com/api-test/user-notification-status',
//     challenges: 'https://app.itsdesifit.com/api-test/challenges',
//     nutrition_survey: 'https://app.itsdesifit.com/api-test/nutrition-survey',
//     diet: 'https://app.itsdesifit.com/api-test/diet',
//     nutritionists: 'https://app.itsdesifit.com/api-test/nutritionists',
//     stripe: 'https://app.itsdesifit.com/api-test/stripe',
//     paypal: 'https://app.itsdesifit.com/api-test/paypal',
//     chargebee: 'https://app.itsdesifit.com/api-test/chargebee',
//     report: 'https://app.itsdesifit.com/api-test/report',
//     video: 'https://app.itsdesifit.com/v1d',
//     script_paypal: 'https://www.paypal.com/sdk/js?client-id=ATgneNPedg-KcWQuRkW41bq9rC6Qy6VDC4_m95LeyRBB2ZoGHgbyQ5gkr34w6t8dK3S0jkUyUDcYc7sM&components=buttons&vault=true'
//   }
// };

export const environment = {
  production: false,
  baseURL: {
    base: "https://itsdev.cspinnova.com",
    login: "https://itsdev.cspinnova.com/api-test/login",
    users: "https://itsdev.cspinnova.com/api-test/users",
    register: "https://itsdev.cspinnova.com/api-test/register",
    current_user: "https://itsdev.cspinnova.com/api-test/users/current",
    exercises_search: "https://itsdev.cspinnova.com/api-test/exercises/search",
    exercises: "https://itsdev.cspinnova.com/api-test/exercises",
    exercise_type: "https://itsdev.cspinnova.com/api-test/bos/exercise-type",
    exercise_muscle: "https://itsdev.cspinnova.com/api-test/bos/muscle-group",
    exercise_training: "https://itsdev.cspinnova.com/api-test/bos/training-group",
    workouts_search: "https://itsdev.cspinnova.com/api-test/workouts/search",
    workouts: "https://itsdev.cspinnova.com/api-test/workouts",
    equipments: "https://itsdev.cspinnova.com/api-test/equipments/search",
    equipment: "https://itsdev.cspinnova.com/api-test/equipments",
    workout_programs: "https://itsdev.cspinnova.com/api-test/workout-programs",
    user_workout_programs: 'https://itsdev.cspinnova.com/api-test/user-workout-programs',
    workout_phase_types: 'https://itsdev.cspinnova.com/api-test/workout-phase-types',
    promotions: 'https://itsdev.cspinnova.com/api-test/promotions',
    subscriptions: 'https://itsdev.cspinnova.com/api-test/subscriptions',
    bos: 'https://itsdev.cspinnova.com/api-test/bos',
    notifications: 'https://itsdev.cspinnova.com/api-test/notifications',
    user_notification_status: 'https://itsdev.cspinnova.com/api-test/user-notification-status',
    challenges: 'https://itsdev.cspinnova.com/api-test/challenges',
    nutrition_survey: 'https://itsdev.cspinnova.com/api-test/nutrition-survey',
    diet: 'https://itsdev.cspinnova.com/api-test/diet',
    nutritionists: 'https://itsdev.cspinnova.com/api-test/nutritionists',
    stripe: 'https://itsdev.cspinnova.com/api-test/stripe',
    paypal: 'https://itsdev.cspinnova.com/api-test/paypal',
    chargebee: 'https://itsdev.cspinnova.com/api-test/chargebee',
    report: 'https://itsdev.cspinnova.com/api-test/report',
    video: 'https://itsdev.cspinnova.com/api-test/v1d',
    mock: 'https://659e6d5a47ae28b0bd35cf35.mockapi.io/getstronger',
    area_wellness: 'http://127.0.0.1:3000',
    category_tmp_delete: 'http://127.0.0.1:3000/categories',
    video_tmp_delete: 'http://127.0.0.1:3000/videos',
    category: 'https://itsdev.cspinnova.com/api-test/category'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
