<div class="text-center p-gutter pb-24 header">
  <div class="container">
    <h2 @fadeInUp class="display-2 mt-16 mb-8">
      {{ "USER_TRANS.INFO.INFO" | translate }}
    </h2>
  </div>

  <div class="text-left mt-24 max-w-3xl mx-auto">
    <mat-accordion>
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title
            >{{ "USER_TRANS.INFO.HOW" | translate }}
          </mat-panel-title>
          <mat-icon svgIcon="mat:assignment"></mat-icon>
        </mat-expansion-panel-header>

        <span *ngIf="translate.currentLang == 'it'">
          <p>
            Ho ideato i miei programmi di allenamento per aiutarti a migliorare
            la tua salute fisica e mentale. Nelle prossime settimane, la mia
            missione è aiutarti a raggiungere il tuo massimo potenziale!
            Eseguirai un mix di allenamenti a circuito, di resistenza e di
            forza, seguendo il principio scientifico del sovraccarico
            progressivo.
          </p>
          <p>
            I miei programmi di allenamento sono ideati per aumentare la tua
            forza e resistenza, e per migliorare la tua generale forma fisica,
            incorporando circuiti, superset, triset e finishers metabolici.
            Troverai anche esercizi per rafforzare il core.
          </p>
          <p>
            Eseguirai 3-4 sessioni di allenamento a settimana e 1-2 sessioni
            cardio, abbinando cardio a bassa e alta intensità.
          </p>
          <p>
            I miei programmi di allenamento hanno un'enfasi sull’allenamento dei
            glutei, perché avere glutei forti aiuta a migliorare la postura,
            prevenire gli infortuni, aumentare l’atletismo, la forza e la
            potenza. La maggior parte della popolazione tende a stare seduta per
            lunghi periodi di tempo; quindi, è molto comune avere glutei
            inattivi.
          </p>
          <p>
            Prima di iniziare qualsiasi allenamento, è molto importante
            riscaldarsi. All’inizio di ogni allenamento, troverai una routine
            suggerita. Un buon riscaldamento aiuterà ad alleviare lo stress
            sulle articolazioni, ad aumentare la temperatura muscolare e la
            frequenza cardiaca e ad assicurare che i muscoli siano ben forniti
            di ossigeno.
          </p>
          <p>
            Troverai anche una routine suggerita per il defaticamento, in modo
            da consentire al tuo corpo di tornare gradualmente al suo stato
            pre-allenamento. È utile fare stretching per ridurre l’accumulo di
            acido lattico, che può portare a crampi e rigidità muscolari.
          </p>
          <p>
            Non dimenticare: anche il recupero è estremamente importante! Per
            raggiungere i tuoi obiettivi, è importante riposare, quanto
            allenarsi, perché quando ti alleni, crei delle microlesioni nei
            muscoli, ed è proprio quando ci riposiamo che i nostri muscoli
            crescono. Ti consiglio di seguire il mio programma settimanale
            suggerito che comprende anche i giorni di riposo. Ti consiglio,
            infine, di vivere una vita sana, attiva ma soprattutto equilibrata.
            Vedrai risultati migliori se ti prendi uno o due giorni di riposo
            ogni settimana.
          </p>
        </span>

        <span *ngIf="translate.currentLang == 'en'">
          <p>
            I’ve designed my fitness programs to help you improve your physical
            and mental health. Over the next weeks, my mission is to help you
            reach your full potential! You will be performing a mix of circuit
            training, resistance training and strength training, following the
            scientific-based principle of progressive overload.
          </p>
          <p>
            My fitness programs are designed to build strength and endurance, as
            well as improve your overall fitness by incorporating circuits,
            supersets, trisets and metabolic finishers. You will also find core
            routines to improve your core strength.
          </p>
          <p>
            You will be performing 3-4 workout sessions a week, and 1-2 cardio
            sessions by mixing low and high intensity cardio.
          </p>
          <p>
            Before starting any workout, it is very important to warm up. You
            will find a suggested routine. A good warm-up will help ease stress
            on the joints, raise your muscle temperature and heart rate, and
            ensure your muscles are well supplied with oxygen.
          </p>
          <p>
            You will also find a suggested cool-down routine, to allow your body
            to gradually return to the pre-workout state. It’s good to stretch
            to help reduce the buildup of lactic acid, which can lead to muscle
            cramping and stiffness.
          </p>
          <p>
            Don’t forget: recovery is also extremely important! To reach your
            fitness goals, it’s honestly as important as working out, because
            when you work out, you create microlesions in the muscles, and it is
            actually when we rest that our muscles grow. I encourage you to
            follow my suggested calendar that also incorporates your rest days.
            I encourage you to live a healthy, active and balanced life and
            believe me, you will see better results if you take a day or two off
            during your week!
          </p>
        </span>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>{{
            "USER_TRANS.INFO.METHOD" | translate
          }}</mat-panel-title>
          <mat-icon svgIcon="mat:description"></mat-icon>
        </mat-expansion-panel-header>

        <span *ngIf="translate.currentLang == 'it'">
          <p>
            Nei miei programmi di allenamento, troverai diverse metodologie tra
            cui:
          </p>
          <p>
            <b>Sistema PHA</b>
            <br />
            PHA è l’acronimo di Peripheral Heart Action Training, un metodo di
            allenamento a circuito che ti consente di spostarti da un esercizio
            all’altro con un riposo minimo o addirittura senza riposo tra
            esercizi. Con l’allenamento PHA, mentre ti sposti da un esercizio
            all’altro, alterni esercizi della parte superiore del corpo a quella
            inferiore (fonte NASM Fitness). Quando esegui un circuito PHA,
            ripeti il ​​circuito per i round elencati nel tuo programma, con una
            pausa di 60 secondi tra i circuiti.
          </p>
          <p>
            <b>Supersets</b>
            <br />
            Un superset è una tecnica utilizzata nell’allenamento di forza in
            cui ci si sposta da un esercizio all’altro senza riposo tra i due
            esercizi. Ti riposerai tra i superset.
          </p>
          <p>
            <b>Trisets</b>
            <br />
            Un triset è una tecnica utilizzata nell’allenamento di forza in cui
            si eseguono tre diversi esercizi uno dopo l’altro, senza pause
            intermedie. Ti riposerai tra i triset.
          </p>
          <p>
            <b>Finisher Metabolico</b>
            <br />
            Un finisher metabolico è una tecnica utilizzata per aumentare la tua
            resistenza, migliorare il condizionamento e le prestazioni atletiche
            eseguendo 1-2 esercizi intensi alla fine del workout.
          </p>
          <p>
            In <b>Stronger GYM</b>, la struttura principale del tuo allenamento
            sarà composta da Esercizio, Serie, Ripetizioni e Recupero.
            L’esercizio è elencato per primo, poi troverai il numero di serie ed
            infine il numero di ripetizioni per ciascuna serie. Troverai anche
            il tempo di recupero suggerito.
          </p>
          <p>
            <b>L’esercizio</b> è il movimento individuale (ad es. hip thrust,
            curl con bilanciere, leg extension) che esegui.
          </p>
          <p>
            Il numero di <b>serie</b> indica il gruppo di ripetizioni di un
            esercizio.
          </p>
          <p>
            Il numero di <b>ripetizioni</b> indica il numero di volte in cui
            eseguirai effettivamente il singolo movimento, sollevando e
            abbassando il peso.
          </p>
          <p>
            <b>Rest periods:</b>
            <br />
            Per aumentare la tua resistenza muscolare, ti consiglio fare una
            pausa tra i 30 secondi e i 2 minuti circa tra serie.
          </p>
          <p>
            Il tempo di recupero è inteso come la pausa tra le serie di un
            esercizio.
          </p>
        </span>

        <span *ngIf="translate.currentLang == 'en'">
          <p>
            In my fitness programs, you will find a variety of methods
            including:
          </p>
          <p>
            <b>PHA System</b><br />

            PHA stands for Peripheral Heart Action Training, a circuit training
            method that keeps you moving from one exercise to another with
            minimal to no rest between exercises. With PHA training, while you
            are moving from one exercise to another, you are alternating upper
            and lower body exercises (source NASM Fitness). When you are
            performing a PHA circuit, you will repeat the circuit for the rounds
            listed in your program, with a 60 second rest between circuits.
          </p>
          <p>
            <b>Supersets</b><br />

            A superset is a technique used in strength training in which you
            move from one exercise to another without taking a break between the
            two exercises. You will rest between supersets.
          </p>
          <p>
            <b>Trisets</b><br />
            A triset is a technique used in strength training in which you
            perform three different exercises one after another without any rest
            between. You will rest between trisets.
          </p>
          <p>
            <b>Metabolic Finishers</b><br />
            A metabolic finisher is a technique used to increase your work
            capacity, enhance your conditioning and athletic performance by
            performing 1-2 intense exercises at the end of your workout. Rest
            periods: To increase your muscular endurance, I recommend resting
            approximately 30 seconds to 2 minutes between sets.
          </p>
          <p>
            In <b>STRONGER GYM</b> the main layout of your workout log will be
            Exercise, Sets, Reps and Rest. The exercise is listed first, then
            the number of sets and the number of reps in each set. You will also
            find the suggested rest period.
          </p>
          <p>
            The <b>exercise</b> is the individual movement (e.g. hip thrust,
            barbell curl, leg extension) that you perform in your workouts.
          </p>
          <p>
            The number of <b>sets</b> indicates the group of repetitions of an
            exercise.
          </p>
          <p>
            The number of <b>repetitions</b> indicates the number of times you
            will actually perform the individual movement, lifting and lowering
            the weight.
          </p>
          <p>
            <b>Rest period</b> is intended as the pause between sets of an
            exercise. To increase your muscular endurance, I recommend resting
            approximately 30 seconds to 2 minutes between sets.
          </p>
        </span>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>{{
            "USER_TRANS.INFO.WARM" | translate
          }}</mat-panel-title>
          <fa-icon [icon]="icon.faRunning" size="lg"></fa-icon>
        </mat-expansion-panel-header>
        <span *ngIf="translate.currentLang == 'en'">
          <p>
            <b>Warm-Up</b>
            <br />
            You should warm up before EVERY workout to prepare your body for the
            increased workload. As your body temperature increases, you’ll
            loosen your joints and increase blood flow to your muscles,
            preventing injury. Before every workout session, you will find a
            suggested routine.
          </p>

          <p>
            <b>Cool-Down</b>
            <br />
            After any workout you should take time to cool down to promote
            muscle recovery, improve flexibility, allow your heart rate and
            breathing to return to normal and to promote relaxation. At the end
            of every workout session, you will find a suggested routine.
          </p>
          <p>
            <b>Glutes Activation</b>
            <br />
            My fitness program has a strong focus on glutes training, because
            having strong glutes will help improve your posture, prevent injury,
            increase athleticism, strength and power. The majority of the
            population tends to sit for long periods of time; therefore, it is
            very common to have inactive glutes. To maximize the benefits of
            your workouts, I suggest that you spend 5 minutes on activating your
            glutes right after your warmup. Glutes activation is a series of
            exercises to strengthen your glutes muscles. You will find a
            suggested routine.
          </p>
        </span>

        <span *ngIf="translate.currentLang == 'it'">
          <p>
            <b>Riscaldamento</b>
            <br />
            Dovresti riscaldarti prima di ogni allenamento per preparare il tuo
            corpo all’aumento del carico di lavoro. Man mano che la temperatura
            corporea aumenta, rilassi le articolazioni e aumenta il flusso
            sanguigno ai muscoli, prevenendo lesioni. All’interno di ogni scheda
            workout, troverai una routine suggerita.
          </p>
          <p>
            <b>Defaticamento</b>
            <br />
            Dopo ogni allenamento dovresti dedicare del tempo al defaticamento
            per favorire il recupero muscolare, migliorare la flessibilità,
            consentire alla frequenza cardiaca e alla respirazione di tornare
            alla normalità e favorire il rilassamento. All’interno di ogni
            scheda workout, troverai una routine suggerita.
          </p>
          <p>
            <b>Attivazione glutei</b>
            <br />
            Il mio programma di allenamento ha un’enfasi sull'allenamento dei
            glutei, perché avere glutei forti aiuta a migliorare la postura,
            prevenire gli infortuni, aumentare l’atletismo, la forza e la
            potenza. La maggior parte della popolazione tende a stare seduta per
            lunghi periodi di tempo; quindi, è molto comune avere glutei
            inattivi. Per massimizzare i benefici dei tuoi allenamenti, ti
            suggerisco di dedicare 5 minuti all’attivazione dei glutei subito
            dopo il riscaldamento. All’interno delle schede workout focalizzate
            sulla parte inferiore del corpo, troverai una routine suggerita.
          </p>
        </span>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title
            >{{ "USER_TRANS.INFO.CARDIO" | translate }}
          </mat-panel-title>
          <fa-icon [icon]="icon.faHeartbeat" size="lg"></fa-icon>
        </mat-expansion-panel-header>

        <span *ngIf="translate.currentLang == 'it'">
          <p>
            <b>Cardio LISS</b>
            <br />
            LISS, l’allenamento cardio a bassa intensità stazionario, implica il
            mantenimento di un livello costante di intensità e ritmo di lavoro
            durante la sessione. Questo tipo di workout viene eseguito al
            50%-65% della frequenza cardiaca massima. Esempi di allenamento LISS
            comprendono: camminare a ritmo moderato, nuotare, andare in
            bicicletta, fare trekking all’aperto. Consiglio una
            passeggiata/jogging sul tapis roulant o all’aperto della durata di
            45-60 minuti.
          </p>

          <p>
            <b>Cardio HIIT</b>
            <br />
            HIIT, l’allenamento cardio a intervalli ad alta intensità prevede
            sforzi momentanei e ripetuti di alta intensità seguiti da momenti
            variabili di recupero. Questo tipo di allenamento viene eseguito
            all’80%-90% della frequenza cardiaca massima. Durante i periodi di
            recupero, che possono durare quanto i periodi di lavoro o possono
            essere più brevi, puoi prendere una vera e propria pausa o eseguire
            gli esercizi al 40%-50% della tua frequenza cardiaca massima. Esempi
            di allenamento HIIT includono saltare la corda, gli sprint e gli
            allenamenti Tabata. In genere, la durata di un allenamento ad alta
            intensità varia da 10 a 30 minuti.
          </p>
        </span>

        <span *ngIf="translate.currentLang == 'en'">
          <p>
            <b>Cardio LISS:</b>
            <br />
            LISS, low-intensity steady-state cardio training involves
            maintaining a consistent level of intensity and work rate during
            your session. This type of workout is performed at 50% to 65% of
            your maximum heart rate. Examples of LISS training include walking
            at a moderate pace, swimming, cycling, hiking outdoors. I recommend
            a walk/jog on the treadmill or outdoors for 45-60 minutes.
          </p>

          <p>
            <b>Cardio HIIT:</b>
            <br />
            HIIT, high-intensity interval cardio training involves repeated and
            quick bouts of high intensity effort followed by varied recovery
            times. This type of workout is performed at 80% to 90% of your
            estimated maximal heart rate. During the recovery periods, which may
            last as long as the work periods or can be shorter, you can either
            take a total rest or perform exercises at 40% to 50% of your
            estimated maximal heart rate. Examples of HIIT training include jump
            rope, sprints and Tabata workouts. I recommend each session to last
            no more than 30 minutes.
          </p>
        </span>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>{{
            "USER_TRANS.INFO.DELOAD" | translate
          }}</mat-panel-title>
          <mat-icon svgIcon="mat:directions_walk"></mat-icon>
        </mat-expansion-panel-header>

        <span *ngIf="translate.currentLang == 'it'">
          <p>
            “Diventi più forte quando recuperi”. L’allenamento crea stress ai
            nostri muscoli e alla nostra mente, quindi è molto importante
            riposare. Oltre ai classici giorni di rest che trovi nel tuo programma
            settimanale, ti consiglio di aggiungere una settimana di scarico ogni
            12-16 settimane di allenamento per preparare il corpo alla
            fase/periodo successivo.
          </p>
          <p>
             Una settimana di scarico, detta anche di
            deload, è una settimana di allenamento in cui ti alleni ma l’intensità
            e il volume dei tuoi allenamenti si riducono. Ecco due opzioni per
            incorporare con successo le settimane di deload:

          </p>
          <ul>
            <li>Riduci del 50-60% il
            carico che usi abitualmente per ciascun esercizio.</li>
            <li>Usa lo stesso
            carico che useresti normalmente, ma diminuisci il numero di volume
            totale (serie x ripetizioni) adottando una delle seguenti strategie:
          <ul>
            <li>riduci dal 30 al 50% il numero di serie;</li>
            <li>esegui da 2 a 4 ripetizioni
            in meno per ogni esercizio.</li>
          </ul>
        </li>
          </ul>
        </span>

        <span *ngIf="translate.currentLang == 'en'">
          <p>
            “You get stronger by recovering from exercise.” Training provides
            physical stress to our muscles and mind, therefore it is very
            important to rest. In addition to the classic rest days that you find
            in your weekly schedule, I recommend adding a deload week every 12-16
            weeks of training to prepare the body for the increased demand of the
            next phase or period.
          </p>
          <p>
            A deload week is a week of training in which you
            still go to the gym to workout, but the intensity and volume of your
            workouts are reduced. Here are a two options to successfully
            incorporate deload weeks:
          </p>
          <ul>
            <li>
              Reduce the weight you use to about 50-60% of what you normally work out for each exercise.
            </li>
            <li>
              Use the same weight as
            you normally would, but drop your number of total volume (sets x reps)
            by either:
              <ul>
                <li>reducing the number of sets you do in your workouts by
            30-to-50%;
                </li>
                <li>
                  doing 2-to-4 fewer reps in each set than you did in your
            previous hard training session
                </li>
              </ul>
            </li>
          </ul>
        </span>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>{{
            "USER_TRANS.INFO.POST" | translate
          }}</mat-panel-title>
          <mat-icon svgIcon="mat:pregnant_woman"></mat-icon>
        </mat-expansion-panel-header>

        <span *ngIf="translate.currentLang == 'it'">
          <p>
            Il programma: STRONGER PostPartum è un programma ideato per chi
            desidera tornare gradualmente ad allenarsi dopo una gravidanza. Puoi
            iniziare questo programma superato il controllo post partum e solo
            dopo avere ottenuto l’autorizzazione dal tuo medico a riprendere
            l’attività fisica.
          </p>
          <p>
            La prima fase è incentrata sulla riabilitazione del pavimento
            pelvico e del core, e pone le basi per le fasi 2 e 3 in cui ci
            concentreremo prima sull’acquisizione della resistenza, e
            successivamente sull'aumento della forza muscolare.
          </p>
          <p>
            Troverai una forte enfasi sulla respirazione diaframmatica e
            l’attivazione del trasverso dell’addome. Per respirare
            correttamente, mettete una mano sulla pancia e una sul petto. Quando
            inspirate con il naso dovete gonfiare solo la pancia, lasciando
            fermo il torace; poi espirate con la bocca aperta, emettendo un
            sospiro, sgonfiando la pancia. Posizionare le mani su pancia e petto
            vi aiuterà a prendere coscienza del movimento e capire se state
            lavorando con la pancia o se state facendo intervenire le coste
            durante la respirazione.
          </p>
          <p>
            Nel programma ci saranno inoltre esercizi per rafforzare il dorso e
            i muscoli motori dell’anca.
          </p>
          <p>
            Vi consiglio infine, prima di iniziare qualsiasi programma di
            fitness post parto, di consultare un’ostetrica o un fisioterapista
            esperto in pavimento pelvico per valutare la diastasi addominale e
            lo stato di salute del pavimento pelvico.
          </p>
          <div class="btn"><button>{{"USER_TRANS.INFO.VIDEO" | translate}}</button></div>
        </span>

        <span *ngIf="translate.currentLang == 'en'">
          <p>
            STRONGER PostPartum is a program designed to gradually return to
            training after pregnancy. You can start this program only after
            your medical provider has cleared you to resume physical activity at
            your postpartum check-up.
          </p>
          <p>
            The first phase focuses on rehabilitation of the pelvic floor and
            core and sets the stage for phases 2 and 3 where we will focus first
            on acquiring endurance and then on increasing muscle strength.
          </p>
          <p>
            You will find a strong emphasis on diaphragmatic breathing to
            properly activate your TVA (transversus abdominis). To breathe
            properly, place one hand on your stomach and the other on your
            chest. When you breathe in through your nose you should inflate only
            the belly, leaving the chest still; then exhale with your mouth
            open, letting out a sigh, deflating the belly. Placing your hands on
            your stomach and chest will help you become aware of the movement
            and understand if you are breathing with your stomach or if you are
            letting your ribs intervene as you breathe.
          </p>
          <p>
            The program will also include exercises to strengthen the back and
            hips.
          </p>
          <p>
            Finally, before starting any postpartum fitness program, I recommend
            that you consult a midwife or pelvic floor physiotherapist to assess
            the degree of your diastasis recti and pelvic floor health.
          </p>
          <div class="btn"><button>{{"USER_TRANS.INFO.VIDEO" | translate}}</button></div>
        </span>

      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>{{
            "USER_TRANS.INFO.MEASURE" | translate
          }}</mat-panel-title>
          <mat-icon svgIcon="mat:straighten"></mat-icon>
        </mat-expansion-panel-header>

        <span *ngIf="translate.currentLang == 'it'">
          <p>
            Durante il tuo percorso di allenamento potresti voler misurare i
            tuoi progressi e, per farlo, ti consiglio di fissare i tuoi giorni
            di check-in ogni 4 settimane. Durante il giorno del check-in, ti
            consiglio semplicemente di scattarti delle foto. È così che
            documenterai i tuoi progressi! Se desideri, puoi anche tenere
            traccia delle tue misure (vita, cosce, busto e braccia), ma non ti
            consiglio di monitorare il tuo peso. Sono fermamente convinta che la
            bilancia non sia in grado di misurare i tuoi veri progressi e che ci
            siano cambiamenti che sono altrettanto importanti, sebbene
            potrebbero non essere visibili. Considera l’aumento della tua forza,
            resistenza, atletismo e della fiducia in te stessa. Inoltre, un
            semplice numero non può misurare quanto grasso corporeo potresti
            aver perso o quanta massa muscolare potresti aver acquisito.
            <br />
            Suggerimenti per le tue foto progresso:
          </p>
          <ul>
            <li>
              Scatta le tue foto progresso indossando gli stessi vestiti
              (reggiseno sportivo/top corto e pantaloncini/biancheria intima)
            </li>
            <li>
              Scatta le tue foto progresso nello stesso luogo e nello stesso
              momento della giornata per garantire una simile illuminazione
            </li>
            <li>
              Scatta le tue foto progresso frontalmente, lateralmente e
              posteriormente
            </li>
            <li>Assicurati che braccia e gambe siano nella stessa posizione</li>
          </ul>
          <p>
            Se desideri condividere i tuoi progressi, tagga @itsdesifit e
            #strongerwithitsdesifit in modo che io possa vedere le tue foto e
            far parte del tuo percorso o invia le tue foto a info@itsdesifit.com
            .
          </p>
        </span>

        <span *ngIf="translate.currentLang == 'en'">
          <p>
            You may want to measure how you progress throughout your fitness
            journey and, to do so, I recommend to plan your check-in days every
            4 weeks. During your check-in day, I simply encourage you to take
            progress photos. That is how you will document your progress! If
            you’d like, you can also keep track of your measurements (waist,
            thighs, bust and arms), but I do not encourage you to keep track of
            your weight. I am a firm believer that a scale cannot measure your
            true progress and that there are many more changes that may not be
            visible but are just as important. Consider your increase in
            strength, endurance, athleticism and confidence. In addition, a
            simple number cannot measure how much body fat you may have lost, or
            how much lean muscle you may have gained.


          </p>
          <p>Progress photos tips:</p>
          <ul>
            <li>
              Take progress photos wearing the same clothes (sports bra/crop top
              and shorts/underwear)
            </li>
            <li>
              Take photos in the same place and time of the day to ensure
              similar lighting
            </li>
            <li>Take front, side and back photos</li>
            <li>Make sure your arms and legs are in the same position</li>
          </ul>
          <p>
            If you would like to share your progress, please tag @itsdesifit and
            #strongerwithitsdesifit so I can see your photos and be part of your
            journey or e-mail them to info@itsdesifit.com .
          </p>
        </span>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

<div class="bg-foreground py-16 px-gutter footer">
  <h2 class="display-1 mt-0 mb-4 text-center">
    {{ "USER_TRANS.INFO.QUEST" | translate }}
  </h2>
  <h3 class="subheading-2 text-hint text-center max-w-lg mx-auto m-0">
    {{ "USER_TRANS.INFO.CONTACT" | translate }}
  </h3>

  <div class="mt-16 max-w-3xl mx-auto flex flex-col sm:flex-row gap-6">
    <div class="text-center p-6 border rounded flex-1">
      <mat-icon class="text-hint icon-4xl" svgIcon="mat:mail"></mat-icon>
      <h4 class="subheading-2 m-0 text-hint">
        {{ "USER_TRANS.INFO.MAILUS" | translate }}
      </h4>
      <h3 class="title mb-0 mt-4">info@itsdesifit.com</h3>
    </div>
  </div>
</div>
