import { environment } from 'src/environments/environment';
import { DaysChangeWeekComponent } from './days-change-week/days-change-week.component';
import { DoneOrDetailsDialogComponent } from './done-or-details-dialog/done-or-details-dialog.component';
import { RestDialogComponent } from './../../auth/login/rest-dialog/rest-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ChangeWeekDialogComponent } from './change-week-dialog/change-week-dialog.component';
import { UserWorkoutComponent } from './../user-workout/user-workout.component';
import { RestService } from './../../services/rest.service';
import { FormControl, FormGroup, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { faCalendar, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { GuiService } from 'src/app/services/gui.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import {Router} from "@angular/router";

@Component({
  selector: 'vex-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  userLogged: any
  icon = {
    "faCalendar": faCalendar,
    "faEll": faEllipsisH
  }
  risposta: any;
  weekWorkout = []
  weeks = []
  selectedWeek = []
  form: UntypedFormGroup
  filteredIds = {}
  filteredWeeks = []
  days: any
  pointerOnDetail: any
  savedWeek:any;
  imgURL: string;
  environment = environment.baseURL.workouts
  colorClasses = ['color1','color2','color3','color4','color5']
  showThunders = false


  constructor(public guiService: GuiService, public translate: TranslateService, public restService: RestService, private fb: UntypedFormBuilder,
              private dialog: MatDialog, public snackBar: MatSnackBar, private router: Router) { }

  ngOnInit(): void {
    this.guiService.resetWeek = false;
    this.days = Array(7)
    this.form = this.fb.group({
    weekControl: [''],
  });
    //this.guiService.userLogged.lastWorkoutProgram.weekHistory = JSON.parse(this.guiService.userLogged.lastWorkoutProgram.weekHistory);

    setTimeout(()=>{
      if(this.guiService.userLogged.lastWorkoutProgram.workoutProgramId !== 'string' || this.guiService.userLogged.lastWorkoutProgram.workoutProgramId == null){
        this.router.navigate[('main/dashboard')]
      }
      setTimeout(()=>this.showThunders = true,2500)
      this.getProgram()
      this.guiService.showSpinner = false,0
      console.debug(this.guiService.userLogged)
      this.guiService.userLogged.lastWorkoutProgram.weekHistory == null ? this.guiService.userLogged.lastWorkoutProgram.weekHistory = [{startWeek: null,days: [null,null,null,null,null,null,null]}] : null
      this.savedWeek = JSON.parse(JSON.stringify(this.guiService.userLogged.lastWorkoutProgram))
      this.getActiveProgram()
    },500)
  }

  getProgram(){
    let idProgram = sessionStorage.getItem('selectedProgramId')
    this.restService.getService('workout_programs', idProgram).subscribe((ris)=>{
      this.risposta = ris;

      if(this.guiService.isCurrentProgram == true){
        this.form.controls['weekControl'].setValue(this.guiService.userLogged.lastWorkoutProgram.week)
        this.selectedWeek = this.risposta.weeks[this.guiService.userLogged.lastWorkoutProgram.week]
        this.guiService.weekSelectedNumber = this.guiService.userLogged.lastWorkoutProgram.week
        this.i = this.guiService.userLogged.lastWorkoutProgram.day
        /* GESTIONE 7 GIORNI */
        if(!this.guiService.userLogged.lastWorkoutProgram.weekHistory){
          this.guiService.userLogged.lastWorkoutProgram.weekHistory =
          [{
            startWeek: null,
            days: [null,null,null,null,null,null,null]
          }]
        }
        let tmp = typeof this.guiService.userLogged.lastWorkoutProgram.weekHistory == "string" ? JSON.parse(this.guiService.userLogged.lastWorkoutProgram.weekHistory) : this.guiService.userLogged.lastWorkoutProgram.weekHistory;
        console.debug(tmp[this.guiService.userLogged.lastWorkoutProgram.week])
        if(!tmp[this.guiService.userLogged.lastWorkoutProgram.week].startWeek){
          tmp[this.guiService.userLogged.lastWorkoutProgram.week] = {
            startWeek: null,
            days: [null,null,null,null,null,null,null]
          }
        }
        let startWeek = moment(tmp[this.guiService.userLogged.lastWorkoutProgram.week].startWeek,'x')
        let now = moment()
        if(now.diff(startWeek, 'days') > 7){
          this.dialog.open(DaysChangeWeekComponent, {
            width: '300px',
            panelClass: 'changeweek-dialog',
          }).afterClosed().subscribe((ris)=>{
            if(ris != 'true'){
              console.debug('si, cambia week')
              if((this.risposta.weeks.length-1) > this.guiService.userLogged.lastWorkoutProgram.week){
              this.guiService.userLogged.lastWorkoutProgram.week = this.guiService.userLogged.lastWorkoutProgram.week+1
              this.guiService.userLogged.lastWorkoutProgram.day = 0
              let tmpWeekHistory = JSON.parse(this.guiService.userLogged.lastWorkoutProgram.weekHistory)
              tmpWeekHistory.push({
                startWeek: null,
                days: [null,null,null,null,null,null,null]
              })
              this.guiService.userLogged.lastWorkoutProgram.weekHistory = JSON.stringify(tmpWeekHistory)
              setTimeout(()=>this.showThunders = true,500)
              this.restService.postService('user_workout_programs','', this.guiService.userLogged.lastWorkoutProgram).subscribe((risposta)=>{
                console.debug(risposta)
                setTimeout(()=>this.showThunders = false,0)
                this.form.controls['weekControl'].setValue(this.guiService.userLogged.lastWorkoutProgram.week)
                this.selectedWeek = this.risposta.weeks[this.guiService.userLogged.lastWorkoutProgram.week]
                this.guiService.weekSelectedNumber = this.guiService.userLogged.lastWorkoutProgram.week
                this.i = this.guiService.userLogged.lastWorkoutProgram.day
                console.debug('week',this.guiService.userLogged.lastWorkoutProgram.week)
                this.doChangeWeek()
              })
              }
            }
          })
        }
      }else{
        this.guiService.userLogged.lastWorkoutProgram.week = 0
        this.guiService.userLogged.lastWorkoutProgram.day = 0
        this.form.controls['weekControl'].setValue(0)
        this.selectedWeek = this.risposta.weeks[0]
        this.guiService.weekSelectedNumber = 0
        this.i = 0
        this.guiService.userLogged.lastWorkoutProgram.weekHistory = [
          {
            startWeek: null,
            days: [null,null,null,null,null,null,null]
          }
        ]
      }

      let week = this.risposta.weeks[this.guiService.weekSelectedNumber];
      let tmpWeek = [null,null,null,null,null,null,null];
      for(let k = 0; k < 7; k++){
        tmpWeek[k] = {id: week['workoutIdDay'+(k+1)]};

        if(week['workoutIdDay'+k] && this.filteredWeeks.indexOf(week['workoutIdDay'+k])< 0){
          this.filteredWeeks.push(week['workoutIdDay'+k])
        }
      }
      this.loadWorkoutOfTheWeek(this.filteredWeeks);

    })
  }

  // checkIfWorkoutIsDone(n){
  //   if(this.guiService.showSpinner == false && this.guiService.isCurrentProgram == true){
  //     return this.guiService.userLogged.lastWorkoutProgram.weekHistory.includes(n)
  //   }
  // }

  programDetails(){
    let selectedDay = 'workoutIdDay'+(this.i+1)
    let id = this.risposta.weeks[this.form.value.weekControl][selectedDay]
    this.guiService.currentDayWorkout = this.i;
    this.guiService.selectedProgramTotalWeeks = this.risposta.numberOfWeeks
    console.debug("currentDayWorkout",this.guiService.currentDayWorkout)
    if(id != null){
    this.restService.getService('workouts',id).subscribe((ris)=>{
      this.dialog.open(UserWorkoutComponent, {
      data: ris || null,
      width: '75vw',
      maxWidth: '90vw',
      height: '90vh',
      panelClass: 'home-modalbox',
      disableClose: true,
      hasBackdrop: true
    }).afterClosed().subscribe((p)=>{

    })
    })
    }else{
      this.snackBar.open('Oggi devi riposare!','',{
        duration: 4000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: 'rest-msg-modal'
      })
    }
  }


  changeWeek(){
    if(this.guiService.isCurrentProgram == true) {
    this.dialog.open(ChangeWeekDialogComponent, {
      width: '300px',
      panelClass: 'changeweek-dialog',
      data: this.form.value.weekControl,
      disableClose: true,
      hasBackdrop: true
    }).afterClosed().subscribe((ris)=>{
      if(ris != 'true'){
        this.doChangeWeek();
      }
      else{
        this.form.controls['weekControl'].setValue(this.guiService.userLogged.lastWorkoutProgram.week)
        this.guiService.weekSelectedNumber = this.guiService.userLogged.lastWorkoutProgram.week
      }

    })
    } else {
      this.form.controls['weekControl'].setValue(this.form.value.weekControl)
      this.selectedWeek = this.risposta.weeks[this.form.value.weekControl]
      this.guiService.weekSelectedNumber = this.form.value.weekControl
      this.i = 0;
      let tmp = [];
      for(let i=0; i<=this.guiService.weekSelectedNumber; i++) {
        if(!tmp[i]) {
          tmp[i] = {
            startWeek: null,
            days: [null,null,null,null,null,null,null]
          }
          console.debug("ADD week");
        }
      }
      console.debug(tmp)
      this.guiService.userLogged.lastWorkoutProgram.weekHistory = tmp;
    }
  }

  doChangeWeek() {
    this.evaluateChangeWeek(this.guiService.weekSelectedNumber,this.form.value.weekControl)
    console.debug(this.savedWeek)
    if(this.savedWeek && this.savedWeek.week == this.form.value.weekControl){
      this.guiService.userLogged.lastWorkoutProgram = JSON.parse(JSON.stringify(this.savedWeek))
      console.debug(this.savedWeek)
      this.selectedWeek = this.risposta.weeks[this.form.value.weekControl]
      this.guiService.weekSelectedNumber = this.form.value.weekControl
      this.i = this.guiService.userLogged.lastWorkoutProgram.day
    }
    else{
      this.form.controls['weekControl'].setValue(this.form.value.weekControl)
      this.selectedWeek = this.risposta.weeks[this.form.value.weekControl]
      this.guiService.weekSelectedNumber = this.form.value.weekControl
      this.i = 0;
      console.debug('typeof', typeof this.guiService.userLogged.lastWorkoutProgram.weekHistory)
      let tmp = typeof this.guiService.userLogged.lastWorkoutProgram.weekHistory == "string" ? JSON.parse(this.guiService.userLogged.lastWorkoutProgram.weekHistory) : this.guiService.userLogged.lastWorkoutProgram.weekHistory;
      for(let i=0; i<=this.guiService.weekSelectedNumber; i++) {
        if(!tmp[i]) {
          tmp[i] = {
            startWeek: null,
            days: [null,null,null,null,null,null,null]
          }
          console.debug("ADD week");
        }
      }
      console.debug(tmp)
      this.guiService.userLogged.lastWorkoutProgram.weekHistory = tmp;
    }





    this.filteredWeeks = [];
    let week = this.risposta.weeks[this.guiService.weekSelectedNumber];
    let tmpWeek = [null,null,null,null,null,null,null];
    for(let k = 0; k < 7; k++){
      tmpWeek[k] = {id: week['workoutIdDay'+(k+1)]};
      if(week['workoutIdDay'+(k+1)] && this.filteredWeeks.indexOf(week['workoutIdDay'+(k+1)])< 0){
        this.filteredWeeks.push(week['workoutIdDay'+(k+1)])
      }
    }
    this.weeks.push({
      week: tmpWeek
    })
    this.loadWorkoutOfTheWeek(this.filteredWeeks)




  }

  loadWorkoutOfTheWeek(baseIndex) {
    let param = {
      "filterAnd": [
        {
        "field": "id",
        "operator": "in",
        "value": baseIndex.toString()
        }
        ],
      "page": 0,
      "size": 200
    }
    this.restService.postService('workouts', '/search', param).subscribe((ris)=>{
      for(let wId of baseIndex){
        console.debug(wId)
        this.filteredIds[wId]= ris.items.find(x => x.id === wId).name
      }
      this.guiService.showSpinner = false
    })
}
  /* METODI CAROSELLO */
  slides: any [] = ['../../../assets/img/demo/1.jpg', '../../../assets/img/demo/2.jpg', '../../../assets/img/demo/3.jpg', '../../../assets/img/demo/4.jpg', '../../../assets/img/demo/5.jpg', '../../../assets/img/demo/6.jpg', '../../../assets/img/demo/7.jpg' ]
  i=0;

  getSlide() {
      return this.slides[this.i];
  }

  getPrev() {
      this.i = this.i===0 ? 0 : this.i - 1;
  }
  getNext() {
      this.i = this.i===this.slides.length-1 ? this.i : this.i + 1;
  }

  selectSlide(indice){
    if(indice >= 0 && indice < this.slides.length){
      this.i= indice
      this.dialog.open(DoneOrDetailsDialogComponent,{
        width: '300px',
        panelClass: 'changeweek-dialog',
        data: this.form.value.weekControl,
        disableClose: true,
        hasBackdrop: true
      }).afterClosed().subscribe((res)=>{
        if(res == 'mark'){
          console.debug('110', this.guiService.userLogged.lastWorkoutProgram.weekHistory)
          let tmp = JSON.parse(JSON.stringify(this.guiService.userLogged.lastWorkoutProgram.weekHistory))
          console.debug('TMP 0', tmp)
          typeof tmp == "string" ? tmp = JSON.parse(tmp) : null
          /* VALUTO SE DEVO AZZERARE I PROGRESSI */
          if(this.guiService.resetWeek){
            this.guiService.userLogged.lastWorkoutProgram.week = this.form.value.weekControl
            this.guiService.userLogged.lastWorkoutProgram.day = 0
            for(let i = this.guiService.weekSelectedNumber; i < this.risposta.weeks.length; i++){
              if(tmp[i]){
                tmp[i].startWeek = null
                tmp[i].days = [null,null,null,null,null,null,null]
              }

              console.debug('indice',i, tmp[i])
            }
            this.guiService.resetWeek = false
          }
          console.debug('TMP', tmp)
          if(JSON.stringify(tmp[this.guiService.weekSelectedNumber].days) === JSON.stringify([null,null,null,null,null,null,null])){
            tmp[this.guiService.weekSelectedNumber].startWeek = moment().format('x');
          }
          tmp[this.guiService.weekSelectedNumber].days[this.i] = moment().format('x')

          this.guiService.userLogged.lastWorkoutProgram.weekHistory = JSON.stringify(tmp)
          /* UTILIZZATO EXERCISEID PROVVISORIO CON ID ALLENAMENTO E NOME ALLENAMENTO */
          let selectedDay = 'workoutIdDay'+(this.i+1)
          let isChangeWeek = this.guiService.userLogged.lastWorkoutProgram.day >= 6 ? true : false
          let lastWorkoutProgram = {
            day:  this.guiService.userLogged.lastWorkoutProgram.day >= 6 ? 0 : this.guiService.userLogged.lastWorkoutProgram.day+1,
            week: this.guiService.userLogged.lastWorkoutProgram.day >= 6 && this.guiService.userLogged.lastWorkoutProgram.week < this.guiService.selectedProgramTotalWeeks ? this.form.value.weekControl+1 : this.form.value.weekControl,
            workoutProgramId: sessionStorage.getItem('selectedProgramId'),
            exerciseId: this.selectedWeek[selectedDay] != null && this.filteredIds[this.selectedWeek[selectedDay]] != undefined ? this.selectedWeek[selectedDay]+'::'+this.filteredIds[this.selectedWeek[selectedDay]] : null,
            weekHistory: this.guiService.userLogged.lastWorkoutProgram.weekHistory
          }
          this.guiService.isCurrentProgram = true
          console.debug(this.guiService.userLogged)

           this.restService.postService('user_workout_programs','', lastWorkoutProgram).subscribe((risposta)=>{
             this.guiService.userLogged.lastWorkoutProgram = risposta
             this.savedWeek = this.guiService.userLogged.lastWorkoutProgram
             if(isChangeWeek == true){
               this.router.navigateByUrl('/main/refresh', { skipLocationChange: true }).then(() => {
                 this.router.navigate(['/main/home']);
               });
            }
           })
        }else if(res == 'true'){
          this.programDetails()
        }
      })
    }
  }

  getActiveProgram(){
    let idProgram = sessionStorage.getItem('selectedProgramId')
    this.restService.getService('workout_programs',idProgram).subscribe((ris)=>{
      console.debug('current program', ris)
      if(ris.id === this.guiService.userLogged.lastWorkoutProgram.workoutProgramId && ris.numberOfWeeks < this.guiService.userLogged.lastWorkoutProgram.week){
        this.guiService.userLogged.lastWorkoutProgram.week--
        this.guiService.showSpinner = true
        this.restService.postService('user_workout_programs','', this.guiService.userLogged.lastWorkoutProgram).subscribe((res)=>{
          this.ngOnInit()
          this.guiService.showSpinner = false
        })
      }
      if(ris.weeks[this.guiService.userLogged.lastWorkoutProgram.week]['workoutIdDay'+(this.guiService.userLogged.lastWorkoutProgram.day + 1)] == null){
        let last = moment(this.guiService.userLogged.lastWorkoutProgram.lastExecution);
        let now = moment();
        console.debug(now.diff(last, 'days'))
        if(now.diff(last, 'days') > 1){
          let tmp = JSON.parse(JSON.stringify(this.guiService.userLogged.lastWorkoutProgram.weekHistory))
          typeof tmp == "string" ? tmp = JSON.parse(tmp) : null
          if(JSON.stringify(tmp[this.guiService.weekSelectedNumber].days) === JSON.stringify([null,null,null,null,null,null,null])){
            tmp[this.guiService.weekSelectedNumber].startWeek = moment().format('x');
          }
          tmp[this.guiService.weekSelectedNumber].days[this.guiService.userLogged.lastWorkoutProgram.day] = moment().format('x')
          this.guiService.userLogged.lastWorkoutProgram.weekHistory = JSON.stringify(tmp)
          this.guiService.userLogged.lastWorkoutProgram.day++
          if(this.guiService.userLogged.lastWorkoutProgram.day > 6){
            this.guiService.userLogged.lastWorkoutProgram.day = 0;
            this.guiService.userLogged.lastWorkoutProgram.week++
            this.guiService.userLogged.currentDayWorkout = ""
          }
          this.restService.postService('user_workout_programs','', this.guiService.userLogged.lastWorkoutProgram).subscribe((risposta)=>{
            console.debug(risposta)
          })
        }else if (now.diff(last, 'days') == 1){
          this.dialog.open(RestDialogComponent,{
          panelClass: 'rest-dialog-modal',
          disableClose: true,
          hasBackdrop: true,
          width: '35vw',
          height: '24vh'
        }).afterClosed().subscribe((param)=>{
          if(param == 'true'){
            console.debug('non voglio riposare')
          }else{
            let tmp = JSON.parse(JSON.stringify(this.guiService.userLogged.lastWorkoutProgram.weekHistory))
            typeof tmp == "string" ? tmp = JSON.parse(tmp) : null
            if(JSON.stringify(tmp[this.guiService.weekSelectedNumber].days) === JSON.stringify([null,null,null,null,null,null,null])){
              tmp[this.guiService.weekSelectedNumber].startWeek = moment().format('x');
            }
            tmp[this.guiService.weekSelectedNumber].days[this.guiService.userLogged.lastWorkoutProgram.day] = moment().format('x')
            this.guiService.userLogged.lastWorkoutProgram.weekHistory = JSON.stringify(tmp)
            this.guiService.userLogged.lastWorkoutProgram.day++
          if(this.guiService.userLogged.lastWorkoutProgram.day > 6){
            this.guiService.userLogged.lastWorkoutProgram.day = 0;
            this.guiService.userLogged.lastWorkoutProgram.week++
            this.guiService.userLogged.currentDayWorkout = ""
          }
          this.restService.postService('user_workout_programs','', this.guiService.userLogged.lastWorkoutProgram).subscribe((res)=>{
            console.debug(res)
          })
          }
        })
        }

      }

    })
  }

  isExecutedDay(idx) {
    let istrue = false;
    let tmp = this.guiService.userLogged.lastWorkoutProgram.weekHistory && this.guiService.userLogged.lastWorkoutProgram.weekHistory != "" ? this.guiService.userLogged.lastWorkoutProgram.weekHistory : null;
    tmp = typeof tmp != "object" ? JSON.parse(tmp) : tmp;
    istrue = tmp && tmp[this.guiService.weekSelectedNumber] ? tmp[this.guiService.weekSelectedNumber].days[idx]!=null : false;
    return istrue;
  }

  evaluateChangeWeek(weekOld,weekNew){
    if(weekNew < weekOld){
      this.guiService.resetWeek = true;
      console.debug('sto tornando indietro')
      console.debug(this.guiService.resetWeek)
    }else{
      this.guiService.resetWeek = false;
    }
  }

  evaluateBgColor(index){
    let x = index
    if(index > 4){
      x = index - 5
    }
    return this.colorClasses[x]
  }

}
