import { MySurveyComponent } from './../../../main/user-nutrition/my-survey/my-survey.component';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faCircle, faCheck, faArrowCircleUp, faArrowCircleDown, faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { GuiService } from 'src/app/services/gui.service';
import { RestService } from 'src/app/services/rest.service';
import { NutritionComponent } from '../nutrition.component';
import * as moment from 'moment';
import { DialogConfirmComponent } from '../../dialog-confirm/dialog-confirm.component';
import { HttpHeaders } from '@angular/common/http';
import { CloseConfirmComponent } from '../../close-confirm/close-confirm.component';

@Component({
  selector: 'vex-nutrition-detail',
  templateUrl: './nutrition-detail.component.html',
  styleUrls: ['./nutrition-detail.component.scss']
})
export class NutritionDetailComponent implements OnInit {
  icon = {
    "faCircle": faCircle,
    "faCheck": faCheck,
    "faArrowUp": faArrowUp,
    "faArrowDown": faArrowDown
  };

  utente: any
  surveys: any;
  pdfSelected: string;
  nutritionSurveyId: any;
  btnDisable = true;


  constructor(
    @Inject(MAT_DIALOG_DATA) private item: any['item'],
    private dialogRef: MatDialogRef<NutritionComponent>,
    private restService: RestService,
    public translate: TranslateService,
    public guiService: GuiService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    setTimeout(() => (this.guiService.showSpinner = true), 0);
     this.utente = this.item
     console.debug(this.utente)
     this.getUserSurveys()
    //  this.utente.masterData.lastSurvey = '11-06-2022'
    //  this.utente.masterData.lastDietUploaded = '11-06-2022'
     this.check30Days(this.utente.masterData.lastSurvey,this.utente.masterData.lastDietUploaded)
  }

  getUserSurveys(){
    this.restService.getService('users', this.utente.user.id + '/nutrition-surveys').subscribe((ris)=>{
      this.surveys = ris
      console.debug(this.surveys)
      setTimeout(() => (this.guiService.showSpinner = false), 0);
    })
  }

  check30Days(lastSurvey,lastDietUploaded){
    let now = moment()

    let dateSurveyAfter = moment(lastSurvey).add(1,'months')

    let dateDietAfter = moment(lastDietUploaded).add(1,'months')

    if(now.isSameOrAfter(dateSurveyAfter)){
      this.utente.masterData.lastSurvey = null
    }

    if(now.isSameOrAfter(dateDietAfter)){
      this.utente.masterData.lastDietUploaded = null
    }
  }

  onFileSelected(event) {
    this.guiService.convertFile(event.target.files[0]).subscribe(base64 => {
      this.pdfSelected = base64;
      console.debug(this.pdfSelected)
      this.btnDisable = false
    });
  }

  getNutritionSurvey(id){
    this.nutritionSurveyId = id
  }

  loadDiet(){
    this.guiService.showSpinner = true
    let newDiet = {
      document: this.pdfSelected,
      documentFileName: "newDiet " + this.utente.masterData.name + ' ' + this.utente.masterData.lastName + '.pdf',
    }
    this.restService.postService('nutrition_survey', '/' + this.nutritionSurveyId + '/diet',newDiet).subscribe((ris)=>{
      console.debug(ris)
      this.guiService.openSnackBar(this.translate.instant('GLOBAL.DIET_LOAD'),this.translate.instant('GLOBAL.CLOSE'))
      this.getUserSurveys()
      this.btnDisable = true
    })
  }


  deleteDiet(): void {
    this.dialog.open(DialogConfirmComponent, {
      width: '250px',
      panelClass: 'dialog-modalbox',
      data: {
        type: 'nutrition_survey',
        id: this.utente.masterData.lastSurveyId + '/diet'
      },
      enterAnimationDuration: '0ms',
      exitAnimationDuration: '1ms',
    }).afterClosed().subscribe((ris)=>{
      if(ris != 'true'){
        this.guiService.showSpinner = true
        this.getUserSurveys()
      }
    })
  }

  downloadDietPdf(idNutSurvey, fileName) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    this.restService.getPdf('nutrition_survey', '/' + idNutSurvey + '/diet/document').subscribe((ris)=>{
      const file = new Blob([ris], {type: 'application/pdf'});
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, '_blank', 'width=1000, height=800');
    })

  }

  openSurvey(survey){
    this.guiService.showDownloadSurveyButton = true
    this.dialog.open(MySurveyComponent, {
      data: survey
    })
  }

  confirmClose(){
    this.dialog.open(CloseConfirmComponent,{
      disableClose: true,
      hasBackdrop: true,
      panelClass: 'close-dialog-modalbox'
    }).afterClosed().subscribe((ris)=>{
      if(ris == 'true'){
        this.dialogRef.close()
      }
    })
  }

  checkDietUpload(diet,survey){
    return moment(diet).isSameOrAfter(survey)
  }
}
