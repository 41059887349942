<div class="container">
  <form [formGroup]="form">
    <div class="card flex-auto">
      <div class="px-6 py-4 border-b flex items-center">
        <h2 class="title m-0">{{"NEW-PROMOTION.NEW" | translate}}</h2>
      </div>

      <div class="px-6 py-4 flex flex-col">
        <mat-form-field>
          <mat-label>{{"NEW-PROMOTION.CODE" | translate}}</mat-label>
          <input matInput formControlName="codice" required />
        </mat-form-field>

        <div class="flex flex-col sm:flex-row sm:gap-4">
          <mat-form-field class="flex-auto">
            <mat-label>{{"NEW-PROMOTION.VALID_FROM" | translate}}</mat-label>
            <input [matDatepicker]="datepickerRef" matInput formControlName="validfrom" required />
            <mat-datepicker-toggle [for]="datepickerRef" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #datepickerRef></mat-datepicker>
          </mat-form-field>
          <mat-form-field class="flex-auto">
            <mat-label>{{"NEW-PROMOTION.VALID_TO" | translate}}</mat-label>
            <input [matDatepicker]="datepickerRef2" matInput formControlName="validto" />
            <mat-datepicker-toggle [for]="datepickerRef2" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #datepickerRef2></mat-datepicker>
          </mat-form-field>
          <div class="flex-auto reset-trial">
            <mat-label>Reset Trial</mat-label>
            <mat-slide-toggle formControlName="resetTrial">
            </mat-slide-toggle>
          </div>
        </div>
        <div class="flex flex-col sm:flex-row sm:gap-4">
        <mat-form-field class="flex-auto">
          <mat-label>{{"NEW-PROMOTION.QUANTITY" | translate}}</mat-label>
          <input matInput formControlName="quantita" type="number" />
          <mat-icon class="mr-2" matPrefix svgIcon="mat:format_list_numbered"></mat-icon>
        </mat-form-field>
        <mat-form-field class="flex-auto">
          <mat-label>{{"NEW-PROMOTION.CYCLE" | translate}}</mat-label>
          <input matInput formControlName="durationInCycles" type="number" />
          <mat-icon class="mr-2" matPrefix svgIcon="mat:format_list_numbered"></mat-icon>
        </mat-form-field>
        </div>
        <div class="flex flex-col sm:flex-row sm:gap-4">
          <mat-form-field class="flex-auto">
            <mat-label>{{"ADMIN_NOTIFICATION.SUB_TYPE" | translate}}</mat-label>
            <mat-select formControlName="sub" multiple required (selectionChange)="checkSubSelection($event.value)">
              <mat-option [value]="option.key" *ngFor="let option of subTypes">{{ option.description }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="flex-auto">
            <mat-label>Restrizioni abbonamento</mat-label>
            <mat-select formControlName="sub_duration" multiple>
              <mat-option [value]="option.key" *ngFor="let option of subDuration">{{ option.description }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="flex flex-col sm:flex-row sm:gap-4">
        <mat-form-field class="flex-auto">
          <mat-label>{{"NEW-PROMOTION.PERCENT" | translate}}</mat-label>
          <input matInput formControlName="percentuale" type="number" />
          <span matSuffix>%</span>
        </mat-form-field>
        <mat-form-field class="flex-auto">
          <mat-label>{{"NEW-PROMOTION.FREE" | translate}}</mat-label>
          <input matInput formControlName="giorni" type="number" />
        </mat-form-field>
        <mat-form-field class="flex-auto">
          <mat-label>{{"NEW-PROMOTION.PRICE" | translate}}</mat-label>
          <input matInput formControlName="prezzo" type="number" />
          <span matSuffix>USD</span>
        </mat-form-field>
        </div>

        <div class="bottoni">
          <button class="m-4" (click)="confirmClose()" mat-button-base>{{"GLOBAL.CLOSE" | translate}}</button>
          <button mat-raised-button [disabled]="form.invalid" (click)="submit(form)" class="m-4 bottone-viola">
            {{"GLOBAL.CREATE" | translate}}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
