<div class="dropdown">
  <div class="dropdown-header flex items-center justify-between">
    <div>
      <div class="dropdown-heading">{{"NOTIFICATION" | translate}}</div>
      <div class="dropdown-subheading d-flex" *ngIf="notifications">
        <span class="select-all-check" *ngIf="notifications.length > 0"><mat-checkbox [(ngModel)]="flagAll" (change)="checkAll($event)"></mat-checkbox></span>
        {{ notifications.length }}
        {{"NEW_NOTIFICATION" | translate}}
      </div>
    </div>

    <!-- <button [matMenuTriggerFor]="settingsMenu" mat-icon-button type="button">
      <mat-icon class="notifications-header-icon" svgIcon="mat:settings"></mat-icon>
    </button> -->
    <button *ngIf="checkedIds.length > 0" class="btn-archive-all" (click)="archiveMultipleNotification()">Archivia Selezionate</button>
  </div>

  <div class="dropdown-content">
    <span *ngFor="let notification of notifications; let i = index; trackBy: trackById"
       [class.read]="notification.read"
       [routerLink]="[]"
       class="notification flex items-center"
       matRipple >
       <span class="check"><mat-checkbox [(ngModel)]="notification.notificationReadDate" (click)="selectNotif(notification.notificationId)"></mat-checkbox></span>
      <mat-icon *ngIf="guiService.notificationTypes[notification['type']]" [ngClass]="guiService.notificationTypes[notification['type']].colorClass"
                [svgIcon]="guiService.notificationTypes[notification['type']].icon"
                class="notification-icon flex-none">
      </mat-icon>
      <mat-icon *ngIf="!guiService.notificationTypes[notification['type']]" [ngClass]="guiService.notificationTypes['default'].colorClass"
                [svgIcon]="guiService.notificationTypes['default'].icon"
                class="notification-icon flex-none">
      </mat-icon>
      <div class="flex-auto" (click)="openNotification(notification)">
        <div class="notification-label" >{{ notification.notification.subject ? notification.notification.subject : notification.notification.title }}</div>
        <div class="notification-description">{{ notification.datetime | relativeDateTime }}</div>
      </div>
      <mat-icon class="notification-chevron flex-none" svgIcon="mat:chevron_right"></mat-icon>
    </span>
  </div>

  <!-- <div class="dropdown-footer flex items-center justify-center" *ngIf="notifications.length != 0">
    <button color="primary" mat-button type="button" (click)="allNotifications()">{{"N_SHOW" | translate}}</button>
  </div> -->
</div>

<!-- <mat-menu #settingsMenu="matMenu" xPosition="before" yPosition="below">
  <button mat-menu-item>
    <mat-icon svgIcon="mat:clear_all"></mat-icon>
    <span>Mark all as read</span>
  </button>

  <button mat-menu-item>
    <mat-icon svgIcon="mat:notifications_off"></mat-icon>
    <span>Disable Notifications</span>
  </button>
</mat-menu> -->
