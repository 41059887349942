<div class="btn">
  <button class="btn-close" mat-dialog-close>
    <mat-icon svgIcon="mat:close"></mat-icon>
  </button>
</div>

<div class="container">
  <div class="ex">
    <div>
      <h1>{{exercise.name}}</h1>
      <div class="descrizione">
        <h2 [innerHTML]="exercise.description"></h2>
      </div>
      <div class="equipo-container" *ngIf="exercise.equipmentIds">
        <h2>{{"WELCOME_DETAIL.EQUIP" | translate}}</h2>
        <div *ngFor="let equip of exercise.equipmentIds" class="equipo">
          <img *ngIf="guiService.filteredIds[equip]" [src]="'assets/img/equipsIcons/'+guiService.filteredIds[equip]+'.svg'" class="svg">
          <h3 *ngIf="guiService.EquipNameIds[equip]">{{guiService.EquipNameIds[equip]}}</h3>
        </div>
      </div>
    </div>

    <div class="alternativeEx" *ngIf="alternative">
      <img [src]="imgURL+'/'+exercise.exerciseAlternativeId+'/images/main'">
      <div>
        <h4>{{"USER_WORKOUT.ALT" | translate}}</h4>
        <p>{{alternative.name}}</p>
      </div>
    </div>

  </div>
  <div class="divisore"></div>
  <div class="tris">
    <img [src]="imgURL+'/'+exercise.id+'/images/main'">
    <div class="divide">
      <h2>{{"USER_WORKOUT.DIFF" | translate}}</h2>
      <h3 *ngIf="exercise.difficulty == 'EASY'">{{"EXERCISE-LIST.EASY" | translate}}</h3>
      <h3 *ngIf="exercise.difficulty == 'INTERMEDIATE'">{{"EXERCISE-LIST.INTERMEDIATE" | translate}}</h3>
      <h3 *ngIf="exercise.difficulty == 'EXPERT'">{{"EXERCISE-LIST.EXPERT" | translate}}</h3>
    </div>
    <div class="divide">
      <h2>{{"USER_WORKOUT.TYPE" | translate}}</h2>
      <h3 *ngIf="exercise.type == 'STRENGTH_TRAINING'">
        {{"USER_WORKOUT.STRENGTH" | translate}}
      </h3>
      <h3 *ngIf="exercise.type == 'BALANCE_AND_STABILITY_TRAINING'">
        {{"USER_WORKOUT.BALANCE" | translate}}
      </h3>
      <h3 *ngIf="exercise.type == 'BODYWEIGHT_TRAINING'">
        {{"USER_WORKOUT.BODYWEIGHT" | translate}}
      </h3>
      <h3 *ngIf="exercise.type == 'AEROBIC_TRAINING'">
        {{"USER_WORKOUT.AEROBIC" | translate}}
      </h3>
      <h3 *ngIf="exercise.type == 'FUNCTIONAL_TRAINING'">
        {{"USER_WORKOUT.FUNCTIONAL" | translate}}
      </h3>
      <h3 *ngIf="exercise.type == 'WARM_UP'">
        {{"USER_WORKOUT.WARM" | translate}}
      </h3>
      <h3 *ngIf="exercise.type == 'FLEXIBILITY_AND_MOBILITY_TRAINING'">
        {{"USER_WORKOUT.FLEXIBILITY" | translate}}
      </h3>
      <h3 *ngIf="exercise.type == 'EXPLOSIVE_STRENGTH_TRAINING'">
        {{"USER_WORKOUT.EXPLOSIVE" | translate}}
      </h3>
      <h3 *ngIf="exercise.type == 'AGILITY_TRAINING'">
        {{"USER_WORKOUT.AGILITY" | translate}}
      </h3>
      <h3 *ngIf="exercise.type == 'CORE_TRAINING'">
        {{"USER_WORKOUT.CORE" | translate}}
      </h3>
      <h3 *ngIf="exercise.type == 'COOL_DOWN'">
        {{"USER_WORKOUT.COOLDOWN" | translate}}
      </h3>
    </div>
    <div class="divide muscle-g">
      <div class="upper-box"></div>
      <h2>{{"USER_WORKOUT.MUSCLE" | translate}}</h2>
      <img class="map" src="../../../../assets/img/_sagoma.png" width="857" height="906" usemap="#sagomamap"
           id="sagoma">
      <map name="sagomamap">
        <area class="ANTERIOR_DELTOIDS" shape="poly" alt=""
              coords="188,194, 180,185, 176,180, 171,177, 158,179, 144,181, 136,183, 128,187, 122,196, 118,205, 117,207, 111,229, 109,239, 105,248, 109,244, 120,237, 130,229, 137,225, 149,217, 164,209, 178,201, 188,196, 188,194">
        <area class="ANTERIOR_DELTOIDS" shape="poly" alt=""
              coords="237,194, 246,185, 250,180, 255,177, 268,179, 281,181, 290,183, 297,187, 304,196, 308,205, 308,207, 315,229, 317,239, 321,248, 316,244, 306,237, 295,229, 289,225, 276,217, 261,209, 247,201, 238,196, 237,194">
        <area class="BICEPS" shape="poly" alt=""
              coords="329,248, 335,282, 340,322, 338,342, 337,347, 337,349, 335,348, 329,343, 323,355, 315,366, 315,357, 317,350, 320,346, 322,339, 322,327, 319,316, 308,295, 291,259, 281,220, 297,228, 311,240, 319,245, 329,248">
        <area class="BICEPS" shape="poly" alt=""
              coords="97,248, 91,282, 86,322, 88,342, 88,347, 89,349, 91,348, 97,343, 103,355, 110,366, 110,357, 108,350, 106,346, 103,339, 103,327, 106,316, 117,295, 134,259, 144,220, 128,228, 114,240, 106,245, 97,248">
        <area class="CALVES" shape="poly" alt=""
              coords="691,631, 693,635, 697,641, 703,650, 709,670, 711,693, 709,732, 702,770, 699,785, 696,797, 695,811, 693,816, 689,826, 684,834, 680,837, 675,828, 671,813, 670,800, 668,765, 664,729, 659,685, 660,659, 661,643, 663,634, 663,629, 664,625, 666,626, 667,628, 673,643, 676,650, 677,656, 680,660, 682,657, 683,653, 684,645, 687,634, 690,629, 691,631">
        <area class="CALVES" shape="poly" alt=""
              coords="577,631, 575,635, 571,641, 565,650, 559,670, 557,693, 559,732, 566,770, 570,785, 572,797, 573,811, 575,816, 579,826, 584,834, 588,837, 593,828, 597,813, 598,800, 600,765, 604,729, 609,685, 608,659, 607,643, 606,634, 605,629, 604,625, 603,626, 601,628, 595,643, 592,650, 591,656, 588,660, 586,657, 585,653, 584,645, 581,634, 579,629, 577,631">
        <area class="CORE" shape="poly" alt=""
              coords="277,290, 273,284, 261,276, 238,263, 213,258, 213,258, 188,263, 164,276, 153,284, 148,290, 150,292, 159,290, 185,280, 213,277, 241,280, 267,290, 275,292, 277,290">
        <area class="CORE" shape="poly" alt=""
              coords="661,366, 655,342, 650,321, 651,276, 654,234, 653,192, 646,165, 641,141, 643,114, 634,114, 634,114, 634,114, 634,114, 634,114, 626,114, 627,141, 622,165, 616,192, 614,234, 618,276, 618,321, 613,342, 607,366, 607,370, 607,378, 609,385, 621,401, 627,418, 628,423, 629,430, 633,433, 634,433, 634,433, 635,433, 639,430, 640,423, 641,418, 647,401, 659,385, 661,378, 661,370, 661,366">
        <area class="CORE" shape="poly" alt=""
              coords="208,419, 201,409, 192,400, 185,396, 178,395, 173,395, 172,395, 172,396, 174,404, 179,413, 184,424, 187,437, 195,446, 203,450, 210,455, 213,456, 213,426, 210,423, 208,419">
        <area class="CORE" shape="poly" alt=""
              coords="254,396, 253,395, 253,395, 247,395, 240,396, 234,400, 224,409, 217,419, 216,423, 213,426, 213,456, 215,455, 223,450, 230,446, 238,437, 242,424, 246,413, 252,404, 254,396">
        <area class="CORE" shape="poly" alt=""
              coords="238,368, 237,364, 233,360, 223,359, 213,359, 213,359, 203,359, 193,360, 188,364, 188,368, 190,394, 199,419, 205,427, 213,432, 213,432, 213,432, 213,432, 213,432, 213,432, 213,432, 213,432, 213,432, 213,432, 213,432, 221,427, 226,419, 235,394, 238,368">
        <area class="CORE" shape="poly" alt=""
              coords="221,328, 217,333, 216,341, 217,348, 221,354, 228,354, 234,349, 237,337, 234,330, 226,328, 221,328, 221,328">
        <area class="CORE" shape="poly" alt=""
              coords="224,299, 232,303, 235,309, 238,315, 238,321, 232,324, 224,324, 218,317, 217,309, 218,301, 224,299">
        <area class="CORE" shape="poly" alt=""
              coords="220,267, 224,268, 229,271, 232,275, 238,291, 237,295, 232,297, 225,295, 219,291, 217,282, 217,274, 219,268, 220,267">
        <area class="CORE" shape="poly" alt=""
              coords="205,328, 209,333, 210,341, 209,348, 204,354, 197,354, 192,349, 188,337, 192,330, 199,328, 205,328, 205,328">
        <area class="CORE" shape="poly" alt=""
              coords="202,299, 194,303, 191,309, 188,315, 188,321, 193,324, 201,324, 207,317, 208,309, 207,301, 202,299">
        <area class="CORE" shape="poly" alt=""
              coords="206,267, 201,268, 197,271, 194,275, 187,291, 188,295, 194,297, 201,295, 206,291, 209,282, 208,274, 206,268, 206,267">
        <area class="CORE" shape="poly" alt=""
              coords="252,292, 247,285, 241,278, 238,276, 239,280, 242,287, 247,303, 248,321, 245,360, 241,386, 240,392, 238,400, 239,405, 242,401, 250,392, 257,382, 262,376, 268,369, 273,361, 276,358, 275,355, 274,351, 272,341, 273,318, 270,315, 264,307, 258,299, 252,292">
        <area class="CORE" shape="poly" alt=""
              coords="173,292, 178,285, 184,278, 188,276, 187,280, 183,287, 179,303, 178,321, 180,360, 184,386, 186,392, 187,400, 187,405, 183,401, 176,392, 168,382, 163,376, 158,369, 152,361, 149,358, 150,355, 151,351, 154,341, 153,318, 155,315, 161,307, 168,299, 173,292">
        <area class="DIAPHRAGM" shape="poly" alt=""
              coords="277,290, 273,284, 261,276, 238,263, 213,258, 213,258, 188,263, 164,276, 153,284, 148,290, 150,292, 159,290, 185,280, 213,277, 241,280, 267,290, 275,292, 277,290">
        <area class="ERECTOR_SPINAE" shape="poly" alt=""
              coords="661,366, 655,342, 650,321, 651,276, 654,234, 653,192, 646,165, 641,141, 643,114, 634,114, 634,114, 634,114, 634,114, 634,114, 626,114, 627,141, 622,165, 616,192, 614,234, 618,276, 618,321, 613,342, 607,366, 607,370, 607,378, 609,385, 621,401, 627,418, 628,423, 629,430, 633,433, 634,433, 634,433, 635,433, 639,430, 640,423, 641,418, 647,401, 659,385, 661,378, 661,370, 661,366">
        <area class="GLUTEUS_MAXIMUS" shape="poly" alt=""
              coords="669,381, 662,383, 647,397, 637,416, 636,433, 640,450, 650,464, 668,473, 689,471, 703,463, 712,451, 715,439, 714,426, 708,408, 697,393, 684,384, 669,381">
        <area class="GLUTEUS_MAXIMUS" shape="poly" alt=""
              coords="600,381, 606,383, 621,397, 631,416, 633,433, 628,450, 619,464, 600,473, 579,471, 565,463, 556,451, 553,439, 554,426, 560,408, 572,393, 584,384, 600,381">
        <area class="GLUTEUS_MEDIUS" shape="poly" alt=""
              coords="661,384, 661,384, 675,381, 688,386, 699,395, 708,410, 712,418, 713,422, 716,425, 717,424, 717,419, 715,410, 713,403, 708,384, 698,367, 686,359, 671,361, 662,369, 657,380, 658,384, 661,384">
        <area class="GLUTEUS_MEDIUS" shape="poly" alt=""
              coords="607,384, 607,384, 593,381, 580,386, 570,395, 560,410, 556,418, 555,422, 552,425, 551,424, 551,419, 553,410, 555,403, 561,384, 570,367, 582,359, 597,361, 606,369, 611,380, 610,384, 607,384">
        <area class="GLUTEUS_MINIMUS" shape="poly" alt=""
              coords="702,375, 698,373, 682,374, 673,381, 672,392, 678,402, 687,409, 713,421, 716,422, 718,423, 717,416, 711,394, 709,387, 705,378, 702,375">
        <area class="GLUTEUS_MINIMUS" shape="poly" alt=""
              coords="566,375, 570,373, 586,374, 595,381, 596,392, 590,402, 581,409, 555,421, 552,422, 550,423, 551,416, 557,394, 559,387, 563,378, 566,375">
        <area class="HAMSTRINGS" shape="poly" alt=""
              coords="651,588, 654,544, 653,509, 652,486, 653,472, 658,466, 665,464, 677,464, 688,469, 698,483, 701,499, 701,541, 698,559, 695,576, 697,593, 705,634, 705,653, 705,654, 703,653, 688,627, 680,613, 674,601, 671,603, 669,608, 666,619, 664,631, 663,637, 661,643, 658,636, 656,629, 652,616, 651,588">
        <area class="HAMSTRINGS" shape="poly" alt=""
              coords="618,588, 614,544, 615,509, 616,486, 615,472, 610,466, 603,464, 591,464, 580,469, 570,483, 567,499, 567,541, 570,559, 573,576, 571,593, 563,634, 563,653, 564,654, 565,653, 580,627, 588,613, 594,601, 597,603, 599,608, 602,619, 604,631, 605,637, 607,643, 610,636, 612,629,616,616, 618,588">
        <area class="GLUTEUS_MAXIMUS GLUTEUS_MEDIUS GLUTEUS_MINIMUS TENSOR_FASCIAE_LATAE" shape="poly" alt=""
              coords="669,381, 662,383, 647,397, 637,416, 636,433, 640,450, 650,464, 668,473, 689,471, 703,463, 712,451, 715,439, 714,426, 708,408, 697,393, 684,384, 669,381">
        <area class="GLUTEUS_MAXIMUS GLUTEUS_MEDIUS GLUTEUS_MINIMUS TENSOR_FASCIAE_LATAE" shape="poly" alt=""
              coords="600,381, 606,383, 621,397, 631,416, 633,433, 628,450, 619,464, 600,473, 579,471, 565,463, 556,451, 553,439, 554,426, 560,408, 572,393, 584,384, 600,381">
        <area class="GLUTEUS_MAXIMUS GLUTEUS_MEDIUS GLUTEUS_MINIMUS TENSOR_FASCIAE_LATAE" shape="poly" alt=""
              coords="661,384, 661,384, 675,381, 688,386, 699,395, 708,410, 712,418, 713,422, 716,425, 717,424, 717,419, 715,410, 713,403, 708,384, 698,367, 686,359, 671,361, 662,369, 657,380, 658,384, 661,384">
        <area class="GLUTEUS_MAXIMUS GLUTEUS_MEDIUS GLUTEUS_MINIMUS TENSOR_FASCIAE_LATAE" shape="poly" alt=""
              coords="607,384, 607,384, 593,381, 580,386, 570,395, 560,410, 556,418, 555,422, 552,425, 551,424, 551,419, 553,410, 555,403, 561,384, 570,367, 582,359, 597,361, 606,369, 611,380, 610,384, 607,384">
        <area class="GLUTEUS_MAXIMUS GLUTEUS_MEDIUS GLUTEUS_MINIMUS TENSOR_FASCIAE_LATAE" shape="poly" alt=""
              coords="702,375, 698,373, 682,374, 673,381, 672,392, 678,402, 687,409, 713,421, 716,422, 718,423, 717,416, 711,394, 709,387, 705,378, 702,375">
        <area class="GLUTEUS_MAXIMUS GLUTEUS_MEDIUS GLUTEUS_MINIMUS TENSOR_FASCIAE_LATAE" shape="poly" alt=""
              coords="566,375, 570,373, 586,374, 595,381, 596,392, 590,402, 581,409, 555,421, 552,422, 550,423, 551,416, 557,394, 559,387, 563,378, 566,375">
        <area class="GLUTEUS_MAXIMUS GLUTEUS_MEDIUS GLUTEUS_MINIMUS TENSOR_FASCIAE_LATAE" shape="poly" alt=""
              coords="287,391, 293,410, 298,431, 302,452, 302,463, 302,468, 302,470, 302,473, 285,438, 273,402, 272,399, 273,398, 280,393, 287,391">
        <area class="GLUTEUS_MAXIMUS GLUTEUS_MEDIUS GLUTEUS_MINIMUS TENSOR_FASCIAE_LATAE" shape="poly" alt=""
              coords="139,391, 132,410, 127,431, 124,452, 123,463, 123,468, 123,470, 123,473, 140,438, 153,402, 153,399, 152,398, 146,393, 139,391">
        <area class="HIP_ADDUCTORS" shape="poly" alt=""
              coords="641,452, 640,453, 640,454, 639,456, 639,457, 638,461, 638,468, 638,470, 639,488, 641,509, 647,535, 651,562, 650,586, 651,612, 652,617, 656,604, 660,587, 662,577, 660,534, 656,492, 655,477, 655,469, 653,465, 645,457, 642,453, 641,452">
        <area class="HIP_ADDUCTORS" shape="poly" alt=""
              coords="627,452, 628,453, 628,454, 629,456, 629,457, 630,461, 630,468, 630,470, 629,488, 627,509, 621,535, 618,562, 618,586, 617,612, 616,617, 613,604, 609,587, 607,577, 609,534, 612,492, 613,477, 613,469, 615,465, 623,457, 627,453, 627,452">
        <area class="HIP_ADDUCTORS" shape="poly" alt=""
              coords="243,428, 248,447, 255,457, 264,465, 261,469, 256,477, 253,483, 242,508, 237,529, 233,552, 230,573, 229,551, 224,527, 220,511, 218,494, 216,479, 217,465, 220,458, 229,446, 237,435, 243,428">
        <area class="HIP_ADDUCTORS" shape="poly" alt=""
              coords="183,428, 177,447, 171,457, 162,465, 164,469, 169,477, 173,483, 183,508, 189,529, 193,552, 195,573, 197,551, 202,527, 205,511, 208,494, 209,479, 209,465, 205,458, 197,446, 188,435, 183,428">
        <area class="HIP_FLEXORS" shape="poly" alt=""
              coords="272,476, 270,479, 267,479, 254,466, 237,446, 230,436, 226,426, 227,410, 225,388, 218,367, 216,360, 219,352, 226,355, 235,367, 242,378, 247,391, 251,394, 256,393, 267,395, 275,399, 278,407, 275,417, 270,443, 272,470, 272,476">
        <area class="HIP_FLEXORS" shape="poly" alt=""
              coords="153,476, 155,479, 159,479, 172,466, 189,446, 195,436, 199,426, 199,410, 201,388, 207,367, 209,360, 207,352, 200,355, 191,367, 184,378, 179,391, 175,394, 169,393, 159,395, 151,399, 147,407, 150,417, 156,443, 153,470, 153,476">
        <area class="LATERAL_DELTOIS" shape="poly" alt=""
              coords="740,244, 738,242, 737,238, 735,230, 732,215, 729,207, 724,196, 716,187, 703,182, 688,182, 685,181, 683,180, 684,179, 691,174, 700,172, 705,173, 723,178, 735,190, 742,212, 746,231, 748,241, 749,246, 747,249, 746,249, 740,244, 740,244">
        <area class="LATERAL_DELTOIS" shape="poly" alt=""
              coords="320,246, 318,243, 308,207, 304,196, 296,187, 281,183, 265,182, 255,183, 252,182, 252,179, 258,175, 267,174, 284,174, 294,174, 302,177, 309,183, 314,190, 320,210, 325,231, 328,247, 329,249, 325,250, 321,248, 320,246">
        <area class="LATERAL_DELTOIS" shape="poly" alt=""
              coords="528,244, 530,242, 531,238, 533,230, 537,215, 539,207, 544,196, 552,187, 565,182, 580,182, 584,181, 585,180, 584,179, 577,174, 568,172, 563,173, 545,178, 533,190, 526,212, 522,231, 520,241, 519,246, 521,249, 522,249, 528,244, 528,244">
        <area class="LATERAL_DELTOIS" shape="poly" alt=""
              coords="106,246, 108,243, 117,207, 122,196, 130,187, 144,183, 161,182, 171,183, 173,182, 174,179, 167,175, 159,174, 141,174, 132,174, 123,177, 117,183, 112,190, 106,210, 101,231, 97,247, 97,249, 100,250, 104,248, 106,246">
        <area class="LATISSIMUS_DORSI" shape="poly" alt=""
              coords="701,256, 699,257, 687,260, 674,260, 664,260, 657,262, 650,267, 644,281, 641,296, 644,312, 651,328, 653,336, 657,350, 660,364, 661,370, 666,366, 669,360, 678,346, 690,326, 695,312, 700,291, 704,271, 706,264, 707,261, 704,258, 701,256, 701,256">
        <area class="LATISSIMUS_DORSI" shape="poly" alt=""
              coords="567,256, 569,257, 581,260, 594,260, 604,260, 611,262, 618,267, 624,281, 627,296, 624,312, 618,328, 615,336, 611,350, 608,364, 607,370, 602,366, 599,360, 590,346, 578,326, 573,312, 568,291, 564,271, 563,264, 561,261, 564,258, 567,256, 567,256">
        <area class="LATISSIMUS_DORSI" shape="poly" alt=""
              coords="279,247, 282,253, 285,259, 286,263, 279,281, 276,299, 275,304, 273,295, 274,286, 274,282, 276,279, 277,264, 277,256, 279,247">
        <area class="LATISSIMUS_DORSI" shape="poly" alt=""
              coords="147,247, 144,253, 141,259, 140,263, 146,281, 150,299, 151,304, 152,295, 152,286, 151,282, 150,279, 148,264, 148,256, 147,247">
        <area class="OBLIQUES" shape="poly" alt=""
              coords="244,353, 243,345, 244,337, 244,336, 243,326, 245,322, 248,319, 245,316, 243,311, 245,302, 244,289, 246,285, 250,283, 251,285, 253,287, 257,288, 261,288, 265,290, 263,293, 263,296, 267,301, 268,304, 269,308, 271,306, 274,307, 274,313, 273,320, 272,335, 272,342, 280,368,283,378, 286,387,279,389, 273,392, 263,400, 251,404, 249,404, 247,401, 244,391, 242,381, 244,353, 244,353">
        <area class="OBLIQUES" shape="poly" alt=""
              coords="181,353, 182,345, 181,337, 181,336, 182,326, 181,322, 178,319, 181,316, 183,311, 180,302, 182,289, 180,285, 176,283, 175,285, 173,287, 169,288, 164,288, 161,290, 162,293, 163,296, 159,301, 157,304, 157,308, 155,306, 151,307, 152,313, 153,320, 154,335, 153,342, 146,368,143,378, 140,387,147,389, 153,392, 162,400, 174,404, 177,404, 179,401, 182,391, 183,381, 182,353, 181,353">
        <area class="OBLIQUES" shape="poly" alt=""
              coords="701,284, 676,327, 673,335, 673,344, 681,340, 687,343, 690,347, 692,350, 691,342, 690,336, 692,324, 694,316, 697,305, 701,284">
        <area class="OBLIQUES" shape="poly" alt=""
              coords="567,284, 593,327, 595,335, 595,344, 588,340, 581,343, 579,347, 576,350, 578,342, 578,336, 576,324, 574,316, 571,305, 567,284">
        <area class="PECTORALIS_MAJOR" shape="poly" alt=""
              coords="281,248, 279,252, 274,262, 264,269, 258,269, 237,263, 231,258, 221,245, 219,232, 219,220, 222,208, 228,199, 238,196, 248,197, 264,204, 277,214, 281,219, 282,226, 281,248">
        <area class="PECTORALIS_MAJOR" shape="poly" alt=""
              coords="145,248, 146,252, 151,262, 161,269, 168,269, 189,263, 195,258, 204,245, 206,232, 206,220, 204,208, 198,199, 187,196, 178,197, 162,204, 148,214, 145,219, 143,226, 145,248">
        <area class="PECTORALIS_MINOR" shape="poly" alt=""
              coords="167,205, 166,205, 164,207, 162,206, 160,205, 160,207, 167,256, 168,258, 171,257, 181,246, 185,237, 187,230, 184,225, 174,215, 168,206, 167,205">
        <area class="PECTORALIS_MINOR" shape="poly" alt=""
              coords="258,205, 260,205, 262,207, 263,206, 265,205, 266,207, 259,256, 257,258, 255,257, 245,246, 240,237, 238,230, 242,225, 251,215, 257,206, 258,205">
        <area class="PELVIC_FLOOR" shape="poly" alt=""
              coords="208,419, 201,409, 192,400, 185,396, 178,395, 173,395, 172,395, 172,396, 174,404, 179,413, 184,424, 187,437, 195,446, 203,450, 210,455, 213,456, 213,426, 210,423, 208,419">
        <area class="PELVIC_FLOOR" shape="poly" alt=""
              coords="254,396, 253,395, 253,395, 247,395, 240,396, 234,400, 224,409, 217,419, 216,423, 213,426, 213,456, 215,455, 223,450, 230,446, 238,437, 242,424, 246,413, 252,404, 254,396">
        <area class="POSTERIOR_DELTOIS" shape="poly" alt=""
              coords="683,180, 683,180, 684,180, 685,180, 685,180, 701,182, 716,187, 724,196, 729,207, 732,215, 735,230, 737,238, 738,242, 740,244, 740,244, 734,238, 730,233, 722,225, 711,214, 703,207, 698,202, 689,194, 681,188, 680,185, 682,180, 683,180, 683,180">
        <area class="POSTERIOR_DELTOIS" shape="poly" alt=""
              coords="585,180, 585,180, 584,180, 583,180, 583,180, 567,182, 552,187, 544,196, 539,207, 537,215, 533,230, 531,238, 530,242, 528,244, 528,244, 534,238, 539,233, 546,225, 557,214, 565,207, 571,202, 579,194, 587,188, 589,185, 586,180, 585,180, 585,180">
        <area class="QUADRICEPS" shape="poly" alt=""
              coords="230,581, 232,554, 234,536, 238,521, 242,506, 251,485, 260,470, 271,456, 287,440, 292,452, 299,466, 302,473, 302,484, 300,503, 297,521, 287,572, 283,601, 281,615, 276,627, 273,626, 273,623, 271,615, 267,606, 265,603, 262,601, 260,602, 254,622, 247,633, 240,635, 234,629,232,621, 230,612,229,597, 229,586, 230,581">
        <area class="QUADRICEPS" shape="poly" alt=""
              coords="196,581, 194,554, 191,536, 188,521, 183,506, 174,485, 165,470, 154,456, 139,440, 133,452, 127,466, 123,473, 124,484, 125,503, 128,521, 139,572, 142,601, 144,615, 150,627, 152,626, 153,623, 154,615, 159,606, 161,603, 164,601, 166,602, 171,622, 178,633, 186,635, 191,629,194,621, 196,612,196,597, 196,586, 196,581">
        <area class="RECTUS_ABDOMINIS" shape="poly" alt=""
              coords="238,368, 237,364, 233,360, 223,359, 213,359, 213,359, 203,359, 193,360, 188,364, 188,368, 190,394, 199,419, 205,427, 213,432, 213,432, 213,432, 213,432, 213,432, 213,432, 213,432, 213,432, 213,432, 213,432, 213,432, 221,427, 226,419, 235,394, 238,368">
        <area class="RECTUS_ABDOMINIS" shape="poly" alt=""
              coords="221,328, 217,333, 216,341, 217,348, 221,354, 228,354, 234,349, 237,337, 234,330, 226,328, 221,328, 221,328">
        <area class="RECTUS_ABDOMINIS" shape="poly" alt=""
              coords="224,299, 232,303, 235,309, 238,315, 238,321, 232,324, 224,324, 218,317, 217,309, 218,301, 224,299">
        <area class="RECTUS_ABDOMINIS" shape="poly" alt=""
              coords="220,267, 224,268, 229,271, 232,275, 238,291, 237,295, 232,297, 225,295, 219,291, 217,282, 217,274, 219,268, 220,267">
        <area class="RECTUS_ABDOMINIS" shape="poly" alt=""
              coords="205,328, 209,333, 210,341, 209,348, 204,354, 197,354, 192,349, 188,337, 192,330, 199,328, 205,328, 205,328">
        <area class="RECTUS_ABDOMINIS" shape="poly" alt=""
              coords="202,299, 194,303, 191,309, 188,315, 188,321, 193,324, 201,324, 207,317, 208,309, 207,301, 202,299">
        <area class="RECTUS_ABDOMINIS" shape="poly" alt=""
              coords="206,267, 201,268, 197,271, 194,275, 187,291, 188,295, 194,297, 201,295, 206,291, 209,282, 208,274, 206,268, 206,267">
        <area class="RHOMBOIDS" shape="poly" alt=""
              coords="671,232, 670,209, 670,187, 668,185, 651,174, 634,163, 634,213, 639,217, 651,226, 665,236, 669,236, 671,232">
        <area class="RHOMBOIDS" shape="poly" alt=""
              coords="598,187, 598,209, 597,232, 599,236, 603,236, 617,226, 629,217, 634,213, 634,163, 617,174, 600,185, 598,187">
        <area class="ROTATOR_CUFF" shape="poly" alt=""
              coords="667,194, 667,194, 664,198, 664,203, 663,218, 661,243, 662,246, 665,248, 685,248, 689,248, 692,247, 694,241, 697,231, 704,216, 711,211, 720,210, 717,207, 714,203, 708,197, 699,194, 667,194">
        <area class="ROTATOR_CUFF" shape="poly" alt=""
              coords="601,194, 601,194, 604,198, 604,203, 605,218, 607,243, 607,246, 603,248, 583,248, 579,248, 576,247, 574,241, 571,231, 564,216, 557,211, 548,210, 551,207, 554,203, 561,197, 569,194, 601,194">
        <area class="ROTATOR_CUFF" shape="poly" alt=""
              coords="269,195, 274,194, 285,196, 288,202, 288,208, 288,209, 286,211, 280,216, 270,233, 269,238, 267,245, 266,250, 262,252, 258,246, 250,209, 251,200, 251,199, 253,199, 257,199, 260,199, 262,198, 269,195">
        <area class="ROTATOR_CUFF" shape="poly" alt=""
              coords="156,195, 152,194, 141,196, 138,202, 137,208, 138,209, 139,211, 146,216, 155,233, 157,238, 158,245, 160,250, 164,252, 168,246, 176,209, 175,200, 174,199, 173,199, 169,199, 165,199, 163,198, 156,195">
        <area class="SERRATUS_ANTERIOR" shape="poly" alt=""
              coords="665,213, 665,217, 665,223, 664,234, 662,245, 660,257, 663,261, 667,265, 682,280, 688,287, 696,293, 698,294, 699,295, 700,291, 701,288, 705,264, 706,263, 698,256, 698,256, 701,256, 703,254, 702,252, 699,249, 694,245, 693,244, 693,244, 696,244, 700,242, 698,239, 692,236,689,234, 692,234,694,233, 693,230, 690,228, 693,227, 691,225, 685,222, 679,220, 678,220, 679,219, 682,219, 683,219, 681,216, 665,213">
        <area class="SERRATUS_ANTERIOR" shape="poly" alt=""
              coords="603,213, 603,217, 603,223, 604,234, 606,245, 608,257, 605,261, 601,265, 587,280, 580,287, 572,293, 571,294, 569,295, 568,291, 567,288, 563,264, 563,263, 570,256, 570,256, 568,256, 565,254, 566,252, 569,249, 574,245, 575,244, 575,244, 573,244, 569,242, 570,239, 576,236,579,234, 576,234,574,233, 576,230, 578,228, 575,227, 577,225, 583,222, 589,220, 590,220, 589,219, 586,219, 586,219, 587,216, 603,213">
        <area class="SERRATUS_ANTERIOR" shape="poly" alt=""
              coords="277,257, 275,256, 265,265, 265,266, 266,267, 266,267, 268,269, 262,276, 263,278, 267,277, 269,279, 266,284, 265,287, 266,289, 268,289, 271,291, 270,293, 270,295, 274,294, 277,291, 278,281, 277,257">
        <area class="SERRATUS_ANTERIOR" shape="poly" alt=""
              coords="148,257, 150,256, 161,265, 161,266, 160,267, 159,267, 158,269, 164,276, 162,278, 158,277, 157,279, 159,284, 161,287, 160,289, 157,289, 155,291, 156,293, 156,295, 152,294, 149,291, 148,281, 148,257">
        <area class="TENSOR_FASCIAE_LATAE" shape="poly" alt=""
              coords="287,391, 293,410, 298,431, 302,452, 302,463, 302,468, 302,470, 302,473, 285,438, 273,402, 272,399, 273,398, 280,393, 287,391">
        <area class="TENSOR_FASCIAE_LATAE" shape="poly" alt=""
              coords="139,391, 132,410, 127,431, 124,452, 123,463, 123,468, 123,470, 123,473, 140,438, 153,402, 153,399, 152,398, 146,393, 139,391">
        <area class="TRANSVERSUS_ABDOMINIS" shape="poly" alt=""
              coords="252,292, 247,285, 241,278, 238,276, 239,280, 242,287, 247,303, 248,321, 245,360, 241,386, 240,392, 238,400, 239,405, 242,401, 250,392, 257,382, 262,376, 268,369, 273,361, 276,358, 275,355, 274,351, 272,341, 273,318, 270,315, 264,307, 258,299, 252,292">
        <area class="TRANSVERSUS_ABDOMINIS" shape="poly" alt=""
              coords="173,292, 178,285, 184,278, 188,276, 187,280, 183,287, 179,303, 178,321, 180,360, 184,386, 186,392, 187,400, 187,405, 183,401, 176,392, 168,382, 163,376, 158,369, 152,361, 149,358, 150,355, 151,351, 154,341, 153,318, 155,315, 161,307, 168,299, 173,292">
        <area class="TRAPEZIUS" shape="poly" alt=""
              coords="687,171, 677,166, 667,160, 657,152, 652,143, 649,131, 649,120, 649,114, 634,114, 634,211, 634,307, 645,289, 656,270, 660,263, 665,257, 669,249, 673,236, 674,227, 674,216, 675,207, 683,198, 694,191, 703,184, 709,173, 704,173, 694,172, 687,171">
        <area class="TRAPEZIUS" shape="poly" alt=""
              coords="619,131, 616,143, 611,152, 601,160, 592,166, 581,171, 574,172, 564,173, 559,173, 565,184, 574,191, 585,198, 593,207, 594,216, 594,227, 595,236, 599,249, 603,257, 608,263, 613,270, 623,289, 634,307, 634,211, 634,114, 619,114, 619,120, 619,131">
        <area class="TRAPEZIUS" shape="poly" alt=""
              coords="264,171, 261,169, 245,156, 240,151, 239,149, 237,150, 237,151, 236,156, 235,161, 235,170, 234,176, 235,177, 248,176, 257,174, 261,173, 264,171, 264,171">
        <area class="TRAPEZIUS" shape="poly" alt=""
              coords="162,171, 164,169, 181,156, 185,151, 187,149, 189,150, 189,151, 190,156, 190,161, 191,170, 192,176, 191,177, 177,176, 168,174, 165,173, 162,171, 162,171">
        <area class="TRICEPS" shape="poly" alt=""
              coords="715,217, 726,224, 740,233, 748,243, 750,251, 753,262, 755,273, 758,292, 758,311, 754,322, 746,334, 743,332, 733,322, 724,309, 718,294, 713,279, 708,264, 708,247, 711,231, 713,223, 714,220, 715,217">
        <area class="TRICEPS" shape="poly" alt=""
              coords="553,217, 542,224, 528,233, 520,243, 518,251, 515,262, 514,273, 511,292, 510,311, 515,322, 522,334, 525,332, 535,322, 544,309, 550,294, 555,279, 560,264, 560,247, 557,231, 555,223, 554,220, 553,217">
      </map>
      <span *ngFor="let muscle of exercise.muscleGroups;">
        <p>{{"MUSCLES."+muscle| translate}} </p>
      </span>
    </div>
  </div>

</div>



