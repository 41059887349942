import { ChangeProgramDialogComponent } from './../../../app/main/change-program-dialog/change-program-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GuiService } from './../../../app/services/gui.service';
import { NavigationItem } from 'src/@vex/interfaces/navigation-item.interface';
import { TranslateService } from '@ngx-translate/core';
import { Component, ElementRef, HostBinding, Input } from '@angular/core';
import { LayoutService } from '../../services/layout.service';
import { ConfigService } from '../../config/config.service';
import { map, startWith, switchMap } from 'rxjs/operators';
import { NavigationService } from '../../services/navigation.service';
import { PopoverService } from '../../components/popover/popover.service';
import { MegaMenuComponent } from '../../components/mega-menu/mega-menu.component';
import { Observable, of } from 'rxjs';
import {RestService} from "../../../app/services/rest.service";
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'vex-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent {

  @Input() mobileQuery: boolean;

  @Input()
  @HostBinding('class.shadow-b')
  hasShadow: boolean;

  navigationItems: NavigationItem[] | any = this.navigationService.items;
  iconFlag: string

  form: UntypedFormGroup
  notifications: any
  nomeProgramma: any
  programs = []

  isHorizontalLayout$: Observable<boolean> = this.configService.config$.pipe(map(config => config.layout === 'horizontal'));
  isVerticalLayout$: Observable<boolean> = this.configService.config$.pipe(map(config => config.layout === 'vertical'));
  isNavbarInToolbar$: Observable<boolean> = this.configService.config$.pipe(map(config => config.navbar.position === 'in-toolbar'));
  isNavbarBelowToolbar$: Observable<boolean> = this.configService.config$.pipe(map(config => config.navbar.position === 'below-toolbar'));
  userVisible$: Observable<boolean> = this.configService.config$.pipe(map(config => config.toolbar.user.visible));

  megaMenuOpen$: Observable<boolean> = of(false);



  constructor(private layoutService: LayoutService,
              private configService: ConfigService,
              private navigationService: NavigationService,
              private popoverService: PopoverService,
              public translate: TranslateService,
              public GuiService: GuiService,
              public restSrv: RestService,
              public rotta: Router,
              public dialog: MatDialog,
              private fb: UntypedFormBuilder) {
                translate.addLangs(['en', 'it']);
                translate.setDefaultLang('it');

                const browserLang = translate.getBrowserLang();
                translate.use(browserLang.match(/en|it/) ? browserLang : 'it');
                if(browserLang == 'it'){
                  this.iconFlag = 'flag:italy'
                }else{
                  this.iconFlag = 'flag:united-states'
                }
               }



  openQuickpanel(): void {
    this.layoutService.openQuickpanel();
  }

  openSidenav(): void {
    this.layoutService.openSidenav();
  }

  openMegaMenu(origin: ElementRef | HTMLElement): void {
    this.megaMenuOpen$ = of(
      this.popoverService.open({
        content: MegaMenuComponent,
        origin,
        offsetY: 12,
        position: [
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top'
          },
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
          },
        ]
      })
    ).pipe(
      switchMap(popoverRef => popoverRef.afterClosed$.pipe(map(() => false))),
      startWith(true),
    );
  }

  openSearch(): void {
    this.layoutService.openSearch();
  }



  changeLanguage(lang){
    this.GuiService.changeLang(lang)
    if(lang === 'en'){
      this.iconFlag = 'flag:united-states'
    }
    if(lang === 'it'){
      this.iconFlag = 'flag:italy'
    }
  }

  public get changeFlag(){
    return this.iconFlag
  }

  ngOnInit() {
    this.notifications = this.GuiService.notificationsList;
    let ref = this;
    setInterval(function(){
        ref.notifications = ref.GuiService.notificationsList;
    }, 10000)
    this.getPrograms()
    this.form = this.fb.group({
      programControl: [''],
    });
  }
  getPrograms(){
    setTimeout(()=>this.GuiService.showSpinner = true,0)
    this.restSrv.postService('workout_programs', '/search', {page: 0, size: 0}).subscribe((ris)=>{
      for(let program in ris.items){
        let checkIfDraft = ris.items[program].workoutProgramStatus
        if(checkIfDraft == 'pubblica'){
          this.programs.push(ris.items[program])
        }
      }
      console.debug(this.programs)
      this.form.controls['programControl'].setValue(this.GuiService.userLogged.lastWorkoutProgram.workoutProgramId)
      this.GuiService.showSpinner = false
    })
  }

  changeProgram(){
    let program = this.programs.find(x => x.id === this.form.value['programControl'])
    console.debug(program)
    this.dialog.open(ChangeProgramDialogComponent,{
      panelClass: 'change-program-dialog',
      // width: '35vw',
      // height: '30vh',
      disableClose: true,
      hasBackdrop: true,
      data: program.name || null
    }).afterClosed().subscribe((ris)=>{
      if(ris != 'true'){
        sessionStorage.setItem("selectedProgram", program.name)
        sessionStorage.setItem("selectedProgramId", program.id)
      this.GuiService.selectedProgram = program.name
      this.GuiService.userLogged.lastWorkoutProgram.workoutProgramId != program.id ? this.GuiService.isCurrentProgram = false : this.GuiService.isCurrentProgram = true
      console.debug('riga161', this.GuiService.isCurrentProgram)
      this.rotta.navigate(['/main/dashboard'])
      }else{
        this.form.controls['programControl'].setValue(this.GuiService.userLogged.lastWorkoutProgram.workoutProgramId)
      }
    })

  }

}
