<div class="container">
  <form [formGroup]="form">
    <div class="card flex-auto">
      <div class="px-6 py-4 border-b flex items-center header-title">
        <h2 class="title m-0">{{"CHALLENGES.EDIT" | translate}}</h2>
        <div class="flex-end">
          <button [matMenuTriggerFor]="languageFormMenu" mat-icon-button type="button" align="end">
            <mat-icon svgIcon="{{iconFormFlag}}"></mat-icon>
          </button>
          <mat-menu #languageFormMenu="matMenu" overlapTrigger="false" >
            <button mat-menu-item (click)="changeFormLanguage('it')">
              <mat-icon svgIcon="flag:italy"></mat-icon>
              <span>Italiano</span>
            </button>
            <button mat-menu-item (click)="changeFormLanguage('en')">
              <mat-icon svgIcon="flag:united-states"></mat-icon>
              <span>English</span>
            </button>
          </mat-menu>
        </div>
      </div>


      <div class="px-6 py-4 flex flex-col">
        <div class="card px-6 py-4 flex flex-col subCards" *ngIf="formLang=='it'">
          <mat-form-field class="flex-auto">
            <mat-label>{{"CHALLENGES.NAME" | translate}} {{"GLOBAL.ITA" | translate}}</mat-label>
            <input formControlName="nome_it" matInput />
          </mat-form-field>

          <div class="flex flex-col sm:flex-row sm:gap-4">
            <mat-form-field class="flex-auto">
              <mat-label>{{"CHALLENGES.PUB" | translate}}</mat-label>
              <input [matDatepicker]="datepickerRef" matInput formControlName="publish" required />
              <mat-datepicker-toggle [for]="datepickerRef" matSuffix></mat-datepicker-toggle>
              <mat-datepicker #datepickerRef></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="flex-auto">
              <mat-label>{{"CHALLENGES.EXP" | translate}}</mat-label>
              <input [matDatepicker]="datepickerRef1" matInput formControlName="expire" required/>
              <mat-datepicker-toggle [for]="datepickerRef1" matSuffix></mat-datepicker-toggle>
              <mat-datepicker #datepickerRef1></mat-datepicker>
            </mat-form-field>
            <div class="flex-auto notification">
              <mat-label>{{"CHALLENGES.ONLY" | translate}}</mat-label>
              <mat-slide-toggle formControlName="notification">
              </mat-slide-toggle>
            </div>
          </div>

          <mat-form-field class="description" *ngIf="form.value.notification == false">
            <mat-label>{{"CHALLENGES.DESC" | translate}} {{"GLOBAL.ITA" | translate}}</mat-label>
            <textarea cols="30" rows="8" matInput formControlName="desc_it"></textarea>
          </mat-form-field>
          <fieldset>
            <legend>NOTA</legend>
            <h1>Il video dovrà essere caricato manualmente sul server e avere come nome lo stesso nome della sfida separato da underscore "_".

              Esempio: Per la sfida "200 REPS ABS CHALLENGE" il file dovrà essere rinominato in "200_REPS_ABS_CHALLENGE.mp4".</h1>
          </fieldset>
        </div>

        <div class="card px-6 py-4 flex flex-col subCards" *ngIf="formLang=='en'">
          <mat-form-field class="flex-auto">
            <mat-label>{{"CHALLENGES.NAME" | translate}} {{"GLOBAL.ENG" | translate}}</mat-label>
            <input formControlName="nome_en" matInput />
          </mat-form-field>

          <div class="flex flex-col sm:flex-row sm:gap-4">
            <mat-form-field class="flex-auto">
              <mat-label>{{"CHALLENGES.PUB" | translate}}</mat-label>
              <input [matDatepicker]="datepickerRef" matInput formControlName="publish" required />
              <mat-datepicker-toggle [for]="datepickerRef" matSuffix></mat-datepicker-toggle>
              <mat-datepicker #datepickerRef></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="flex-auto">
              <mat-label>{{"CHALLENGES.EXP" | translate}}</mat-label>
              <input [matDatepicker]="datepickerRef1" matInput formControlName="expire" required/>
              <mat-datepicker-toggle [for]="datepickerRef1" matSuffix></mat-datepicker-toggle>
              <mat-datepicker #datepickerRef1></mat-datepicker>
            </mat-form-field>
            <div class="flex-auto notification">
              <mat-label>{{"CHALLENGES.ONLY" | translate}}</mat-label>
              <mat-slide-toggle formControlName="notification">
              </mat-slide-toggle>
            </div>
          </div>

          <mat-form-field class="description" *ngIf="form.value.notification == false">
            <mat-label>{{"CHALLENGES.DESC" | translate}} {{"GLOBAL.ENG" | translate}}</mat-label>
            <textarea cols="30" rows="8" matInput formControlName="desc_en"></textarea>
          </mat-form-field>

          <fieldset>
          <legend>Il video dovrà essere caricato manualmente sul server e avere come nome lo stesso nome della sfida separato da underscore "_".

            Esempio: Per la sfida "200 REPS ABS CHALLENGE" il file dovrà essere rinominato in "200_REPS_ABS_CHALLENGE.mp4".</legend>
        </fieldset>
        </div>

        <video [src]="VIDEO_ENV+'/'+challenge.name.replaceAll(' ', '_')+'.mp4'" controls #myVideo></video>


        <div class="bottoni">
          <button (click)="confirmClose()" mat-button-base class="m-4">{{"GLOBAL.CLOSE" | translate}}</button>
          <button mat-raised-button [disabled]="form.invalid" (click)="submit()" class="m-4 bottone-viola">
            {{"GLOBAL.EDIT" | translate}}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
