<div class="container py-gutter" *ngIf="user">
  <div class="card overflow-hidden">
    <div class="h-64 relative overflow-hidden">
      <img class="w-full h-full object-cover" src="../../../assets/img/demo/backgroundLogin.png">
      <div class="absolute bg-contrast-black opacity-25 top-0 right-0 bottom-0 left-0 w-full h-full z-0"></div>

    </div>

    <div class="z-10 relative -mt-16 px-gutter flex items-center">

      <div  class="avatar avatar-desktop h-24 w-24 flex-none align-start hidden sm:block border-2 border-white ng-tns-c280-3 ng-trigger ng-trigger-scaleIn">
        <h3>{{user.masterData.name.charAt(0)}}{{user.masterData.lastName.charAt(0)}}</h3>
      </div>

      <div class="max-w-full flex-auto sm:ltr:ml-6 sm:rtl:mr-6">
        <div class="h-16 flex items-end">
          <h1 @fadeInRight class="headline text-contrast-white pb-2 m-0">{{user.masterData.name}} {{user.masterData.lastName}}</h1>
        </div>

        <nav class="vex-tabs vex-tabs-dense border-0" mat-tab-nav-bar>
          <a #rla="routerLinkActive"
             *ngFor="let link of links"
             [active]="rla.isActive"
             [disabled]="link.disabled"
             [routerLink]="link.route"
             mat-tab-link
             queryParamsHandling="preserve"
             [routerLinkActiveOptions]="link.routerLinkActiveOptions || { exact: false }"
             routerLinkActive>
            {{ link.label }}
          </a>
        </nav>
      </div>
    </div>
  </div>

  <div  class="mt-6 flex flex-col md:flex-row md:items-start">
    <div class="flex-auto"><router-outlet></router-outlet></div>
    <div class="card max-w-unset md:max-w-xs w-full flex-none md:ltr:ml-6 md:rtl:mr-6 mt-6 md:mt-0 promos">
      <div class="px-6 py-4 border-b">
        <h2 class="title m-0">{{"USER_TRANS.NEWS" | translate}}</h2>
      </div>

      <div class="px-6 py-4 flex flex-col gap-4">
        <div class="flex items-center">

        </div>
      </div>
    </div>
  </div>
</div>
