import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'vex-msg-contact-support',
  templateUrl: './msg-contact-support.component.html',
  styleUrls: ['./msg-contact-support.component.scss']
})
export class MsgContactSupportComponent implements OnInit {

  constructor(public translate: TranslateService) { }

  ngOnInit(): void {
  }

}
