import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'vex-confirm-upgrade-modal',
  templateUrl: './confirm-upgrade-modal.component.html',
  styleUrls: ['./confirm-upgrade-modal.component.scss']
})
export class ConfirmUpgradeModalComponent implements OnInit {

  constructor(public translate: TranslateService, private dialogRef: MatDialogRef<ConfirmUpgradeModalComponent>) { }

  ngOnInit(): void {
  }


  confirm(){
    this.dialogRef.close('done')
  }


}
