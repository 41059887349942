<div class="container">
  <form [formGroup]="form">
    <div class="card flex-auto">
      <div class="px-6 py-4 border-b flex items-center header-title">
        <h2 class="title m-0">{{"NEW-EXERCISE.NEW" | translate}}</h2>
        <div class="flex-end">
          <button [matMenuTriggerFor]="languageFormMenu" mat-icon-button type="button" align="end">
            <mat-icon svgIcon="{{iconFormFlag}}"></mat-icon>
          </button>
          <mat-menu #languageFormMenu="matMenu" overlapTrigger="false" >
            <button mat-menu-item (click)="changeFormLanguage('it')">
              <mat-icon svgIcon="flag:italy"></mat-icon>
              <span>Italiano</span>
            </button>
            <button mat-menu-item (click)="changeFormLanguage('en')">
              <mat-icon svgIcon="flag:united-states"></mat-icon>
              <span>English</span>
            </button>
          </mat-menu>
        </div>
      </div>

      <div class="px-6 py-4 flex flex-col">

        <div class="card px-6 py-4 flex flex-col subCards" *ngIf="formLang=='it'">
          <mat-form-field class="flex-auto">
            <mat-label>{{"NEW-EXERCISE.NAME" | translate}} {{"GLOBAL.ITA" | translate}}</mat-label>
            <input formControlName="nome_it" matInput required />
          </mat-form-field>

          <mat-form-field class="flex-auto">
            <mat-label>{{"NEW-EXERCISE.MUSCLE" | translate}}</mat-label>
            <mat-select formControlName="muscolo" required multiple>
              <mat-option [value]="option.key" *ngFor="let option of muscle">{{ option.description }}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="flex-auto">
            <mat-label>{{"NEW-WORKOUT-PROG.EQUIP" | translate}}</mat-label>
            <mat-select formControlName="attrezzatura" multiple>
              <mat-option [value]="option.id" *ngFor="let option of attrezzatura">{{ option.name }}</mat-option>
            </mat-select>
          </mat-form-field>

          <div class="flex flex-col sm:flex-row sm:gap-4">
            <mat-form-field class="flex-auto">
            <mat-label>{{"NEW-EXERCISE.TYPE" | translate}}</mat-label>
            <mat-select formControlName="tipo" required>
              <mat-option></mat-option>
              <mat-option [value]="option.key" *ngFor="let option of typs">{{ option.description }}</mat-option>
            </mat-select>
          </mat-form-field>
            <mat-form-field class="flex-auto">
            <mat-label>{{"NEW-EXERCISE.DIFF" | translate}}</mat-label>
            <mat-select formControlName="difficolta" required>
              <mat-option></mat-option>
              <mat-option [value]="option.key" *ngFor="let option of level">{{ option.description }}</mat-option>
            </mat-select>
          </mat-form-field>
          </div>

          <mat-form-field>
            <mat-label>{{"NEW-EXERCISE.ALTERNATIVE" | translate}}</mat-label>
              <input type="text"
              aria-label="Number"
              matInput
              [formControlName]="'alternative'"
              [matAutocomplete]="auto"
              (keydown)="searchAltExercise()"
              (focus)="searchAltExercise()">
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let ex of exercises" [value]="ex.id">
                <img [src]="imgURL+'/'+ex.id+'/images/main/'" class="exercise-img-option">
                {{ex.name}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <div class="description">
            <mat-label>{{"NEW-EXERCISE.DESC" | translate}} {{"GLOBAL.ITA" | translate}}</mat-label>
            <quill-editor
                   formControlName="desc_it"
                    [styles]="{ flex: '1 1 auto' }"></quill-editor>
          </div>


        </div>

        <div class="card px-6 py-4 flex flex-col subCards" *ngIf="formLang=='en'">
          <mat-form-field class="flex-auto">
            <mat-label>{{"NEW-EXERCISE.NAME" | translate}} {{"GLOBAL.ENG" | translate}}</mat-label>
            <input formControlName="nome_en" matInput required />
          </mat-form-field>

          <mat-form-field class="flex-auto">
            <mat-label>{{"NEW-EXERCISE.MUSCLE" | translate}}</mat-label>
            <mat-select formControlName="muscolo" required multiple>
              <mat-option [value]="option.key" *ngFor="let option of muscle">{{ option.description }}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="flex-auto">
            <mat-label>{{"NEW-WORKOUT-PROG.EQUIP" | translate}}</mat-label>
            <mat-select formControlName="attrezzatura" multiple>
              <mat-option [value]="option.id" *ngFor="let option of attrezzatura">{{ option.name }}</mat-option>
            </mat-select>
          </mat-form-field>

          <div class="flex flex-col sm:flex-row sm:gap-4">
            <mat-form-field class="flex-auto">
            <mat-label>{{"NEW-EXERCISE.TYPE" | translate}}</mat-label>
            <mat-select formControlName="tipo" required>
              <mat-option></mat-option>
              <mat-option [value]="option.key" *ngFor="let option of typs">{{ option.description }}</mat-option>
            </mat-select>
          </mat-form-field>
            <mat-form-field class="flex-auto">
            <mat-label>{{"NEW-EXERCISE.DIFF" | translate}}</mat-label>
            <mat-select formControlName="difficolta" required>
              <mat-option></mat-option>
              <mat-option [value]="option.key" *ngFor="let option of level">{{ option.description }}</mat-option>
            </mat-select>
          </mat-form-field>
          </div>

          <mat-form-field>
            <mat-label>{{"NEW-EXERCISE.ALTERNATIVE" | translate}}</mat-label>
              <input type="text"
              aria-label="Number"
              matInput
              [formControlName]="'alternative'"
              [matAutocomplete]="auto"
              (keydown)="searchAltExercise()"
              (focus)="searchAltExercise()">
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let ex of exercises" [value]="ex.id">
                <img [src]="imgURL+'/'+ex.id+'/images/main/'" class="exercise-img-option">
                {{ex.name}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <div class="description">
            <mat-label>{{"NEW-EXERCISE.DESC" | translate}} {{"GLOBAL.ENG" | translate}}</mat-label>
            <quill-editor
                   formControlName="desc_en"
                    [styles]="{ flex: '1 1 auto' }"></quill-editor>
          </div>

        </div>
        <div class="filename">
            <input type="file" (change)="onFileSelected($event)" id="fileInput_EN" class="mb-4 mt-4"/>
          </div>

        <div class="bottoni">
          <button class="m-4" (click)="confirmClose()" mat-button-base>{{"GLOBAL.CLOSE" | translate}}</button>
          <button mat-raised-button [disabled]="form.invalid" (click)="submit()" class="m-4 bottone-viola">
            {{"GLOBAL.CREATE" | translate}}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

