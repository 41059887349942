import { GuiService } from './../../services/gui.service';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { RestService } from 'src/app/services/rest.service';

export function ConfirmPasswordValidator(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    let control = formGroup.controls[controlName];
    let matchingControl = formGroup.controls[matchingControlName]
    if (
      matchingControl.errors &&
      !matchingControl.errors.confirmPasswordValidator
    ) {
      return;
    }
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ confirmPasswordValidator: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}

@Component({
  selector: 'vex-reset-psw',
  templateUrl: './reset-psw.component.html',
  styleUrls: ['./reset-psw.component.scss'],
  animations: [fadeInUp400ms]
})
export class ResetPswComponent implements OnInit {
  form: FormGroup
  email: any;
  token: any;
  encodedEmail: any;
  visible = false;
  inputType = 'password';

  constructor(
    private fb: FormBuilder,
    public restService: RestService,
    private route: ActivatedRoute,
    public guiService: GuiService,
    private router: Router,
    private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      password:['', [Validators.required]],
      confirmPassword: ['', [Validators.required]]
    },{
      validator: ConfirmPasswordValidator("password", "confirmPassword")
    })
    this.email = this.route.snapshot.paramMap.get('email');
    this.token = this.route.snapshot.paramMap.get('token');
    this.encodeBase64(this.email)
  }

  encodeBase64(param){
    this.encodedEmail = atob(param)
    console.debug('encoded email =',this.encodedEmail)
  }

  send(){
    this.restService.postService('users', '/reset/' + this.email +'/'+ this.token,{password: this.form.value.password}).subscribe((ris)=>{
      console.debug(ris)
      this.guiService.openSnackBar('password cambiata con successo', 'chiudi')
      this.router.navigate(['auth/login']);
    })
  }

  toggleVisibility() {
    if (this.visible) {
      this.inputType = 'password';
      this.visible = false;
      this.cd.markForCheck();
    } else {
      this.inputType = 'text';
      this.visible = true;
      this.cd.markForCheck();
    }
  }

}
