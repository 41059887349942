import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GuiService } from 'src/app/services/gui.service';

@Component({
  selector: 'vex-register-confirm',
  templateUrl: './register-confirm.component.html',
  styleUrls: ['./register-confirm.component.scss']
})
export class RegisterConfirmComponent implements OnInit {

  constructor(public router: Router, public guiService: GuiService) {

   }

  ngOnInit(): void {
    setTimeout(()=>this.guiService.showSpinner = false,2000)
  }

  goToLogin(){
    setTimeout(()=>this.guiService.showSpinner = true,0)
    setTimeout(() => {
      this.guiService.showSpinner = false
      this.router.navigate(['auth/login']);
    }, 1500);
  }

}
