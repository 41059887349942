import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { GuiService } from 'src/app/services/gui.service';
import { RestService } from 'src/app/services/rest.service';
import * as moment from 'moment';

@Component({
  selector: 'vex-assign-ondemand',
  templateUrl: './assign-ondemand.component.html',
  styleUrls: ['./assign-ondemand.component.scss']
})
export class AssignOndemandComponent implements OnInit {

  users: any
  dataSource : MatTableDataSource<any> | null
  displayedColumns: string[] = ['checkbox','utente', 'data-iscrizione','ondemand'];
  searchCtrl = new UntypedFormControl();
  pageSize = 10;
  pageNumber = 0;
  pageSizeOptions: number[] = [5, 10, 15, 50];
  filteredIds = {}
  lunghezza: any;
  promotions: any;

  searchCtrlReg_from = new UntypedFormControl();
  searchCtrlReg_to = new UntypedFormControl();
  searchCtrlPay = new UntypedFormControl();
  searchCtrlType = new UntypedFormControl();
  searchCtrlExp_from = new UntypedFormControl();
  searchCtrlExp_to = new UntypedFormControl();
  searchCtrlName = new UntypedFormControl();
  searchCtrlLastName = new UntypedFormControl();
  searchCtrlPromo = new UntypedFormControl();
  searchCtrlRequest = new UntypedFormControl();
  /* sort var */
  asc: any;
  fieldParam: string;
  searchAltFlag: boolean;
  sortFlag: boolean;

  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  checkedUsers = [];

  constructor(private restService: RestService, public translate: TranslateService, private dialog: MatDialog, public guiService: GuiService) { }

  ngOnInit(): void {
    this.getUsers()
    this.asc = false;
    this.searchAltFlag = false;
    this.sortFlag = false;
    this.getPromos()
  }

  getUsers(){
    setTimeout(()=>this.guiService.showSpinner = true,0)
    this.dataSource = new MatTableDataSource();
    let param = {
      page: this.pageNumber,
      size: this.pageSize,
      masterData: {
        orders: [
          {
            field: "registrationDate",
            ascending: false
          }
        ]
      }
    };

    this.restService.postService('users', '/full/search', param).subscribe((ris)=>{
      this.lunghezza = ris.totalItems
      this.users = ris["items"]
      console.debug('utenti', ris.items)
      this.dataSource.data = this.users;
      this.table ? this.table.renderRows() : null;
      this.guiService.showSpinner = false
    })
  }

  onPageChanged(pageEvent: PageEvent) {
    this.pageNumber = pageEvent.pageIndex+1;
    this.pageSize = pageEvent.pageSize;
    if(this.searchAltFlag == false && this.sortFlag == false){
      this.getUsers()
    }else if(this.sortFlag == true){
      this.sortParam(this.fieldParam, this.asc)
    }else if(this.searchAltFlag == true){
      this.search(this.fieldParam, this.asc)
    }
  }

  sortParam(field?,asc?){
    let defaultURL = "/search";
    this.asc = asc
    this.sortFlag = true
    this.fieldParam = field
    let param: any;
    param = {
      page: this.pageNumber,
      size: this.pageSize,
        orders: [
        {
         field: this.fieldParam,
         ascending : this.asc
        }
      ]
    }

    if(field=="registrationDate") {
      defaultURL = "/full/search";
      param = {
        page: this.pageNumber,
        size: this.pageSize,
        masterData: {
          orders: [
            {
              field: "registrationDate",
              ascending: this.asc
            }
          ]
        }
      };
    }

    if(field=="subscriptionType") {
      defaultURL = "/full/search";
      param = {
        page: this.pageNumber,
        size: this.pageSize,
        payment: {
          orders: [
            {
              field: "subscriptionType",
              ascending: this.asc
            }
          ]
        }
      };
    }

    if(field=="subscriptionStatus") {
      defaultURL = "/full/search";
      param = {
        page: this.pageNumber,
        size: this.pageSize,
        payment: {
          orders: [
            {
              field: "subscriptionStatus",
              ascending: this.asc
            }
          ]
        }
      };
    }

    if(field=="subscriptionExpiration") {
      defaultURL = "/full/search";
      param = {
        page: this.pageNumber,
        size: this.pageSize,
        payment: {
          orders: [
            {
              field: "subscriptionExpiration",
              ascending: this.asc
            }
          ]
        }
      };
    }

    this.restService.postService('users', defaultURL, param).subscribe((ris)=>{
      this.users = []
      this.lunghezza = ris.totalItems
      this.users = ris["items"]
      console.debug('utenti', ris.items)
      this.dataSource.data = this.users;
      this.table ? this.table.renderRows() : null;
      this.guiService.showSpinner = false
    })
  }

  reload(){
    this.searchCtrlReg_from.reset()
    this.searchCtrlReg_to.reset()
    this.searchCtrl.reset()
    this.searchCtrlLastName.reset()
    this.searchCtrlName.reset()
    this.searchCtrlPay.reset()
    this.searchCtrlType.reset()
    this.searchCtrlExp_from.reset()
    this.searchCtrlExp_to.reset()
    this.searchCtrlPromo.reset()
    this.searchAltFlag = false;
    this.sortFlag = false;
    this.pageNumber = 0;
    this.pageSize = 10;
    this.paginator.firstPage()
    this.getUsers()
  }

  search(field?,asc?){
    console.debug('searchAltFlag')
    this.searchAltFlag = true
    field ? this.fieldParam = field : null
    this.asc = asc
      let param = {
        user: {
          "filterAnd": [],
          "orders": []
        },
        masterData:{
          "filterAnd": [],
          "orders": []
        },
        payment:{
          "filterAnd": [],
          "orders": []
        },
        "page": this.pageNumber,
        "size": this.pageSize
      }
      if(this.fieldParam=="registrationDate") {
          param.masterData['orders'].push(
            {
              field: this.fieldParam,
              ascending: this.asc
            }
            )
      }

      if(this.fieldParam=="subscriptionType") {
          param.payment['orders'].push(
              {
                field: this.fieldParam,
                ascending: this.asc
              })
      }

      if(this.fieldParam=="subscriptionStatus") {
          param.payment['orders'].push(
            {
                field: this.fieldParam,
                ascending: this.asc
              }
          )
      }

      if(this.fieldParam=="subscriptionExpiration") {
          param.payment['orders'].push(
            {
                field: this.fieldParam,
                ascending: this.asc
              }
          )
      }


      if(this.searchCtrl.value != null){
        param.user["filterAnd"].push(
          {
            "field": "email",
            "operator": "like",
            "value": this.searchCtrl.value.replace(/^\s+|\s+$/gm,'')
          }
        )
      }
      if(this.searchCtrlName.value != null){
        param.masterData["filterAnd"].push(
          {
            "field": "name",
            "operator": "like",
            "value": this.searchCtrlName.value.replace(/^\s+|\s+$/gm,'')
          }
        )
      }
      if(this.searchCtrlLastName.value != null){
        param.masterData["filterAnd"].push(
          {
            "field": "lastName",
            "operator": "like",
            "value": this.searchCtrlLastName.value.replace(/^\s+|\s+$/gm,'')
          }
        )
      }

      if(this.searchCtrlReg_from.value != null){
        let reg_to = moment(this.searchCtrlReg_to.value).format('MM-DD-YYYY')

        param.masterData["filterAnd"].push(
          {
            "field": "registrationDate",
            "operator": "gte",
            "dateValue": moment(this.searchCtrlReg_from.value).format('x')
          }
        ),
        param.masterData["filterAnd"].push(
          {
            "field": "registrationDate",
            "operator": "lte",
            "dateValue": moment(reg_to + " 23:59:59").format('x')
          }
        )
      }

      if(this.searchCtrlPromo.value != null){
        param.payment["filterAnd"].push(
          {
            "field": "activePromotionCodes",
            "operator": "eq",
            "value": this.searchCtrlPromo.value
          }
        )
      }

      if(this.searchCtrlPay.value != null){
        param.payment["filterAnd"].push(
          {
            "field": "subscriptionStatus",
            "operator": "like",
            "value": this.searchCtrlPay.value
          }
        )
      }

      if(this.searchCtrlType.value != null){
        param.payment["filterAnd"].push(
          {
            "field": "subscriptionType",
            "operator": "eq",
            "value": this.searchCtrlType.value
          }
        )
      }

      if(this.searchCtrlExp_from.value != null){
        let exp_to = moment(this.searchCtrlExp_to.value).format('MM-DD-YYYY')
        param.payment["filterAnd"].push(
          {
            "field": "subscriptionExpiration",
            "operator": "gte",
            "dateValue": moment(this.searchCtrlExp_from.value).format('x')
          }
        ),
        param.payment["filterAnd"].push(
          {
            "field": "subscriptionExpiration",
            "operator": "lte",
            "dateValue": moment(exp_to + " 23:59:59").format('x')
          }
        )
      }

      if(this.searchCtrlRequest.value != null){
        let tmpField
        this.searchCtrlRequest.value == 'PAUSED' ? tmpField ='subscriptionStatusRequest' : tmpField ='subscriptionExpirationPendingStatus'
        param.payment["filterAnd"].push(
          {
            "field": tmpField,
            "operator": "like",
            "value": this.searchCtrlRequest.value
          }
        )
      }

      console.debug('param prima della chiamata', param)
      this.restService.postService('users', '/full/search?ALL_LANGUAGES=true', param).subscribe({
        next: (ris)=>{
          this.lunghezza = ris.totalItems
          this.users = ris["items"];
          console.debug('utenti', ris.items)
          this.dataSource.data = this.users;
          this.table ? this.table.renderRows() : null;
          this.guiService.showSpinner = false;
        },error: (e)=>{
          setTimeout(()=>this.guiService.showSpinner = false,1500)
        }
      })
  }

  getPromos(){
    let param = {
      page: 0,
      size: 100
    }
    this.restService.postService('promotions', '/search', param).subscribe((res)=>{
      this.promotions = res.items
      console.debug(res)
    })
  }

  resetPage(){
    this.pageNumber = 0
  }

  checkUser(userId){
    this.checkedUsers.indexOf(userId) > -1 ? this.checkedUsers.splice(this.checkedUsers.indexOf(userId), 1) : this.checkedUsers.push(userId)
    console.debug(this.checkedUsers)
  }

  checkOndemandPromotion(functionalityPromotions, planId){
    return functionalityPromotions.find(x => x.code === 'ONDEMAND') && functionalityPromotions.find(x => x.code === 'ONDEMAND').subscriptionIds && functionalityPromotions.find(x => x.code === 'ONDEMAND').subscriptionIds.includes(planId)
    ? true : false;
  }

  assignPromotion(){
    if(this.checkedUsers && this.checkedUsers.length > 0){
      const body = {
        usersId: this.checkedUsers,
        functionalityPromotionId: "66c49c8c8a082f7b0c9ecbf6"
      }
      setTimeout(()=>this.guiService.showSpinner = true,0)
      this.restService.postService('users','/functionality-promotion/activate', body).subscribe((ris)=>{
        this.guiService.openSnackBar(this.translate.instant('GLOBAL.EDIT_SUCCESS'), "OK!")
        this.checkedUsers = [];
        this.guiService.showSpinner = false;
        this.getUsers()
      })
    }
  }
}
