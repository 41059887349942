import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { WellnessService } from 'src/app/services/wellness.service';
import VidyardEmbed from '@vidyard/embed-code';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'vex-on-demand-video-detail',
  templateUrl: './on-demand-video-detail.component.html',
  styleUrls: ['./on-demand-video-detail.component.scss']
})
export class OnDemandVideoDetailComponent implements OnInit {
  video: any;
  categoryName: string;
  currentLang: string;
  parsedPdf

  constructor(public wellnessSrv: WellnessService,public translate: TranslateService, private router: Router,private httpClient: HttpClient) { }

  ngOnInit(): void {
    this.currentLang = this.translate.currentLang.toUpperCase()
    this.video = this.wellnessSrv.videoSelected;
    this.categoryName = this.wellnessSrv.categoryName;
    this.parsedPdf = this.video.pdf ? JSON.parse(this.video.pdf) : null;
    if(!this.video){
      this.router.navigate(['main/ondemand'])
    }
  }


  showVideo() {
    const placeholderImage = document.querySelector('img.vidyard-player-embed');
    placeholderImage.setAttribute('src', `https://play.vidyard.com/${this.video.i18nvideoId[this.currentLang]}.jpg`)
    placeholderImage.setAttribute('data-uuid', this.video.i18nvideoId[this.currentLang])

    let ref = this;

    VidyardEmbed.api.renderDOMPlayers()
    const players = VidyardEmbed.api.getPlayersByUUID(this.video.i18nvideoId[this.currentLang]);
    const playerIndex = players.length - 1
    console.debug(players)
    players[playerIndex].ready();
    players[playerIndex].on('ready', function () {
      console.debug('PLAYER READY')
      players[playerIndex].play()
    })

    players[playerIndex].on('play', function () {
      console.debug('PLAYER IN PLAY')
      setTimeout(() => {
        players[playerIndex].toggleFullscreen()
      }, 1000);

    })

    players[playerIndex].on('pause', function () {
      console.debug('PLAYER PAUSED')
    })

    players[playerIndex].on()
  }

  goToUpgrade() {
    this.router.navigate(['auth/upgrade-plan'])
  }

  backButton(){
    this.router.navigate(['main/ondemand'])
  }

  downloadPdf(){
    return this.httpClient.get(this.parsedPdf[this.currentLang], {
      responseType: 'arraybuffer',
      headers: new HttpHeaders().append('Content-Type', 'application/pdf'),
    }).subscribe((ris)=> {
      const file = new Blob([ris], {type: 'application/pdf'});
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, '_blank', 'width=1000, height=800');
    });
  }
}
