import { TranslateService } from '@ngx-translate/core';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'vex-change-program-dialog',
  templateUrl: './change-program-dialog.component.html',
  styleUrls: ['./change-program-dialog.component.scss']
})
export class ChangeProgramDialogComponent implements OnInit {
  programName: any;

  constructor(private dialogRef: MatDialogRef<any>, public translate: TranslateService, @Inject(MAT_DIALOG_DATA) private item: any['item']) { }

  ngOnInit(): void {
    console.debug('Voglio cambiare il programma in ',this.item)
    this.programName = this.item
  }

  changeProgram(){
    this.dialogRef.close()
  }

  close(){
    this.dialogRef.close('true')
  }

}
