import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { WellnessService } from 'src/app/services/wellness.service';

@Component({
  selector: 'vex-on-demand-video-list',
  templateUrl: './on-demand-video-list.component.html',
  styleUrls: ['./on-demand-video-list.component.scss']
})
export class OnDemandVideoListComponent implements OnInit {
  videos: any;
  categoryName: string;
  currentLang: string;

  constructor(public wellnessSrv: WellnessService, private router: Router, public translate: TranslateService) { }

  ngOnInit(): void {
    this.currentLang = this.translate.currentLang.toUpperCase()
    this.videos = this.wellnessSrv.videoSelected;
    this.categoryName = this.wellnessSrv.categoryName;
    if(!this.videos){
      this.router.navigate(['main/ondemand'])
    }
  }

  backButton(){
    this.router.navigate(['main/ondemand'])
  }

  videoDetail(video, categoryName, areaBack) {
    this.wellnessSrv.videoSelected = video;
    this.wellnessSrv.categoryName = categoryName;
    this.wellnessSrv.macroAreaBack = areaBack;

    setTimeout(() => {
       this.router.navigate(['main/ondemand-video-detail'])
    }, 200);
  }

}
