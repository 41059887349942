<div *ngIf="!selectedCategory">
  <h1>{{"WELLNESS.ON_DEMAND" | translate}}</h1>
  <div class="container mt-5e">
    <div class="banner" *ngIf="banner">
      <div *ngFor="let video of banner.videos; let k = index">
        <div class="banner-slide" *ngIf="currentBannerSlide === k"
          [style.background-image]="'url('+'https://play.vidyard.com/'+video.i18nvideoId[translate.currentLang.toUpperCase()]+'.jpg)'">
          <button class="btn-prev" *ngIf="currentBannerSlide > 0" (click)="changeSlide(k-1)"><mat-icon
              svgIcon="mat:arrow_back_ios"></mat-icon></button>
          <button class="btn-start">{{"WELLNESS.START_NOW" | translate}}</button>
          <button class="btn-next" *ngIf="currentBannerSlide < banner.videos.length-1"
            (click)="changeSlide(k+1)"><mat-icon svgIcon="mat:arrow_forward_ios"></mat-icon></button>
          <div class="slide-counter">
            <span *ngFor="let slide of banner.videos; let f = index"
              [class.current-slide]="f === currentBannerSlide"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="contenitore-categories">
      <div class="categories" *ngFor="let item of categories" (click)="openCategory(item)">
        <p>{{item.i18nName[translate.currentLang.toUpperCase()]}}</p>
      </div>
    </div>
  </div>
</div>

<div *ngIf="selectedCategory" class="contenitore-sub">
  <h1>
    <span class="back-button" *ngIf="selectedCategory" (click)="backButton()">
      <mat-icon svgIcon="mat:arrow_back_ios"></mat-icon>
    </span>
    {{selectedCategory.i18nName[translate.currentLang.toUpperCase()]}}
  </h1>
  <div class="slides-main" style="margin-bottom: 26px;">
    <div *ngFor="let video of selectedCategory.videos; let i = index" style="position: relative;" class="sub-video"
      [style.margin-right]="'15px'">
      <span *ngIf="i < 2"
        (click)="videoDetail(video,selectedCategory.i18nName[translate.currentLang.toUpperCase()],selectedCategory)">
        <div class="video-area">
          <img *ngIf="video.i18nvideoId && video.i18nvideoId[translate.currentLang.toUpperCase()]"
            class="vidyard-player-embed"
            [src]="'https://play.vidyard.com/'+video.i18nvideoId[translate.currentLang.toUpperCase()]+'.jpg'" data-v="4"
            data-type="inline" />
        </div>
        <p>{{video.i18nName && video.i18nName[translate.currentLang.toUpperCase()] ?
          video.i18nName[translate.currentLang.toUpperCase()] : null}}</p>
        <div class="locked" *ngIf="video.videoBlocked">
          <img src="../../../assets/img/Lucchetto.png" alt="" class="lock-icon">
        </div>
      </span>
      <span *ngIf="selectedCategory.videos.length > 2 && i === 2" class="more-video-container"
        (click)="showMoreVideos(selectedCategory, selectedCategory.i18nName[translate.currentLang.toUpperCase()], selectedCategory.videos)">
        <div style="height: 130px; width: 228px;">
        </div>
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px"
            fill="currentColor">
            <path
              d="m490-439 212-141-212-141v282Zm193 241h123q0 21-15.5 35.5T753-146L213-81q-25 3-44.5-11.5T146-132L82-630q-3-25 12.07-44.96Q109.14-694.93 134-698l64-7v61l-57 6 65 498 477-58Zm-365-60q-24 0-42-18t-18-42v-502q0-24 18-42t42-18h502q24 0 42 18t18 42v502q0 24-18 42t-42 18H318Zm0-60h502v-502H318v502Zm251-251ZM206-140Z" />
          </svg>
        </span>
        <p>{{"WELLNESS.MORE_VIDEOS" | translate}}</p>
      </span>
    </div>
  </div>
  <div *ngFor="let item of subCategories">
    <h2 *ngIf="item.parentId === selectedCategory.id">{{item.name}}</h2>
    <div *ngIf="item.parentId === selectedCategory.id" style="margin: 26px 0;">
      <div *ngFor="let video of item.videos; let i = index" style="position: relative;" class="sub-video"
        [style.margin-right]="'15px'">
        <span *ngIf="i < 2"
          (click)="videoDetail(video,selectedCategory.i18nName[translate.currentLang.toUpperCase()],selectedCategory)">
          <div class="video-area">
            <img *ngIf="video.i18nvideoId && video.i18nvideoId[translate.currentLang.toUpperCase()]"
              class="vidyard-player-embed"
              [src]="'https://play.vidyard.com/'+video.i18nvideoId[translate.currentLang.toUpperCase()]+'.jpg'"
              data-v="4" data-type="inline" />
          </div>
          <p>{{video.name}}</p>
          <div class="locked" *ngIf="video.videoBlocked">
            <img src="../../../assets/img/Lucchetto.png" alt="" class="lock-icon">
          </div>
        </span>

        <span *ngIf="selectedCategory.videos.length > 2 && i === 2" class="more-video-container"
          (click)="showMoreVideos(selectedCategory, item.i18nName[translate.currentLang.toUpperCase()],item.videos)">
          <div style="height: 130px; width: 228px;">
          </div>
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px"
              fill="currentColor">
              <path
                d="m490-439 212-141-212-141v282Zm193 241h123q0 21-15.5 35.5T753-146L213-81q-25 3-44.5-11.5T146-132L82-630q-3-25 12.07-44.96Q109.14-694.93 134-698l64-7v61l-57 6 65 498 477-58Zm-365-60q-24 0-42-18t-18-42v-502q0-24 18-42t42-18h502q24 0 42 18t18 42v502q0 24-18 42t-42 18H318Zm0-60h502v-502H318v502Zm251-251ZM206-140Z" />
            </svg>
          </span>
          <p>{{"WELLNESS.MORE_VIDEOS" | translate}}</p>
        </span>
      </div>
    </div>
  </div>
</div>
