import { GuiService } from 'src/app/services/gui.service';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'vex-pay-modal',
  templateUrl: './pay-modal.component.html',
  styleUrls: ['./pay-modal.component.scss']
})
export class PayModalComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<any>, public guiService: GuiService) { }

  ngOnInit(): void {
  }

  method(param){
    this.dialogRef.close(param)
  }

}
