<div class="container">
  <h1 *ngIf="guiService.userLogged && guiService.userLogged.masterData">{{"USER_TRANS.HI" | translate}} {{guiService.userLogged.masterData.name}}!</h1>
  <div class="week_title">
    <div class="w-70"></div>
      <form [formGroup]="form" *ngIf="risposta">
        <mat-form-field appearance="fill" id="homeMat">
          <mat-select formControlName="weekControl" (selectionChange)="changeWeek()">
            <mat-option *ngFor="let week of risposta.weeks; let i = index" [value]="i">
              {{"week "+i}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        </form>
    </div>
  <div class="week">

    <div class="thunders" *ngIf="guiService.userLogged" [class.thunder-opacity]="showThunders == false">
      <span class="thunderbolt"  *ngFor="let day of days; let i = index" (click)="selectSlide(i)">
        <div [class.current_day]="guiService.userLogged.lastWorkoutProgram.day == i" [class.passed_day]="isExecutedDay(i)">
          <span class="material-symbols-outlined">
            flash_on
            </span>
        </div>
        <p [class.current_day]="guiService.userLogged.lastWorkoutProgram.day == i">day {{i+1}}</p>
      </span>
    </div>
    <div *ngIf="showThunders == false" class="thunder-spinner">
      <fa-icon class="flash" [icon]="icon.faEll"></fa-icon>
    </div>
  </div>

  <h2>{{"USER_TRANS.D_WORK" | translate}}</h2>
  <div class="w_daily" *ngIf="selectedWeek" [class]="evaluateBgColor(i)" [style.background-image]="'url('+environment+'/'+selectedWeek['workoutIdDay'+(i+1)]+'/images/main)'" [class.rest]="selectedWeek['workoutIdDay'+(i+1)] == null">
    <button (click)='getPrev()'><mat-icon svgIcon="mat:arrow_back_ios"></mat-icon></button>
    <div class="carousel-inner"  role="listbox" (click)="programDetails()">
      <span class="w-title" *ngIf="selectedWeek['workoutIdDay'+(i+1)] != null">{{filteredIds[selectedWeek["workoutIdDay"+(i+1)]]}}</span>
      <span *ngIf="selectedWeek['workoutIdDay'+(i+1)] == null" class="w-title restTitle">rest</span>
    </div>
    <button (click)='getNext()'><mat-icon svgIcon="mat:arrow_forward_ios"></mat-icon></button>
  </div>

  <h2>{{"USER_TRANS.W_WORK" | translate}}</h2>
  <div class="w_oftheweek">
    <div class="days" *ngIf="guiService.userLogged && selectedWeek">
      <span><fa-icon [icon]="icon.faCalendar"></fa-icon>{{"WORKOUT-PROG.DAY1" | translate}}: {{selectedWeek["workoutIdDay1"]?filteredIds[selectedWeek["workoutIdDay1"]]:'REST'}}</span>
      <span><fa-icon [icon]="icon.faCalendar"></fa-icon>{{"WORKOUT-PROG.DAY2" | translate}}: {{selectedWeek["workoutIdDay2"]?filteredIds[selectedWeek["workoutIdDay2"]]:'REST'}}</span>
      <span><fa-icon [icon]="icon.faCalendar"></fa-icon>{{"WORKOUT-PROG.DAY3" | translate}}: {{selectedWeek["workoutIdDay3"]?filteredIds[selectedWeek["workoutIdDay3"]]:'REST'}}</span>
      <span><fa-icon [icon]="icon.faCalendar"></fa-icon>{{"WORKOUT-PROG.DAY4" | translate}}: {{selectedWeek["workoutIdDay4"]?filteredIds[selectedWeek["workoutIdDay4"]]:'REST'}}</span>
      <span><fa-icon [icon]="icon.faCalendar"></fa-icon>{{"WORKOUT-PROG.DAY5" | translate}}: {{selectedWeek["workoutIdDay5"]?filteredIds[selectedWeek["workoutIdDay5"]]:'REST'}}</span>
      <span><fa-icon [icon]="icon.faCalendar"></fa-icon>{{"WORKOUT-PROG.DAY6" | translate}}: {{selectedWeek["workoutIdDay6"]?filteredIds[selectedWeek["workoutIdDay6"]]:'REST'}}</span>
      <span><fa-icon [icon]="icon.faCalendar"></fa-icon>{{"WORKOUT-PROG.DAY7" | translate}}: {{selectedWeek["workoutIdDay7"]?filteredIds[selectedWeek["workoutIdDay7"]]:'REST'}}</span>
    </div>
  </div>

</div>
