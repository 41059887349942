import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'vex-days-change-week',
  templateUrl: './days-change-week.component.html',
  styleUrls: ['./days-change-week.component.scss']
})
export class DaysChangeWeekComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<any>, public translate: TranslateService) { }

  ngOnInit(): void {
  }

  changeWeek(){
    this.dialogRef.close()
  }

  close(){
    this.dialogRef.close('true')
  }
}
