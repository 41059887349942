import { GuiService } from 'src/app/services/gui.service';
import { RestService } from 'src/app/services/rest.service';
import { formatDate } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { toDate } from 'date-fns';
import { PromotionsComponent } from '../promotions.component';
import { CloseConfirmComponent } from '../../close-confirm/close-confirm.component';
import moment from 'moment';

@Component({
  selector: 'vex-promotion-edit',
  templateUrl: './promotion-edit.component.html',
  styleUrls: ['./promotion-edit.component.scss']
})
export class PromotionEditComponent implements OnInit {
  form: UntypedFormGroup

  promozione: any
  data
  subTypes: any;
  subDuration: any;
  subscriptions: any;
  couponInMockApi: any;

  constructor(private fb: UntypedFormBuilder, private dialogRef: MatDialogRef<PromotionsComponent>, @Inject(MAT_DIALOG_DATA) private item: any['item'], public translate: TranslateService, public restService: RestService, public guiService: GuiService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      codice: [null, Validators.required],
      validfrom: [null, Validators.required],
      validto: [null],
      quantita: [null],
      percentuale: [null],
      giorni: [null],
      prezzo: [null],
      sub: [null],
      durationInCycles: [null],
      resetTrial: [null],
      sub_duration: [null]
    })

    this.promozione = this.item
    console.debug('modifica promo', this.item)
    this.getSubs()

    let tmpFrom = moment(this.promozione.startDate)
    let tmpTo = moment(this.promozione.endDate)
    let offSet = moment().utcOffset()

    if (offSet < 0) {
      tmpFrom = tmpFrom.add(Math.abs(offSet), 'minutes')
      tmpTo = tmpTo.add(Math.abs(offSet), 'minutes')
    } else {
      tmpFrom = tmpFrom.subtract(Math.abs(offSet), 'minutes')
      tmpTo = tmpTo.subtract(Math.abs(offSet), 'minutes')
    }
    console.debug('oninit', tmpFrom)
    console.debug('oninit', tmpTo)

    this.form.controls['codice'].setValue(this.promozione.code);
    this.form.controls['validfrom'].setValue(tmpFrom.format("YYYY") + "-" + tmpFrom.format("MM") + "-" + tmpFrom.format("DD"));
    if (this.promozione.endDate) {
      this.form.controls['validto'].setValue(tmpTo.format("YYYY") + "-" + tmpTo.format("MM") + "-" + tmpTo.format("DD"));
    }
    this.form.controls['quantita'].setValue(this.promozione.quantity);
    this.form.controls['percentuale'].setValue(this.promozione.percentage);
    this.form.controls['giorni'].patchValue(this.promozione.freeDays);
    this.form.controls['prezzo'].patchValue(this.promozione.amountOff ? this.promozione.amountOff.price : null);
    this.form.controls['sub'].patchValue(this.promozione.subscriptionTypeRetrictions ? this.promozione.subscriptionTypeRetrictions : null);
    this.form.controls['durationInCycles'].setValue(this.promozione.durationInCycles);
    this.form.controls['resetTrial'].setValue(this.promozione.resetTrialDays);
  }

  submit(form) {
    let tmpFrom = moment(this.form.value.validfrom)
    let tmpTo = moment(this.form.value.validto)
    let offSet = moment().utcOffset()

    console.debug("OFFSET", offSet);
    /* if(offSet < 0){
       tmpFrom = tmpFrom.add(Math.abs(offSet),'minutes')
       tmpTo = tmpTo.add(Math.abs(offSet),'minutes')
     }else{
       tmpFrom = tmpFrom.subtract(Math.abs(offSet),'minutes')
       tmpTo = tmpTo.subtract(Math.abs(offSet),'minutes')
     }*/
    console.debug('dopo', tmpFrom)
    console.debug('dopo', tmpTo)

    let editPromo = {
      code: this.form.value.codice,
      comulable: false,
      description: "",
      endDate: this.form.value.validto ? tmpTo.format("YYYY") + "-" + tmpTo.format("MM") + "-" + tmpTo.format("DD") : null,
      freeDays: this.form.value.giorni,
      i18nDescription: {},
      i18nName: {},
      image: "",
      imageFileName: "",
      name: "",
      percentage: this.form.value.percentuale,
      amountOff: { currency: "$", price: this.form.value.prezzo },
      repeatable: false,
      startDate: tmpFrom.format("YYYY") + "-" + tmpFrom.format("MM") + "-" + tmpFrom.format("DD"),
      quantity: this.form.value.quantita,
      subscriptionTypeRetrictions: this.form.value.sub,
      resetTrialDays: this.form.value.resetTrial,
      durationInCycles: this.form.value.durationInCycles
    }
    this.restService.putService('promotions', '/' + this.item.id + '?ALL_LANGUAGES=true', editPromo).subscribe((ris) => {
      this.couponInMockApi ? this.editCouponInMockApi() : this.setCouponInMockApi()
      this.dialogRef.close('done')
      this.guiService.openSnackBar(ris.name + this.translate.instant('GLOBAL.EDIT_SUCCESS'), this.translate.instant('GLOBAL.CLOSE'))
    })
  }

  getSubType() {
    this.restService.getService('bos', 'subscription-type').subscribe((ris) => {
      console.debug('tipo sub', ris);
      this.subTypes = ris;
      this.getSubDuration()

    })
  }

  getSubs() {
    setTimeout(() => this.guiService.showSpinner = true, 0)
    this.restService.postService('subscriptions', '/search', { page: 0, size: 30 }).subscribe((ris) => {
      console.debug('subscriptions', ris.items)
      this.subscriptions = ris.items
      this.getSubType()

    })
  }

  getSubDuration() {
    this.restService.getService('bos', 'subscription-duration').subscribe((ris) => {
      console.debug('subscription-duration', ris)
      this.subDuration = ris
      this.getCouponInMockApi()
    })
  }


  getCouponInMockApi() {
    this.restService.getService('mock', 'promos').subscribe((ris) => {
      this.couponInMockApi = ris.find(x => x.code === this.promozione.code)
      if (this.couponInMockApi) {
        let tmp = []
        for (let sub of this.subscriptions) {
          if (this.couponInMockApi.identifier.includes(sub.id)) {
            tmp.includes(sub.duration) ? null : tmp.push(sub.duration)
          }
        }
        this.form.controls['sub_duration'].setValue(tmp)
      }

      this.guiService.showSpinner = false
    })
  }

  editCouponInMockApi() {
    if (this.form.value.sub_duration && this.form.value.sub_duration.length > 0) {
      this.couponInMockApi.identifier = [];
      for (let sub of this.subscriptions) {
        if (this.form.value.sub.includes(sub.type)) {
          if (this.form.value.sub_duration.includes(sub.duration)) {
            this.couponInMockApi.identifier.push(sub.id)
          }
        }
      }
      console.debug(this.couponInMockApi)

      this.restService.putService('mock', 'promos' + '/' + this.couponInMockApi.id, this.couponInMockApi).subscribe((ris) => {
        this.guiService.openSnackBar('Restrizioni modificate su mock!', this.translate.instant('GLOBAL.CLOSE'))
      })
    } else if (!this.form.value.sub_duration || this.form.value.sub_duration && this.form.value.sub_duration.length == 0) {
      this.restService.deleteService('mock', 'promos' + '/' + this.couponInMockApi.id, this.couponInMockApi).subscribe((ris) => {
        this.guiService.openSnackBar('Restrizioni eliminate da mock!', this.translate.instant('GLOBAL.CLOSE'))
      })
    }

  }

  setCouponInMockApi() {
    if (this.form.value.sub_duration && this.form.value.sub_duration.length > 0) {
      let tmpItem = {
        "code": this.form.value.codice,
        "identifier": []
      }

      for (let sub of this.subscriptions) {
        if (this.form.value.sub.includes(sub.type)) {
          if (this.form.value.sub_duration.includes(sub.duration)) {
            tmpItem.identifier.push(sub.id)
          }
        }
      }
      console.debug(tmpItem)
      this.restService.postService('mock', 'promos', tmpItem).subscribe((ris) => {
        this.guiService.openSnackBar('Restrizioni impostate su mock!', this.translate.instant('GLOBAL.CLOSE'))
      })
    }

  }

  confirmClose() {
    this.dialog.open(CloseConfirmComponent, {
      disableClose: true,
      hasBackdrop: true,
      panelClass: 'close-dialog-modalbox'
    }).afterClosed().subscribe((ris) => {
      if (ris == 'true') {
        this.dialogRef.close()
      }
    })
  }
}
