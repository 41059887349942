import { ConsoleComponent } from './admin/console/console.component';
import { AppSelectionModalComponent } from './auth/register/app-selection-modal/app-selection-modal.component';
import { RegisterComponent } from './auth/register/register.component';
import { PaypalButtonComponent } from './auth/register/paypal-button/paypal-button.component';
import { TokenInterceptor } from './services/token.interceptor';
import { EditSubComponent } from './admin/subscription/edit-sub/edit-sub.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VexModule } from '../@vex/vex.module';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CustomLayoutModule } from './custom-layout/custom-layout.module';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { DashboardComponent } from './main/dashboard/dashboard.component';
import { DashboardModule } from './main/dashboard/dashboard.module';
import {MatIcon, MatIconModule} from "@angular/material/icon";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SubscriptionNewComponent } from './admin/subscription/new-sub/subscription-new.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { PromotionNewComponent } from './admin/promotion/promotion-new/promotion-new.component';
import { ProgramNewComponent } from './admin/program/program-new/program-new.component';
import { NewExerciseComponent } from './admin/exercise/new-exercise/new-exercise.component';
import { MatSliderModule } from '@angular/material/slider';
import { PromotionsComponent } from './admin/promotion/promotions.component';
import { PromotionsModule } from './admin/promotion/promotions.module';
import { ExerciseComponent } from './admin/exercise/exercise.component';
import { ExerciseModule } from './admin/exercise/exercise.module';
import { ProgramComponent } from './admin/program/program.component';
import { ProgramModule } from './admin/program/program.module';
import { EditExerciseComponent } from './admin/exercise/edit-exercise/edit-exercise.component';
import { WorkoutNewComponent } from './admin/workout/workout-new/workout-new.component';
import { WorkoutComponent } from './admin/workout/workout.component';
import { WorkoutModule } from './admin/workout/workout.module';
import { ProgramEditComponent } from './admin/program/program-edit/program-edit.component';
import { PromotionEditComponent } from './admin/promotion/promotion-edit/promotion-edit.component';
import { DialogConfirmComponent } from './admin/dialog-confirm/dialog-confirm.component';
import { UserEditComponent } from './admin/user/user-edit/user-edit.component';
import { WorkoutEditComponent } from './admin/workout/workout-edit/workout-edit.component';
import {MatNativeDateModule} from "@angular/material/core";
import { QuillModule } from 'ngx-quill';
import {MatGridListModule} from "@angular/material/grid-list";
import {MatChipsModule} from "@angular/material/chips";
import {MatCardModule} from "@angular/material/card";
import {NgxMatSelectSearchModule} from "ngx-mat-select-search";
import {MatExpansionModule} from '@angular/material/expansion';
import { HomeComponent } from './main/home/home.component';
import { InfoComponent } from './main/info/info.component';
import { ProfileComponent } from './main/user/profile/profile.component';
import { UserProfileComponent } from './main/user/user-profile.component';
import { SubComponent } from './main/user/sub/sub.component';
import { NutritionComponent } from './admin/nutrition/nutrition.component';
import { NutritionDetailComponent } from './admin/nutrition/nutrition-detail/nutrition-detail.component';
import { NotificationsComponent } from './main/notifications/notifications.component';
import { AdministratorComponent } from './admin/administrator/administrator.component';
import { AdminProfileComponent } from './admin/administrator/admin-profile/admin-profile.component';
import { AdminNotificationsComponent } from './admin/admin-notifications/admin-notifications.component';
import { NewNotificationComponent } from './admin/admin-notifications/new-notification/new-notification.component';
import { ChallengeComponent } from './admin/challenge/challenge.component';
import { EditChallengeComponent } from './admin/challenge/edit-challenge/edit-challenge.component';
import { NewChallengeComponent } from './admin/challenge/new-challenge/new-challenge.component';
import { UserChallengeComponent } from './main/user-challenge/user-challenge.component';
import { UserNutritionComponent } from './main/user-nutrition/user-nutrition.component';
import { UserWorkoutComponent } from './main/user-workout/user-workout.component';
import { WorkoutExeComponent } from './main/workout-exe/workout-exe.component';
import { PauseDialogComponent } from './main/pause-dialog/pause-dialog.component';
import { WorkoutInfoDialogComponent } from './main/user-workout/workout-info-dialog/workout-info-dialog.component';
import { ChangeProgramDialogComponent } from './main/change-program-dialog/change-program-dialog.component';
import { EditNotificationComponent } from './admin/admin-notifications/edit-notification/edit-notification.component';
import { ResetPasswordComponent } from './main/user/reset-password/reset-password.component';
import { AcceptChallengeDialogComponent } from './main/user-challenge/accept-challenge-dialog/accept-challenge-dialog.component';
import { ResetPswComponent } from './auth/reset-psw/reset-psw.component';
import { MySurveyComponent } from './main/user-nutrition/my-survey/my-survey.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MyDietComponent } from './main/user-nutrition/my-diet/my-diet.component';
import { CustomerListComponent } from './nutritionist/customer-list/customer-list.component';
import { NutriComponent } from './nutritionist/nutri/nutri.component';
import { NutriProfileComponent } from './nutritionist/nutri/nutri-profile/nutri-profile.component';
import { SecurePipePipe } from './services/secure-pipe.pipe';
import { UpgradePlanComponent } from './auth/upgrade-plan/upgrade-plan.component';
import { AdminResetPasswordComponent } from './admin/administrator/admin-reset-password/admin-reset-password.component';
import { NutriResetPasswordComponent } from './nutritionist/nutri/nutri-reset-password/nutri-reset-password.component';
import { ChangeSubstatusModalComponent } from './main/user/sub/change-substatus-modal/change-substatus-modal.component';
import { PaymentRejectedComponent } from './auth/payment-rejected/payment-rejected.component';
import { PayModalComponent } from './main/user/sub/pay-modal/pay-modal.component';
import { FacebookComponent } from './main/facebook/facebook.component';
import { UserProgramComponent } from './main/user-program/user-program.component';
import { UserProgramInfoComponent } from './main/user-program/user-program-info/user-program-info.component';
import { MatSortModule } from '@angular/material/sort';
import { CloseConfirmComponent } from './admin/close-confirm/close-confirm.component';
import { MatDividerModule } from '@angular/material/divider';
import { ReportsComponent } from './admin/reports/reports.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TimeChallengeComponent } from './admin/challenge/time-challenge/time-challenge.component';
import { TimeChallengeDetailComponent } from './admin/challenge/time-challenge/time-challenge-detail/time-challenge-detail.component';
import { RegisterConfirmComponent } from './auth/register-confirm/register-confirm.component';
import { TmpMsgContactComponent } from './main/user/sub/tmp-msg-contact/tmp-msg-contact.component';
import { EmailAlreadyModalComponent } from './auth/register/email-already-modal/email-already-modal.component';
import { ErrorUpgradeModalComponent } from './auth/upgrade-plan/error-upgrade-modal/error-upgrade-modal.component';
import { ConfirmUpgradeModalComponent } from './auth/upgrade-plan/confirm-upgrade-modal/confirm-upgrade-modal.component';
import { RegSubInfoComponent } from './auth/register/reg-sub-info/reg-sub-info.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MsgContactSupportComponent } from './main/user/sub/msg-contact-support/msg-contact-support.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { CategorieComponent } from './admin/categorie/categorie.component';
import { MatTreeModule } from '@angular/material/tree';
import { NewCategoryComponent } from './admin/categorie/new-category/new-category.component';
import { EditCategoryComponent } from './admin/categorie/edit-category/edit-category.component';
import { NewVideoComponent } from './admin/categorie/new-video/new-video.component';
import { EditVideoComponent } from './admin/categorie/edit-video/edit-video.component';
import { AssignOndemandComponent } from './admin/assign-ondemand/assign-ondemand.component';
import { OnDemandComponent } from './main/on-demand/on-demand.component';
import { OnDemandVideoListComponent } from './main/on-demand/on-demand-video-list/on-demand-video-list.component';
import { OnDemandVideoDetailComponent } from './main/on-demand/on-demand-video-detail/on-demand-video-detail.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader (http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent, DashboardComponent, SubscriptionNewComponent, PromotionNewComponent, ProgramNewComponent, NewExerciseComponent, PromotionsComponent, ExerciseComponent, ProgramComponent, EditExerciseComponent, WorkoutNewComponent, WorkoutComponent, ProgramEditComponent, PromotionEditComponent, DialogConfirmComponent, UserEditComponent, WorkoutEditComponent, EditSubComponent, HomeComponent, InfoComponent, ProfileComponent, UserProfileComponent, SubComponent, NutritionComponent, NutritionDetailComponent, NotificationsComponent, AdministratorComponent, AdminProfileComponent, AdminNotificationsComponent, NewNotificationComponent, ChallengeComponent, EditChallengeComponent, NewChallengeComponent, UserChallengeComponent, UserNutritionComponent, UserWorkoutComponent, WorkoutExeComponent, PauseDialogComponent, WorkoutInfoDialogComponent, ChangeProgramDialogComponent, EditNotificationComponent, ResetPasswordComponent, AcceptChallengeDialogComponent, ResetPswComponent, MySurveyComponent, MyDietComponent, CustomerListComponent, NutriComponent, NutriProfileComponent, SecurePipePipe, UpgradePlanComponent, AdminResetPasswordComponent, NutriResetPasswordComponent, ChangeSubstatusModalComponent, PaymentRejectedComponent, PayModalComponent, FacebookComponent, UserProgramComponent, UserProgramInfoComponent, PaypalButtonComponent, CloseConfirmComponent,RegisterComponent, AppSelectionModalComponent, ConsoleComponent, ReportsComponent, TimeChallengeComponent, TimeChallengeDetailComponent, RegisterConfirmComponent, TmpMsgContactComponent, EmailAlreadyModalComponent, ErrorUpgradeModalComponent, ConfirmUpgradeModalComponent, RegSubInfoComponent, MsgContactSupportComponent,CategorieComponent,NewCategoryComponent,EditCategoryComponent,NewVideoComponent,EditVideoComponent, AssignOndemandComponent, OnDemandComponent, OnDemandVideoListComponent, OnDemandVideoDetailComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    QuillModule.forRoot({
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
          ['blockquote', 'code-block'],

          [{ header: 1 }, { header: 2 }],               // custom button values
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ script: 'sub' }, { script: 'super' }],      // superscript/subscript
          [{ indent: '-1' }, { indent: '+1' }],          // outdent/indent
          [{ direction: 'rtl' }],                         // text direction

          [{ size: ['small', false, 'large', 'huge'] }],  // custom dropdown
          [{ header: [1, 2, 3, 4, 5, 6, false] }],

          [{ color: [] }, { background: [] }],          // dropdown with defaults from theme
          [{ align: [] }],

          ['clean'],                                         // remove formatting button

          ['link', 'image', 'video']                         // link and image, video
        ]
      }
    }),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient],

      }
  }),

    NgxMatSelectSearchModule,

    // Vex
    VexModule,
    CustomLayoutModule,
    MatSelectModule,
    MatIconModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatSelectModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatSliderModule,
    PromotionsModule,
    MatMenuModule,
    MatTableModule,
    MatPaginatorModule,
    ExerciseModule,
    MatTabsModule,
    MatTooltipModule,
    ProgramModule,
    MatStepperModule,
    MatRadioModule,
    DragDropModule,
    WorkoutModule,
    DashboardModule,
    MatDialogModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatGridListModule,
    MatChipsModule,
    MatCardModule,
    MatExpansionModule,
    MatSortModule,
    PdfViewerModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    MatButtonToggleModule,
    MatAutocompleteModule,
    MatTreeModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  schemas:[
    CUSTOM_ELEMENTS_SCHEMA
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
