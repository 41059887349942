<div class="container mt-4">
  <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">
    <h2 class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l hidden sm:block flex-none">
      <span *ngIf="selection.isEmpty()">{{"CUSTOMERS" | translate}}</span>
    </h2>
    <div
      class="rounded-full border px-4 max-w-[300px] flex-auto flex items-center border border-gray-300 mr-3">
      <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon>
      <input [formControl]="searchCtrl" class="px-4 py-2 border-0 outline-none w-full bg-transparent" placeholder="{{'SUB_TABLE.S_EMAIL' | translate}}" type="search" />
    </div>
    <div
      class="rounded-full border px-4 max-w-[230px] flex-auto flex items-center border border-gray-300 mr-3">
      <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon>
      <input [formControl]="searchCtrlName" class="px-4 py-2 border-0 outline-none w-full bg-transparent" placeholder="{{'SUB_TABLE.S_NAME' | translate}}" type="search" />
    </div>
    <div
      class="rounded-full border px-4 max-w-[230px] flex-auto flex items-center border border-gray-300 mr-3">
      <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon>
      <input [formControl]="searchCtrlLastName" class="px-4 py-2 border-0 outline-none w-full bg-transparent" placeholder="{{'SUB_TABLE.S_LASTNAME' | translate}}" type="search" />
    </div>
    <button class="ml-4 flex-none ltr:mr-4 ltr:border-r" color="primary" mat-mini-fab type="button" (click)="search();resetPage()">
      <mat-icon svgIcon="mat:search"></mat-icon>
    </button>
    <button (click)="reload()" class="ml-4 flex-none blue-bottone" color="primary" mat-mini-fab matTooltip="reload" type="button" >
      <mat-icon svgIcon="mat:autorenew"></mat-icon>
    </button>
    <button class="ml-4 flex-none" color="primary" mat-mini-fab type="button" (click)="createUser()">
      <mat-icon svgIcon="mat:person_add"></mat-icon>
    </button>
    <button class="ml-4 flex-none" color="primary" mat-mini-fab type="button" (click)="exportCsv()">
      <mat-icon svgIcon="mat:cloud_download"></mat-icon>
    </button>

     <!-- <button class="ml-4 flex-none" color="primary" mat-mini-fab type="button" (click)="usersRequestCsv()">
      <mat-icon svgIcon="mat:assignment_returned"></mat-icon>
    </button> -->
    <span class="flex-1"></span>

    <!-- <button class="ml-4 flex-none" mat-icon-button matTooltip="Filter Columns" type="button">
      <mat-icon svgIcon="mat:filter_list"></mat-icon>
    </button> -->

  </div>

  <div class="bg-app-bar px-6 border-b sticky left-0 flex items-center bg2">
    <div class="flex items-center filter-bar">
      <nav class="vex-tabs border-0 flex-auto cerca" mat-tab-nav-bar>
        <mat-form-field class="nut-form">
          <mat-label class="label-light">{{"SUB_TABLE.REG" | translate}}</mat-label>

          <mat-date-range-input [rangePicker]="picker">
            <input matStartDate placeholder="M/DD/YYYY" [formControl]="searchCtrlReg_from">
            <input matEndDate placeholder="M/DD/YYYY" [formControl]="searchCtrlReg_to">
          </mat-date-range-input>

          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
        <mat-form-field class="nut-form">
          <mat-label class="label-light">{{"SUB_TABLE.TYPE" | translate}}</mat-label>
          <mat-select [formControl]="searchCtrlType">
            <mat-option [value]="'GOLD'">GOLD</mat-option>
            <mat-option [value]="'DIAMOND'">DIAMOND</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="nut-form">
          <mat-label class="label-light">{{"SUB_TABLE.PAY" | translate}}</mat-label>
          <mat-select [formControl]="searchCtrlPay">
            <mat-option [value]="'ACTIVE'">{{"STATUS.PAYED" | translate}}</mat-option>
            <mat-option [value]="'PAUSED'">{{"STATUS.PAUSED" | translate}}</mat-option>
            <mat-option [value]="'SUSPENDED'">{{"STATUS.NOT_PAYED" | translate}}</mat-option>
            <mat-option [value]="'CUST_CANCELED'">{{"STATUS.CANCELED" | translate}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="nut-form">
          <mat-label class="label-light">{{"SUB_TABLE.EXP" | translate}}</mat-label>
          <mat-date-range-input [rangePicker]="picker2">
            <input matStartDate placeholder="M/DD/YYYY" [formControl]="searchCtrlExp_from">
            <input matEndDate placeholder="M/DD/YYYY" [formControl]="searchCtrlExp_to">
          </mat-date-range-input>

          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-date-range-picker #picker2></mat-date-range-picker>
        </mat-form-field>
        <mat-form-field class="nut-form">
          <mat-label class="label-light">{{"SUB_TABLE.S_PROMO" | translate}}</mat-label>
          <mat-select [formControl]="searchCtrlPromo">
            <mat-option [value]="promo.code" *ngFor="let promo of promotions">{{promo.code}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="nut-form">
          <mat-label class="label-light">{{"SUB_TABLE.REQUEST" | translate}}</mat-label>
          <mat-select [formControl]="searchCtrlRequest">
            <mat-option [value]="'PAUSED'">{{"SUB_TABLE.PAUSE" | translate}}</mat-option>
            <mat-option [value]="'CUST_CANCELED'">{{"SUB_TABLE.CANC" | translate}}</mat-option>
          </mat-select>
        </mat-form-field>
      </nav>

    </div>
  </div>

  <table mat-table [dataSource]="dataSource" *ngIf="users" matSort>
    <ng-container matColumnDef="imported">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let user" class="logo-td">
        <span *ngIf="user.user.imported == true"><mat-icon svgIcon="mat:swap_vert" matTooltip="memberpress"></mat-icon></span>
        <br>
        <span *ngIf="user.userPaymentInfo && user.userPaymentInfo.paymentType != null">
          <span *ngIf="user.userPaymentInfo.paymentType == 'CHARGEBEE' && user.userPaymentInfo.chargebeePaymentInfo && user.userPaymentInfo.chargebeePaymentInfo.customerId">
            <a [href]="'https://itsdesifit.chargebee.com/d/customers/'+user.userPaymentInfo.chargebeePaymentInfo.customerId" target="_blank">
              <img *ngIf="user.userPaymentInfo.paymentSubtype == null" src="../../../assets/img/icons/logos/chargebee.svg" class="gw-logos">
              <img *ngIf="user.userPaymentInfo.paymentSubtype == 'APPLE'" src="../../../assets/img/icons/logos/apple.svg" class="gw-logos">
            </a>
          </span>

          <span *ngIf="user.userPaymentInfo.paymentType == 'STRIPE'">
            <a [href]="'https://dashboard.stripe.com/subscriptions/'+user.userPaymentInfo.stripePaymentInfo.subscription" target="_blank">
              <img src="../../../assets/img/icons/logos/stripe.png" class="gw-logos">
            </a>
          </span>
          <span *ngIf="user.userPaymentInfo.paymentType == 'PAYPAL'">
            <a *ngIf="user.userPaymentInfo.paypalPaymentInfo && user.userPaymentInfo.paypalPaymentInfo.subscriptionId" [href]="'https://www.paypal.com/billing/subscriptions/'+user.userPaymentInfo.paypalPaymentInfo.subscriptionId" target="_blank">
              <img src="../../../assets/img/icons/logos/paypal.png" class="gw-logos">
            </a>
          </span>
        </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="utente">
      <th mat-header-cell *matHeaderCellDef mat-sort-header (click)="searchAltFlag == false ? sortParam('email', asc == true ? false : true) : search('email', asc == true ? false : true)">{{"SUB_TABLE.USER" | translate}}</th>
      <td mat-cell *matCellDef="let user"><span class="name_lastname">  {{ user.masterData.name }} {{ user.masterData.lastName }}</span><br><span class="mail">{{ user.user.email }}</span> </td>
    </ng-container>
    <ng-container matColumnDef="data-iscrizione">
      <th mat-header-cell *matHeaderCellDef mat-sort-header (click)="searchAltFlag == false ? sortParam('registrationDate', asc == true ? false : true) : search('registrationDate', asc == true ? false : true)">{{"CUST_TABLE.DATE" | translate}}</th>
      <td mat-cell *matCellDef="let user">{{ user.masterData.registrationDate | date: 'shortDate'}}</td>
    </ng-container>
    <ng-container matColumnDef="tipo-sub">
      <th mat-header-cell *matHeaderCellDef mat-sort-header (click)="searchAltFlag == false ? sortParam('subscriptionType', asc == true ? false : true) : search('subscriptionType', asc == true ? false : true)">{{"CUST_TABLE.TYPE" | translate}}</th>
      <td mat-cell *matCellDef="let user"><span >{{ user.userPaymentInfo != null && user.userPaymentInfo.subscriptionType != null? user.userPaymentInfo.subscriptionType : 'N/A' }}</span></td>
    </ng-container>
    <ng-container matColumnDef="status-abbonamento">
      <th mat-header-cell *matHeaderCellDef mat-sort-header (click)="searchAltFlag == false ? sortParam('subscriptionStatus', asc == true ? false : true) : search('subscriptionStatus', asc == true ? false : true)">{{"CUST_TABLE.SUB_STATUS" | translate}}</th>
      <td mat-cell *matCellDef="let user" >
        <span *ngIf="user.userPaymentInfo && user.userPaymentInfo.subscriptionStatus == null || user.userPaymentInfo == null">
          N/A
        </span>
        <span *ngIf="user.userPaymentInfo && user.userPaymentInfo.subscriptionStatus != null && user.userPaymentInfo.subscriptionStatus == 'ACTIVE'">
          <fa-icon [icon]="icon.faCircle" class="icon payed"></fa-icon>
          {{"USER-EDIT.ACTIVE" | translate}}
          <small *ngIf="user.userPaymentInfo.subscriptionExpirationPendingStatus == 'PAUSED' || user.userPaymentInfo.subscriptionStatusRequest == 'PAUSED'">({{"USER-EDIT.NEXT" | translate}}: {{"USER-EDIT.PAUSED" | translate}})</small>
          <small *ngIf="user.userPaymentInfo.subscriptionExpirationPendingStatus == 'SUSPENDED' || user.userPaymentInfo.subscriptionStatusRequest == 'SUSPENDED'">({{"USER-EDIT.NEXT" | translate}}: {{"USER-EDIT.CUST_CANCELED" | translate}})</small>
          <small *ngIf="user.userPaymentInfo.subscriptionExpirationPendingStatus == 'CUST_CANCELED' || user.userPaymentInfo.subscriptionStatusRequest == 'CUST_CANCELED'">({{"USER-EDIT.NEXT" | translate}}: {{"USER-EDIT.CUST_CANCELED" | translate}})</small>
        </span>
        <span *ngIf="user.userPaymentInfo && user.userPaymentInfo.subscriptionStatus != null && user.userPaymentInfo.subscriptionStatus == 'SUSPENDED'">
          <fa-icon [icon]="icon.faCircle" class="icon not-payed"></fa-icon>
          {{"USER-EDIT.SUSPENDED" | translate}}
        </span>
        <span *ngIf="user.userPaymentInfo && user.userPaymentInfo.subscriptionStatus != null && user.userPaymentInfo.subscriptionStatus == 'CUST_CANCELED'">
          <fa-icon [icon]="icon.faCircle" class="icon not-payed"></fa-icon>
          {{"USER-EDIT.CUST_CANCELED" | translate}}
        </span>
        <span *ngIf="user.userPaymentInfo && user.userPaymentInfo.subscriptionStatus != null && user.userPaymentInfo.subscriptionStatus == 'PAUSED'">
          <fa-icon [icon]="icon.faCircle" class="icon paused"></fa-icon>
          {{"USER-EDIT.PAUSED" | translate}}
        </span>
        <!-- {{ user.userPaymentInfo != null && user.userPaymentInfo.subscriptionStatus != null ? icons[user.userPaymentInfo.subscriptionStatus]  : 'N/A' }} -->
      </td>
    </ng-container>
    <ng-container matColumnDef="scadenza-abbonamento">
      <th mat-header-cell *matHeaderCellDef mat-sort-header (click)="searchAltFlag == false ? sortParam('subscriptionExpiration', asc == true ? false : true) : search('subscriptionExpiration', asc == true ? false : true)">{{"CUST_TABLE.SUB_EXPIRE" | translate}}</th>
      <td mat-cell *matCellDef="let user">
        <span *ngIf="user.userPaymentInfo && user.userPaymentInfo.subscriptionExpiration == null || user.userPaymentInfo == null">N/A</span>
        <span *ngIf="user.userPaymentInfo && user.userPaymentInfo.subscriptionExpiration != null">{{ user.userPaymentInfo.subscriptionExpiration | date: 'shortDate'}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="promo-code">
      <th mat-header-cell *matHeaderCellDef>{{"CUST_TABLE.PROMO" | translate}}</th>
      <td mat-cell *matCellDef="let   user">
        <span *ngIf="user.userPaymentInfo && user.userPaymentInfo.activePromotionCodes" class="promo-code">{{user.userPaymentInfo.activePromotionCodes}}</span>
        <!-- <span *ngIf="user.userPaymentInfo && user.userPaymentInfo.promotionCodesToBeActivated" class="promo-code">{{user.userPaymentInfo.promotionCodesToBeActivated}}</span> -->
      </td>
    </ng-container>
    <ng-container matColumnDef="last-payment">
      <th mat-header-cell *matHeaderCellDef>{{"CUST_TABLE.LAST_PAY" | translate}}</th>
      <td mat-cell *matCellDef="let   user">
        <span *ngIf="user.userPaymentInfo && user.userPaymentInfo.lastPayment">{{user.userPaymentInfo.lastPayment.amount | currency}} <br> {{user.userPaymentInfo.lastPayment.date | date: 'shortDate'}}</span>
      </td>
    </ng-container>
    <!-- Action Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row" class="w-10 text-secondary" >
        <button (click)="$event.stopPropagation()"
                [matMenuTriggerData]="{ customer: row }"
                [matMenuTriggerFor]="actionsMenu"
                mat-icon-button
                type="button">
          <mat-icon svgIcon="mat:more_horiz"></mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>


  </table>
  <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize" [length]="lunghezza" (page)="onPageChanged($event)" class="sticky left-0"></mat-paginator>

</div>

<mat-menu #actionsMenu="matMenu" xPosition="before" yPosition="below">
  <ng-template let-customer="customer" matMenuContent>
    <button  mat-menu-item (click)="editUser(customer)">
      <mat-icon svgIcon="mat:edit"></mat-icon>
      <span>{{"GLOBAL.EDIT" | translate}}</span>
    </button>
    <button  mat-menu-item (click)="deleteUser(customer.user.id)">
      <mat-icon svgIcon="mat:delete"></mat-icon>
      <span>{{"GLOBAL.DELETE" | translate}}</span>
    </button>
  </ng-template>
</mat-menu>
