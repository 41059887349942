<div class="contenitore">
  <div class="content">
    <div class="top-logo">
      <img class="img-top-logo" src="../../../assets/img/demo/logo_checkout.png">
    </div>
    <div class="step">
      <div>
        <div class="number-circle">1</div>
        <h2 class="step-title">
          {{"REGISTER.SELECT_SUB" | translate}}
        </h2>
        <h2 class="discount-title">
          {{"REGISTER.DISCOUNT_MSG" | translate}}
        </h2>
      </div>
      <div class="choose-plan">
        <div class="plan-box choose-duration" *ngFor="let duration of avaiable_duration">
          <div class="header-plan-duration duration-box" [class.selected]="default_selection.duration == duration" (click)="selectDuration(duration,templatePromo)">
            <p *ngIf="duration == 'H'" class="save-title">
              <img src="../../../assets/img/demo/thunderbolt_gold.svg">
              {{"REGISTER.SAVE_MSG" | translate}}
            </p>
            <p *ngIf="duration != 'H'" class="mt-4">

            </p>
            <h4>{{"REGISTER."+duration | translate}}</h4>
          </div>
        </div>
      </div>
      <div class="choose-plan second-step" *ngIf="default_selection.duration == 'M'">
        <div class="plan-box" *ngFor="let plan of subs_M" >
          <div class="tag-info">
            <h3 *ngIf="plan.type == 'GOLD'">{{"REGISTER.WORKOUT" | translate}}</h3>
            <h3 *ngIf="plan.type == 'DIAMOND'">{{"REGISTER.WORKOUT_MEAL" | translate}}</h3>
          </div>
          <div class="header-plan-duration" [class.selected]="default_selection.type == plan.type" (click)="selectPlan(plan.type,templatePromo)">
            <h4 *ngIf="default_selection.type == plan.type"><img src="../../../assets/img/demo/thunderbolt_white.svg">{{plan.type}}</h4>
            <h4 *ngIf="default_selection.type != plan.type" class="color-black"><img src="../../../assets/img/demo/thunderbolt_black.svg">{{plan.type}}</h4>
            <div class="inside-bg">
              <p *ngIf="plan.type =='GOLD'" class="label-days">{{"REGISTER.SEVEN_DAYS" | translate}}</p>
              <p *ngIf="plan.type =='DIAMOND'" class="label-days">{{"REGISTER.MOST_COMPLETE" | translate}}</p>
              <h3 class="price">$ {{plan.price.price}}<span class="price-duration"> / {{"REGISTER.PERIOD_"+plan.duration | translate}}</span></h3>
              <div class="tag-details">
                <button class="show-details" (click)="showSubDetails(plan)">{{"REGISTER.DISCOVER" | translate}}</button>
              </div>
              <p>{{"REGISTER.CANCEL" | translate}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="choose-plan second-step" *ngIf="default_selection.duration == 'Q'">
        <div class="plan-box" *ngFor="let plan of subs_Q">
          <div class="tag-info">
            <h3 *ngIf="plan.type == 'GOLD'">{{"REGISTER.WORKOUT" | translate}}</h3>
            <h3 *ngIf="plan.type == 'DIAMOND'">{{"REGISTER.WORKOUT_MEAL" | translate}}</h3>
          </div>
          <div class="header-plan-duration" [class.selected]="default_selection.type == plan.type" (click)="selectPlan(plan.type,templatePromo)">
            <h4 *ngIf="default_selection.type == plan.type"><img src="../../../assets/img/demo/thunderbolt_white.svg">{{plan.type}}</h4>
            <h4 *ngIf="default_selection.type != plan.type" class="color-black"><img src="../../../assets/img/demo/thunderbolt_black.svg">{{plan.type}}</h4>
            <div class="inside-bg">
              <p *ngIf="plan.type =='GOLD'" class="label-days">{{"REGISTER.SEVEN_DAYS" | translate}}</p>
              <p *ngIf="plan.type =='DIAMOND'" class="label-days">{{"REGISTER.MOST_COMPLETE" | translate}}</p>
              <h3 class="price">$ {{plan.price.price}}<span class="price-duration"> / {{"REGISTER.PERIOD_"+plan.duration | translate}}</span></h3>
              <div class="tag-details">
                <button class="show-details" (click)="showSubDetails(plan)">{{"REGISTER.DISCOVER" | translate}}</button>
              </div>
              <p>{{"REGISTER.CANCEL" | translate}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="choose-plan second-step" *ngIf="default_selection.duration == 'H'">
        <div class="plan-box" *ngFor="let plan of subs_H">
          <div class="tag-info">
            <h3 *ngIf="plan.type == 'GOLD'">{{"REGISTER.WORKOUT" | translate}}</h3>
            <h3 *ngIf="plan.type == 'DIAMOND'">{{"REGISTER.WORKOUT_MEAL" | translate}}</h3>
          </div>
          <div class="header-plan-duration" [class.selected]="default_selection.type == plan.type" (click)="selectPlan(plan.type,templatePromo)">
            <h4 *ngIf="default_selection.type == plan.type"><img src="../../../assets/img/demo/thunderbolt_white.svg">{{plan.type}}</h4>
            <h4 *ngIf="default_selection.type != plan.type" class="color-black"><img src="../../../assets/img/demo/thunderbolt_black.svg">{{plan.type}}</h4>
            <div class="inside-bg">
              <p *ngIf="plan.type =='GOLD'" class="label-days">{{"REGISTER.SEVEN_DAYS" | translate}}</p>
              <p *ngIf="plan.type =='DIAMOND'" class="label-days">{{"REGISTER.MOST_COMPLETE" | translate}}</p>
              <h3 class="price">$ {{plan.price.price}}<span class="price-duration"> / {{"REGISTER.PERIOD_"+plan.duration | translate}}</span></h3>
              <div class="tag-details">
                <button class="show-details" (click)="showSubDetails(plan)">{{"REGISTER.DISCOVER" | translate}}</button>
              </div>
              <p>{{"REGISTER.CANCEL" | translate}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="choose-plan second-step" *ngIf="default_selection.duration == 'Y'">
        <div class="plan-box" *ngFor="let plan of subs_Y">
          <div class="tag-info">
            <h3 *ngIf="plan.type == 'GOLD'">{{"REGISTER.WORKOUT" | translate}}</h3>
            <h3 *ngIf="plan.type == 'DIAMOND'">{{"REGISTER.WORKOUT_MEAL" | translate}}</h3>
          </div>
          <div class="header-plan-duration" [class.selected]="default_selection.type == plan.type" (click)="selectPlan(plan.type,templatePromo)">
            <h4 *ngIf="default_selection.type == plan.type"><img src="../../../assets/img/demo/thunderbolt_white.svg">{{plan.type}}</h4>
            <h4 *ngIf="default_selection.type != plan.type" class="color-black"><img src="../../../assets/img/demo/thunderbolt_black.svg">{{plan.type}}</h4>
            <div class="inside-bg">
              <p *ngIf="plan.type =='GOLD'" class="label-days">{{"REGISTER.SEVEN_DAYS" | translate}}</p>
              <p *ngIf="plan.type =='DIAMOND'" class="label-days">{{"REGISTER.MOST_COMPLETE" | translate}}</p>
              <h3 class="price">$ {{plan.price.price}}<span class="price-duration"> / {{"REGISTER.PERIOD_"+plan.duration | translate}}</span></h3>
              <div class="tag-details">
                <button class="show-details" (click)="showSubDetails(plan)">{{"REGISTER.DISCOVER" | translate}}</button>
              </div>
              <p>{{"REGISTER.CANCEL" | translate}}</p>
            </div>
        </div>
      </div>
    </div>

    <div class="step">
      <div>
        <div class="number-circle">2</div>
        <h2 class="step-title">
          {{"REGISTER.CREA" | translate}}
        </h2>
      </div>
      <div class="mt-4" [formGroup]="verticalAccountFormGroup">
        <div class="step-box flex flex-col sm:flex-row gap-2 sm:gap-6 mb-2">
          <div class="flex-auto">
            <label>{{"REGISTER.NAME" | translate}} *</label>
            <input formControlName="name" matInput required>
          </div>

          <div class="flex-auto">
            <label>{{"REGISTER.LASTNAME" | translate}} *</label>
            <input formControlName="lastname" matInput required>

          </div>
        </div>

        <div class="step-box flex flex-col sm:flex-row gap-2 sm:gap-6 mb-2">
          <div class="flex-auto">
            <label>{{"REGISTER.EMAIL" | translate}} *</label>
            <input formControlName="email" matInput required class="lower" type="email" email="true" (keyup)="forceEmailLowerCase()">
          </div>
          <div class="flex-auto">
            <label>{{"REGISTER.BIRTHDAY" | translate}} *</label>
            <input type="date" formControlName="birthday" matInput>
          </div>
        </div>

        <div class="step-box">
          <div>
            <label>{{"REGISTER.NATION" | translate}} *</label>
              <select formControlName="country" required>
                  <option></option>
                  <option *ngFor="let option of countryOptions" [value]="option">{{ option }}</option>
              </select>
          </div>

        </div>
      </div>
    </div>

    <div class="step">
      <div class="mb-4">
        <div class="number-circle">3</div>
        <h2 class="step-title">
          Password
        </h2>
      </div>
      <div class="step-box flex flex-col sm:flex-row gap-2 sm:gap-6 mb-2" [formGroup]="verticalPasswordFormGroup">
        <div class="flex-auto">
          <label>Password *</label>
          <input [type]="passwordInputType" formControlName="password" matInput required>
          <!-- <button (click)="showPassword()" *ngIf="passwordInputType === 'password'" mat-icon-button matSuffix
                  type="button">
            <mat-icon svgIcon="mat:visibility"></mat-icon>
          </button>
          <button (click)="hidePassword()" *ngIf="passwordInputType === 'text'" mat-icon-button matSuffix
                  type="button">
            <mat-icon svgIcon="mat:visibility_off"></mat-icon>
          </button> -->
          <mat-hint *ngIf="verticalPasswordFormGroup.value.password && verticalPasswordFormGroup.value.password.length < 5">{{"REGISTER.PSW_CHARACTER" | translate}}</mat-hint>
        </div>

        <div class="flex-auto">
          <label>{{"REGISTER.CONFIRM_PSW" | translate}} *</label>
          <input [type]="passwordInputType" formControlName="passwordConfirm" matInput required>
          <!-- <button (click)="showPassword()" *ngIf="passwordInputType === 'password'" mat-icon-button matSuffix
                  type="button">
            <mat-icon svgIcon="mat:visibility"></mat-icon>
          </button>
          <button (click)="hidePassword()" *ngIf="passwordInputType === 'text'" mat-icon-button matSuffix
                  type="button">
            <mat-icon svgIcon="mat:visibility_off"></mat-icon>
          </button> -->
          <mat-hint *ngIf="verticalPasswordFormGroup.value.password != verticalPasswordFormGroup.value.passwordConfirm">{{"REGISTER.PSW_NOMATCH" | translate}}</mat-hint>
          <mat-hint *ngIf="verticalPasswordFormGroup.value.password && verticalPasswordFormGroup.value.password === verticalPasswordFormGroup.value.passwordConfirm">{{"REGISTER.PSW_MATCH" | translate}}</mat-hint>
        </div>
      </div>
    </div>

    <div class="step">
      <div>
        <div class="number-circle">4</div>
        <h2 class="step-title">
          {{"REGISTER.PROMO" | translate}}
        </h2>
      </div>
      <div class="mt-4" [formGroup]="verticalPaymentFormGroup">
        <div class="flex-auto">
          <label>{{"REGISTER.PROMO_MSG" | translate}}</label>
          <div class="div-promo">
            <input formControlName="coupon" matInput placeholder='{{"REGISTER.INSERT" | translate}}' (keyup)="checkPromo(templatePromo)">
            <span class="promo-icons">
              <mat-icon class="promo-valid" *ngIf="isPromoValid == true" svgIcon="mat:check_circle_outline"></mat-icon>
              <mat-icon class="promo-invalid" *ngIf="isPromoValid == false && verticalPaymentFormGroup.value.coupon" svgIcon="mat:error_outline"></mat-icon>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="step">
      <div>
        <div class="number-circle">5</div>
        <h2 class="step-title">
          {{"REGISTER.TERMS" | translate}}
        </h2>
      </div>
      <div class="checkbox-zone" [formGroup]="verticalConfirmFormGroup">
        <mat-checkbox class="checkbox" formControlName="terms">
          <span *ngIf="translate.currentLang == 'it'">
            <p>Accetto i vostri <span class="link" (click)="goTo('terms')">Termini e condizioni d'uso</span> e <span class="link" (click)="goTo('policy')">l'Informativa sulla privacy</span> e acconsento all'utilizzo dei miei dati personali per l'elaborazione delle iscrizioni, per migliorare la mia esperienza su questa piattaforma come descritto nell'informativa sulla privacy a ricevere e-mail da <span class="link" (click)="goTo('itsdesifit')">itsdesifit.com</span></p>
          </span>
          <span *ngIf="translate.currentLang == 'en'">
            <p>I accept your <span class="link" (click)="goTo('terms')">Terms of Service</span> and <span class="link" (click)="goTo('policy')">Privacy Policy</span>, I consent to the use of my personal data for processing subscriptions, enhancing my experience on this platform as outlined in the privacy policy and to receiving emails from <span class="link">itsdesifit.com</span></p>
          </span>
        </mat-checkbox>
      </div>
    </div>
    <div class="btn-div">
      <p class="mb-3">{{"REGISTER.PAY_WITH" | translate}}</p>
      <button (click)="submit()" [disabled]="verticalConfirmFormGroup.invalid || showMiniSpinner == true || !isPromoValid && verticalPaymentFormGroup.value.coupon" [class.disabled]="verticalConfirmFormGroup.invalid || showMiniSpinner == true || !isPromoValid && verticalPaymentFormGroup.value.coupon" class="btn-create">
                  <span *ngIf="showMiniSpinner == false">{{"REGISTER.CREATE" | translate}}</span>
                  <span *ngIf="showMiniSpinner == true">
                    <mat-icon><mat-spinner color="warn" diameter="20">
                    </mat-spinner></mat-icon>
                  </span>
          </button>
    </div>
  </div>
</div>
<div *ngIf="guiService.showSpinner" class="spinner">
  <span class="spinner-center">
    <mat-progress-spinner mode="indeterminate" color="accent"></mat-progress-spinner>
  </span>
  <div class="spinner-center-text">
    <h3 *ngIf="translate.currentLang == 'it'">
        Si prega di non bloccare lo schermo o di passare ad altre applicazioni.
      </h3>
      <h3 *ngIf="translate.currentLang == 'en'">
        Please do not lock the screen or switch to another app.
      </h3>
  </div>
</div>
</div>

<ng-template #templatePromo>
  <div class="dialog-msg-div">
    <mat-icon class="promo-invalid" svgIcon="mat:error_outline"></mat-icon>
    <h1 *ngIf="translate.currentLang == 'it'">Il codice inserito non è valido sul piano selezionato.</h1>
    <h1 *ngIf="translate.currentLang == 'en'">The code entered is not valid on the selected plan.</h1>
  </div>
 </ng-template>
