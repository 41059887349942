import { TranslateService } from '@ngx-translate/core';
import { WorkoutInfoDialogComponent } from './workout-info-dialog/workout-info-dialog.component';
import { Router } from '@angular/router';
import { GuiService } from 'src/app/services/gui.service';
import { RestService } from './../../services/rest.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'vex-user-workout',
  templateUrl: './user-workout.component.html',
  styleUrls: ['./user-workout.component.scss']
})
export class UserWorkoutComponent implements OnInit {
  icon = {
    "faEll": faEllipsisH
  }
  workout
  filteredIds = {}
  filteredExs = []
  imgURL: string;
  filteredPhaseIds = {}
  filteredPhaseTypes = []
  environment = environment.baseURL.workouts
  colorClasses = ['color1','color2','color3','color4','color5']
  filteredEquipementIds = {}
  equipments: any;
  showEquips = false

  constructor(@Inject(MAT_DIALOG_DATA) private item: any['item'], public restService: RestService, public guiService: GuiService, public router: Router, private dialogRef: MatDialogRef<any>,private dialog: MatDialog, public translate:  TranslateService) { }

  ngOnInit(): void {
    this.imgURL = environment.baseURL.exercises;
    setTimeout(()=>this.guiService.showSpinner = true,0)
    setTimeout(()=>this.showEquips = true,2500)
    this.workout = this.item
    console.debug('workout', this.workout)
    this.getCurrentProgram()
    for(let phase of this.workout.phases){
      phase.workoutPhaseTypeId = JSON.parse(phase.workoutPhaseTypeId)
      for(let ex of phase.exercises){
        this.filteredExs.push(ex.exerciseId)
      }
    }
    this.loadExerciseName(this.filteredExs,this.filteredPhaseTypes)
     console.debug(this.filteredIds)
     console.debug(this.filteredPhaseIds)
  }

  loadExerciseName(baseIndex, baseIndex2) {
    let param = {
      "filterAnd": [
        {
        "field": "id",
        "operator": "in",
        "value": baseIndex.toString()
        }
        ],
      "page": 0,
      "size": 200
    }
    this.restService.postService('exercises', '/search', param).subscribe((ris)=>{
      this.equipments = []
      for(let wId of baseIndex){
        if (ris.items.find(x => x.id === wId).equipmentIds) {
          for (let equipId of ris.items.find(x => x.id === wId).equipmentIds) {
            !this.equipments.includes(equipId) ? this.equipments.push(equipId) : null
          }
        }

        this.filteredIds[wId]= ris.items.find(x => x.id === wId).name
      }
      this.guiService.loadEquipmentNames(this.equipments)
      this.guiService.getEquipImgs(this.equipments)
      this.guiService.showSpinner = false
    })
    this.restService.postService('workout_phase_types', '/search',{}).subscribe((ris2)=>{
      for(let pId of baseIndex2){
        this.filteredPhaseIds[pId]= ris2.items.find(x => x.id === pId).name
      }
    })
}

showInfos(ex){
 this.restService.getService('exercises', ex.exerciseId).subscribe((ris)=>{
  this.dialog.open(WorkoutInfoDialogComponent,{
    data: ris,
    panelClass: "infos-modalbox",
    width: '65vw',
    maxWidth: '70vw',
    height: '80vh'
  })
 })


}

goToWorkout(){
  this.guiService.selectedWorkout = this.workout
  this.router.navigate(['main/workout-exe'])
  this.dialogRef.close()
}

evaluateBgColor(index){
  let x = index
  if(index > 4){
    x = index - 5
  }
  return this.colorClasses[x]
}

getCurrentProgram(){
  this.restService.getService('workout_programs',sessionStorage.getItem('selectedProgramId')).subscribe((ris)=>{
    console.debug(ris, 'programma corrente')
    this.equipments = ris.equipmentIds
  })
}

}
