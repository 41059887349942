<div class="bg-pattern">
  <div @fadeInUp class="card overflow-hidden w-full max-w-xs">
    <div class="p-6 pb-0 flex flex-col items-center justify-center">
      <div class="fill-current text-center">
        <img class="w-16" id="logodesi" src="../../../assets/img/demo/logo.png">
      </div>
    </div>

    <div class="text-center mt-4">
      <h4 class="body-2 text-secondary m-0">{{"OPENAPP.MSG" | translate}}</h4>
    </div>

    <div class="box">
      <!-- <button class="btn" (click)="goToAPP()">{{"OPENAPP.OPEN" | translate}}</button>
      <button class="btn" (click)="goToStore()">{{"OPENAPP.DOWNLOAD" | translate}}</button> -->
      <button class="btn" (click)="goToStore()">{{"OPENAPP.OPEN_TMP" | translate}}</button>
      <button mat-button mat-dialog-close class="text-uppercase">{{ "MSG.CLOSE" | translate }}</button>
    </div>
  </div>
</div>
