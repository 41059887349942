import { GuiService } from './../../services/gui.service';
import { RestService } from './../../services/rest.service';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Chart, registerables } from 'chart.js';
import { UntypedFormControl } from '@angular/forms';
Chart.register(...registerables);
import * as moment from 'moment';
import { MatTable } from '@angular/material/table';
import { Router } from '@angular/router';

@Component({
  selector: 'vex-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  periodSelected: any = 'mensile'
  amountsOrtransactions: any = 'amounts'
  displayedColumns: string[] = ['date', 'pending','failed','complete','refunded','colleted','refunded-money','tax','net-total'];
  transactions: any;
  myLineChartMonths: any;
  myLineChartDays: any;
  myPie: any;
  topDatas: any;
  labelsDay:any;
  labelsMonths: any;
  completed: any;
  refunded: any;
  failed: any;
  searchSpinner: boolean = true;

  @ViewChild(MatTable) table: MatTable<any>;

  /*VAR DATI AGGREGATI TABELLE */
  failedTransactions= 0;
  completedTransactions= 0;
  refundedTransactions = 0;
  amountCollected = 0;
  amountRefunded= 0;
  amountFailed = 0;
  taxesCollected= 0;
  totalIncome= 0;

  diamondSub = 0;
  goldSub = 0;

  tableDatas: any;

  mesi = [
  {label:'January', value: 1},
  {label:'February', value: 2},
  {label:'March', value: 3},
  {label:'April', value: 4},
  {label:'May', value: 5},
  {label:'June', value: 6},
  {label:'July', value: 7},
  {label:'August', value: 8},
  {label:'September', value: 9},
  {label:'October', value: 10},
  {label:'November', value: 11},
  {label:'December', value: 12}
  ]

 monthCtrl = new UntypedFormControl();
 yearCtrl = new UntypedFormControl();
 subCtrl = new UntypedFormControl();


  constructor(private restService: RestService,private changeDetectorRef: ChangeDetectorRef, public guiService: GuiService, public router: Router) { }

  ngOnInit(): void {
    this.guiService.userLogged && this.guiService.userLogged.user.roles.includes('COLLAB') == true ? this.router.navigate(['admin/user']) : null

    this.yearCtrl.setValue(String(moment().year()))
    this.monthCtrl.setValue(this.mesi[moment().month()].value)
    this.subCtrl.setValue('ALL')

    this.getDaysOfMonth();
    this.getMonthsOfYear();
    this.getTopDatas()
    this.getDatas()
  }

  getMyPie(){
    this.myPie = new Chart('myPie', {
      type: 'pie',
      data: {
        labels: [
          'Gold Plan',
          'Diamond Plan'
        ],
        datasets: [{
          label: 'Subscription',
          data: [this.goldSub ? this.goldSub : 0, this.diamondSub ? this.diamondSub : 0],
          backgroundColor: [
            'rgb(54, 162, 235)',
            'rgb(255, 205, 86)'
          ],
          hoverOffset: 4
        }]
      }
    })

  }

  getMonthsOfYear(){
    this.myLineChartMonths = new Chart('myLineChart',{
      type: 'line',
      data: {
        labels: ['January','February','March','April','May', 'June','July','August','September', 'October','November','December'],
        datasets: [{
          label: 'Completed',
          data: this.completed ? this.completed : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          fill: false,
          borderColor: 'rgb(131, 255, 0)',
          tension: 0.1
        },
        {
          label: 'Refunded',
          data: this.refunded ? this.refunded : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          fill: false,
          borderColor: 'rgb(255, 0, 0)',
          tension: 0.1
        },
        {
          label: 'Failed',
          data: this.failed ? this.failed : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          fill: false,
          borderColor: 'rgb(217, 236, 0)',
          tension: 0.1
        }]
      }
    })
  }

  getDaysOfMonth(){
    this.myLineChartDays = new Chart('myLineChartTwo',{
      type: 'line',
      data: {
        labels: this.labelsDay ? this.labelsDay : ['01','02','03','04','05', '06','07','08','09', '10','11','12','13','14','15','16','17', '18','19','20','21', '22','23','24','25','26','27','28','29','30'],
        datasets: [{
          label: 'Completed',
          data: this.completed ? this.completed : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          fill: false,
          borderColor: 'rgb(131, 255, 0)',
          tension: 0.1
        },
        {
          label: 'Refunded',
          data: this.refunded ? this.refunded : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          fill: false,
          borderColor: 'rgb(255, 0, 0)',
          tension: 0.1
        },
        {
          label: 'Failed',
          data: this.failed ? this.failed : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          fill: false,
          borderColor: 'rgb(217, 236, 0)',
          tension: 0.1
        }]
      }
    })
  }

  getDatas(){
    this.searchSpinner = true;

    let params = {
      month: this.periodSelected != 'mensile' ? null : this.monthCtrl.value,
      year: this.periodSelected == 'sempre' ? null : this.yearCtrl.value,
      subscriptionType: this.subCtrl.value == 'ALL' ? null : this.subCtrl.value
    }
    this.restService.postService('report','/transactions-info',params).subscribe((ris)=>{
      this.searchSpinner = false;
      console.debug('prova datas', ris)
      this.transactions = []
      this.transactions = ris

      this.tableDatas = this.transactions;

      this.failedTransactions = 0;
      this.completedTransactions = 0;
      this.refundedTransactions = 0;
      this.amountCollected = 0;
      this.amountRefunded = 0;
      this.amountFailed = 0;
      this.taxesCollected = 0;
      this.totalIncome = 0;
      this.labelsDay = []
      this.labelsMonths = []
      this.completed = []
      this.refunded = []
      this.failed = []

      this.diamondSub = 0;
      this.goldSub = 0;

      for(let i = 0; i<ris.length; i++){
        this.failedTransactions += ris[i].failedTransactions
        this.completedTransactions += ris[i].completedTransactions;
        this.refundedTransactions += ris[i].refundedTransactions;
        this.amountCollected += ris[i].amountCollected
        this.amountFailed += ris[i].amountFailed
        this.amountRefunded += ris[i].amountRefunded
        this.taxesCollected += ris[i].taxesCollected
        this.totalIncome += ris[i].totalIncome

        this.diamondSub += ris[i].amountDiamond
        this.goldSub += ris[i].amountGold

        if(this.periodSelected == 'mensile'){
          this.labelsDay.push(ris[i].day)
        }if(this.periodSelected == 'mensile'){
          this.labelsMonths.push(ris[i].month)
        }

        if(this.amountsOrtransactions == 'amounts'){
          this.completed.push(ris[i].amountCollected)
          this.refunded.push(ris[i].amountRefunded)
          this.failed.push(ris[i].amountFailed)
        }if(this.amountsOrtransactions == 'transactions'){
          this.completed.push(ris[i].completedTransactions)
          this.refunded.push(ris[i].refundedTransactions)
          this.failed.push(ris[i].failedTransactions)
        }

      }
      this.periodSelected == 'annuale' ? (this.myLineChartMonths.destroy(),this.getMonthsOfYear()) : null;
      this.periodSelected == 'mensile' ? (this.myLineChartDays.destroy(),this.getDaysOfMonth()) : null;
      if(this.subCtrl.value != 'ALL'){
        this.myPie.destroy()
      }else if(this.myPie){
        this.myPie.destroy()
        this.getMyPie()
      }else{
        this.getMyPie()
      }

    })
  }

  getTopDatas(){
    this.restService.getService('report','transactions-members').subscribe((ris)=>{
      console.debug('top datas', ris)
      this.topDatas = ris
    })
  }

}
