import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'vex-accept-challenge-dialog',
  templateUrl: './accept-challenge-dialog.component.html',
  styleUrls: ['./accept-challenge-dialog.component.scss']
})
export class AcceptChallengeDialogComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<any>, public translate: TranslateService) { }

  ngOnInit(): void {
  }

  accept(){
    /* qui va fatta la chiamata per fare partire la notifica */
    this.dialogRef.close()
  }

  close(){
    this.dialogRef.close('true')
  }

}
