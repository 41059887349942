import { PromotionEditComponent } from './promotion-edit/promotion-edit.component';
import { TranslateService } from '@ngx-translate/core';
import { PromotionNewComponent } from './promotion-new/promotion-new.component';
import { ProgramNewComponent } from '../program/program-new/program-new.component';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { RestService } from 'src/app/services/rest.service';
import { DialogConfirmComponent } from '../dialog-confirm/dialog-confirm.component';
import { GuiService } from 'src/app/services/gui.service';
import { Router } from '@angular/router';

@Component({
  selector: 'vex-promotion',
  templateUrl: './promotions.component.html',
  styleUrls: ['./promotions.component.scss']
})
export class PromotionsComponent implements OnInit {
  promos: any;
  dataSource : MatTableDataSource<any> | null
  displayedColumns: string[] = ['vuota','codice', 'valido-da', 'valido-al', 'abbonamento','quantita', 'formula', 'actions'];
  selection = new SelectionModel<any>(true, []);
  searchCtrl = new UntypedFormControl();
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 15, 50];
  pageNumber = 0;
  lunghezza: any;
  /* sort var */
  asc: any;
  fieldParam: string;
  searchAltFlag: boolean;
  sortFlag: boolean;
  mockCoupons: any;

  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private restService: RestService, private dialog: MatDialog, public translate: TranslateService, public guiService: GuiService,public router: Router) {

   }

  ngOnInit(): void {
    this.guiService.userLogged && this.guiService.userLogged.user.roles.includes('COLLAB') == true ? this.router.navigate(['admin/user']) : null
    this.getPromotions()
    this.asc = true;
    this.searchAltFlag = false;
    this.sortFlag = false;
  }

  ngAfterViewInit(): void {
    //this.dataSource.paginator = this.paginator;
    //this.dataSource.sort = this.sort;
  }

  getPromotions(){
    setTimeout(()=>this.guiService.showSpinner = true,0)
    this.dataSource = new MatTableDataSource();
    let param = {
      "orders": [
        {
          "field": "dateCreated",
          "ascending": false
        }
      ],
      page: this.pageNumber,
      size: this.pageSize
    }
    this.restService.postService('promotions', '/search', param).subscribe((ris)=>{
      this.lunghezza = ris.totalItems
      this.promos = ris["items"];
      console.debug(this.promos)
      this.dataSource.data = this.promos
      this.table ? this.table.renderRows() : null;
      this.guiService.showSpinner = false
      this.getCouponsInMockApi()
    })
  }

  onPageChanged(pageEvent: PageEvent) {
    this.pageNumber = pageEvent.pageIndex+1;
    this.pageSize = pageEvent.pageSize;
    if(this.searchAltFlag == false && this.sortFlag == false){
      this.getPromotions()
    }else if(this.sortFlag == true){
      this.sortParam(this.fieldParam, this.asc)
    }else if(this.searchAltFlag == true){
      this.searchAltExercise()
    }
  }

  createPromotion(){
    this.dialog.open(PromotionNewComponent,{
      disableClose: true,
      hasBackdrop: true
    }).afterClosed().subscribe((promotion) => {
      if(promotion == 'done'){
        this.getPromotions()
      }
    })
  }

  editPromotion(item?: any['item']){
    this.dialog.open(PromotionEditComponent,
      {
        data: item || null,
        disableClose: true,
        hasBackdrop: true
      }).afterClosed().subscribe((promotion) => {
      if(promotion == 'done'){
        this.getPromotions()
      }
    })
  }

  deletePromotion(id,couponCode): void {
    this.dialog.open(DialogConfirmComponent, {
      width: '250px',
      panelClass: 'dialog-modalbox',
      data: {
        type: 'promotions',
        id: id
      },
      enterAnimationDuration: '0ms',
      exitAnimationDuration: '1ms',
    }).afterClosed().subscribe((ris)=>{
      if(ris != 'true'){
        this.deleteMockApiCoupon(couponCode)
        this.getPromotions()
      }
    })
  }

  searchAltExercise() {
    if(this.searchCtrl.value.length > 3){
    this.searchAltFlag = true
    console.debug(this.searchCtrl.value)
    let param = {
      "filterAnd": [
        {
        "field": "code",
        "operator": "startwithignorecase",
        "value": this.searchCtrl.value
        }
        ],
      "page": this.pageNumber,
      "size": this.pageSize
    }
    this.restService.postService('promotions','/search'+'?ALL_LANGUAGES=true', param).subscribe((ris)=>{
      this.lunghezza = ris.totalItems
      this.promos = ris["items"];
      console.debug(this.promos)
      this.dataSource.data = this.promos
      this.table ? this.table.renderRows() : null;
      this.guiService.showSpinner = false
    })
  }else if(this.searchCtrl.value.length == 0){
    this.reload()
  }
  }

  sortParam(field?,asc?){
    this.asc = asc
    this.sortFlag = true
    this.fieldParam = field
    let param = {
      page: this.pageNumber,
      size: this.pageSize,
      orders: [
        {
         field: this.fieldParam,
         ascending : this.asc
        }
      ]
    }
    setTimeout(()=>this.guiService.showSpinner = true,0)
    this.restService.postService('promotions', '/search', param).subscribe((ris)=>{
      this.lunghezza = ris.totalItems
      this.promos = ris["items"];
      console.debug(this.promos)
      this.dataSource.data = this.promos
      this.table ? this.table.renderRows() : null;
      this.guiService.showSpinner = false
    })
  }

  reload(){
    this.searchCtrl.reset()
    this.searchAltFlag = false;
    this.sortFlag = false;
    this.pageNumber = 0;
    this.pageSize = 10;
    this.getPromotions()
  }

  getCouponsInMockApi() {
    this.restService.getService('mock', 'promos').subscribe((ris) => {
      this.mockCoupons = ris;
    })
  }

  deleteMockApiCoupon(couponCode:any){
    let tmpCoupon = this.mockCoupons.find(x => x.code === couponCode)
    console.debug(tmpCoupon)
    if(tmpCoupon){
      this.restService.deleteService('mock', 'promos' + '/' + tmpCoupon.id, tmpCoupon).subscribe((ris) => {
      })
    }
  }

}
