import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'vex-email-already-modal',
  templateUrl: './email-already-modal.component.html',
  styleUrls: ['./email-already-modal.component.scss']
})
export class EmailAlreadyModalComponent implements OnInit {

  constructor(public translate: TranslateService) { }

  ngOnInit(): void {
  }

}
