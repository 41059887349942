import { MatDialog } from '@angular/material/dialog';
import {WorkoutComponent} from './../workout.component';
import {GuiService} from 'src/app/services/gui.service';
import {Component, OnInit} from '@angular/core';
import {FormControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {fadeInRight400ms} from 'src/@vex/animations/fade-in-right.animation';
import {fadeInUp400ms} from 'src/@vex/animations/fade-in-up.animation';
import {scaleIn400ms} from 'src/@vex/animations/scale-in.animation';
import {stagger80ms} from 'src/@vex/animations/stagger.animation';
import {RestService} from 'src/app/services/rest.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {TranslateService} from "@ngx-translate/core";
import {faAdd, faClose, faCircle} from '@fortawesome/free-solid-svg-icons';
import {MatDialogRef} from '@angular/material/dialog';
import {environment} from 'src/environments/environment';
import { CloseConfirmComponent } from '../../close-confirm/close-confirm.component';

@Component({
  selector: 'vex-workout-new',
  templateUrl: './workout-new.component.html',
  styleUrls: ['./workout-new.component.scss'],
  animations: [
    stagger80ms,
    fadeInUp400ms,
    scaleIn400ms,
    fadeInRight400ms
  ]
})
export class WorkoutNewComponent implements OnInit {
  icon = {
    "faAdd": faAdd,
    "faClose": faClose
  };

  formLang: any = 'it';
  iconFormFlag: any;
  flags = {
    en: 'flag:united-states',
    it: 'flag:italy'
  };

  verticalPlanFormGroup: UntypedFormGroup;
  verticalSelectExerciseFormGroup: UntypedFormGroup;
  verticalSetExerciseFormGroup: any;

  imgURL: string;
  pre_type;
  selectedValue;
  newSelectedValue;
  exercises: any;
  programs: any;
  selectedExercises: any = [];
  imgSelected: string;
  week_number: any = []
  searchCtrl = new UntypedFormControl();

  phases = [{
    name: '',
    i18nName: {},
    workoutPhaseTypeId: "",
    exercises: [],
    repetitions: 0,
    restTimeSeconds: 0
  }];
  selectedPhase: any;
  typs: any

  workout = [{
    name: '',
    i18nName: [],
    description: "",
    file: "",
    phases: [{
      name: '',
      name_i18n: {},
      type: "",
      exercises: [{}]
    }]
  }]

  showMiniSpinner: any;

  constructor(
    private fb: UntypedFormBuilder,
    public restService: RestService,
    private _snackBar: MatSnackBar,
    public translate: TranslateService,
    public guiService: GuiService,
    private dialogRef: MatDialogRef<WorkoutComponent>,
    public dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.showMiniSpinner = false
    this.imgURL = environment.baseURL.exercises;
    this.changeFormLanguage(this.translate.currentLang);

    this.verticalPlanFormGroup = this.fb.group({
      nome_it: [null],
      nome_en: [null],
      desc_it: [null],
      desc_en: [null],
      file: [null],
      programs: [null],
      week_number: [null]
    });

    this.verticalSelectExerciseFormGroup = this.fb.group({});

    for (let i in this.guiService.languages) {
      let language = this.guiService.languages[i]
      this.verticalSelectExerciseFormGroup.addControl('name_' + language + '_0', new FormControl(null, Validators.required));
    }

    this.verticalSelectExerciseFormGroup.addControl('type_it_0', new FormControl(null));
    this.verticalSelectExerciseFormGroup.addControl('type_en_0', new FormControl(null));
    this.verticalSelectExerciseFormGroup.addControl('series_0', new FormControl(null));
    this.verticalSelectExerciseFormGroup.addControl('series_rest_0', new FormControl(null, Validators.required));

    this.verticalSetExerciseFormGroup = this.fb.group({});


    this.getExercises()
    this.getPrograms()
  }


  submit() {
    this.configExerciseParam()
    let newWorkout = {
      name: this.verticalPlanFormGroup.value.nome_en,
      i18nName: {
        "EN": this.verticalPlanFormGroup.value.nome_en,
        "IT": this.verticalPlanFormGroup.value.nome_it
      },
      description: this.verticalPlanFormGroup.value.desc_en,
      i18nDescription: {
        "EN": this.verticalPlanFormGroup.value.desc_en,
        "IT": this.verticalPlanFormGroup.value.desc_it
      },
      file: null,
      phases: this.phases,
      image: this.imgSelected,
      imageFileName: this.verticalPlanFormGroup.value.nome_en,
      tags: this.week_number,
      workoutProgramId: this.verticalPlanFormGroup.value.programs
    }
    this.showMiniSpinner = true
    console.debug('NEW-WORKOUT', newWorkout)
     this.restService.postService('workouts', '?ALL_LANGUAGES=true', newWorkout).subscribe((ris) => {
       console.debug(ris)
       this.showMiniSpinner = false
       this.dialogRef.close('done')
       this.guiService.openSnackBar(ris.name + this.translate.instant('GLOBAL.NEW_SUCCESS'), this.translate.instant('GLOBAL.CLOSE'))
     })
  }

  getExercises() {
    this.restService.postService('exercises_search', '', {page: 0, size: 50}).subscribe((ris) => {
      this.exercises = ris["items"]
    })
  }

  getPrograms(){
    this.restService.postService('workout_programs', '/search?ALL_LANGUAGES=true', {}).subscribe((ris) => {
      console.debug('programmi', ris)
      for(let k in ris.items){
        ris.items[k].name = ris.items[k].name.replace("DRAFT::", '')
      }
      console.debug(ris.items,' prrrrogram')
      this.programs = ris["items"]
    })
  }





  // NUOVA GESTIONE CREAZIONE PROGRAMMA
  addPhase() {

    console.debug("ADD PHASE", this.verticalSelectExerciseFormGroup.value);
    for (let i in this.guiService.languages) {
      let language = this.guiService.languages[i]
      this.verticalSelectExerciseFormGroup.addControl('name_' + language + '_' + this.phases.length, new FormControl(null, Validators.required));
    }
    this.verticalSelectExerciseFormGroup.addControl('type_it_' + this.phases.length, new FormControl(null));
    this.verticalSelectExerciseFormGroup.addControl('type_en_' + this.phases.length, new FormControl(null));
    this.verticalSelectExerciseFormGroup.addControl('series_' + this.phases.length, new FormControl(null));
    this.verticalSelectExerciseFormGroup.addControl('series_rest_' + this.phases.length, new FormControl(null, Validators.required));
    this.phases.push({
      name: "",
      i18nName: {},
      workoutPhaseTypeId: "",
      exercises: [],
      repetitions: 0,
      restTimeSeconds: 0
    });

    console.debug("PHASE ADDED", this.phases, this.verticalSelectExerciseFormGroup.value)
    ;
  }

  addExerciseFromPhase(ex) {

    this.verticalSetExerciseFormGroup.addControl('reps_' + this.selectedPhase + '_' + this.phases[this.selectedPhase].exercises.length, new FormControl(null));
    this.verticalSetExerciseFormGroup.addControl('side_' + this.selectedPhase + '_' + this.phases[this.selectedPhase].exercises.length, new FormControl(null));
    this.verticalSetExerciseFormGroup.addControl('time_' + this.selectedPhase + '_' + this.phases[this.selectedPhase].exercises.length, new FormControl(null));
    this.verticalSetExerciseFormGroup.addControl('rest_' + this.selectedPhase + '_' + this.phases[this.selectedPhase].exercises.length, new FormControl(null));
    let exObj = {
      "eachSideFlag": false,
      "executionTimeSeconds": 0,
      "exerciseId": ex.id,
      "repetitions": 0,
      "restTimeSeconds": 0,
      "name": ex.name
    }
    this.phases[this.selectedPhase].exercises.push(exObj);
    console.debug('phases',this.phases)
    console.debug('verticalSetExerciseFormGroup',this.verticalSetExerciseFormGroup)
  }

  removeExerciseFromPhase(phase, exI) {
    if (confirm(this.translate.instant('ALERT.MESSAGE')) == true){
      for(let f = exI; f < this.phases[phase].exercises.length; f++){
        this.verticalSetExerciseFormGroup.controls["reps_" + phase + "_" + f].setValue(this.verticalSetExerciseFormGroup.value["reps_" + phase + "_" + (f+1)])
        this.verticalSetExerciseFormGroup.controls["side_" + phase + "_" + f].setValue(this.verticalSetExerciseFormGroup.value["side_" + phase + "_" + (f+1)])
        this.verticalSetExerciseFormGroup.controls["time_" + phase + "_" + f].setValue(this.verticalSetExerciseFormGroup.value["time_" + phase + "_" + (f+1)])
        this.verticalSetExerciseFormGroup.controls["rest_" + phase + "_" + f].setValue(this.verticalSetExerciseFormGroup.value["rest_" + phase + "_" + (f+1)])
        if(f == this.phases[phase].exercises.length){
          console.debug('length',this.phases[phase].exercises.length)
          this.verticalSetExerciseFormGroup.removeControl("reps_" + phase + "_" + (this.phases[phase].exercises.length-1))
          this.verticalSetExerciseFormGroup.removeControl("side_" + phase + "_" + (this.phases[phase].exercises.length-1))
          this.verticalSetExerciseFormGroup.removeControl("time_" + phase + "_" + (this.phases[phase].exercises.length-1))
          this.verticalSetExerciseFormGroup.removeControl("rest_" + phase + "_" + (this.phases[phase].exercises.length-1))
        }

      }
      console.debug(this.phases)
      this.phases[phase].exercises.splice(exI, 1);
    }
  }

  selectPhase(i) {
    this.selectedPhase = i;
  }

  setPhasesparam() {
    for (let k in this.phases) {
      let item = this.phases[k];
      let phaseDesc = {
        "EN": this.verticalSelectExerciseFormGroup.value["type_en_" + k],
        "IT": this.verticalSelectExerciseFormGroup.value["type_it_" + k]
      }
      item.name = this.verticalSelectExerciseFormGroup.value["name_en_" + k];
      item.workoutPhaseTypeId = JSON.stringify(phaseDesc);
      item.repetitions = this.verticalSelectExerciseFormGroup.value["series_" + k];
      item.restTimeSeconds = this.verticalSelectExerciseFormGroup.value["series_rest_" + k]
      item.i18nName = {}
      for (let l in this.guiService.languages) {
        let language = this.guiService.languages[l]
        let tmp = item.i18nName
        tmp[language.toUpperCase()] = this.verticalSelectExerciseFormGroup.value["name_" + language + '_' + k]
        item.i18nName = tmp
      }

    }


  }

  configExerciseParam() {
    for (let k in this.phases) {
      let item = this.phases[k];
      for (let e in item.exercises) {
        let exercise = JSON.parse(JSON.stringify(item.exercises[e]))

        exercise.repetitions = this.verticalSetExerciseFormGroup.value["reps_" + k + "_" + e];
        exercise.eachSideFlag = this.verticalSetExerciseFormGroup.value["side_" + k + "_" + e];
        exercise.executionTimeSeconds = this.verticalSetExerciseFormGroup.value["time_" + k + "_" + e];
        exercise.restTimeSeconds = this.verticalSetExerciseFormGroup.value["rest_" + k + "_" + e];

        this.phases[k].exercises[e] = exercise
      }

    }

  }

  /* GESTIONE DRAG'N DROP */


  drop(event: CdkDragDrop<any[]>, index) {
    this.configExerciseParam()
    moveItemInArray(this.phases[index].exercises, event.previousIndex, event.currentIndex);
    this.dropExercise(index,event.previousIndex, event.currentIndex)
  }

  dropExercise(index,previousIndex,currentIndex){

    let tmpPrevious = this.phases[index].exercises[previousIndex]
    for(let f = 0; f < this.phases[index].exercises.length; f++) {
      console.debug("F",f,this.phases[index].exercises[f].restTimeSeconds);
      this.verticalSetExerciseFormGroup.controls["reps_" + index + "_" + f].setValue(this.phases[index].exercises[f].repetitions);
      this.verticalSetExerciseFormGroup.controls["side_" + index + "_" + f].setValue(this.phases[index].exercises[f].eachSideFlag);
      this.verticalSetExerciseFormGroup.controls["time_" + index + "_" + f].setValue(this.phases[index].exercises[f].executionTimeSeconds);
      this.verticalSetExerciseFormGroup.controls["rest_" + index + "_" + f].setValue(this.phases[index].exercises[f].restTimeSeconds);
    }
  }

  // resetExerciseParam() {
  //   this.verticalSetExerciseFormGroup.reset();
  //   this.verticalSetExerciseFormGroup = this.fb.group({});

  //   for (let i in this.phases) {
  //     let phase = this.phases[i];

  //     for (let e in phase.exercises) {
  //       this.verticalSetExerciseFormGroup.addControl(
  //         "reps_" + i + "_" + e,
  //         new FormControl(null)
  //       );
  //       this.verticalSetExerciseFormGroup.addControl(
  //         "side_" + i + "_" + e,
  //         new FormControl(null)
  //       );
  //       this.verticalSetExerciseFormGroup.addControl(
  //         "time_" + i + "_" + e,
  //         new FormControl(null)
  //       );
  //       this.verticalSetExerciseFormGroup.addControl(
  //         "rest_" + i + "_" + e,
  //         new FormControl(null)
  //       );
  //     }
  //   }

  // }

  changeFormLanguage(lang) {
    this.iconFormFlag = this.flags[lang];
    this.formLang = lang;
  }

  onFileSelected(event) {
    this.guiService.convertFile(event.target.files[0]).subscribe(base64 => {
      console.debug("BASE64", base64);
      this.imgSelected = base64;
    });
  }

  removePhase(idx) {

    if(confirm(this.translate.instant("ALERT.PHASE_DEL_CONFIRM"))){
      this.slideController(idx);
    }

  }

  initController(){
    let baseIndex = []
    for (let i in this.phases) {
      let phase = this.phases[i];

      for (let g in this.guiService.languages) {
        let language = this.guiService.languages[g]
        this.verticalSelectExerciseFormGroup.addControl(
          "name_" + language + "_" + i,
          new FormControl(null, Validators.required)
        );
      }

      this.verticalSelectExerciseFormGroup.addControl(
        "type_it_" + i,
        new FormControl(null)
      );

      this.verticalSelectExerciseFormGroup.addControl(
        "type_en_" + i,
        new FormControl(null)
      );

      this.verticalSelectExerciseFormGroup.addControl(
        "series_" + i,
        new FormControl(null)
      );

      this.verticalSelectExerciseFormGroup.addControl(
        "series_rest_" + i,
        new FormControl(null, Validators.required)
      );

      this.verticalSelectExerciseFormGroup.controls["name_it_" + i].setValue(
        phase.i18nName["IT"]
      );
      this.verticalSelectExerciseFormGroup.controls["name_en_" + i].setValue(
        phase.i18nName["EN"]
      );

      let phaseDesc = typeof phase.workoutPhaseTypeId == "string" && phase.workoutPhaseTypeId != '' ? JSON.parse(phase.workoutPhaseTypeId) : {"EN": '', "IT": ''};

      this.verticalSelectExerciseFormGroup.controls["type_it_" + i].setValue(
        phaseDesc['IT']
      );
      this.verticalSelectExerciseFormGroup.controls["type_en_" + i].setValue(
        phaseDesc['EN']
      );

      this.verticalSelectExerciseFormGroup.controls["series_" + i].setValue(
        phase.repetitions
      );
      this.verticalSelectExerciseFormGroup.controls["series_rest_" + i].setValue(
        phase.restTimeSeconds
      );

      for (let e in phase.exercises) {
        let item = phase.exercises[e];

        if (baseIndex.indexOf(item.exerciseId) < 0) {
          baseIndex.push(item.exerciseId)
        }

        this.verticalSetExerciseFormGroup.addControl(
          "reps_" + i + "_" + e,
          new FormControl(null)
        );
        this.verticalSetExerciseFormGroup.addControl(
          "side_" + i + "_" + e,
          new FormControl(null)
        );
        this.verticalSetExerciseFormGroup.addControl(
          "time_" + i + "_" + e,
          new FormControl(null)
        );
        this.verticalSetExerciseFormGroup.addControl(
          "rest_" + i + "_" + e,
          new FormControl(null)
        );

        this.verticalSetExerciseFormGroup.controls[
        "reps_" + i + "_" + e
          ].setValue(item.repetitions);
        this.verticalSetExerciseFormGroup.controls[
        "side_" + i + "_" + e
          ].setValue(item.eachSideFlag);
        this.verticalSetExerciseFormGroup.controls[
        "time_" + i + "_" + e
          ].setValue(item.executionTimeSeconds);
        this.verticalSetExerciseFormGroup.controls[
        "rest_" + i + "_" + e
          ].setValue(item.restTimeSeconds);
      }
    }
  }

  slideController(idx) {

    for (let i = 0; i < this.phases.length-1; i++) {
      i >= idx ? this.verticalSelectExerciseFormGroup.controls["name_it_" + i].setValue(this.verticalSelectExerciseFormGroup.value["name_it_" + (i + 1)]) : null;
      i >= idx ? this.verticalSelectExerciseFormGroup.controls["name_en_" + i].setValue(this.verticalSelectExerciseFormGroup.value["name_en_" + (i + 1)]) : null;
      i >= idx ? this.verticalSelectExerciseFormGroup.controls["type_it_" + i].setValue(this.verticalSelectExerciseFormGroup.value["type_it_" + (i + 1)]) : null;
      i >= idx ? this.verticalSelectExerciseFormGroup.controls["type_it_" + i].setValue(this.verticalSelectExerciseFormGroup.value["type_it_" + (i + 1)]) : null;
      i >= idx ? this.verticalSelectExerciseFormGroup.controls["series_" + i].setValue(this.verticalSelectExerciseFormGroup.value["series_" + (i + 1)]) : null;
      i >= idx ? this.verticalSelectExerciseFormGroup.controls["series_rest_" + i].setValue(this.verticalSelectExerciseFormGroup.value["series_rest_" + (i + 1)]) : null;
      i >= idx ? this.phases[i].exercises = this.phases[(i+1)].exercises : null;
    }

    this.initController()
    this.configExerciseParam()


    this.verticalSelectExerciseFormGroup.removeControl("name_it_" + (this.phases.length-1));
    this.verticalSelectExerciseFormGroup.removeControl("name_en_" + (this.phases.length-1));
    this.verticalSelectExerciseFormGroup.removeControl("type_it_" + (this.phases.length-1));
    this.verticalSelectExerciseFormGroup.removeControl("type_en_" + (this.phases.length-1));
    this.verticalSelectExerciseFormGroup.removeControl("series_" + (this.phases.length-1));
    this.verticalSelectExerciseFormGroup.removeControl("series_rest_" + (this.phases.length-1));
    this.phases.splice(-1);
  }

  searchAltExercise() {
    if(this.searchCtrl.value.length > 3){
    console.debug(this.searchCtrl.value)
    let param = {
      "filterAnd": [
        {
        "field": "i18nName."+this.translate.currentLang.toUpperCase(),
        "operator": "like",
        "value": this.searchCtrl.value
        }
        ],
      "page": 0,
      "size": 0
    }
    this.restService.postService('exercises_search', '?ALL_LANGUAGES=true', param).subscribe((ris)=>{
      this.exercises = ris["items"]
    })
  }else if(this.searchCtrl.value.length == 0){
    this.getExercises()
  }
  }

  addWeekToChip(wNumber){
    if(wNumber != null){
      this.week_number.push(('week '+wNumber))
      this.verticalPlanFormGroup.controls['week_number'].setValue(null)
    }
  }

  removeWeekFromChip(i){
    this.week_number.splice(i, 1)
    console.debug(this.week_number)
  }

  confirmClose(){
    this.dialog.open(CloseConfirmComponent,{
      disableClose: true,
      hasBackdrop: true,
      panelClass: 'close-dialog-modalbox'
    }).afterClosed().subscribe((ris)=>{
      if(ris == 'true'){
        this.dialogRef.close()
      }
    })
  }

  validateNo(e): boolean {
    console.debug('control if number')
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false
    }
    return true
  }
}
