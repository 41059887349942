<div *ngIf="user">
  <form [formGroup]="form">
    <div class="container py-gutter">
      <div class="card overflow-hidden">
        <div class="h-20 relative overflow-hidden">

          <div class="absolute bg-contrast-black opacity-25 top-0 right-0 bottom-0 left-0 w-full h-full z-0"></div>
        </div>

        <div class="z-10 relative -mt-16 px-gutter flex items-center">

          <span class="circle-name">{{user.masterData.name.charAt(0)}}{{user.masterData.lastName.charAt(0)}}</span>

          <div class="max-w-full flex-auto sm:ltr:ml-6 sm:rtl:mr-6" >
            <div class="h-16 flex items-end">
              <h1 @fadeInRight class="headline text-contrast-white pb-2 m-0">{{user.masterData.name}} {{user.masterData.lastName}}</h1>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="mt-2 flex flex-col md:flex-row md:items-start">
      <div class="flex-auto">
        <div class="card">
          <div class="px-gutter py-4 border-b">
            <h2 class="title m-0">{{"USER-EDIT.INFO" | translate}}</h2>
          </div>

          <div class="px-gutter py-4 grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div class="py-3 flex items-center">
              <div @scaleIn
                   class="w-10 h-10 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
                <mat-icon class="icon-sm" svgIcon="mat:phone"></mat-icon>
              </div>

              <div @fadeInRight>

                <mat-form-field>
                  <mat-label>{{"USER-EDIT.PHONE_NUMBER" | translate}}</mat-label>
                  <input matInput formControlName="phonenumber"/>
                </mat-form-field>

              </div>
            </div>

            <div class="py-3 flex items-center">
              <div @scaleIn
                   class="w-10 h-10 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
                <mat-icon class="icon-sm" svgIcon="mat:date_range"></mat-icon>
              </div>

              <div @fadeInRight>
                <mat-form-field>
                  <mat-label>{{"USER-EDIT.REG_DATE" | translate}}</mat-label>
                  <input [matDatepicker]="datepickerRef" matInput formControlName="registration" />
                  <mat-datepicker-toggle [for]="datepickerRef" matSuffix></mat-datepicker-toggle>
                  <mat-datepicker #datepickerRef></mat-datepicker>
                </mat-form-field>
              </div>
            </div>

            <div class="py-3 flex items-center">
              <div @scaleIn
                   class="w-10 h-10 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
                <mat-icon class="icon-sm" svgIcon="mat:mail"></mat-icon>
              </div>

              <div @fadeInRight>
                <mat-form-field>
                  <mat-label>{{"USER-EDIT.EMAIL" | translate}}</mat-label>
                  <input matInput required formControlName="email"/>
                </mat-form-field>
              </div>
            </div>

            <div class="py-3 flex items-center">
              <div @scaleIn
                   class="w-10 h-10 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
                <mat-icon class="icon-sm" svgIcon="mat:access_time"></mat-icon>
              </div>

              <div @fadeInRight>
                <mat-form-field>
                  <mat-label>{{"USER-EDIT.BIRT_DATE" | translate}}</mat-label>
                  <input [matDatepicker]="datepickerRef2" matInput formControlName="birthday" />
                  <mat-datepicker-toggle [for]="datepickerRef2" matSuffix></mat-datepicker-toggle>
                  <mat-datepicker #datepickerRef2></mat-datepicker>
                </mat-form-field>
              </div>
            </div>

          </div>
        </div>

        <div class="card mt-4">
          <div class="px-gutter py-4 border-b">
            <h2 class="title m-0">{{"USER-EDIT.RESET-PASSWORD" | translate}}</h2>
          </div>

          <div class="px-gutter py-4 grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div class="py-3 flex items-center">
              <div @scaleIn
                   class="w-10 h-10 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
                <mat-icon class="icon-sm" svgIcon="mat:key"></mat-icon>
              </div>

              <div @fadeInRight>

                <mat-form-field>
                  <mat-label>{{"USER-EDIT.PASSWORD" | translate}}</mat-label>
                  <input matInput required formControlName="password"/>
                </mat-form-field>

              </div>
            </div>

            <div class="py-3 flex items-center">
              <div @scaleIn
                   class="w-10 h-10 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
                <mat-icon class="icon-sm" svgIcon="mat:key"></mat-icon>
              </div>

              <div @fadeInRight>
                <mat-form-field>
                  <mat-label>{{"USER-EDIT.CONFIRM_PSW" | translate}}</mat-label>
                  <input matInput required formControlName="confirmpassword"/>
                </mat-form-field>
              </div>
            </div>

          </div>
        </div>

        <div class="card mt-4">
          <div class="px-gutter py-4 border-b">
            <h2 class="title m-0">{{"USER-EDIT.SUB" | translate}} <span *ngIf="user.userPaymentInfo.chargebeePaymentInfo && user.userPaymentInfo.chargebeePaymentInfo.subscriptionId"><mat-icon svgIcon="mat:exit_to_app" class="cursore" (click)="goToChargeBee(user.userPaymentInfo.chargebeePaymentInfo.subscriptionId)"></mat-icon></span></h2>

          </div>

          <div @stagger class="px-gutter py-4 grid grid-cols-1 sm:grid-cols-2 gap-4 paystatus" >

            <div class="flex-auto mt-6" *ngIf="user.userPaymentInfo != null">

              <div class="m-0 body-1">{{"USER-EDIT.SUB_STATUS" | translate}}</div>
              <div class="change_status" *ngIf="user.userPaymentInfo.subscriptionStatus === 'ACTIVE'">
                <span *ngIf="user.userPaymentInfo.subscriptionExpirationPendingStatus == null && user.userPaymentInfo.subscriptionStatusRequest == null">
                  {{"USER-EDIT.ACTIVE" | translate}}
                  <fa-icon [icon]="icon.faCircle" class="icon payed"></fa-icon>
                  <!-- <button class="btn-change" (click)="changeSubStatus('PAUSED')">{{"USER-EDIT.SET_PAUSED" | translate}}</button> -->
                  <button class="btn-change" (click)="chargeBeePortal(user.user.id)" *ngIf="user.userPaymentInfo.paymentType == 'CHARGEBEE'">{{"PROFILE_MENU.MANAGE" | translate}}</button>
                </span>
                <span *ngIf="user.userPaymentInfo.subscriptionExpirationPendingStatus == 'PAUSED' || user.userPaymentInfo.subscriptionStatusRequest == 'PAUSED'">
                  {{"USER-EDIT.ACTIVE" | translate}}
                  <fa-icon [icon]="icon.faCircle" class="icon payed"></fa-icon>,
                  {{"USER-EDIT.NEXT" | translate}}: {{"USER-EDIT.PAUSED" | translate}}
                  <!-- <button class="btn-change" (click)="changeSubStatus('ACTIVE')">{{"USER-EDIT.SET_ACTIVE" | translate}}</button> -->
                  <button class="btn-change" (click)="chargeBeePortal(user.user.id)" *ngIf="user.userPaymentInfo.paymentType == 'CHARGEBEE'">{{"PROFILE_MENU.MANAGE" | translate}}</button>
                </span>
                <span *ngIf="user.userPaymentInfo.subscriptionExpirationPendingStatus == 'SUSPENDED' || user.userPaymentInfo.subscriptionExpirationPendingStatus == 'CUST_CANCELED' || user.userPaymentInfo.subscriptionStatusRequest == 'SUSPENDED' || user.userPaymentInfo.subscriptionStatusRequest == 'CUST_CANCELED'">
                  {{"USER-EDIT.ACTIVE" | translate}}
                  <fa-icon [icon]="icon.faCircle" class="icon payed"></fa-icon>,
                  {{"USER-EDIT.NEXT" | translate}}: {{"USER-EDIT.SUSPENDED" | translate}}
                </span>
              </div>
              <span *ngIf="user.userPaymentInfo.subscriptionStatus === 'PAUSED'">
                {{"USER-EDIT.PAUSED" | translate}}
                <fa-icon [icon]="icon.faCircle" class="icon paused"></fa-icon>
                <!-- <button class="btn-change" (click)="changeSubStatus('ACTIVE')">{{"USER-EDIT.SET_ACTIVE" | translate}}</button> -->
                <button class="btn-change" (click)="chargeBeePortal(user.user.id)" *ngIf="user.userPaymentInfo.paymentType == 'CHARGEBEE'">{{"PROFILE_MENU.MANAGE" | translate}}</button>
              </span>
              <span *ngIf="user.userPaymentInfo && user.userPaymentInfo.subscriptionStatus === 'SUSPENDED' || user.userPaymentInfo && user.userPaymentInfo.subscriptionStatus === 'CUST_CANCELED' || user.userPaymentInfo && user.userPaymentInfo.subscriptionStatus == null || user.userPaymentInfo == null">
                {{"USER-EDIT.SUSPENDED" | translate}}
                <fa-icon [icon]="icon.faCircle" class="icon not-payed"></fa-icon>
              </span>
            </div>

            <div @stagger class="flex-auto mt-6" >

                <div class="m-0 body-1 mr-3">{{"USER-EDIT.SUB_TYPE" | translate}}</div>
                {{user.userPaymentInfo.subscriptionType}}
            </div>

            <div @stagger class="flex-auto mt-6" >

                <div class="m-0 body-1">{{"USER-EDIT.SUB_EXP" | translate}}</div>
                {{user.userPaymentInfo.subscriptionExpiration | date: 'shortDate'}}

            </div>
            <div class="flex-auto mt-6">
              <div class="m-0 body-1">{{"USER-EDIT.CODE" | translate}}</div>
              {{user.userPaymentInfo.activePromotionCodes }}
            </div>
           </div>
        </div>

      </div>

      <div class="card max-w-unset md:max-w-xs w-full flex-none md:ltr:ml-3 md:rtl:mr-3 mt-6 md:mt-0 promos">
        <div class="px-6 py-4 border-b">
          <h2 class="title m-0">{{"USER-EDIT.LAST_ACT" | translate}}</h2>
        </div>

        <div @stagger class="px-6 py-4 flex flex-col gap-4" >
            <div @stagger class="lasts">
              <div class="m-0 body-1">{{"USER-EDIT.LOGIN_COUNT" | translate}}</div>
              <span>{{user.user.loginCount}}</span>
            </div>
            <span *ngIf="programInfo">
              <div @stagger class="lasts">
                <div class="m-0 body-1">{{"USER-EDIT.LOGIN_LAST" | translate}}</div>
                <span></span>
              </div>
              <div @stagger class="lasts">
                <div class="m-0 body-1">{{"USER-EDIT.LAST_PROG" | translate}}</div>
                <span>{{programInfo.name}}</span>
              </div>
              <div @stagger class="lasts">
                <div class="m-0 body-1">{{"USER-EDIT.LAST_WEEK" | translate}}</div>
                <span>{{item.lastWorkoutProgram.week}}</span>
              </div>
              <div @stagger class="lasts">
                <div class="m-0 body-1">{{"USER-EDIT.LAST_WORK" | translate}}</div>
                <span>{{lastWorkoutName}}</span>
              </div>
            </span>
        </div>

        <div class="px-6 py-4 border-b">
          <h2 class="title m-0">{{"USER-EDIT.SUB" | translate}}</h2>
        </div>
        <div @stagger class="px-6 py-4 flex flex-col gap-4">

          <span *ngIf="user.userPaymentInfo != null && user.userPaymentInfo.subscriptionId != null && user.userPaymentInfo.subscriptionType == 'DIAMOND'">
            <div class="lasts" >
              <div class="m-0 body-1">{{"USER-EDIT.DIET" | translate}}</div>
              <span *ngIf="user.masterData.lastDietUploaded != null && checkDietUpload(user.masterData.lastDietUploaded,user.masterData.lastSurvey)">
                <fa-icon [icon]="icon.faCheck" class="icon survey is-survey"></fa-icon>
                {{"PROFILE_MENU.DIET" | translate}}
                {{user.masterData.lastDietUploaded | date: 'shortDate'}}
              </span>
              <span *ngIf="user.masterData.lastDietUploaded == null || !checkDietUpload(user.masterData.lastDietUploaded,user.masterData.lastSurvey)">
                <fa-icon [icon]="icon.faCheck" class="icon survey not-survey"></fa-icon>
                {{"PROFILE_MENU.NOT_DIET" | translate}}
              </span>
            </div>
            </span>
            <span *ngIf="user.userPaymentInfo != null && user.userPaymentInfo.subscriptionId != null && user.userPaymentInfo.subscriptionType == 'DIAMOND'">
            <div class="lasts" >
              <div class="m-0 body-1">{{"USER-EDIT.SURVEY" | translate}}</div>
                <span *ngIf="user.masterData.lastSurvey != null && mnt().diff(mnt(user.masterData.lastSurvey), 'days') <= 30">
                  <fa-icon [icon]="icon.faCheck" class="icon survey is-survey"></fa-icon>
                  {{"PROFILE_MENU.SURVEY" | translate}}
                  {{user.masterData.lastSurvey | date: 'shortDate'}}
                </span>
              <span *ngIf="user.masterData.lastSurvey == null || mnt().diff(mnt(user.masterData.lastSurvey), 'days') > 30">
                <fa-icon [icon]="icon.faCheck" class="icon survey not-survey"></fa-icon>
                {{"PROFILE_MENU.NOT_SURVEY" | translate}}
              </span>
            </div>
          </span>

          <div class="lasts" *ngIf="user.userPaymentInfo != null">

            <div class="m-0 body-1">{{"USER-EDIT.PAY_STATUS" | translate}}</div>

            <span *ngIf="user.userPaymentInfo != null && user.userPaymentInfo.subscriptionStatus != null && user.userPaymentInfo.subscriptionStatus != 'SUSPENDED'">
                <fa-icon [icon]="icon.faCheck" class="icon survey is-survey"></fa-icon>
                {{"PROFILE_MENU.PAYED" | translate}}
                {{user.userPaymentInfo.subscriptionLastActivation | date: 'shortDate'}}
            </span>
            <span *ngIf="user.userPaymentInfo != null && user.userPaymentInfo.subscriptionStatus != null && user.userPaymentInfo.subscriptionStatus == 'SUSPENDED' || user.userPaymentInfo && user.userPaymentInfo.subscriptionStatus == null || user.userPaymentInfo == null">
              <fa-icon [icon]="icon.faCheck" class="icon survey not-survey"></fa-icon>
              {{"PROFILE_MENU.NOT_PAYED" | translate}}
            </span>

        </div>
        <div class="lasts" *ngIf="user.userPaymentInfo != null">

          <div class="m-0 body-1">{{"CUST_TABLE.LAST_PAY" | translate}}</div>

          <span *ngIf="user.userPaymentInfo.lastPayment">
              {{user.userPaymentInfo.lastPayment.amount | currency}}
              <br>
              {{user.userPaymentInfo.lastPayment.date | date: 'shortDate'}}
          </span>
      </div>

        <div class="lasts" *ngIf="user.user.imported != null">

          <div class="m-0 body-1">{{"USER-EDIT.PLATFORM" | translate}}</div>
          <span *ngIf="user.user.imported == true">
            <mat-icon svgIcon="mat:swap_vert" matTooltip="memberpress"></mat-icon>
            Memberpress
          </span>
          <span *ngIf="user.user.imported == false">
            Itsdesifit FE
          </span>
      </div>

      <div class="lasts" *ngIf="user.userPaymentInfo != null && user.userPaymentInfo.subscriptionExpirationPendingStatus == 'PAUSED'">

          <div class="m-0 body-1">{{"USER-EDIT.P_INFO" | translate}}</div>
          <span *ngIf="user.userPaymentInfo.subscriptionSuspensionTypeRequest"> {{"USER-EDIT.SUSP_TIME" | translate}}: <span *ngIf="user.userPaymentInfo.subscriptionSuspensionTypeRequest">{{("USER-EDIT." + user.userPaymentInfo.subscriptionSuspensionTypeRequest) | translate}}</span></span> <br>
          <span *ngIf="user.userPaymentInfo.subscriptionReactivationDate">{{"USER-EDIT.REACT" | translate}}: {{user.userPaymentInfo.subscriptionReactivationDate | date: 'shortDate'}}</span>
      </div>

        </div>
      </div>

    </div>
    <div class="bottoni">
      <button mat-button-base (click)="confirmClose()"  class="m-4 bianco">{{"GLOBAL.CLOSE" | translate}}</button>
      <button mat-raised-button  class="m-4 bottone-viola" (click)="submit()">{{"GLOBAL.EDIT" | translate}}</button>
    </div>
  </form>
</div>
