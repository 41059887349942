import { GuiService } from 'src/app/services/gui.service';
import { RestService } from 'src/app/services/rest.service';
import { TimeChallengeComponent } from './../time-challenge.component';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { ExcelService } from 'src/app/services/excel.service';

@Component({
  selector: 'vex-time-challenge-detail',
  templateUrl: './time-challenge-detail.component.html',
  styleUrls: ['./time-challenge-detail.component.scss']
})
export class TimeChallengeDetailComponent implements OnInit {
  pageNumberAccepted: number = 0;
  pageNumberRejected: number = 0;
  pageNumberPending: number = 0;

  lunghezzaAccepted
  lunghezzaRejected
  lunghezzaPending

  accepted: any;
  rejected: any;
  pending: any;
  dataSource2;


  constructor(private dialogRef: MatDialogRef<TimeChallengeComponent>,@Inject(MAT_DIALOG_DATA) public item: any['item'], public restService: RestService, public guiService: GuiService,public translate: TranslateService, private excelService: ExcelService) { }

  ngOnInit(): void {
    console.debug(this.item)
    if(this.item.type == 'rejected'){
      this.getRejected()
    }else if(this.item.type == 'accepted'){
      this.getAccepted()
    }else if(this.item.type == 'pending'){
      this.getPending()
    }
  }

  onPageChangedAccepted(pageEvent: PageEvent) {
    this.pageNumberAccepted = pageEvent.pageIndex;
    this.getAccepted()
  }

  onPageChangedRejected(pageEvent: PageEvent) {
    this.pageNumberRejected = pageEvent.pageIndex;
    this.getRejected()
  }

  onPageChangedPending(pageEvent: PageEvent) {
    this.pageNumberPending = pageEvent.pageIndex;
    this.getPending()
  }

  getAccepted(){
    setTimeout(()=>this.guiService.showSpinner = true,0)
    this.restService.postService('report','/challenge-accepted', {page: this.pageNumberAccepted, size: 10, challengeId: this.item.data.id}).subscribe((ris)=>{
      console.debug('accepted',ris)
      this.accepted = {}
      this.accepted = ris
      this.lunghezzaAccepted = ris.totalItems
      this.guiService.showSpinner = false
    })
  }

  getRejected(){
    setTimeout(()=>this.guiService.showSpinner = true,0)
    this.restService.postService('report','/challenge-rejected', {page: this.pageNumberRejected, size: 10, challengeId: this.item.data.id}).subscribe((ris)=>{
      console.debug('rejected',ris)
      this.rejected = {}
      this.rejected = ris
      this.lunghezzaRejected = ris.totalItems
      this.guiService.showSpinner = false
    })
  }

  getPending(){
    setTimeout(()=>this.guiService.showSpinner = true,0)
    this.restService.postService('report','/challenge-pending', {page: this.pageNumberPending, size: 10, challengeId: this.item.data.id}).subscribe((ris)=>{
      console.debug('pending',ris)
      this.pending = {}
      this.pending = ris
      this.lunghezzaPending = ris.totalItems
      this.guiService.showSpinner = false
    })
  }

  exportAsXLSX(type?:any): void {
    let ref
    if(type == 'pending'){
      ref = this.lunghezzaPending
    }if(type == 'rejected'){
      ref = this.lunghezzaRejected
    }else if(type == 'accepted'){
      ref = this.lunghezzaAccepted
    }
    console.debug(ref)
    setTimeout(()=>this.guiService.showSpinner = true,0)
    this.restService.postService('report','/challenge-'+type, {page: 0, size: ref, challengeId: this.item.data.id}).subscribe((ris)=>{
      this.dataSource2 = ris.items
      let excelJsonData:any = this.dataSource2;
      console.log(JSON.stringify(excelJsonData));
      this.excelService.exportAsExcelFile(excelJsonData, "sample");
      this.guiService.showSpinner = false
    })

  }

}
