<div mat-dialog-content>
    <div *ngIf="translate.currentLang == 'en'">
      <p class="red">NOTICE:</p>
      Before beginning a post-pregnancy fitness program, you should discuss with your physician or healthcare provider. Please read the following statements carefully:
      <ul>
        <li>I did not experience complications of any kind during labor;</li>
        <li>I have completed my postpartum medical check-up at 6-week postpartum and obtained authorization from my physician or healthcare provider to resume physical activity and participate in exercise;</li>
        <li>The examination carried out by a medical professional did not reveal any significant complications of the pelvic floor or weakening of the latter;</li>
        <li>I do not currently suffer from diastasis rectus abdominis or significant pain related to childbirth or have suffered in the past. </li>
      </ul>
      I certify that the statements contained herein are true and I accept under my sole responsibility the consequences deriving from signing false statements.
    </div>
    <div *ngIf="translate.currentLang == 'it'">
      <p class="red">NOTA:</p>
      Al fine di intraprendere un percorso di allenamento sicuro e responsabile, pregasi leggere
      attentamente quanto segue:
      <ul>
        <li>Non si sono verificate complicazioni di varia natura durante il travaglio;</li>
        <li>Trascorse le sei settimane, ho effettuato il controllo medico post -parto e ottenuto l’autorizzazione alla ripresa dell’attività fisica;</li>
        <li>L’esame effettuato dallo specialista non ha rilevato significative complicazioni del  pavimento pelvico o l’indebolimento dello stesso;</li>
        <li>Non ho sofferto in passato e non soffro attualmente di diastasi dei retti addominali, né di significativi dolori correlati al parto.</li>
      </ul>
      Con la presente affermo che le dichiarazioni contenute nel presente documento corrispondono
      totalmente al vero e accetto sotto la mia esclusiva responsabilità le conseguenze derivanti dalla
      sottoscrizione di dichiarazioni mendaci.
    </div>
</div>
<div class="btn-group">
  <button (click)="close()">{{"CHANGE_PROGRAM.CANCEL" | translate}}</button>
  <button cdkFocusInitial (click)="accept()">{{"CHANGE_PROGRAM.SURE2" | translate}}</button>
</div>
