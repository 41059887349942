<div class="container mt-4">
  <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">
    <h2 class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l hidden sm:block flex-none">
      <span *ngIf="selection.isEmpty()">{{"SUBS" | translate}}</span>
    </h2>
    <div
      class="rounded-full border px-4 max-w-[300px] flex-auto flex items-center border border-gray-300">
      <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon>
      <input [formControl]="searchCtrl"  class="px-4 py-2 border-0 outline-none w-full bg-transparent" placeholder="{{'GLOBAL.SEARCH' | translate}}" type="search" />
    </div>

    <button class="ml-4 flex-none ltr:mr-4 ltr:border-r" color="primary" mat-mini-fab type="button" (click)="search();resetPage()">
      <mat-icon svgIcon="mat:search"></mat-icon>
    </button>
    <button (click)="reload()" class="ml-4 flex-none blue-bottone" color="primary" mat-mini-fab matTooltip="reload" type="button" >
      <mat-icon svgIcon="mat:autorenew"></mat-icon>
    </button>
    <button class="ml-4 flex-none" color="primary" mat-mini-fab matTooltip="Add Customer" type="button" (click)="createSub()">
      <mat-icon svgIcon="mat:add"></mat-icon>
    </button>
  </div>

  <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center bg2">
    <div class="flex items-center filter-bar">
      <nav class="vex-tabs border-0 flex-auto cerca" mat-tab-nav-bar>
        <mat-form-field class="nut-form">
          <mat-label class="label-light">{{"SUB_TABLE.PERIOD" | translate}}</mat-label>
          <mat-select [formControl]="searchCtrlDuration">
            <mat-option [value]="dur.key" *ngFor="let dur of duration">{{dur.description}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="nut-form">
          <mat-label class="label-light">{{"SUB_TABLE.VALID_FROM" | translate}}</mat-label>
          <input matInput [formControl]="searchCtrlStart" type="date">
        </mat-form-field>
        <!-- <mat-form-field class="nut-form">
          <mat-label class="label-light">{{"SUB_TABLE.VALID_TO" | translate}}</mat-label>
          <input matInput [formControl]="searchCtrlEnd" type="date">
        </mat-form-field> -->
        <mat-form-field class="nut-form">
          <mat-label class="label-light">{{"SUB_TABLE.FREE_DAYS" | translate}}</mat-label>
          <input matInput [formControl]="searchCtrlFree" type="number">
        </mat-form-field>
      </nav>

    </div>
  </div>
  <table mat-table [dataSource]="dataSource" *ngIf="subs" matSort>
    <ng-container matColumnDef="image">
      <th *matHeaderCellDef mat-header-cell></th>
      <td *matCellDef="let row" class="w-8 min-w-8 pr-0" mat-cell>
      </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header (click)="sortParam('name', asc == true ? false : true)">{{"SUB_TABLE.NAME" | translate}}</th>
      <td mat-cell *matCellDef="let sub">{{ sub.name }}</td>
    </ng-container>
    <ng-container matColumnDef="durata">
      <th mat-header-cell *matHeaderCellDef mat-sort-header (click)="sortParam('duration', asc == true ? false : true)">{{"SUB_TABLE.PERIOD" | translate}}</th>
      <td mat-cell *matCellDef="let sub">
        {{sub.duration}}
      </td>
    </ng-container>
    <ng-container matColumnDef="valid-from">
      <th mat-header-cell *matHeaderCellDef mat-sort-header (click)="sortParam('startDate', asc == true ? false : true)">{{"SUB_TABLE.VALID_FROM" | translate}}</th>
      <td mat-cell *matCellDef="let sub">{{ sub.startDate | date: 'shortDate'}}</td>
    </ng-container>
    <!-- <ng-container matColumnDef="valid-to">
      <th mat-header-cell *matHeaderCellDef mat-sort-header (click)="sortParam('endDate', asc == true ? false : true)">{{"SUB_TABLE.VALID_TO" | translate}}</th>
      <td mat-cell *matCellDef="let sub">{{ sub.endDate | date: 'shortDate'}}</td>
    </ng-container> -->
    <ng-container matColumnDef="free-days">
      <th mat-header-cell *matHeaderCellDef mat-sort-header (click)="sortParam('freeDays', asc == true ? false : true)">{{"SUB_TABLE.FREE_DAYS" | translate}}</th>
      <td mat-cell *matCellDef="let sub">
        {{sub.trialPeriod}}
      </td>
    </ng-container>
    <ng-container matColumnDef="price">
      <th mat-header-cell *matHeaderCellDef>{{"SUB_TABLE.PRICE" | translate}}</th>
      <td mat-cell *matCellDef="let sub">
       <span *ngIf="sub.price">{{sub.price.price}} $</span>
      </td>
    </ng-container>

    <!-- Action Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row" class="w-10 text-secondary" >
        <button (click)="$event.stopPropagation()"
                [matMenuTriggerData]="{ customer: row }"
                [matMenuTriggerFor]="actionsMenu"
                mat-icon-button
                type="button">
          <mat-icon svgIcon="mat:more_horiz"></mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>


  </table>
  <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize" [length]="lunghezza" (page)="onPageChanged($event)" class="sticky left-0"></mat-paginator>

</div>

<mat-menu #actionsMenu="matMenu" xPosition="before" yPosition="below">
  <ng-template let-customer="customer" matMenuContent>
    <button  mat-menu-item (click)="editSub(customer)">
      <mat-icon svgIcon="mat:edit"></mat-icon>
      <span>{{"GLOBAL.EDIT" | translate}}</span>
    </button>
    <button  mat-menu-item (click)="deleteSub(customer.id)">
      <mat-icon svgIcon="mat:delete"></mat-icon>
      <span>{{'GLOBAL.DELETE' | translate}}</span>
    </button>
  </ng-template>
</mat-menu>
