import { TokenInterceptor } from './token.interceptor';
import { TranslateService } from '@ngx-translate/core';
import { GuiService } from 'src/app/services/gui.service';
import {HttpClient, HttpEvent, HttpHandler, HttpHeaders, HttpRequest, HttpResponse} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {catchError, finalize, map} from "rxjs/operators";
import {BehaviorSubject, Observable, of, throwError} from "rxjs";
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';


export interface AuthData {
  token: string
}
@Injectable({
  providedIn: 'root'
})
export class RestService {
  baseURL = environment.baseURL;
  tkn = "";
  lang = "";
  baseOPT = {};
  public tknSubj = new BehaviorSubject<null | AuthData>(null)
  token$ = this.tknSubj.asObservable()

  constructor(public router: Router, private httpClient: HttpClient, public translate: TranslateService, private _snackBar: MatSnackBar) {

  }

  public setTkn(tkn) {
    this.tkn = tkn;
  }
  public getTkn() {
    this.tkn = sessionStorage.getItem("tkn");
    return sessionStorage.getItem("tkn");
  }

  public getService(base, ctx, opt?): Observable<any> {
    !opt ? opt = this.baseOPT : null;
    if(sessionStorage.getItem("tkn") || sessionStorage.getItem("tkn") != null){
      opt = {
          headers: {
              "Authorization": "Bearer "+ sessionStorage.getItem("tkn"),
              "LANGUAGE_CODE": this.translate.currentLang.toUpperCase()
          }
      };
  }
    return this.httpClient.get(this.baseURL[base] + '/' + ctx, opt).pipe(
        catchError((err) => {
          console.log('error caught in service');
          console.error(err.message);

          //Handle the error here

          return throwError(err);    //Rethrow it back to component
        })
    );
  }


  public doLogout() {
    sessionStorage.removeItem("tkn");
    sessionStorage.removeItem("exp");
    sessionStorage.removeItem("username");
    sessionStorage.removeItem("menu");
    sessionStorage.removeItem("selectedProgramId");
    sessionStorage.removeItem("selectedProgram");
    this.tknSubj.next(null);
    this.tkn = null;
    this.setTkn(null);
    this.router.navigate(["auth/login"]);
  }

  public postService(base, ctx, json, opt?): Observable<any> {

    !opt ? opt = this.baseOPT : null;
    if(sessionStorage.getItem("tkn") || sessionStorage.getItem("tkn") != null){
      opt = {
          headers: {
              "Authorization": "Bearer "+ sessionStorage.getItem("tkn"),
              "LANGUAGE_CODE": this.translate.currentLang.toUpperCase()
          }
      };
  }

    return this.httpClient.post(this.baseURL[base] + '' + ctx, json, opt).pipe(
        catchError((err) => {
          console.log('error caught in service')
          console.error(err);

          //Handle the error here

          return throwError(err);    //Rethrow it back to component
        })
    );
  }

  public putService(base, ctx, json, opt?): Observable<any> {

    !opt ? opt = this.baseOPT : null;

    return this.httpClient.put(this.baseURL[base] + '' + ctx, json, opt).pipe(
        catchError((err) => {
          console.log('error caught in service')
          console.error(err);

          //Handle the error here

          return throwError(err);    //Rethrow it back to component
        })
    );
  }

  public deleteService(base, ctx, json, opt?): Observable<any> {

    !opt ? opt = this.baseOPT : null;

    return this.httpClient.delete(this.baseURL[base] + '' + ctx, {responseType:'text'}).pipe(
        catchError((err) => {
          console.debug('error caught in service',err);
          console.error(err);

          //Handle the error here

          return throwError(err);    //Rethrow it back to component
        })
    );
  }

  public doLogin(ctx, json) {
    const opt = {};
    return this.httpClient.post(this.baseURL.login + '' + ctx, json, { observe: "response"}).pipe(
        catchError((err) => {
          if(err.status != 409) {
            this.openSnackBar(this.translate.instant("MSG.LOGIN_FAIL"), this.translate.instant("MSG.CLOSE"), 'error');
          }
          //Handle the error here

          return throwError(err);    //Rethrow it back to component
        })
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    console.debug()
    return (error: any): Observable<T> => {

      this.log(`${operation} failed: ${error.message}`);

      return of(result as T);
    };
  }

  private log(message: string) {
    console.debug(message);
  }

  openSnackBar(message: string, action?: any, typ?: string,time?:number) {
    let config: MatSnackBarConfig = {}
    config.panelClass = typ ? typ : 'default';
    config.duration = time ? time : 3000
    this._snackBar.open(message, action, config);
  }

  public chargeBeeCheckout(base, ctx, json, tkn): Observable<any> {


    return this.httpClient.post(this.baseURL[base] + '' + ctx, json, { headers: {Authorization: 'Bearer ' + tkn}}).pipe(
        catchError((err) => {
          console.log('error caught in service')
          console.error(err);

          //Handle the error here

          return throwError(err);    //Rethrow it back to component
        })
    );
  }

  public stripeCheckout(base, ctx, json, tkn): Observable<any> {


    return this.httpClient.post(this.baseURL[base] + '' + ctx, json, { headers: {Authorization: 'Bearer ' + tkn},responseType: 'text' }).pipe(
        catchError((err) => {
          console.log('error caught in service')
          console.error(err);

          //Handle the error here

          return throwError(err);    //Rethrow it back to component
        })
    );
  }

  public paypalCheckout(base,ctx, tkn): Observable<any>{
    return this.httpClient.get(this.baseURL[base] + ''+ ctx,{ headers: {Authorization: 'Bearer ' + tkn},responseType: 'text' }).pipe(
      catchError((err) => {
        console.log('error caught in Paypal Service ')
        console.error(err);

        //Handle the error here

        return throwError(err);    //Rethrow it back to component
      })
    )
  }

  public getPdf(base, ctx): Observable<any> {

    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    headers = headers.set("Access-Control-Allow-Origin", '*');
    return this.httpClient.get(this.baseURL[base] + '' + ctx, {responseType: 'blob' }).pipe(
        catchError((err) => {
          console.log('error caught in service')
          console.error(err);

          //Handle the error here

          return throwError(err);    //Rethrow it back to component
        })
    );
  }

  public getCsv(base,ctx, json): Observable<any> {

    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');
    headers = headers.set("Access-Control-Allow-Origin", '*');
    return this.httpClient.post(this.baseURL[base] + ''+ctx ,json, {responseType: 'text' }).pipe(
        catchError((err) => {
          console.log('error caught in service')
          console.error(err);

          //Handle the error here

          return throwError(err);    //Rethrow it back to component
        })
    );
  }

  public handleUrl(base, ctx, json, opt?): Observable<any> {


    return this.httpClient.post(this.baseURL[base] + '' + ctx, json, opt).pipe(
        catchError((err) => {
          console.log('error caught in service')
          console.error(err);

          //Handle the error here

          return throwError(err);    //Rethrow it back to component
        })
    );
  }
}
