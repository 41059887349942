import { data } from 'jquery';
import { RestService } from './../../services/rest.service';
import { GuiService } from 'src/app/services/gui.service';
import { Component, OnInit } from '@angular/core';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);
import * as moment from 'moment';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'vex-console',
  templateUrl: './console.component.html',
  styleUrls: ['./console.component.scss'],
  animations: [
    trigger(
      'myAnimation',
      [
        transition(
          ':enter', [
          style({ transform: 'translateX(100%)', opacity: 0 }),
          animate('500ms', style({ transform: 'translateX(0)', 'opacity': 1 }))
        ]
        ),
        transition(
          ':leave', [
          style({ transform: 'translateX(0)', 'opacity': 1 }),
          animate('500ms', style({ transform: 'translateX(100%)', 'opacity': 0 }))
        ]
        )]
    )
  ]
})
export class ConsoleComponent implements OnInit {
  actives: number;
  paused: number;
  gold: number;
  diamond: number;
  nutritionists: number;
  admins: number;
  ambassadors: number;
  expandToggle: boolean | null = null;
  expandeToggleAllUsers: boolean | null = null;
  allUsers: number;
  custCanceled: number;
  expired: number;
  suspended: number;


  constructor(public guiService: GuiService, public restService: RestService, public router: Router) { }

  ngOnInit(): void {
    setTimeout(() => this.guiService.showSpinner = false, 0)
    this.activeUsers()
    this.goldUsers()
    this.diamondUsers()
    this.getAllUsers()
  }

  activeUsers() {
    let param = {
      page: 0,
      size: 1,
      payment: {
        filterAnd: [
          {
            field: "subscriptionStatus",
            operator: "like",
            value: "ACTIVE"
          }
        ],
        orders: []
      }
    };
    this.restService.postService('users', '/full/search', param).subscribe((ris) => {
      this.actives = ris.totalItems
    })
  }

  pausedUsers() {
    let param = {
      page: 0,
      size: 1,
      payment: {
        filterAnd: [
          {
            field: "subscriptionStatus",
            operator: "like",
            value: "PAUSED"
          }
        ],
        orders: []
      }
    };
    this.restService.postService('users', '/full/search', param).subscribe((ris) => {
      this.paused = ris.totalItems
    })
  }

  goldUsers() {
    let param = {
      page: 0,
      size: 1,
      payment: {
        filterAnd: [
          {
            field: "subscriptionType",
            operator: "like",
            value: "GOLD"
          },
          {
            field: "subscriptionStatus",
            operator: "like",
            value: "ACTIVE"
          }
        ],
        orders: []
      },
      "user":{
        "filterAnd": [
      {
      "field": "roles",
      "operator": "neq",
      "value": 'AMBASSADOR'
      },
      {
        "field": "roles",
        "operator": "neq",
        "value": 'ADMIN'
      },
      {
        "field": "roles",
        "operator": "neq",
        "value": 'NUTRITIONIST'
      }
      ]
      }
    };
    this.restService.postService('users', '/full/search', param).subscribe((ris) => {
      this.gold = ris.totalItems
    })
  }

  getAmbassadors() {
    let param = {
      page: 0,
      size: 1,
      "user": {
        "filterAnd": [
          {
            "field": "roles",
            "operator": "eq",
            "value": 'AMBASSADOR'
          }
        ]
      }
    };
    this.restService.postService('users', '/full/search', param).subscribe((ris) => {
      this.ambassadors = ris.totalItems;
    })
  }

  getAdmins() {
    let param = {
      page: 0,
      size: 1,
      "user": {
        "filterAnd": [
          {
            "field": "roles",
            "operator": "eq",
            "value": 'ADMIN'
          }
        ]
      }
    };
    this.restService.postService('users', '/full/search', param).subscribe((ris) => {
      this.admins = ris.totalItems;
    })
  }

  getNutritionists() {
    let param = {
      page: 0,
      size: 1,
      "user": {
        "filterAnd": [
          {
            "field": "roles",
            "operator": "eq",
            "value": 'NUTRITIONIST'
          }
        ]
      }
    };
    this.restService.postService('users', '/full/search', param).subscribe((ris) => {
      this.nutritionists = ris.totalItems;
    })
  }

  getAllUsers() {
    let param = {
      page: 0,
      size: 1
    };
    this.restService.postService('users', '/full/search', param).subscribe((ris) => {
      this.allUsers = ris.totalItems;
    })
  }

  diamondUsers() {
    let param = {
      page: 0,
      size: 1,
      payment: {
        filterAnd: [
          {
            field: "subscriptionType",
            operator: "like",
            value: "DIAMOND"
          },
          {
            field: "subscriptionStatus",
            operator: "like",
            value: "ACTIVE"
          }
        ],
        orders: []
      },
      "user":{
        "filterAnd": [
      {
      "field": "roles",
      "operator": "neq",
      "value": 'AMBASSADOR'
      },
      {
        "field": "roles",
        "operator": "neq",
        "value": 'ADMIN'
      },
      {
        "field": "roles",
        "operator": "neq",
        "value": 'NUTRITIONIST'
      }
      ]
      }
    };
    this.restService.postService('users', '/full/search', param).subscribe((ris) => {
      this.diamond = ris.totalItems
    })
  }

  calcActiveUsers() {
    /* CALCOLO PER UTENTI ATTIVI DI RUOLO 'USER' */
    return this.actives - (this.admins + this.nutritionists + this.ambassadors)
  }

  expand(param: boolean) {
    if (this.expandToggle == null) {
      this.getAdmins();
      this.getAmbassadors();
      this.getNutritionists();
    }
    this.expandToggle = param
  }

  getCustCanceled() {
    let param = {
      page: 0,
      size: 1,
      payment: {
        filterAnd: [
          {
            field: "subscriptionStatus",
            operator: "like",
            value: "CUST_CANCELED"
          }
        ],
        orders: []
      }
    };
    this.restService.postService('users', '/full/search', param).subscribe((ris) => {
      this.custCanceled = ris.totalItems;
    })
  }

  getSuspended() {
    let param = {
      page: 0,
      size: 1,
      payment: {
        filterAnd: [
          {
            field: "subscriptionStatus",
            operator: "like",
            value: "SUSPENDED"
          }
        ],
        orders: []
      }
    };
    this.restService.postService('users', '/full/search', param).subscribe((ris) => {
      this.suspended = ris.totalItems;
    })
  }

  getExpired() {
    let param = {
      page: 0,
      size: 1,
      payment: {
        filterAnd: [
          {
            field: "subscriptionStatus",
            operator: "like",
            value: "EXPIRED"
          }
        ],
        orders: []
      }
    };
    this.restService.postService('users', '/full/search', param).subscribe((ris) => {
      this.expired = ris.totalItems;
    })
  }

  expandAllUsers(param: boolean) {
    if (this.expandeToggleAllUsers == null) {
      this.getCustCanceled();
      this.getSuspended();
      this.getExpired();
      this.pausedUsers();
    }
    this.expandeToggleAllUsers = param
  }

  calcNA() {
    console.debug(this.allUsers - (this.actives + this.paused + this.custCanceled + this.suspended + this.expired))
    return this.allUsers - (this.actives + this.paused + this.custCanceled + this.suspended + this.expired)
  }

  //  sortByKey(array, key) {
  //   return array.sort(function(a, b) {
  //       var x = a[key]; var y = b[key];
  //       return ((x > y) ? -1 : ((x < y) ? 1 : 0));
  //   });
  // }

}

