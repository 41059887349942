import { GuiService } from 'src/app/services/gui.service';
import { RestService } from 'src/app/services/rest.service';
import { PromotionsComponent } from '../promotions.component';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CloseConfirmComponent } from '../../close-confirm/close-confirm.component';
import moment from 'moment';

@Component({
  selector: 'vex-promotion-new',
  templateUrl: './promotion-new.component.html',
  styleUrls: ['./promotion-new.component.scss']
})
export class PromotionNewComponent implements OnInit {

  form: UntypedFormGroup
  subTypes: any;
  subDuration:any;
  subscriptions: any;

  constructor(private fb: UntypedFormBuilder,  private dialogRef: MatDialogRef<PromotionsComponent>,public translate: TranslateService, public restService: RestService, public guiService: GuiService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      codice:[null, Validators.required],
      validfrom:[null, Validators.required],
      validto:[null],
      quantita:[null],
      percentuale: [null],
      giorni: [null],
      prezzo: [],
      sub: [null],
      durationInCycles: [null],
      resetTrial: [null],
      sub_duration: [null]
    })
    this.getSubs()

  }

  submit(form){
    let tmpFrom = moment(this.form.value.validfrom)
    let tmpTo = moment(this.form.value.validto)
    let offSet = moment().utcOffset()

    let newPromo = {
      code: this.form.value.codice,
      comulable: false,
      description: "",
      endDate: this.form.value.validto ? tmpTo.format("YYYY")+"-"+tmpTo.format("MM")+"-"+tmpTo.format("DD") : null,
      freeDays: this.form.value.giorni,
      i18nDescription: {},
      i18nName: {},
      image: "",
      imageFileName: "",
      name: "",
      percentage: this.form.value.percentuale,
      amountOff: {currency: "$",price: this.form.value.prezzo},
      repeatable: false,
      startDate: tmpFrom.format("YYYY")+"-"+tmpFrom.format("MM")+"-"+tmpFrom.format("DD"),
      quantity: this.form.value.quantita,
      subscriptionTypeRetrictions: this.form.value.sub,
      durationInCycles: this.form.value.durationInCycles,
      resetTrialDays: this.form.value.resetTrial
    }
    this.restService.postService('promotions', '', newPromo).subscribe((ris)=>{
      this.form.value.sub_duration ? this.setCouponInMockApi() : null
      console.debug(ris)
      this.dialogRef.close('done')
      this.guiService.openSnackBar(ris.name + this.translate.instant('GLOBAL.NEW_SUCCESS'), this.translate.instant('GLOBAL.CLOSE'))
    })
  }

  getSubType(){
    this.restService.getService('bos', 'subscription-type').subscribe((ris)=>{
      console.debug('tipo sub', ris);
      this.subTypes = ris;
      this.getSubDuration()
    })
  }

  getSubs(){
    setTimeout(()=>this.guiService.showSpinner = true,0)
    this.restService.postService('subscriptions', '/search', {page: 0,size: 30}).subscribe((ris)=>{
      console.debug('subscriptions',ris.items)
      this.subscriptions = ris.items
      this.getSubType()

    })
  }

  checkSubSelection(event:any){
    console.debug(event)
  }

  setCouponInMockApi(){
    let tmpItem = {
      "code": this.form.value.codice,
      "identifier": []
    }

    for(let sub of this.subscriptions){
      if(this.form.value.sub.includes(sub.type)){
        if(this.form.value.sub_duration.includes(sub.duration)){
          tmpItem.identifier.push(sub.id)
        }
      }
    }
    console.debug(tmpItem)
    this.restService.postService('mock','promos',tmpItem).subscribe((ris)=>{
      this.guiService.openSnackBar('Restrizioni impostate su mock!', this.translate.instant('GLOBAL.CLOSE'))
    })
  }

  getSubDuration(){
    this.restService.getService('bos', 'subscription-duration').subscribe((ris)=>{
      console.debug('subscription-duration',ris)
      this.subDuration = ris
      this.guiService.showSpinner = false
    })
  }

  confirmClose(){
    this.dialog.open(CloseConfirmComponent,{
      disableClose: true,
      hasBackdrop: true,
      panelClass: 'close-dialog-modalbox'
    }).afterClosed().subscribe((ris)=>{
      if(ris == 'true'){
        this.dialogRef.close()
      }
    })
  }

}
