import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { faCalendarWeek } from '@fortawesome/free-solid-svg-icons';
import { GuiService } from 'src/app/services/gui.service';
import { RestService } from 'src/app/services/rest.service';
import { environment } from 'src/environments/environment';
import { DashboardComponent } from '../../dashboard/dashboard.component';

@Component({
  selector: 'vex-user-program-info',
  templateUrl: './user-program-info.component.html',
  styleUrls: ['./user-program-info.component.scss']
})
export class UserProgramInfoComponent implements OnInit {

  icon = {
    "faCalendar": faCalendarWeek,

  }
  program
  filteredIds = {}
  environment = environment.baseURL.workout_programs

  constructor(private dialogRef: MatDialogRef<UserProgramInfoComponent>,
    @Inject(MAT_DIALOG_DATA) private item: any['item'],
    public restService: RestService,
    public guiService: GuiService,
    private rotta: Router) { }

  ngOnInit(): void {
    console.debug(this.item)
    this.program = this.item
  }

closeDialog(){
  this.dialogRef.close()
}

}
