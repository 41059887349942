import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'vex-rest-dialog',
  templateUrl: './rest-dialog.component.html',
  styleUrls: ['./rest-dialog.component.scss']
})
export class RestDialogComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<any>) { }

  ngOnInit(): void {
  }

  iWantRest(){
    this.dialogRef.close()
  }

  close(){
    this.dialogRef.close('true')
  }

}
