import { NutritionDetailComponent } from './nutrition-detail/nutrition-detail.component';
import { GuiService } from "src/app/services/gui.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import { faCircle, faCheck, faPlus } from "@fortawesome/free-solid-svg-icons";
import { SelectionModel } from "@angular/cdk/collections";
import { UntypedFormControl } from "@angular/forms";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { RestService } from "src/app/services/rest.service";
import * as moment from 'moment';
import { ExcelService } from 'src/app/services/excel.service';

@Component({
  selector: "vex-nutrition",
  templateUrl: "./nutrition.component.html",
  styleUrls: ["./nutrition.component.scss"],
})
export class NutritionComponent implements OnInit {
  icon = {
    "faCircle": faCircle,
    "faCheck": faCheck,
    "faPlus": faPlus
  };

  icons = {
    "PAID": '<fa-icon [icon]="icon.faCircle" class="icon payed"></fa-icon>',
    "NOT_PAID": '<fa-icon [icon]="icon.faCircle" class="icon not-payed"></fa-icon>'
  }

  mnt = moment

  users: any;
  dataSource: MatTableDataSource<any> | null;
  displayedColumns: string[] = [
    "checkbox",
    "utente",
    "nutrizionista",
    "data-iscrizione",
    "stato-pagamento",
    "questionario",
    "upload-diet",
    "actions",
  ];
  searchCtrl = new UntypedFormControl();
  pageSize = 10;
  pageNumber = 0;
  pageSizeOptions: number[] = [5, 10, 15, 50];
  lunghezza: any;

  checkedUser = [];
  nutrizioniste = [];
  nutSelected: any
  nutIndex = {}
  searchCtrlNutr = new UntypedFormControl();
  searchCtrlReg_from = new UntypedFormControl();
  searchCtrlReg_to = new UntypedFormControl();
  searchCtrlPay = new UntypedFormControl();
  searchCtrlSurvey_from = new UntypedFormControl();
  searchCtrlSurvey_to = new UntypedFormControl();
  searchCtrlDiet_from = new UntypedFormControl();
  searchCtrlDiet_to = new UntypedFormControl();
  searchCtrlName = new UntypedFormControl();
  searchCtrlLastName = new UntypedFormControl();
  /* sort var */
  asc: any;
  fieldParam: string;
  searchAltFlag: boolean;
  sortFlag: boolean;

  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private restService: RestService,
    public translate: TranslateService,
    private dialog: MatDialog,
    public guiService: GuiService,
    private excelService: ExcelService
  ) {}

  ngOnInit(): void {
    this.getNutritionist()
    this.asc = true;
    this.searchAltFlag = false;
    this.sortFlag = false;
  }

  ngAfterViewInit(): void {
    //this.dataSource.paginator = this.paginator;
    //this.dataSource.sort = this.sort;
  }

  getUsers() {
    setTimeout(() => (this.guiService.showSpinner = true), 0);
    this.dataSource = new MatTableDataSource();
    let param = {
      payment:{
        "filterAnd": [
      {
      field: "subscriptionType",
      operator: "eq",
      value: 'DIAMOND'
      },
      {
        field: "subscriptionStatus",
        operator: "eq",
        value: 'ACTIVE'
      }
      ]
      },
      masterData: {
        orders: [
          {
            field: "registrationDate",
            ascending: false
          }
        ]
      },
      page: this.pageNumber,
      size: this.pageSize
     }
    this.restService.postService('users', '/full/search', param).subscribe((ris) => {
      this.lunghezza = ris.totalItems
      this.users = ris["items"];
      console.debug('utenti', ris.items)
      this.dataSource.data = this.users;
      this.table ? this.table.renderRows() : null;
      this.guiService.showSpinner = false;
    });
  }

  getNutritionist(){
    let param = {
      "user":{
        "filterAnd": [
      {
      "field": "roles",
      "operator": "eq",
      "value": 'NUTRITIONIST'
      }
      ]
      }
     }
    this.restService.postService('users','/full/search',param).subscribe((ris)=>{
      this.nutrizioniste = []
      for(let i = 0; i < ris.items.length; i++){
        this.nutrizioniste.push(ris.items[i].masterData)
        this.nutIndex[ris.items[i].masterData.userId] = ris.items[i].masterData.name
      }
      console.debug(this.nutrizioniste)
      this.getUsers();
    })
  }


  onPageChanged(pageEvent: PageEvent) {
    this.pageNumber = pageEvent.pageIndex+1;
    this.pageSize = pageEvent.pageSize;
    if(this.searchAltFlag == false && this.sortFlag == false){
      this.getUsers()
    }else if(this.sortFlag == true){
      this.sortParam(this.fieldParam, this.asc)
    }else if(this.searchAltFlag == true){
      this.search(this.fieldParam, this.asc)
    }
  }

  userDetail(item?: any['item']){
    console.debug(item)
    this.dialog.open(NutritionDetailComponent, {
      width: '65vw',
      maxWidth: '65vw',
      height: '95vh',
      panelClass: 'user-detail',
      data: item || null,
      disableClose: true,
      hasBackdrop: true
    }).afterClosed().subscribe((user) => {
      if(user){

      }
    })
  }

  selectUser(i){
  console.debug(i)
  let indice = i
  if(this.checkedUser.includes(indice) === false){
    this.checkedUser.push(indice)
    console.debug(this.checkedUser)
  }
  else{
    var index = this.checkedUser.indexOf(indice);
    if (index !== -1) {
      this.checkedUser.splice(index, 1);
      console.debug(this.checkedUser)
    }
  }

  }

  // sceltaNutrizionista(nut, $event){
  //   this.nutSelected = nut
  //   $event.stopPropagation();
  // }

  assegna(nut){
    let assignNut = {
      nutritionistId: nut.userId,
      usersId: this.checkedUser
    }

    this.restService.postService('nutrition_survey', '/assign', assignNut).subscribe((ris)=>{
      this.guiService.openSnackBar(this.translate.instant("NUTRI_TABLE.ASSIGN") + ' '+ nut.name, this.translate.instant("GLOBAL.CLOSE"))
      console.debug('assegnati a ', nut.name)
      console.debug(ris)
      this.checkedUser = [];
      this.getUsers()
    })
  }

  search(field?,asc?) {
    this.searchAltFlag = true
    field ? this.fieldParam = field : null
   this.asc = asc
    let param = {
      user: {
        "filterAnd": [],
        "orders":[]
      },
      masterData:{
        "filterAnd": [],
        "orders":[]
      },
      payment:{
        "filterAnd": [
          {
            field: "subscriptionType",
            operator: "eq",
            value: 'DIAMOND'
            }
        ],
        "orders":[]
      },
      "page": this.pageNumber,
      "size": this.pageSize
    }
    /* SORT */
    if(this.fieldParam == 'subscriptionStatus'){
      param.payment['orders'].push(
        {
         field: this.fieldParam,
         ascending : this.asc
        }
      )
    }
    if(this.fieldParam == 'email'){
      param.user['orders'].push(
        {
         field: this.fieldParam,
         ascending : this.asc
        }
      )
    }
    if(this.fieldParam == 'lastDietUploaded' || this.fieldParam == 'lastSurvey' || this.fieldParam == 'nutritionistId'||this.fieldParam == 'registrationDate'){
      param.masterData['orders'].push(
        {
         field: this.fieldParam,
         ascending : this.asc
        }
      )
    }
    /* filtri */
    if(this.searchCtrlNutr.value != null){
      param.masterData["filterAnd"].push(
        {
          "field": "nutritionistId",
          "operator": "eq",
          "value": this.searchCtrlNutr.value
        }
      )
    }
    if(this.searchCtrlReg_from.value != null){
      let reg_to = moment(this.searchCtrlReg_to.value).format('MM-DD-YYYY')
      param.masterData["filterAnd"].push(
        {
          "field": "registrationDate",
          "operator": "gte",
          "dateValue": moment(this.searchCtrlReg_from.value).format('x')
        }
      ),
      param.masterData["filterAnd"].push(
        {
          "field": "registrationDate",
          "operator": "lte",
          "dateValue": moment(reg_to + " 23:59:59").format('x')
        }
      )
    }
    if(this.searchCtrl.value != null){
      param.user["filterAnd"].push(
        {
          "field": "email",
          "operator": "like",
          "value": this.searchCtrl.value.replace(/^\s+|\s+$/gm,'')
        }
      )
    }
    if(this.searchCtrlPay.value != null){
      param.payment["filterAnd"].push(
        {
          "field": "subscriptionStatus",
          "operator": "like",
          "value": this.searchCtrlPay.value
        }
      )
    }
    if(this.searchCtrlName.value != null){
      param.masterData["filterAnd"].push(
        {
          "field": "name",
          "operator": "like",
          "value": this.searchCtrlName.value.replace(/^\s+|\s+$/gm,'')
        }
      )
    }
    if(this.searchCtrlLastName.value != null){
      param.masterData["filterAnd"].push(
        {
          "field": "lastName",
          "operator": "like",
          "value": this.searchCtrlLastName.value.replace(/^\s+|\s+$/gm,'')
        }
      )
    }
    if(this.searchCtrlSurvey_from.value != null){
      let survey_to = moment(this.searchCtrlSurvey_to.value).format('MM-DD-YYYY')
      param.masterData["filterAnd"].push(
        {
          "field": "lastSurvey",
          "operator": "gte",
          "dateValue": moment(this.searchCtrlSurvey_from.value).format('x')
        }
      ),
      param.masterData["filterAnd"].push(
        {
          "field": "lastSurvey",
          "operator": "lte",
          "dateValue": moment(survey_to + " 23:59:59",).format('x')
        }
      )
    }
    if(this.searchCtrlDiet_from.value != null){
      let diet_to = moment(this.searchCtrlDiet_to.value).format('MM-DD-YYYY')
      param.masterData["filterAnd"].push(
        {
          "field": "lastDietUploaded",
          "operator": "gte",
          "dateValue": moment(this.searchCtrlDiet_from.value).format('x')
        }
      ),
      param.masterData["filterAnd"].push(
        {
          "field": "lastDietUploaded",
          "operator": "lte",
          "dateValue": moment(diet_to + " 23:59:59").format('x')
        }
      )
    }
    console.debug('param prima della chiamata', param)
    this.restService.postService('users', '/full/search?ALL_LANGUAGES=true', param).subscribe({
      next: (ris)=>{
        this.lunghezza = ris.totalItems
        this.users = ris["items"];
        console.debug('utenti', ris.items)
        this.dataSource.data = this.users;
        this.table ? this.table.renderRows() : null;
        this.guiService.showSpinner = false;
      },error: (e)=>{
        setTimeout(()=>this.guiService.showSpinner = false,1500)
      }
    })

  }

  sortParam(field?,asc?){
    this.asc = asc
    this.sortFlag = true
    this.fieldParam = field
    let param: any;
    if(this.fieldParam == 'subscriptionStatus'){
      param = {
        page: this.pageNumber,
        size: this.pageSize,
      payment:{
        filterAnd: [
          {
          field: "subscriptionType",
          operator: "eq",
          value: 'DIAMOND'
          },
          ],
        orders: [
        {
         field: this.fieldParam,
         ascending : this.asc
        }
      ]
      }
      }
    }
    if(this.fieldParam == 'email'){
      param = {
        page: this.pageNumber,
        size: this.pageSize,
        user:{
          orders: [
          {
           field: this.fieldParam,
           ascending : this.asc
          }
        ]
        },
        payment:{
          filterAnd: [
            {
            field: "subscriptionType",
            operator: "eq",
            value: 'DIAMOND'
            },
            ]
        }
      }
    }
    if(this.fieldParam == 'lastDietUploaded' || this.fieldParam == 'lastSurvey' || this.fieldParam == 'nutritionistId'||this.fieldParam == 'registrationDate'){
      param = {
        page: this.pageNumber,
        size: this.pageSize,
        masterData:{
          orders: [
          {
           field: this.fieldParam,
           ascending : this.asc
          }
        ]
        },
        payment:{
          filterAnd: [
            {
            field: "subscriptionType",
            operator: "eq",
            value: 'DIAMOND'
            },
            ]
        }
      }
    }
    setTimeout(()=>this.guiService.showSpinner = true,0)
    this.restService.postService('users', '/full/search', param).subscribe({
      next: (ris)=>{
        this.lunghezza = ris.totalItems
      this.users = ris["items"];
      console.debug('utenti', ris.items)
      this.dataSource.data = this.users;
      this.table ? this.table.renderRows() : null;
      this.guiService.showSpinner = false;
      },error:(e)=>{
        setTimeout(()=>this.guiService.showSpinner = false,1500)
      }

    });
  }

  reload(){
    this.searchCtrlNutr.reset()
    this.searchCtrlReg_from.reset()
    this.searchCtrlReg_to.reset()
    this.searchCtrlPay.reset()
    this.searchCtrlSurvey_from.reset()
    this.searchCtrlSurvey_to.reset()
    this.searchCtrlDiet_from.reset()
    this.searchCtrlDiet_to.reset()
    this.searchAltFlag = false;
    this.sortFlag = false;
    this.pageNumber = 0;
    this.pageSize = 10;
    this.paginator.firstPage()
    this.getUsers()
  }

  checkDietUpload(diet,survey){
    return moment(diet).isSameOrAfter(survey)
  }

  check30Days(lastSurvey){
    let now = moment()
    let dateSurveyAfter = moment(lastSurvey).add(1,'months')

    return now.isSameOrAfter(dateSurveyAfter)
  }

  resetPage(){
    this.pageNumber = 0
    this.paginator.firstPage()
    this.search()
  }

  report(){
    let param = {
      payment:{
        "filterAnd": [
      {
      field: "subscriptionType",
      operator: "eq",
      value: 'DIAMOND'
      }
      ]
      },
      masterData: {
        orders: [
          {
            field: "registrationDate",
            ascending: false
          }
        ]
      },
      page: 0,
      size: 2000
     }
     let tmpArr = []
     setTimeout(()=>this.guiService.showSpinner = true,0)
    this.restService.postService('users', '/full/search', param).subscribe((ris) => {
      console.debug(ris)
      for(let i = 0; i < ris.items.length; i++){
        let item = ris.items[i]
        if(item.userPaymentInfo.subscriptionStatus){
          tmpArr.push({
          name: item.masterData.name,
          lastName: item.masterData.lastName,
          email: item.user.email,
          nutritionist: this.nutIndex[item.masterData.nutritionistId],
          promoCode: item.userPaymentInfo.activePromotionCodes ? item.userPaymentInfo.activePromotionCodes[0] : null,
          lastPayment: item.userPaymentInfo.lastPayment ? item.userPaymentInfo.lastPayment.amount : null
        })
        console.debug(item)
        }
      }
      let excelJsonData:any = tmpArr;
      this.excelService.exportAsExcelFile(excelJsonData, "diamond_plans");
      this.guiService.showSpinner = false
    });
  }
}
