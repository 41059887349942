import { Component, type OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { GuiService } from 'src/app/services/gui.service';
import { RestService } from 'src/app/services/rest.service';
import { MatTreeFlatDataSource, MatTreeFlattener, MatTreeModule } from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';
import { tr } from 'date-fns/locale';
import { MatDialog } from '@angular/material/dialog';
import { NewCategoryComponent } from './new-category/new-category.component';
import { DialogConfirmComponent } from '../dialog-confirm/dialog-confirm.component';
import { EditCategoryComponent } from './edit-category/edit-category.component';
import { NewVideoComponent } from './new-video/new-video.component';
import { EditVideoComponent } from './edit-video/edit-video.component';
import { TranslateService } from '@ngx-translate/core';
import {Clipboard} from '@angular/cdk/clipboard';

interface ExampleFlatNode {
  expandable: boolean;
  name_it: string;
  name_en: string;
  level: number;
  id: string;
}

@Component({
  selector: 'vex-categorie',
  templateUrl: './categorie.component.html',
  styleUrls: ['./categorie.component.scss'],
})
export class CategorieComponent implements OnInit {
  searchCtrl = new UntypedFormControl();
  private _transformer = (node: any, level: number) => {
    return {
      expandable: node.expandable,
      name_it: node.name_it,
      name_en: node.name_en,
      level: level,
      id: node.id,
      videos: node.videos,
      macroAreaId: node.macroAreaId
    };
  };
  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level,
    node => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  selectedNode: any;
  allCategories: any;
  saveVideoOrder: boolean = false;
  lang_to_show: string;

  constructor(private restService: RestService, public guiService: GuiService,private dialog: MatDialog,public translate: TranslateService, private clipboard: Clipboard) {

  }

  ngOnInit(): void {
    this.search();
    this.lang_to_show = this.translate.currentLang;
  }

  search(selectedNode?:any) {
    setTimeout(() => { this.guiService.showSpinner = true }, 100);
    this.restService.postService('category','/search?ALL_LANGUAGES=true',{page:0, size: 100}).subscribe((res) => {
      let str = []
      console.debug(res)
      let ris = res.items;
      for (let item of ris) {
        if (!item.parentId) {
          str.push({
            name_it: item.i18nName['IT'],
            name_en: item.i18nName['EN'],
            expandable: true,
            children: [],
            id: item.id,
            videos: item.videos ? item.videos : [],
            macroAreaId: item.id
          })
        }
      }
      for (let item of ris) {
        if (item.parentId) {
          for (let s of str) {
            if (item.parentId === s.id) {
              s.children.push({
                name_it: item.i18nName['IT'],
                name_en: item.i18nName['EN'],
                expandable: false,
                id: item.id,
                videos: item.videos ? item.videos : []
              })
            }

          }
        }
      }
      this.dataSource.data = str
      this.allCategories = res.items;
      this.selectedNode = null;
      selectedNode ? this.checkSelected(selectedNode) : null
      this.guiService.showSpinner = false;
    })
  }

  checkSelected(node: any) {
    console.debug('NODO SELEZIONATO___', node)
    this.saveVideoOrder = false;
    this.selectedNode = node
    this.getElementsOfNode(node.videos)
  }

  getElementsOfNode(videos: any){
    this.selectedNode.videos = videos;
  }

  createCategory(node?:any,noParent?: boolean){
    if(node){
      console.debug('NODO SELEZIONATO___', node)
      this.selectedNode = node
    }
    this.dialog.open(NewCategoryComponent, {
      width: '100vw',
      maxWidth: '90vw',
      height: '95vh',
      panelClass: 'challenge-new-modalbox',
      disableClose: true,
      hasBackdrop: true,
      data: !noParent ? this.selectedNode.id : null
    }).afterClosed().subscribe((category) => {
      if(category == 'done'){
        this.search()
      }
    })
  }

  deleteCategory(id:string){
    console.debug(this.allCategories)
    console.debug(this.allCategories.filter(x => x.parentId === id))

    this.dialog.open(DialogConfirmComponent, {
      width: '250px',
      enterAnimationDuration: '0ms',
      exitAnimationDuration: '1ms',
      panelClass: 'dialog-modalbox',
      data: {
        type: 'category',
        id: id
      }
    }).afterClosed().subscribe((ris)=>{
      if(ris != 'true'){
        let idsToRemove = this.allCategories.filter(x => x.parentId === id);
        if(idsToRemove && idsToRemove.length > 0){
          idsToRemove.forEach(element => {
            this.restService.deleteService('category', '/' + element.id, {}).subscribe((ris) => {

            })
          });
        }
        this.selectedNode = null
        this.search()

      }
    })
  }

  editCategory(item?: any['item']){
    this.restService.getService('category', item.id+'?ALL_LANGUAGES=true').subscribe((ris)=>{
    this.dialog.open(EditCategoryComponent, {
      width: '100vw',
      maxWidth: '90vw',
      height: '95vh',
      data: ris || null,
      disableClose: true,
      hasBackdrop: true
    }).afterClosed().subscribe((category) => {
      if(category == 'done'){
        this.search()
      }
    })
  })
  }

  createVideo(){
    let selectedCat = this.allCategories.find((x: any) => x.id === this.selectedNode.id);
    this.dialog.open(NewVideoComponent, {
      width: '100vw',
      maxWidth: '90vw',
      height: '95vh',
      panelClass: 'challenge-new-modalbox',
      disableClose: true,
      hasBackdrop: true,
      data: selectedCat
    }).afterClosed().subscribe((category) => {
      if(category == 'done'){
        this.search(this.selectedNode)
      }
    })
  }

  deleteVideo(index:number){
    /* SI UTILIZZA L'INDICE PERCHE I VIDEO NON HANNO ID */
    let selectedCat = this.allCategories.find((x: any) => x.id === this.selectedNode.id);

    this.dialog.open(DialogConfirmComponent, {
      width: '250px',
      enterAnimationDuration: '0ms',
      exitAnimationDuration: '1ms',
      panelClass: 'dialog-modalbox',
      data: {
        type: 'deleteVideoCategory'
      }
    }).afterClosed().subscribe((ris)=>{
      if(ris != 'true'){
        selectedCat.videos.splice(index,1)
        setTimeout(() => { this.guiService.showSpinner = true }, 100);
        this.restService.putService('category', '/'+selectedCat.id, selectedCat).subscribe((ris)=>{
          this.selectedNode.videos.splice(index,1)
          this.search(this.selectedNode)
          this.guiService.showSpinner = false
        })

      }
    })
  }

  editVideo(index: number){
    let selectedCat = this.allCategories.find((x: any) => x.id === this.selectedNode.id);
    let tmpObj = {
      category: selectedCat,
      videoIndex: index,
      lang: this.lang_to_show
    }

    this.dialog.open(EditVideoComponent, {
      width: '100vw',
      maxWidth: '90vw',
      height: '95vh',
      data:  tmpObj || null,
      disableClose: true,
      hasBackdrop: true
    }).afterClosed().subscribe((videos) => {
      if(videos == 'done'){
        this.search(this.selectedNode)
      }
    })
  }

  moveVideo(direction: string,videoIndex: number){
    let selectedCat = this.allCategories.find((x: any) => x.id === this.selectedNode.id);
    this.saveVideoOrder = true;

    if(direction === 'up'){
      let itemToMove = selectedCat.videos.splice(videoIndex, 1)[0];
      selectedCat.videos.splice(videoIndex-1, 0, itemToMove)
    }else{
      let itemToMove = selectedCat.videos.splice(videoIndex, 1)[0];
      selectedCat.videos.splice(videoIndex+1, 0, itemToMove)
    }
  }

  reorderVideos(){
    let selectedCat = this.allCategories.find((x: any) => x.id === this.selectedNode.id);
    this.saveVideoOrder = false;
    this.restService.putService('category', '/'+selectedCat.id, selectedCat).subscribe((ris)=>{
      this.guiService.openSnackBar(this.translate.instant('GLOBAL.EDIT_SUCCESS'), "OK!")
    })
  }

  copyMacroId(idMacro:string, templateRef: any){
    this.clipboard.copy(idMacro)
        let dialogRef = this.dialog.open(templateRef,{
          width:'300px'
        })
        dialogRef.afterOpened().subscribe(_ =>{
          setTimeout(() => {
            dialogRef.close();
          }, 1300);
        })
  }

  openVidyardVideoEdit(id){
    window.open(`https://secure.vidyard.com/organizations/3730852/players/${id}?edit=true`,'_blank')
  }
}
