import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'vex-done-or-details-dialog',
  templateUrl: './done-or-details-dialog.component.html',
  styleUrls: ['./done-or-details-dialog.component.scss']
})
export class DoneOrDetailsDialogComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<any>, public translate: TranslateService) { }

  ngOnInit(): void {
  }

  details(){
    this.dialogRef.close('true')
  }

  markAsRead(){
    this.dialogRef.close('mark')
  }

  close(){
    this.dialogRef.close()
  }

}
