<div class="container">
  <form [formGroup]="form">
    <div class="card flex-auto">
      <div class="px-6 py-4 border-b flex items-center header-title">
        <h2 class="title m-0">{{"ADMIN_NOTIFICATION.NEW" | translate}}</h2>
        <div class="flex-end">
          <button [matMenuTriggerFor]="languageFormMenu" mat-icon-button type="button" align="end">
            <mat-icon svgIcon="{{iconFormFlag}}"></mat-icon>
          </button>
          <mat-menu #languageFormMenu="matMenu" overlapTrigger="false" >
            <button mat-menu-item (click)="changeFormLanguage('it')">
              <mat-icon svgIcon="flag:italy"></mat-icon>
              <span>Italiano</span>
            </button>
            <button mat-menu-item (click)="changeFormLanguage('en')">
              <mat-icon svgIcon="flag:united-states"></mat-icon>
              <span>English</span>
            </button>
          </mat-menu>
        </div>
      </div>

      <div class="px-6 py-4 flex flex-col">
        <div class="card px-6 py-4 flex flex-col subCards" *ngIf="formLang=='it'">
          <mat-form-field class="flex-auto">
            <mat-label>{{"ADMIN_NOTIFICATION.NAME" | translate}} {{"GLOBAL.ITA" | translate}}</mat-label>
            <input formControlName="nome_it" matInput />
          </mat-form-field>

            <mat-form-field class="flex-auto">
              <mat-label>{{"ADMIN_NOTIFICATION.PUBLISH" | translate}}</mat-label>
              <input type="date" matInput formControlName="publish" />
            </mat-form-field>

          <div class="flex flex-col sm:flex-row sm:gap-4">
            <mat-form-field class="flex-auto">
              <mat-label>{{"ADMIN_NOTIFICATION.SUB_TYPE" | translate}}</mat-label>
              <mat-select formControlName="sub">
                <mat-option></mat-option>
                <mat-option [value]="option.key" *ngFor="let option of subTypes">{{ option.description }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="flex-auto">
              <mat-label>{{"ADMIN_NOTIFICATION.TYPE" | translate}}</mat-label>
              <mat-select formControlName="tipo" required>
                <mat-option></mat-option>
                <mat-option [value]="option.key" *ngFor="let option of types">{{ option.description }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <mat-form-field class="description">
            <mat-label>{{"ADMIN_NOTIFICATION.DESC" | translate}} {{"GLOBAL.ITA" | translate}}</mat-label>
            <textarea cols="30" rows="4" matInput formControlName="desc_it"></textarea>
          </mat-form-field>
        </div>

        <div class="card px-6 py-4 flex flex-col subCards" *ngIf="formLang=='en'">
          <mat-form-field class="flex-auto">
            <mat-label>{{"ADMIN_NOTIFICATION.NAME" | translate}} {{"GLOBAL.ENG" | translate}}</mat-label>
            <input formControlName="nome_en" matInput />
          </mat-form-field>


            <mat-form-field class="flex-auto">
              <mat-label>{{"ADMIN_NOTIFICATION.PUBLISH" | translate}}</mat-label>
              <input type="date" matInput formControlName="publish" />
            </mat-form-field>

          <div class="flex flex-col sm:flex-row sm:gap-4">
            <mat-form-field class="flex-auto">
              <mat-label>{{"ADMIN_NOTIFICATION.SUB_TYPE" | translate}}</mat-label>
              <mat-select formControlName="sub">
                <mat-option></mat-option>
                <mat-option [value]="option.key" *ngFor="let option of subTypes">{{ option.description }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="flex-auto">
              <mat-label>{{"ADMIN_NOTIFICATION.TYPE" | translate}}</mat-label>
              <mat-select formControlName="tipo" required>
                <mat-option></mat-option>
                <mat-option [value]="option.key" *ngFor="let option of types">{{ option.description }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>


          <mat-form-field class="description">
            <mat-label>{{"ADMIN_NOTIFICATION.DESC" | translate}} {{"GLOBAL.ENG" | translate}}</mat-label>
            <textarea cols="30" rows="4" matInput formControlName="desc_en"></textarea>
          </mat-form-field>
        </div>

        <div class="bottoni">
          <button class="m-4" mat-dialog-close mat-button-base>{{"GLOBAL.CLOSE" | translate}}</button>
          <button mat-raised-button [disabled]="form.invalid" (click)="submit(form)" class="m-4 bottone-viola">
            {{"GLOBAL.CREATE" | translate}}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
