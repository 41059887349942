import { MatDialog } from '@angular/material/dialog';
import { SubscriptionComponent } from './../subscription.component';
import { RestService } from './../../../services/rest.service';
import { GuiService } from './../../../services/gui.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CloseConfirmComponent } from '../../close-confirm/close-confirm.component';
import * as moment from 'moment';

@Component({
  selector: 'vex-new-sub',
  templateUrl: './subscription-new.component.html',
  styleUrls: ['./subscription-new.component.scss',
  '../../../../../node_modules/quill/dist/quill.snow.css',
  '../../../../@vex/styles/partials/plugins/quill/_quill.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SubscriptionNewComponent implements OnInit {
  form: UntypedFormGroup
  formLang: any;
  iconFormFlag: any;
  flags = {
    en: 'flag:united-states',
    it: 'flag:italy'
  };

  durate: any
  imgSelected: string;

  constructor(private fb: UntypedFormBuilder, public translate: TranslateService, public guiService: GuiService, public restService: RestService, private dialogRef: MatDialogRef<SubscriptionComponent>, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.changeFormLanguage(this.translate.currentLang);
    this.form = this.fb.group({
      nome_it:[null, Validators.required],
      nome_en:[null, Validators.required],
      valda:[null, Validators.required],
      vala:[null],
      giornigratis: [null],
      prezzo: [null, Validators.required],
      durata: [null, Validators.required],
      desc_it: [null],
      desc_en: [null],
      subType: [null,Validators.required]
    })
    this.getSubDuration()
  }
  getSubDuration(){
    this.restService.getService('bos', '/subscription-duration').subscribe((ris)=>{
      this.durate = ris
    })
  }

  submit(form){
    let newSub = {
      description: this.form.value.desc_en,
      duration: this.form.value.durata,
      endDate: this.form.value.vala,
      trialPeriod: this.form.value.giornigratis,
      i18nDescription: {
        EN: this.form.value.desc_en,
        IT: this.form.value.desc_it
      },
      i18nName: {
        EN: this.form.value.nome_en,
        IT: this.form.value.nome_it
      },
      image: this.imgSelected,
      imageFileName: this.form.value.nome_en,
      name: this.form.value.nome_en,
      price:
        {
          currency: "$",
          price: this.form.value.prezzo
        },
      startDate: this.form.value.valda,
      type: this.form.value.subType
    }

    if(moment(newSub.endDate).isAfter(newSub.startDate)){
      this.restService.postService('subscriptions', '?ALL_LANGUAGES=true', newSub).subscribe((ris)=>{
        console.debug(ris)
        this.guiService.openSnackBar(ris.name + this.translate.instant('GLOBAL.NEW_SUCCESS'), this.translate.instant('GLOBAL.CLOSE'))
        this.dialogRef.close('done')
      })
    }else{
      this.guiService.openSnackBar('La data di inizio deve essere successiva alla data di fine', this.translate.instant('GLOBAL.CLOSE'))
    }

  }

  changeFormLanguage(lang) {
    this.iconFormFlag = this.flags[lang];
    this.formLang = lang;
}

onFileSelected(event) {
  this.guiService.convertFile(event.target.files[0]).subscribe(base64 => {
    console.debug("BASE64",base64);
    this.imgSelected = base64;
  });
}

confirmClose(){
  this.dialog.open(CloseConfirmComponent,{
    disableClose: true,
    hasBackdrop: true,
    panelClass: 'close-dialog-modalbox'
  }).afterClosed().subscribe((ris)=>{
    if(ris == 'true'){
      this.dialogRef.close()
    }
  })
}
}
