import { EditSubComponent } from './edit-sub/edit-sub.component';
import { SubscriptionNewComponent } from './new-sub/subscription-new.component';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { faCircle, faCheck } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { RestService } from 'src/app/services/rest.service';
import { DialogConfirmComponent } from '../dialog-confirm/dialog-confirm.component';
import { GuiService } from 'src/app/services/gui.service';
import * as moment from 'moment';

@Component({
  selector: 'vex-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {
  subs: any
  dataSource : MatTableDataSource<any> | null
  displayedColumns: string[] = ['image', 'name','durata','valid-from', 'free-days', 'price', 'actions'];
  selection = new SelectionModel<any>(true, []);
  searchCtrl = new UntypedFormControl();
  pageSize = 10;
  pageNumber = 0;
  pageSizeOptions: number[] = [5, 10, 15, 50];
  lunghezza: any;
  duration: any;

  searchCtrlDuration = new UntypedFormControl();
  searchCtrlStart = new UntypedFormControl();
  searchCtrlEnd = new UntypedFormControl();
  searchCtrlFree = new UntypedFormControl();
   /* sort var */
   asc: any;
   fieldParam: string;
   searchAltFlag: boolean;
   sortFlag: boolean;

  icon = {
    "faCircle": faCircle,
    "faCheck": faCheck
  };

  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private restService: RestService, public translate: TranslateService, private dialog: MatDialog, public guiService: GuiService) {
    translate.addLangs(['en', 'it']);
                translate.setDefaultLang('it');

                const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|it/) ? browserLang : 'it');
   }

  ngOnInit(): void {
    this.getSubs()
    this.getDuration()
    this.asc = true;
    this.searchAltFlag = false;
    this.sortFlag = false;
  }

  ngAfterViewInit(): void {
    //this.dataSource.paginator = this.paginator;
    //this.dataSource.sort = this.sort;
  }

  getSubs(){
    setTimeout(()=>this.guiService.showSpinner = true,0)
    this.dataSource = new MatTableDataSource();
    this.restService.postService('subscriptions', '/search', {page: this.pageNumber,size: this.pageSize}).subscribe((ris)=>{
      this.lunghezza = ris.totalItems
      this.subs = ris["items"]
      console.debug(this.subs)
      this.dataSource.data = this.subs;
      this.table ? this.table.renderRows() : null;
      this.guiService.showSpinner = false
    })
  }

  getDuration(){
    this.restService.getService('bos', 'subscription-duration').subscribe((ris)=>{
      this.duration = ris
    })
  }

  onPageChanged(pageEvent: PageEvent) {
    this.pageNumber = pageEvent.pageIndex+1;
    this.pageSize = pageEvent.pageSize;
    if(this.searchAltFlag == false && this.sortFlag == false){
      this.getSubs()
    }else if(this.sortFlag == true){
      this.sortParam(this.fieldParam, this.asc)
    }else if(this.searchAltFlag == true){
      this.search()
    }
  }

  deleteSub(id): void {
    this.dialog.open(DialogConfirmComponent, {
      width: '250px',
      data: {
        type: 'subscriptions',
        id: id
      },
      panelClass: 'dialog-modalbox'
    }).afterClosed().subscribe((ris)=>{
      if(ris != 'true'){
        this.getSubs()
      }
    })
  }

  createSub(){
    this.dialog.open(SubscriptionNewComponent, {
      height: '85vh',
      panelClass: 'custom-modalboxx',
      disableClose: true,
      hasBackdrop: true
    }).afterClosed().subscribe((sub) => {
      if(sub == 'done'){
        this.getSubs()
      }
    })
  }

  editSub(item?: any['item']){
    this.restService.getService('subscriptions', item.id + '?ALL_LANGUAGES=true').subscribe((ris)=>{
      console.debug('editsub', ris)
    this.dialog.open(EditSubComponent, {
      data: ris || null,
      height: '85vh',
      panelClass: 'custom-modalboxx',
      disableClose: true,
      hasBackdrop: true
    }).afterClosed().subscribe((sub) => {
      if(sub == 'done'){
        this.getSubs()
      }
    })
  })
  }


  search(){
    this.searchAltFlag = true
    let param = {
      "filterAnd": [],
      "page": this.pageNumber,
      "size": this.pageSize
    }

    if(this.searchCtrl.value != null){
      param["filterAnd"].push({
        "field": "i18nName."+this.translate.currentLang.toUpperCase(),
        "operator": "like",
        "value": this.searchCtrl.value.replace(/^\s+|\s+$/gm,'')
        })
    }

    if(this.searchCtrlDuration.value != null){
      param["filterAnd"].push({
        "field": "duration",
        "operator": "eq",
        "value": this.searchCtrlDuration.value
        })
    }

    if(this.searchCtrlStart.value != null){
      param["filterAnd"].push(
        {
          "field": "startDate",
          "operator": "gte",
          "dateValue": moment(this.searchCtrlStart.value + " 00:00:00","YYYY-MM-DD HH:mm:ss").format('x')
        }
      ),
      param["filterAnd"].push(
        {
          "field": "startDate",
          "operator": "lte",
          "dateValue": moment(this.searchCtrlStart.value + " 23:59:59","YYYY-MM-DD HH:mm:ss").format('x')
        }
      )
    }

    if(this.searchCtrlEnd.value != null){
      param["filterAnd"].push(
        {
          "field": "endDate",
          "operator": "gte",
          "dateValue": moment(this.searchCtrlEnd.value + " 00:00:00","YYYY-MM-DD HH:mm:ss").format('x')
        }
      ),
      param["filterAnd"].push(
        {
          "field": "endDate",
          "operator": "lte",
          "dateValue": moment(this.searchCtrlEnd.value + " 23:59:59","YYYY-MM-DD HH:mm:ss").format('x')
        }
      )
    }

    if(this.searchCtrlFree.value != null){
      param["filterAnd"].push({
        "field": "trialPeriod",
        "operator": "eq",
        "value": this.searchCtrlFree.value
        })
    }

    this.restService.postService('subscriptions','/search'+ '?ALL_LANGUAGES=true', param).subscribe((ris)=>{
      this.lunghezza = ris.totalItems
      this.subs = ris["items"]
      console.debug(this.subs)
      this.dataSource.data = this.subs;
      this.table ? this.table.renderRows() : null;
      this.guiService.showSpinner = false
    })
  }

  sortParam(field?,asc?){
    this.asc = asc
    this.sortFlag = true
    this.fieldParam = field
    let param = {
      page: this.pageNumber,
      size: this.pageSize,
      orders: [
        {
         field: this.fieldParam,
         ascending : this.asc
        }
      ]
    }
    setTimeout(()=>this.guiService.showSpinner = true,0)
    this.restService.postService('subscriptions', '/search', param).subscribe((ris)=>{
      this.lunghezza = ris.totalItems
      this.subs = ris["items"]
      console.debug(this.subs)
      this.dataSource.data = this.subs;
      this.table ? this.table.renderRows() : null;
      this.guiService.showSpinner = false
    })
  }

  reload(){
    this.searchCtrl.reset()
    this.searchCtrlDuration.reset()
    this.searchCtrlEnd.reset()
    this.searchCtrlStart.reset()
    this.searchCtrlFree.reset()

    this.searchAltFlag = false;
    this.sortFlag = false;
    this.pageNumber = 0;
    this.pageSize = 10;
    this.getSubs()
  }
  resetPage(){
    this.pageNumber = 0
  }
}
