<div class="container">
  <button class="btn-close" mat-dialog-close><mat-icon svgIcon="mat:close"></mat-icon></button>
  <div class="workoutName" [class]="evaluateBgColor(guiService.currentDayWorkout)" [style.background-image]="'url('+environment+'/'+workout.id+'/images/main)'">
    <h1 class="w-title">{{workout.name.split("::").pop()}}</h1>
  </div>
  <div class="equipments">
    <h1 class="equip-tile">{{"USER_WORKOUT.EQUIP" | translate}}</h1>
    <div class="equipo-container">
      <div *ngFor="let equip of equipments" class="equipo" [class.equips-opacity]="showEquips == false">
        <img *ngIf="guiService.filteredIds[equip]" [src]="'assets/img/equipsIcons_violet/'+guiService.filteredIds[equip]+'.svg'" class="svg">
        <h3 *ngIf="guiService.EquipNameIds[equip]">{{guiService.EquipNameIds[equip]}}</h3>
      </div>
      <div *ngIf="showEquips == false" class="equips-spinner">
        <fa-icon class="flash fa-2xl" [icon]="icon.faEll"></fa-icon>
      </div>
    </div>
  </div>
  <div class="phases" *ngFor="let phase of workout.phases">
    <div class="phase">
      <h2 class="phase-name">
        {{phase.name}}
        <span *ngIf="phase.repetitions > 1">- {{phase.repetitions}} {{"USER_WORKOUT.SERIES" | translate}}</span>
        <span *ngIf="phase.repetitions == 1">- {{phase.repetitions}} {{"USER_WORKOUT.SERIE" | translate}}</span>
      </h2>
      <p *ngIf="phase.workoutPhaseTypeId && translate.currentLang == 'en'" class="phaseType">{{phase.workoutPhaseTypeId['EN']}}</p>
      <p *ngIf="phase.workoutPhaseTypeId && translate.currentLang == 'it'" class="phaseType">{{phase.workoutPhaseTypeId['IT']}}</p>
      <div class="exercise" *ngFor="let ex of phase.exercises">
        <img [src]="imgURL+'/'+ex.exerciseId+'/images/main'">
        <div class="ex-reps-name">
          <h3>{{filteredIds[ex.exerciseId]}} </h3>
          <p>
            <span *ngIf="ex.repetitions != 0">{{"USER_WORKOUT.REPS" | translate}}: {{ex.repetitions}} <span></span></span>
            <span *ngIf="ex.executionTimeSeconds != 0 && ex.executionTimeSeconds <60 ">{{"USER_WORKOUT.EXEC" | translate}}: {{ex.executionTimeSeconds}} {{"USER_WORKOUT.SEC" | translate}}</span>
              <span *ngIf="ex.executionTimeSeconds != 0 && ex.executionTimeSeconds >= 60 ">
                {{"USER_WORKOUT.EXEC" | translate}}: {{(ex.executionTimeSeconds/60)}} {{ ((ex.executionTimeSeconds/60)==1 ? "USER_WORKOUT.MIN" : "USER_WORKOUT.MINs") | translate}}</span>
            <span *ngIf="ex.eachSideFlag === true"> {{"USER_WORKOUT.SIDE" | translate}}</span>
          </p>

        </div>
        <div class="icona">
            <mat-icon svgIcon="mat:info" (click)="showInfos(ex)"></mat-icon>
          </div>
      </div>
    </div>
  </div>
  <button class="btn-workout" (click)="goToWorkout()">{{"USER_WORKOUT.WORK" | translate}}</button>
</div>
