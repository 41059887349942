import { SubscriptionComponent } from './../subscription.component';
import { RestService } from './../../../services/rest.service';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import {FormControl, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { toDate } from 'date-fns';
import {GuiService} from "../../../services/gui.service";
import { formatDate } from '@angular/common';
import { CloseConfirmComponent } from '../../close-confirm/close-confirm.component';
import * as moment from 'moment';

@Component({
  selector: 'vex-edit-sub',
  templateUrl: './edit-sub.component.html',
  styleUrls: ['./edit-sub.component.scss',
  '../../../../../node_modules/quill/dist/quill.snow.css',
  '../../../../@vex/styles/partials/plugins/quill/_quill.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditSubComponent implements OnInit {
  form: UntypedFormGroup;
  formLang: any;
  iconFormFlag: any;
  flags = {
    en: 'flag:united-states',
    it: 'flag:italy'
  };

  durate: any

  sub: any;
  imgSelected: string;

  constructor(
    private fb: UntypedFormBuilder,
    public translate: TranslateService,
    public guiService: GuiService,
    public restService: RestService,
    @Inject(MAT_DIALOG_DATA) private item: any['item'],
    private dialogRef: MatDialogRef<SubscriptionComponent>,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    this.changeFormLanguage(this.translate.currentLang);
    this.sub = this.item
    this.form = this.fb.group({
      nome_it:[null, Validators.required],
      nome_en:[null, Validators.required],
      valda:[null, Validators.required],
      vala:[null],
      giornigratis: [null],
      prezzo: [null, Validators.required],
      durata: [null, Validators.required],
      desc_it: [null],
      desc_en: [null],
      subType: [null]
    })
    this.getSubDuration()

    this.form.controls['valda'].setValue(formatDate(this.sub.startDate,'yyyy-MM-dd','en'));
    this.form.controls['vala'].setValue(formatDate(this.sub.endDate,'yyyy-MM-dd','en'));
    this.form.controls['giornigratis'].setValue(this.sub.trialPeriod);
    this.form.controls['durata'].setValue(this.sub.duration);
    this.form.controls['prezzo'].setValue(this.sub.price ?this.sub.price.price : null);
    this.form.controls['nome_it'].setValue(this.sub.i18nName['IT']);
    this.form.controls['nome_en'].setValue(this.sub.i18nName['EN']);
    this.form.controls['desc_it'].setValue(this.sub.i18nDescription['IT']);
    this.form.controls['desc_en'].setValue(this.sub.i18nDescription['EN']);
    this.form.controls['subType'].setValue(this.sub.type);

  }

  getSubDuration(){
    this.restService.getService('bos', '/subscription-duration').subscribe((ris)=>{
      this.durate = ris
    })
  }

  submit(form){
    let editSub = {
      description: this.form.value.desc_en,
      duration: this.form.value.durata,
      endDate: this.form.value.vala,
      trialPeriod: this.form.value.giornigratis,
      i18nDescription: {
        EN: this.form.value.desc_en,
        IT: this.form.value.desc_it
      },
      i18nName: {
        EN: this.form.value.nome_en,
        IT: this.form.value.nome_it
      },
      image: this.imgSelected,
      imageFileName: this.form.value.nome_en,
      name: this.form.value.nome_en,
      price:
        {
          currency: "$",
          price: this.form.value.prezzo
        },
      startDate: this.form.value.valda,
      type: this.form.value.subType
    }
     if(moment(editSub.endDate).isAfter(editSub.startDate)){
        this.restService.putService('subscriptions','/'+this.item.id + '?ALL_LANGUAGES=true', editSub).subscribe((ris)=>{
          this.guiService.openSnackBar(ris.name + this.translate.instant('GLOBAL.EDIT_SUCCESS'), this.translate.instant('GLOBAL.CLOSE'))
          this.dialogRef.close('done')
        })
     }else{
        this.guiService.openSnackBar('La data di inizio deve essere successiva alla data di fine', this.translate.instant('GLOBAL.CLOSE'))
     }


  }

  changeFormLanguage(lang) {
      this.iconFormFlag = this.flags[lang];
      this.formLang = lang;
  }

  onFileSelected(event) {
    this.guiService.convertFile(event.target.files[0]).subscribe(base64 => {
      console.debug("BASE64",base64);
      this.imgSelected = base64;
    });
  }

  confirmClose(){
    this.dialog.open(CloseConfirmComponent,{
      disableClose: true,
      hasBackdrop: true,
      panelClass: 'close-dialog-modalbox'
    }).afterClosed().subscribe((ris)=>{
      if(ris == 'true'){
        this.dialogRef.close()
      }
    })
  }
}
