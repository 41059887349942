import { RestService } from './rest.service';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'securePipe'
})
export class SecurePipePipe implements PipeTransform {

  constructor(private httpClient: HttpClient,
    private domSanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
    public restService: RestService) {
}

transform(url): any {
  console.debug('transform',url)
 this.restService.getService('https://app.itsdesifit.com/api/exercises/images/main/633589797245d36a35326dc7','').subscribe((ris)=>{
  return ris
 })

}

}
