import { NotificationsComponent } from './../../../../../app/main/notifications/notifications.component';
import { MatDialog } from '@angular/material/dialog';
import { PopoverRef } from './../../../../components/popover/popover-ref';
import { Router } from '@angular/router';
import { GuiService } from 'src/app/services/gui.service';
import { Component, OnInit } from '@angular/core';
import { Notification } from '../interfaces/notification.interface';
import { DateTime } from 'luxon';
import { trackById } from '../../../../utils/track-by';
import { TranslateService } from '@ngx-translate/core';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'vex-toolbar-notifications-dropdown',
  templateUrl: './toolbar-notifications-dropdown.component.html',
  styleUrls: ['./toolbar-notifications-dropdown.component.scss']
})
export class ToolbarNotificationsDropdownComponent implements OnInit {

  notifications = this.guiService.notificationsList
  flagAll: boolean = false;

  trackById = trackById;

  checkedIds:any = []

  constructor(public translate: TranslateService, public guiService : GuiService, public router: Router,public popover: PopoverRef, public dialog: MatDialog, private restService: RestService) { }

  ngOnInit() {
  }

  allNotifications(){
    if(this.guiService.userLogged.user.roles[0] == 'ADMIN'){
      console.debug('notifiche amministratore')
      this.router.navigate(['/admin/admin-notifications'])
    }
    if(this.guiService.userLogged.user.roles[0] == 'USER'){
      console.debug('notifiche utente')
      this.router.navigate(['/main/notifications'])
    }
    this.popover.close()
  }

  openNotification(not){
    console.debug(not)
    this.dialog.open(NotificationsComponent,{
      data: not || null
    })
    this.popover.close()
  }

  selectNotif(idNotification){
    console.debug(this.flagAll)
    if(this.checkedIds.includes(idNotification) === false){
      this.checkedIds.push(idNotification)
      console.debug(this.checkedIds)
    }
    else{
      var index = this.checkedIds.indexOf(idNotification);
      if (index !== -1) {
        this.checkedIds.splice(index, 1);
        console.debug(this.checkedIds)
        this.flagAll == true ? this.flagAll = false : null
      }
    }
    console.debug(this.notifications)
  }

  archiveMultipleNotification(){
    for(let k in this.checkedIds){
      let idNotifica = this.checkedIds[k]
      setTimeout(()=>this.guiService.showSpinner = true,0)
      this.restService.postService('user_notification_status','/' + idNotifica + '/read', {}).subscribe((ris)=>{
       console.debug('notifica letta', ris)
       if((this.checkedIds.length-1) == Number(k)){
        this.restService.getService('current_user', '').subscribe((risposta)=>{
         this.guiService.notificationsList = risposta.notifications
         this.notifications = risposta.notifications
       })
        this.checkedIds = []
        this.popover.close()
        setTimeout(()=>this.guiService.showSpinner = false,0)
      }

     })
    }
  }

  checkAll(evento){
    console.debug(evento)
    if(evento.checked){
      for(let notification of this.notifications){
        notification.notificationReadDate = true
        this.selectNotif(notification.notificationId)
      }
      this.flagAll = true
    }else{
      for(let notification of this.notifications){
        notification.notificationReadDate = false
        this.selectNotif(notification.notificationId)
      }
      this.flagAll = false
    }

  }
}
