import { GuiService } from 'src/app/services/gui.service';
import { RestService } from 'src/app/services/rest.service';
import { ChallengeComponent } from './../challenge.component';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CloseConfirmComponent } from '../../close-confirm/close-confirm.component';

@Component({
  selector: 'vex-new-challenge',
  templateUrl: './new-challenge.component.html',
  styleUrls: ['./new-challenge.component.scss']
})
export class NewChallengeComponent implements OnInit {
  form: UntypedFormGroup;
  formLang: any;
  iconFormFlag: any;
  flags = {
    en: 'flag:united-states',
    it: 'flag:italy'
  };

  constructor(private fb: UntypedFormBuilder, private dialogRef: MatDialogRef<ChallengeComponent>, private translate: TranslateService, public restService: RestService, public guiService: GuiService,private dialog: MatDialog) { }

  ngOnInit(): void {
    this.changeFormLanguage(this.translate.currentLang);
    this.form = this.fb.group({
      nome_it:[null, Validators.required],
      nome_en:[null, Validators.required],
      publish: [null, Validators.required],
      expire: [null],
      desc_it: [null],
      desc_en: [null],
      file: [null],
      notification: [false]
    })
  }

  submit(){
    let chall = {
      creationDate: '',
      expirationDate: this.form.value.expire,
      name: this.form.value.nome_en,
      description: this.form.value.desc_en,
      i18nDescription: {
        EN: this.form.value.desc_en,
        IT: this.form.value.desc_it
      },
      i18nName: {
        EN: this.form.value.nome_en,
        IT: this.form.value.nome_it
      },
      video: "",
      videoFileName: this.form.value.nome_it,
      videoUrl: "",
      publishDate: this.form.value.publish,
      notificationEnable: this.form.value.notification
    }

    this.restService.postService('challenges', '?ALL_LANGUAGES=true', chall).subscribe((ris)=>{
      this.guiService.openSnackBar(this.translate.instant('GLOBAL.NEW_SUCCESS'), "OK!")
      this.dialogRef.close('done')
    })
  }

  changeFormLanguage(lang) {
    this.iconFormFlag = this.flags[lang];
    this.formLang = lang;
}

confirmClose(){
  this.dialog.open(CloseConfirmComponent,{
    disableClose: true,
    hasBackdrop: true,
    panelClass: 'close-dialog-modalbox'
  }).afterClosed().subscribe((ris)=>{
    if(ris == 'true'){
      this.dialogRef.close()
    }
  })
}
}
