import { RestService } from 'src/app/services/rest.service';
import { GuiService } from 'src/app/services/gui.service';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { Link } from 'src/@vex/interfaces/link.interface';
import { scaleIn400ms } from 'src/@vex/animations/scale-in.animation';
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation';

@Component({
  selector: 'vex-administrator',
  templateUrl: './administrator.component.html',
  styleUrls: ['./administrator.component.scss'],
  animations: [
    scaleIn400ms,
    fadeInRight400ms
  ]
})
export class AdministratorComponent implements OnInit {
  links: Link[] = [
    {
      label: this.translate.instant('PROFILE_MENU.PROFILE'),
      route: './admin-profile',
      routerLinkActiveOptions: { exact: true }
    },
    {
      label: 'password',
      route: './admin-reset-password'
    }
  ];

  user

  constructor(public translate: TranslateService, public guiService: GuiService, public restService: RestService) { }

  ngOnInit(): void {
    setTimeout(()=>this.guiService.showSpinner = false,0)
    this.restService.getService('current_user', '').subscribe((ris)=>{
      this.user = ris
      console.debug(this.user)
      this.guiService.userLogged = ris;

    })
  }

}
