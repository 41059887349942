<div class="contenitore-generale bg-app-bar">
  <div class="title">
    <h1 *ngIf="translate.currentLang == 'it'">Avviso</h1>
    <h1 *ngIf="translate.currentLang == 'en'">Notice</h1>
  </div>
  <div class="container">
    <p *ngIf="translate.currentLang == 'it'">Stiamo prendendo in carico la tua richiesta. Il nuovo abbonamento verrà attivato al termine dell’attuale ciclo di pagamento. Ti ricordiamo inoltre che le promozioni non sono valide in fase di downgrade. Per assistenza, scrivici ad <a href="mailto:info@itsdesifit.com">info@itsdesifit.com</a></p>
    <p *ngIf="translate.currentLang == 'en'">We are processing your request. Your new subscription will be activated at the end of the current payment cycle. Promo codes linked to your previous subscription are non-transferable. For assistance, email us at <a href="mailto:info@itsdesifit.com">info@itsdesifit.com</a></p>

    <div class="bottoni">
      <button *ngIf="translate.currentLang == 'it'" (click)="confirm()">CONFERMA CAMBIO PIANO</button>
      <button *ngIf="translate.currentLang == 'en'" (click)="confirm()">CONFIRM CHANGE PLAN</button>
    </div>
  </div>
</div>
