<!-- <div class="container mt-4">
  <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">
    <h2 class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l hidden sm:block flex-none">
      <span >{{"ADMIN_NOTIFICATION.NOTIF" | translate}}</span>
    </h2>
    <div
      class="bg-foreground rounded-full border px-4 max-w-[300px] flex-auto flex items-center border border-gray-300">
      <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon>
      <input [formControl]="searchCtrl" (keyup)="searchAltExercise()" (search)="searchAltExercise()" class="px-4 py-2 border-0 outline-none w-full bg-transparent" placeholder="{{'GLOBAL.SEARCH' | translate}}" type="search" />
    </div>

    <span class="flex-1"></span>

    <button (click)="reload()" class="ml-4 flex-none blue-bottone" color="primary" mat-mini-fab matTooltip="reload" type="button" >
      <mat-icon svgIcon="mat:autorenew"></mat-icon>
    </button>
    <button class="ml-4 flex-none" color="primary" mat-mini-fab matTooltip="Add Notification" type="button" (click)="createNotification()">
      <mat-icon svgIcon="mat:add"></mat-icon>
    </button>
  </div>
  <table mat-table [dataSource]="dataSource" *ngIf="notifications" matSort>
    <ng-container matColumnDef="image">
      <th *matHeaderCellDef mat-header-cell></th>
      <td *matCellDef="let row" class="w-8 min-w-8 pr-0" mat-cell>
      </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header (click)="sortParam('subject', asc == true ? false : true)">{{"ADMIN_NOTIFICATION.NAME" | translate}}</th>
      <td mat-cell *matCellDef="let notification">{{ notification.name ? notification.name : notification.title }}</td>
    </ng-container>
    <ng-container matColumnDef="publish">
      <th mat-header-cell *matHeaderCellDef mat-sort-header (click)="sortParam('startDate', asc == true ? false : true)">{{"ADMIN_NOTIFICATION.DATE" | translate}}</th>
      <td mat-cell *matCellDef="let notification">
        {{notification.startDate | date: 'shortDate'}}
      </td>
    </ng-container>
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header (click)="sortParam('type', asc == true ? false : true)">{{"ADMIN_NOTIFICATION.TYPE" | translate}}</th>
      <td mat-cell *matCellDef="let notification">{{ notification.type}}</td>
    </ng-container>
    <ng-container matColumnDef="sub-type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header (click)="sortParam('subscriptionType', asc == true ? false : true)">{{"ADMIN_NOTIFICATION.SUB_TYPE" | translate}}</th>
      <td mat-cell *matCellDef="let notification">{{ notification.subscriptionType ? notification.subscriptionType : 'DIAMOND & GOLD'}}</td>
    </ng-container>
    <ng-container matColumnDef="message">
      <th mat-header-cell *matHeaderCellDef>{{"ADMIN_NOTIFICATION.MSG" | translate}}</th>
      <td mat-cell *matCellDef="let notification" class="messaggio">
        {{notification.message}}
      </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row" class="w-10 text-secondary" >
        <button (click)="$event.stopPropagation()"
                [matMenuTriggerData]="{ customer: row }"
                [matMenuTriggerFor]="actionsMenu"
                mat-icon-button
                type="button">
          <mat-icon svgIcon="mat:more_horiz"></mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>


  </table>
  <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize" [length]="lunghezza" (page)="onPageChanged($event)" class="sticky left-0"></mat-paginator>

</div>
-->
<mat-menu #actionsMenu="matMenu" xPosition="before" yPosition="below">
  <ng-template let-customer="customer" matMenuContent>
    <button  mat-menu-item (click)="editNotification(customer)">
      <mat-icon svgIcon="mat:edit"></mat-icon>
      <span>{{"GLOBAL.EDIT" | translate}}</span>
    </button>
    <button  mat-menu-item (click)="deleteNotification(customer.id)">
      <mat-icon svgIcon="mat:delete"></mat-icon>
      <span>{{'GLOBAL.DELETE' | translate}}</span>
    </button>
    <button  mat-menu-item (click)="copyLink(customer.id,templateClip)">
      <mat-icon svgIcon="mat:link"></mat-icon>
      <span>{{"CHALLENGE-TIME.DEEP" | translate}}</span>
    </button>
  </ng-template>
</mat-menu>

<div class="container mt-4">
  <div class="filters">
    <a class="uppercase" (click)="selectedFilter = 'ALL'; getNotifications()" [class.selected]="selectedFilter =='ALL'">{{"ADMIN-NOTIF.ALL" | translate}}</a>
    <a class="uppercase" (click)="selectedFilter = 'MAIL'; filter('MAIL')" [class.selected]="selectedFilter =='MAIL'">Mail</a>
    <a class="uppercase" (click)="selectedFilter = 'MESSAGE'; filter('MESSAGE')" [class.selected]="selectedFilter =='MESSAGE'">{{"ADMIN-NOTIF.MSG_INAPP" | translate}}</a>
    <input type="date" [formControl]="searchCtrlDate">
    <button class="ml-4 flex-none" color="primary" mat-mini-fab matTooltip="search" type="button" (click)="filter(selectedFilter)" *ngIf="searchAltFlag != true">
      <mat-icon svgIcon="mat:search"></mat-icon>
    </button>
    <button class="ml-4 flex-none" color="primary" mat-mini-fab matTooltip="search" type="button" (click)="subFilter(selectedSubFilter)" *ngIf="searchAltFlag == true">
      <mat-icon svgIcon="mat:search"></mat-icon>
    </button>
    <button (click)="reload()" class="ml-4 flex-none blue-bottone" color="primary" mat-mini-fab matTooltip="reload" type="button" >
      <mat-icon svgIcon="mat:autorenew"></mat-icon>
    </button>
    <button class="ml-4 flex-none" color="primary" mat-mini-fab matTooltip="Add Notification" type="button" (click)="createNotification()">
      <mat-icon svgIcon="mat:add"></mat-icon>
    </button>
  </div>
  <div class="filters2" *ngIf="selectedFilter == 'MAIL'">
    <a (click)="selectedSubFilter = 'BENVENUTA'; subFilter('BENVENUTA')" [class.selected]="selectedSubFilter =='BENVENUTA'">{{"ADMIN-NOTIF.WELCOME" | translate}}</a>
    <a (click)="selectedSubFilter = 'REGISTRAZIONE'; subFilter('REGISTRAZIONE')" [class.selected]="selectedSubFilter =='REGISTRAZIONE'">{{"ADMIN-NOTIF.REGISTER" | translate}}</a>
    <a (click)="selectedSubFilter = 'CANCELLAZIONE'; subFilter('CANCELLAZIONE')" [class.selected]="selectedSubFilter =='CANCELLAZIONE'">{{"ADMIN-NOTIF.CANC" | translate}}</a>
    <a (click)="selectedSubFilter = 'PAUSA'; subFilter('PAUSA')" [class.selected]="selectedSubFilter =='PAUSA'">{{"ADMIN-NOTIF.PAUSE" | translate}}</a>
  </div>
  <div class="filters2" *ngIf="selectedFilter == 'MESSAGE'">
    <a (click)="selectedSubFilter = 'SCADENZA'; subFilter('SCADENZA')" [class.selected]="selectedSubFilter =='SCADENZA'">{{"ADMIN-NOTIF.EXPIRY" | translate}}</a>
    <a (click)="selectedSubFilter = 'SURVEY'; subFilter('SURVEY')" [class.selected]="selectedSubFilter =='SURVEY'">{{"ADMIN-NOTIF.SURVEY" | translate}}</a>
    <a (click)="selectedSubFilter = 'ASSEGNAZIONE'; subFilter('ASSEGNAZIONE')" [class.selected]="selectedSubFilter =='ASSEGNAZIONE'">{{"ADMIN-NOTIF.ASSIGNMENT" | translate}}</a>
    <a (click)="selectedSubFilter = 'CARICAMENTO_DIETA'; subFilter('CARICAMENTO_DIETA')" [class.selected]="selectedSubFilter =='CARICAMENTO_DIETA'">{{"ADMIN-NOTIF.UPLOAD_DIET" | translate}}</a>
  </div>
    <div class="container2">
      <div class="div1" >
        <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize" [length]="lunghezza" (page)="onPageChanged($event)" class="sticky left-0"></mat-paginator>
        <span *ngFor="let notification of notifications">
          <div class="notification-label">
            <h2 (click)="openNotification(notification)">{{ notification.title ? notification.title : notification.subject }}</h2>
            <h3>{{ notification.type}}</h3>
            <h4>{{notification.startDate | date: 'shortDate': 'UTC'}}</h4>
            <button (click)="$event.stopPropagation()"
                [matMenuTriggerData]="{ customer: notification }"
                [matMenuTriggerFor]="actionsMenu"
                mat-icon-button
                type="button">
          <mat-icon svgIcon="mat:more_horiz" style="transform: rotate(90deg)"></mat-icon>
            </button>
          </div>
        </span>

      </div>
      <div class="div2">
        <span *ngIf="(notificationSelected | json) !='{}'">
          <div class="name-notif">
            <h4>{{notificationSelected["startDate"] | date: 'shortDate': 'UTC'}}</h4>
            <h2>{{ notificationSelected["title"] ? notificationSelected["title"] : notificationSelected["subject"] }}</h2>
          </div>
          <h3>Tipo: {{ notificationSelected["type"]}}</h3>
          <h5 *ngIf="notificationSelected['subscriptionType']">Abbonamento: {{notificationSelected["subscriptionType"]}}</h5>
          <p [innerHTML]="notificationSelected['message']"></p>
        </span>
      </div>
    </div>
</div>

<ng-template #templateClip>
  <div class="dialog-msg-div">
    <h1 *ngIf="translate.currentLang == 'it'">Copiato negli appunti</h1>
    <h1 *ngIf="translate.currentLang == 'en'">Copied to clipboard</h1>
  </div>
 </ng-template>
