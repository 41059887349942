import { TranslateService } from '@ngx-translate/core';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GuiService } from 'src/app/services/gui.service';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'vex-change-substatus-modal',
  templateUrl: './change-substatus-modal.component.html',
  styleUrls: ['./change-substatus-modal.component.scss']
})
export class ChangeSubstatusModalComponent implements OnInit {
  status:any;
  selections: any = [
    {value: 'ONE_MONTH', key: this.translate.instant("USER-EDIT.1MONTH")},
    {value: 'TWO_MONTH', key: this.translate.instant("USER-EDIT.2MONTH")},
    {value: 'THREE_MONTH', key: this.translate.instant("USER-EDIT.3MONTH")},
    {value: 'SUSPEND', key: this.translate.instant("USER-EDIT.NOMONTH")}
  ]
  selectedPause: any;

  constructor(private dialogRef: MatDialogRef<any>,@Inject(MAT_DIALOG_DATA) private item: any, public guiService: GuiService, public restService: RestService, public translate: TranslateService) { }

  ngOnInit(): void {
    this.status = this.item
    console.debug(this.status)
  }


  changeStatus(status){
    if(status == 'ACTIVE'){
      this.dialogRef.close('ACTIVE')
    }
    else if(status == 'PAUSED'){
      console.debug('scelta select', this.selectedPause)
      this.dialogRef.close(this.selectedPause)
    }else if(status == 'SUSPEND'){
      this.dialogRef.close('SUSPEND')
    }
  }

  close(){
    console.debug('annullato')
    this.dialogRef.close('true')
  }
  onChange(e) {
    this.selectedPause = e.value
    console.debug(e.value)
 }

}
