<div class="sfondo">
  <div class="box" *ngIf="guiService.showSpinner == false">
    <div class="center">
      <mat-icon svgIcon="mat:check_circle_outline"></mat-icon>
    </div>
    <h1>{{"REG-CONFIRM.TITLE" | translate}}</h1>
    <p>{{"REG-CONFIRM.MSG" | translate}}</p>
    <div class="center"><button (click)="goToLogin()">{{"PAYMENT_REJ.LOGIN" | translate}}</button></div>
  </div>
</div>
<div *ngIf="guiService.showSpinner" class="spinner">
  <span class="spinner-center"><mat-progress-spinner mode="indeterminate" color="accent"></mat-progress-spinner></span>
</div>
