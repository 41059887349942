import { EditChallengeComponent } from './edit-challenge/edit-challenge.component';
import { NewChallengeComponent } from './new-challenge/new-challenge.component';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { GuiService } from 'src/app/services/gui.service';
import { RestService } from 'src/app/services/rest.service';
import { DialogConfirmComponent } from '../dialog-confirm/dialog-confirm.component';
import * as moment from 'moment';

@Component({
  selector: 'vex-challenge',
  templateUrl: './challenge.component.html',
  styleUrls: ['./challenge.component.scss']
})
export class ChallengeComponent implements OnInit {
  challenges: any;
  dataSource : MatTableDataSource<any> | null;
  displayedColumns: string[] = ['vuota', 'nome', 'data-pubblicazione', 'descrizione', 'actions'];
  selection = new SelectionModel<any>(true, []);
  searchCtrl = new UntypedFormControl();
  searchCtrlPublication = new UntypedFormControl();
  pageSize = 10;
  pageNumber = 0;
  pageSizeOptions: number[] = [5, 10, 15, 50];
  lunghezza: any;
  /* sort var */
  asc: any;
  fieldParam: string;
  searchAltFlag: boolean;
  sortFlag: boolean;

  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private restService: RestService, private dialog: MatDialog, public guiService: GuiService, public translate: TranslateService) { }

  ngOnInit(): void {
    this.getChallenges();
    this.asc = true;
    this.searchAltFlag = false;
    this.sortFlag = false;
  }

  ngAfterViewInit(): void {
    // this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;
  }

  getChallenges(){
    setTimeout(()=>this.guiService.showSpinner = true,0)
    this.dataSource = new MatTableDataSource();
    this.restService.postService('challenges', '/search', {page: this.pageNumber, size: this.pageSize}).subscribe((ris)=>{
      this.lunghezza = ris.totalItems
      this.challenges = ris["items"];
      console.debug(ris)
      this.dataSource.data = this.challenges;
      this.table ? this.table.renderRows() : null;
      this.guiService.showSpinner = false
    })
  }

  onPageChanged(pageEvent: PageEvent) {
    this.pageNumber = pageEvent.pageIndex+1;
    this.pageSize = pageEvent.pageSize;
    if(this.searchAltFlag == false && this.sortFlag == false){
      this.getChallenges()
    }else if(this.sortFlag == true){
      this.sortParam(this.fieldParam, this.asc)
    }else if(this.searchAltFlag == true){
      this.search()
    }
  }

  createChallenge(){
    this.dialog.open(NewChallengeComponent, {
      width: '100vw',
      maxWidth: '90vw',
      height: '95vh',
      panelClass: 'challenge-new-modalbox',
      disableClose: true,
      hasBackdrop: true
    }).afterClosed().subscribe((challenge) => {
      if(challenge == 'done'){
        this.getChallenges()
      }
    })
  }

  deleteChallenge(id): void {
    this.dialog.open(DialogConfirmComponent, {
      width: '250px',
      enterAnimationDuration: '0ms',
      exitAnimationDuration: '1ms',
      panelClass: 'dialog-modalbox',
      data: {
        type: 'challenges',
        id: id
      }
    }).afterClosed().subscribe((ris)=>{
      if(ris != 'true'){
        this.getChallenges()
      }
    })
  }

  editChallenge(item?: any['item']){
    this.restService.getService('challenges', item.id + '?ALL_LANGUAGES=true').subscribe((ris)=>{
    this.dialog.open(EditChallengeComponent, {
      width: '100vw',
      maxWidth: '90vw',
      height: '95vh',
      data: ris || null,
      disableClose: true,
      hasBackdrop: true
    }).afterClosed().subscribe((challenge) => {
      if(challenge == 'done'){
        this.getChallenges()
      }
    })
  })
  }

  search(){
    this.searchAltFlag = true
    let param = {
      "filterAnd": [],
      "page": this.pageNumber,
      "size": this.pageSize
    }

    if(this.searchCtrl.value != null){
      param["filterAnd"].push({
        "field": "i18nName."+this.translate.currentLang.toUpperCase(),
        "operator": "like",
        "value": this.searchCtrl.value.replace(/^\s+|\s+$/gm,'')
        })
    }

    if(this.searchCtrlPublication.value != null){
      param["filterAnd"].push({
        "field": "publishDate",
        "operator": "gte",
        "dateValue": moment(this.searchCtrlPublication.value + " 00:00:00","YYYY-MM-DD HH:mm:ss").format('x')
      }),
      param["filterAnd"].push(
        {
          "field": "publishDate",
          "operator": "lte",
          "dateValue": moment(this.searchCtrlPublication.value + " 23:59:59","YYYY-MM-DD HH:mm:ss").format('x')
        }
      )
    }

    this.restService.postService('challenges', '/search'+'?ALL_LANGUAGES=true', param).subscribe((ris)=>{
      this.lunghezza = ris.totalItems
      this.challenges = ris["items"];
      console.debug(ris)
      this.dataSource.data = this.challenges;
      this.table ? this.table.renderRows() : null;
      this.guiService.showSpinner = false
    })
  }

  sortParam(field?,asc?){
    this.asc = asc
    this.sortFlag = true
    this.fieldParam = field
    let param = {
      page: this.pageNumber,
      size: this.pageSize,
      orders: [
        {
         field: this.fieldParam,
         ascending : this.asc
        }
      ]
    }
    setTimeout(()=>this.guiService.showSpinner = true,0)
    this.restService.postService('challenges', '/search', param).subscribe((ris)=>{
      this.lunghezza = ris.totalItems
      this.challenges = ris["items"];
      console.debug(ris)
      this.dataSource.data = this.challenges;
      this.table ? this.table.renderRows() : null;
      this.guiService.showSpinner = false
    })
  }

  reload(){
    this.searchCtrlPublication.reset()
    this.searchCtrl.reset()
    this.searchAltFlag = false;
    this.sortFlag = false;
    this.pageNumber = 0;
    this.pageSize = 10;
    this.getChallenges()
  }

  resetPage(){
    this.pageNumber = 0;
  }
}
