import { formatDate } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { toDate } from 'date-fns';
import * as moment from 'moment';
import { GuiService } from 'src/app/services/gui.service';
import { RestService } from 'src/app/services/rest.service';
import { CloseConfirmComponent } from '../../close-confirm/close-confirm.component';
import { ChallengeComponent } from '../challenge.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'vex-edit-challenge',
  templateUrl: './edit-challenge.component.html',
  styleUrls: ['./edit-challenge.component.scss']
})
export class EditChallengeComponent implements OnInit {
  form: UntypedFormGroup;
  formLang: any;
  iconFormFlag: any;
  flags = {
    en: 'flag:united-states',
    it: 'flag:italy'
  };
  challenge: any;
  VIDEO_ENV = environment.baseURL.video;

  constructor(private fb: UntypedFormBuilder, private dialogRef: MatDialogRef<ChallengeComponent>,@Inject(MAT_DIALOG_DATA) private item: any['item'], private translate: TranslateService, public guiService: GuiService,public restService: RestService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.challenge = this.item;
    console.debug(this.challenge)
    this.changeFormLanguage(this.translate.currentLang);
    this.form = this.fb.group({
      nome_it:[null, Validators.required],
      nome_en:[null, Validators.required],
      publish: [null, Validators.required],
      expire: [null],
      desc_it: [null],
      desc_en: [null],
      file: [null],
      notification: [false]
    })


    this.form.controls['nome_it'].setValue(this.challenge.i18nName['IT']);
    this.form.controls['nome_en'].setValue(this.challenge.i18nName['EN']);
    this.form.controls['desc_it'].setValue(this.challenge.i18nDescription['IT']);
    this.form.controls['desc_en'].setValue(this.challenge.i18nDescription['EN']);
    this.form.controls['notification'].setValue(this.challenge.notificationEnable);
    this.form.controls['expire'].setValue(this.challenge.expirationDate ? formatDate(this.challenge.expirationDate,'yyyy-MM-dd','en') : null);
    this.form.controls['publish'].setValue(this.challenge.publishDate ? formatDate(this.challenge.publishDate,'yyyy-MM-dd','en') : null);

  }

  submit(){
    let chall = {
      creationDate: '',
      expirationDate: this.form.value.expire,
      name: this.form.value.nome_en,
      description: this.form.value.desc_en,
      i18nDescription: {
        IT: this.form.value.desc_it,
        EN: this.form.value.desc_en,
      },
      i18nName: {
        IT: this.form.value.nome_it,
        EN: this.form.value.nome_en,
      },
      video: "",
      videoFileName: this.form.value.nome_it,
      videoUrl: "",
      publishDate: this.form.value.publish,
      notificationEnable: this.form.value.notification
    }

    this.restService.putService('challenges', '/'+ this.item.id, chall).subscribe((ris)=>{
      this.guiService.openSnackBar(this.translate.instant('GLOBAL.EDIT_SUCCESS'), "OK!")
      this.dialogRef.close('done')
    })
  }

  changeFormLanguage(lang) {
    this.iconFormFlag = this.flags[lang];
    this.formLang = lang;
}
confirmClose(){
  this.dialog.open(CloseConfirmComponent,{
    disableClose: true,
    hasBackdrop: true,
    panelClass: 'close-dialog-modalbox'
  }).afterClosed().subscribe((ris)=>{
    if(ris == 'true'){
      this.dialogRef.close()
    }
  })
}

}
