import { GuiService } from './../../services/gui.service';
import { WorkoutEditComponent } from './workout-edit/workout-edit.component';
import { DialogConfirmComponent } from './../dialog-confirm/dialog-confirm.component';
import { WorkoutNewComponent } from './workout-new/workout-new.component';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { RestService } from 'src/app/services/rest.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'vex-workout',
  templateUrl: './workout.component.html',
  styleUrls: ['./workout.component.scss']
})
export class WorkoutComponent implements OnInit {
  workouts: any
  dataSource : MatTableDataSource<any> | null
  displayedColumns: string[] = ['vuota', 'nome', 'programma','tags', 'actions'];
  selection = new SelectionModel<any>(true, []);
  searchCtrl = new UntypedFormControl();
  searchCtrlProgram = new UntypedFormControl();
  searchCtrlWeek = new UntypedFormControl();
  pageSize = 10;
  pageNumber = 0;
  lunghezza: any;
  pageSizeOptions: number[] = [5, 10, 15, 50];
  programs: any;
  programsNames = {}
  /* sort var */
  asc: any;
  fieldParam: string;
  searchAltFlag: boolean;
  sortFlag: boolean;

  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private restService: RestService, private dialog: MatDialog, public guiService: GuiService, public translate: TranslateService) { }

  ngOnInit(): void {
    this.getPrograms()
    this.asc = true;
    this.searchAltFlag = false;
    this.sortFlag = false;
  }

  ngAfterViewInit(): void {
    //this.dataSource.paginator = this.paginator;
    //this.dataSource.sort = this.sort;
  }


  getWorkouts(){
    setTimeout(()=>this.guiService.showSpinner = true,0)
    this.dataSource = new MatTableDataSource();
    this.restService.postService('workouts_search', '', {page: this.pageNumber,size: this.pageSize}).subscribe((ris)=>{
      this.lunghezza = ris.totalItems
      this.workouts = ris["items"];
      console.debug(this.workouts)
      this.dataSource.data = this.workouts;
      this.table ? this.table.renderRows() : null;
      this.guiService.showSpinner = false
    })
  }

  onPageChanged(pageEvent: PageEvent) {
    this.pageNumber = pageEvent.pageIndex+1;
    this.pageSize = pageEvent.pageSize;
    if(this.searchAltFlag == false && this.sortFlag == false){
      this.getWorkouts()
    }else if(this.sortFlag == true){
      this.sortParam(this.fieldParam, this.asc)
    }else if(this.searchAltFlag == true){
      this.search()
    }
  }

  getPrograms(){
    this.restService.postService('workout_programs', '/search', {size: 100}).subscribe((ris)=>{
      for(let k in ris.items){
        ris.items[k].name = ris.items[k].name.replace("DRAFT::", '')
        this.programsNames[ris.items[k].id] = ris.items[k].name
      }
      this.programs = ris.items
      console.debug(this.programs)
      console.debug(this.programsNames)
      this.getWorkouts()
    })
  }


  createWorkout(){
    this.dialog.open(WorkoutNewComponent, {
      width: '100vw',
      maxWidth: '90vw',
      height: '95vh',
      panelClass: 'workout-new-modalbox',
      disableClose: true,
      hasBackdrop: true
    }).afterClosed().subscribe((workout) => {
      if(workout == 'done'){
        this.getWorkouts()
      }
    })
  }

  deleteWorkout(id): void {
    this.dialog.open(DialogConfirmComponent, {
      width: '250px',
      enterAnimationDuration: '0ms',
      exitAnimationDuration: '1ms',
      panelClass: 'dialog-modalbox',
      data: {
        type: 'workouts',
        id: id
      }
    }).afterClosed().subscribe((ris)=>{
      if(ris != 'true'){
        this.getWorkouts()
      }
    })
  }

  editWorkout(item?: any['item']){
    this.restService.getService('workouts', item.id + '?ALL_LANGUAGES=true').subscribe((ris)=>{
      this.dialog.open(WorkoutEditComponent, {
            width: '100vw',
            maxWidth: '90vw',
            height: '95vh',
            panelClass: 'workout-edit-modalbox',
            data: ris || null,
            disableClose: true,
            hasBackdrop: true
          }).afterClosed().subscribe((workout) => {
            if(workout == 'done'){
              this.searchAltFlag ? this.search() : this.getWorkouts()
            }
          })
    })

  }


  search(){
    this.searchAltFlag = true
    let param = {
      "filterAnd": [],
      "page": this.pageNumber,
      "size": this.pageSize
    }

    if(this.searchCtrl.value != null){
      param["filterAnd"].push({
        "field": "i18nName."+this.translate.currentLang.toUpperCase(),
        "operator": "like",
        "value": this.searchCtrl.value.replace(/^\s+|\s+$/gm,'')
        })
    }

    if(this.searchCtrlProgram.value != null){
      param["filterAnd"].push({
        "field": "workoutProgramId",
        "operator": "eq",
        "value": this.searchCtrlProgram.value
        })
    }

    if(this.searchCtrlWeek.value != null){
      param["filterAnd"].push({
        "field": "tags",
        "operator": "eq",
        "value": "week "+this.searchCtrlWeek.value
        })
    }
    this.restService.postService('workouts_search', '?ALL_LANGUAGES=true', param).subscribe((ris)=>{
      this.lunghezza = ris.totalItems
      this.workouts = ris["items"];
      console.debug(this.workouts)
      this.dataSource.data = this.workouts;
      this.table ? this.table.renderRows() : null;
      this.guiService.showSpinner = false
    })
  }

  sortParam(field?,asc?){
    this.asc = asc
    this.sortFlag = true
    this.fieldParam = field
    let param = {
      page: this.pageNumber,
      size: this.pageSize,
      orders: [
        {
         field: this.fieldParam,
         ascending : this.asc
        }
      ]
    }
    setTimeout(()=>this.guiService.showSpinner = true,0)
    this.restService.postService('workouts_search', '', param).subscribe((ris)=>{
      this.lunghezza = ris.totalItems
      this.workouts = ris["items"];
      console.debug(this.workouts)
      this.dataSource.data = this.workouts;
      this.table ? this.table.renderRows() : null;
      this.guiService.showSpinner = false
    })
  }

  reload(){
    this.searchCtrl.reset()
    this.searchCtrlProgram.reset()
    this.searchCtrlWeek.reset()
    this.searchAltFlag = false;
    this.sortFlag = false;
    this.pageNumber = 0;
    this.paginator.firstPage()
    this.pageSize = 10;
    this.getWorkouts()
  }

  resetPage(){
    this.pageNumber = 0
    this.paginator.firstPage()
    this.search()
  }

  whiteStartSpace(){
    if(this.searchCtrl.value && this.searchCtrl.value.length > 0){
      this.searchCtrl.setValue(this.searchCtrl.value.trimStart())
    }
  }
}
