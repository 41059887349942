import { RestService } from 'src/app/services/rest.service';
import { GuiService } from 'src/app/services/gui.service';
import { AdminNotificationsComponent } from './../admin-notifications.component';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CloseConfirmComponent } from '../../close-confirm/close-confirm.component';

import moment from 'moment';

@Component({
  selector: 'vex-new-notification',
  templateUrl: './new-notification.component.html',
  styleUrls: ['./new-notification.component.scss']
})
export class NewNotificationComponent implements OnInit {
  form: UntypedFormGroup;
  formLang: any;
  iconFormFlag: any;
  flags = {
    en: 'flag:united-states',
    it: 'flag:italy'
  };

  types: any;
  subTypes: any

  constructor(private fb: UntypedFormBuilder,  private dialogRef: MatDialogRef<AdminNotificationsComponent>,public translate: TranslateService, public guiService: GuiService, public restService: RestService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.changeFormLanguage(this.translate.currentLang);
    this.populateSelects()
    this.form = this.fb.group({
      nome_it:[null, Validators.required],
      nome_en:[null, Validators.required],
      publish: [null],
      tipo: [null],
      desc_it: [null],
      desc_en: [null],
      sub: [null]
    })
  }
  submit(form){
    let tmpFrom = moment(this.form.value.publish)

    let newNotification = {
      description: this.form.value.desc_en,
      i18nDescription: {
        EN: this.form.value.desc_en,
        IT: this.form.value.desc_it
      },
      i18nMessage: {
        EN: this.form.value.desc_en,
        IT: this.form.value.desc_it
      },
      i18nName: {
        EN: this.form.value.nome_en,
        IT: this.form.value.nome_it
      },
      i18nSubject: {
        EN: this.form.value.nome_en,
        IT: this.form.value.nome_it
      },
      message: this.form.value.desc_en,
      name: this.form.value.nome_en,
      startDate: tmpFrom.format("YYYY")+"-"+tmpFrom.format("MM")+"-"+tmpFrom.format("DD"),
      subject: this.form.value.nome_en,
      subscriptionType: this.form.value.sub,
      type: this.form.value.tipo,
      userId: null
    }
    console.debug(newNotification)
     this.restService.postService('notifications', '?ALL_LANGUAGES=true', newNotification).subscribe((ris)=>{
      console.debug(ris)
      this.guiService.openSnackBar(this.translate.instant('GLOBAL.NEW_SUCCESS'), "OK!")
      this.dialogRef.close('done')
     })

  }
  changeFormLanguage(lang) {
    this.iconFormFlag = this.flags[lang];
    this.formLang = lang;
}

populateSelects(){
  this.restService.getService('bos', 'notification-type').subscribe((ris)=>{
    console.debug('notification type', ris);
    this.types = ris
  })

  this.restService.getService('bos', 'subscription-type').subscribe((ris)=>{
    console.debug('tipo sub', ris);
    this.subTypes = ris;
  })
}
confirmClose(){
  this.dialog.open(CloseConfirmComponent,{
    disableClose: true,
    hasBackdrop: true,
    panelClass: 'close-dialog-modalbox'
  }).afterClosed().subscribe((ris)=>{
    if(ris == 'true'){
      this.dialogRef.close()
    }
  })
}

}
