<div class="cont">
  <mat-grid-list cols="3" rowHeight="100px" [gutterSize]="'0'">

      <mat-grid-tile class="header" colspan="1">
        <div>
          <h1>
            {{utente.masterData.name}} {{utente.masterData.lastName}}
          </h1>
        </div>
      </mat-grid-tile>
      <mat-grid-tile class="header" colspan="2">
        <div>
          <div class="w-100">
            <h3>{{"NUTRI_DETAIL.ARCH" | translate}}</h3>
          </div>
          <div class="w-50">
            <h2>{{"NUTRI_DETAIL.SURVEY" | translate}}</h2>
          </div>
          <div class="w-50">
            <h2>{{"NUTRI_DETAIL.DIETS" | translate}}</h2>
          </div>
        </div>
      </mat-grid-tile>

    <mat-grid-tile rowspan="3" colspan="1">
      <div>
        <div class="w-100">
          <p>{{"NUTRI_DETAIL.REGISTER" | translate}} {{utente.masterData.registrationDate | date: 'shortDate'}}</p>
        </div>
        <div class="w-100">
          <p *ngIf="utente.userPaymentInfo && utente.userPaymentInfo.subscriptionStatus != 'SUSPENDED'">
            <span><fa-icon [icon]="icon.faCircle" class="icon payed"></fa-icon></span>
            {{"NUTRI_DETAIL.PAYED" | translate}}
          </p>
          <p *ngIf="utente.userPaymentInfo && utente.userPaymentInfo.subscriptionStatus == 'SUSPENDED' || utente.userPaymentInfo && utente.userPaymentInfo.subscriptionStatus == null || utente.userPaymentInfo == null">
            <span><fa-icon [icon]="icon.faCircle" class="icon not-payed"></fa-icon></span>
            {{"NUTRI_DETAIL.NOT_PAYED" | translate}}
          </p>
        </div>
        <div class="w-100">
          <p>
            <span *ngIf="utente.masterData.lastSurvey != null"><fa-icon [icon]="icon.faCheck" class="icon survey is-survey"></fa-icon></span>
            <span *ngIf="utente.masterData.lastSurvey == null"><fa-icon [icon]="icon.faCheck" class="icon survey not-survey"></fa-icon></span>
            {{"NUTRI_DETAIL.SURVEY" | translate}}
          </p>
        </div>
        <div class="w-100">
          <p>
            <span *ngIf="utente.masterData.lastDietUploaded != null && checkDietUpload(utente.masterData.lastDietUploaded,utente.masterData.lastSurvey)"><fa-icon [icon]="icon.faCheck" class="icon survey is-survey"></fa-icon></span>
            <span *ngIf="utente.masterData.lastDietUploaded == null || !checkDietUpload(utente.masterData.lastDietUploaded,utente.masterData.lastSurvey)"><fa-icon [icon]="icon.faCheck" class="icon survey not-survey"></fa-icon></span>
            {{"NUTRI_DETAIL.DIET" | translate}}
          </p>
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile rowspan="3" colspan="2">


        <div class="padd">
        <div *ngFor="let survey of surveys; let i = index" class="tablerow">
          <div class="w-50 data">
            <span>
              <span (click)="openSurvey(survey)"><fa-icon [icon]="icon.faArrowDown" class="icon survey not-survey"></fa-icon></span>
              {{survey.creationDate | date: 'shortDate'}}
            </span>
          </div>
          <div class="w-50 data" *ngIf="survey.diet != null">
            <span (click)="downloadDietPdf(survey.id,'diet '+utente.masterData.name +' '+utente.masterData.lastName)"><fa-icon [icon]="icon.faArrowDown" class="icon survey not-survey"></fa-icon></span>
            {{survey.diet.dateUploaded | date: 'shortDate'}}
            <span (click)="deleteDiet()" *ngIf="i == 0"><mat-icon svgIcon="mat:delete_forever"></mat-icon></span>
          </div>
          <div class="w-50 data upload-dieta" *ngIf="survey.diet == null">
            <label for="file-input" (click)="getNutritionSurvey(survey.id)">
            <span class="upload-icon"><fa-icon [icon]="icon.faArrowUp" class="icon survey is-survey upload-icon"></fa-icon></span>
            </label>
            <input id="file-input" (change)="onFileSelected($event)" type="file" />
          </div>
        </div>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
  <div class="bottoni">
    <button (click)="confirmClose()" mat-button-base class="m-4">{{"GLOBAL.CLOSE" | translate}}</button>
    <button mat-raised-button  class="m-4 bottone-viola" [disabled]="btnDisable" (click)="loadDiet()">
      {{"GLOBAL.EDIT" | translate}}
    </button>
  </div>
</div>
