  <mat-accordion class="example-headers-align">
    <mat-expansion-panel expanded>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{"NEW-WORKOUT-PROG.EDIT_DATA" | translate}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="form">
        <div class="card flex-auto">

          <div class="px-6 py-4 border-b flex items-center header-title">
            <h2 class="title m-0"></h2>
            <div class="flex-end">
              <button [matMenuTriggerFor]="languageFormMenu" mat-icon-button type="button" align="end">
                <mat-icon svgIcon="{{iconFormFlag}}"></mat-icon>
              </button>
              <mat-menu #languageFormMenu="matMenu" overlapTrigger="false" >
                <button mat-menu-item (click)="changeFormLanguage('it')">
                  <mat-icon svgIcon="flag:italy"></mat-icon>
                  <span>Italiano</span>
                </button>
                <button mat-menu-item (click)="changeFormLanguage('en')">
                  <mat-icon svgIcon="flag:united-states"></mat-icon>
                  <span>English</span>
                </button>
              </mat-menu>
            </div>
          </div>

          <div>
            <div class="card px-6 py-4 flex flex-col subCards" *ngIf="formLang=='it'">
              <div class="flex flex-col sm:flex-row sm:gap-4">
                <mat-form-field class="flex-auto">
                  <mat-label>{{"NEW-WORKOUT-PROG.NAME" | translate}}</mat-label>
                  <input formControlName="nome_it" matInput />
                </mat-form-field>
                <mat-form-field class="flex-auto bozza">
                  <mat-label>{{"NEW-WORKOUT-PROG.PUBLISH" | translate}}</mat-label>
                  <mat-select formControlName="bozza">
                    <mat-option value="bozza">{{"NEW-WORKOUT-PROG.DRAFT" | translate}}</mat-option>
                    <mat-option value="pubblica">{{"NEW-WORKOUT-PROG.PUBLISH" | translate}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="flex flex-col sm:flex-row sm:gap-4">
                <mat-form-field class="flex-auto">
                <mat-label>{{"NEW-WORKOUT-PROG.EQUIP" | translate}}</mat-label>
                <mat-select formControlName="attrezzatura" multiple>
                  <mat-option [value]="option.id" *ngFor="let option of attrezzatura">{{ option.name }}</mat-option>
                </mat-select>
              </mat-form-field>
              </div>

              <div class="flex flex-col sm:flex-row sm:gap-4">

                <mat-form-field class="flex-auto">
                  <mat-label>{{"NEW-WORKOUT-PROG.WEEKS" | translate}}</mat-label>
                  <input formControlName="week_n" matInput type="number">

                </mat-form-field>

                <mat-form-field class="flex-auto">
                  <mat-label>{{"NEW-WORKOUT-PROG.W_PERWEEK" | translate}}</mat-label>
                  <input  matInput type="number" formControlName="workout_settimanali">

                </mat-form-field>
                <mat-form-field class="flex-auto">
                  <mat-label>{{"NEW-WORKOUT-PROG.W_DURATION" | translate}}</mat-label>
                  <input  matInput type="number" formControlName="workout_durata">
                </mat-form-field>
              </div>



              <mat-form-field class="description">
                <mat-label>{{"NEW-WORKOUT-PROG.DESC" | translate}}</mat-label>
                <textarea cols="30" rows="8" matInput formControlName="desc_it"></textarea>
              </mat-form-field>

            </div>

            <div class="card px-6 py-4 flex flex-col subCards" *ngIf="formLang=='en'">
              <div class="flex flex-col sm:flex-row sm:gap-4">
                <mat-form-field class="flex-auto">
                  <mat-label>{{"NEW-WORKOUT-PROG.NAME" | translate}}</mat-label>
                  <input formControlName="nome_en" matInput />
                </mat-form-field>
                <mat-form-field class="flex-auto bozza">
                  <mat-label>{{"NEW-WORKOUT-PROG.PUBLISH" | translate}}</mat-label>
                  <mat-select formControlName="bozza">
                    <mat-option value="bozza">{{"NEW-WORKOUT-PROG.DRAFT" | translate}}</mat-option>
                    <mat-option value="pubblica">{{"NEW-WORKOUT-PROG.PUBLISH" | translate}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="flex flex-col sm:flex-row sm:gap-4">
                <mat-form-field class="flex-auto">
                <mat-label>{{"NEW-WORKOUT-PROG.EQUIP" | translate}}</mat-label>
                <mat-select formControlName="attrezzatura" multiple>
                  <mat-option [value]="option.id" *ngFor="let option of attrezzatura">{{ option.name }}</mat-option>
                </mat-select>
              </mat-form-field>
              </div>

              <div class="flex flex-col sm:flex-row sm:gap-4">

                <mat-form-field class="flex-auto">
                  <mat-label>{{"NEW-WORKOUT-PROG.WEEKS" | translate}}</mat-label>
                  <input formControlName="week_n" matInput type="number">

                </mat-form-field>

                <mat-form-field class="flex-auto">
                  <mat-label>{{"NEW-WORKOUT-PROG.W_PERWEEK" | translate}}</mat-label>
                  <input  matInput type="number" formControlName="workout_settimanali">

                </mat-form-field>
                <mat-form-field class="flex-auto">
                  <mat-label>{{"NEW-WORKOUT-PROG.W_DURATION" | translate}}</mat-label>
                  <input  matInput type="number" formControlName="workout_durata">
                </mat-form-field>
              </div>



              <mat-form-field class="description">
                <mat-label>{{"NEW-WORKOUT-PROG.DESC" | translate}}</mat-label>
                <textarea cols="30" rows="8" matInput formControlName="desc_en"></textarea>
              </mat-form-field>

            </div>
            <div class="mt-4 mb-4">
              <h3 class="title mb-3">upload</h3>
              <input type="file" (change)="onFileSelected($event)" formControlName="file">
            </div>

          </div>
        </div>
      </form>
      </mat-expansion-panel>
      <mat-expansion-panel class="mb-5">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{"NEW-WORKOUT-PROG.SET_WEEK" | translate}}
          </mat-panel-title>
        </mat-expansion-panel-header>
  <div class="container">
      <div class="div1" *ngIf="weeks">
        <div class="week" *ngFor="let week of weeks; let w = index">
          <div class="title t-week">
            <button mat-button color="warn" (click)="removeWeek(w)">
              <mat-icon svgIcon="mat:remove_circle_outline"></mat-icon>
            </button>
            <h3>{{"WORKOUT-PROG.WEEK" | translate}} {{w}}</h3>
          </div>
          <div class="days">
            <span *ngFor="let d of days; let i = index">
              <div class="calendar" [class.selected]="selectedViewItem.indexOf(w+'_'+i)>=0">
                <span *ngIf="week.week[i].id != null && week.week[i].name" class="nomeWorkout" matTooltip="{{week.week[i].name}}">
                  <span class="trash"><mat-icon svgIcon="mat:delete" (click)="removeWorkout(w,i)"></mat-icon></span>
                  <span class="workoutLabel">{{week.week[i].name}}</span>
                </span>
                <span *ngIf="week.week[i].id != null && !week.week[i].name" class="nomeWorkout" matTooltip="{{filteredIds[week.week[i].id]}}">
                  <span class="trash"><mat-icon svgIcon="mat:delete" (click)="removeWorkout(w,i)"></mat-icon></span>
                  <span class="workoutLabel" >{{filteredIds[week.week[i].id]}}</span>
                </span>
                <span *ngIf="week.week[i].id == null"><mat-icon svgIcon="mat:playlist_add" class="icona-tasto" (click)="workoutSelection(w,i)"></mat-icon>
                </span>
                <em>{{d.value}}</em>
              </div>
            </span>
          </div>

        </div>
        <div class="add-btn-wrapper">
          <button mat-flat-button color="primary" (click)="addWeek()">
            {{ "WORKOUT-PROG.ADD_WEEK" | translate }}
          </button>
        </div>


      </div>


    <div class="div2">
      <div class="search-btn">
        <div>
          <!-- <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon>
          <input class="px-4 py-2 border-0 outline-none w-full bg-transparent" [formControl]="searchCtrl" (keyup)="searchAltExercise()" (search)="searchAltExercise()" placeholder="{{search}}" type="search" /> -->
          <mat-form-field>
            <mat-select [formControl]="searchCtrl">
              <mat-option [value]="'week '+i" *ngFor="let w of weeks; let i = index">
                week {{i}}
              </mat-option>
            </mat-select>
        </mat-form-field>
        <button class="w-full" (click)="searchWorkout()"><mat-icon svgIcon="mat:search"></mat-icon>{{'GLOBAL.SEARCH2' | translate}}</button>
        </div>

      </div>
      <div class="result">
        <mat-chip-listbox *ngFor="let w of workouts">

            <mat-chip class="chip_workout">
              {{w.name}}
              <button (click)="addWorkout(w)" matChipRemove>
                <mat-icon svgIcon="mat:add"></mat-icon>
            </button>
            </mat-chip>


        </mat-chip-listbox>
      </div>

    </div>

  </div>

  </mat-expansion-panel>
  </mat-accordion>

  <div class="bottoni">
    <button (click)="confirmClose()" mat-button-base class="m-4">{{"GLOBAL.CLOSE" | translate}}</button>
    <button mat-raised-button (click)="submit()" class="m-4 bottone-viola">
      {{"GLOBAL.EDIT" | translate}}
    </button>
  </div>

