<div class="container">
  <form [formGroup]="form">
    <div class="card flex-auto">
      <div class="px-6 py-4 border-b flex items-center header-title">
        <h2 class="title m-0">{{!form.get('parentId').value && form.get('parentId').value != '' ? ("WELLNESS.EDIT_MODAL.EDIT"| translate) : ("WELLNESS.EDIT_MODAL.EDIT_SUB"| translate)}}</h2>
        <div class="flex-end">
          <button [matMenuTriggerFor]="languageFormMenu" mat-icon-button type="button" align="end">
            <mat-icon svgIcon="{{iconFormFlag}}"></mat-icon>
          </button>
          <mat-menu #languageFormMenu="matMenu" overlapTrigger="false" >
            <button mat-menu-item (click)="changeFormLanguage('it')">
              <mat-icon svgIcon="flag:italy"></mat-icon>
              <span>Italiano</span>
            </button>
            <button mat-menu-item (click)="changeFormLanguage('en')">
              <mat-icon svgIcon="flag:united-states"></mat-icon>
              <span>English</span>
            </button>
          </mat-menu>
        </div>
      </div>


      <div class="px-6 py-4 flex flex-col">
        <div class="card px-6 py-4 flex flex-col subCards" *ngIf="formLang=='it'">
          <mat-form-field class="flex-auto">
            <mat-label>{{"WELLNESS.EDIT_MODAL.NAME" | translate}} {{"GLOBAL.ITA" | translate}}</mat-label>
            <input formControlName="nome_it" matInput />
          </mat-form-field>

          <div class="flex flex-col sm:flex-row sm:gap-4">
            <mat-form-field class="flex-auto">
              <mat-label>{{"WELLNESS.EDIT_MODAL.PARENT" | translate}}</mat-label>
                <input
                type="text"
                matInput
                [formControlName]="'parentId'"
                [matAutocomplete]="auto"
                (keyup)="searchCat()"
                (focus)="searchCat()">
              <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let item of categories" [value]="item">
                  {{item.name}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>

          <mat-form-field class="description">
            <mat-label>{{"CHALLENGES.DESC" | translate}} {{"GLOBAL.ITA" | translate}}</mat-label>
            <textarea cols="30" rows="8" matInput formControlName="desc_it"></textarea>
          </mat-form-field>
        </div>

        <div class="card px-6 py-4 flex flex-col subCards" *ngIf="formLang=='en'">
          <mat-form-field class="flex-auto">
            <mat-label>{{"WELLNESS.EDIT_MODAL.NAME" | translate}} {{"GLOBAL.ENG" | translate}}</mat-label>
            <input formControlName="nome_en" matInput />
          </mat-form-field>

          <div class="flex flex-col sm:flex-row sm:gap-4">
            <mat-form-field class="flex-auto">
              <mat-label>{{"WELLNESS.EDIT_MODAL.PARENT" | translate}}</mat-label>
                <input
                type="text"
                matInput
                [formControlName]="'parentId'"
                [matAutocomplete]="auto"
                (keyup)="searchCat()"
                (focus)="searchCat()">
              <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let item of categories" [value]="item">
                  {{item.name}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>

          <mat-form-field class="description">
            <mat-label>{{"CHALLENGES.DESC" | translate}} {{"GLOBAL.ENG" | translate}}</mat-label>
            <textarea cols="30" rows="8" matInput formControlName="desc_en"></textarea>
          </mat-form-field>
        </div>



        <div class="bottoni">
          <button (click)="confirmClose()" mat-button-base class="m-4">{{"GLOBAL.CLOSE" | translate}}</button>
          <button mat-raised-button [disabled]="form.invalid" (click)="submit()" class="m-4 bottone-viola">
            {{"GLOBAL.EDIT" | translate}}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
