<div class="bg-pattern w-full h-full flex flex-col items-center justify-center">
  <div [@fadeInUp] class="card overflow-hidden w-full max-w-xs">
    <div class="p-6 pb-0 flex flex-col items-center justify-center">
      <div class="fill-current text-center">
        <img class="w-16" id="logodesi" src="../../../assets/img/demo/logo.png">
      </div>
    </div>

    <div class="text-center mt-4">
      <h2 class="title m-0">{{"AUTH_RESET.RESET" | translate}}</h2>
      <h4 class="body-2 text-secondary m-0">{{"AUTH_RESET.ENTER_PSW" | translate}}</h4>
    </div>

    <div [formGroup]="form" class="p-6 flex flex-col">
      <mat-form-field>
        <mat-label>password</mat-label>

        <mat-icon class="mr-2" matPrefix svgIcon="mat:key"></mat-icon>
        <input formControlName="password" matInput required minlength="6" [type]="inputType">
        <button (click)="toggleVisibility()" mat-icon-button matSuffix type="button">
          <mat-icon *ngIf="visible" svgIcon="mat:visibility"></mat-icon>
          <mat-icon *ngIf="!visible" svgIcon="mat:visibility_off"></mat-icon>
        </button>
        <mat-error *ngIf="form.get('password').hasError('required')">
          {{"AUTH_RESET.ENTER" | translate}}
        </mat-error>
        <mat-error *ngIf="form.get('password').hasError('minlength')">
          {{"AUTH_RESET.ATLEAST" | translate}} {{6 -form.value.password.length}} {{"AUTH_RESET.MORE" | translate}}
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{"AUTH_RESET.CONFIRM" | translate}}</mat-label>

        <mat-icon class="mr-2" matPrefix svgIcon="mat:key"></mat-icon>
        <input formControlName="confirmPassword" matInput required [type]="inputType">
        <button (click)="toggleVisibility()" mat-icon-button matSuffix type="button">
          <mat-icon *ngIf="visible" svgIcon="mat:visibility"></mat-icon>
          <mat-icon *ngIf="!visible" svgIcon="mat:visibility_off"></mat-icon>
        </button>
        <mat-error *ngIf="form.get('confirmPassword').errors" class="alert alert-danger">
          <div *ngIf="form.get('confirmPassword').errors.required">{{"AUTH_RESET.CONFIRM_ERR_MSG" | translate}}</div>

          <div *ngIf="form.get('confirmPassword').errors.confirmPasswordValidator">{{"AUTH_RESET.CONFIRM_ERR_MSG2" | translate}}</div>
        </mat-error>
      </mat-form-field>

      <button (click)="send()" mat-raised-button type="button" id="buttColor">
        {{"AUTH_RESET.RESET_PSW" | translate}}
      </button>
    </div>
  </div>
</div>
