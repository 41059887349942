import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GuiService } from 'src/app/services/gui.service';
import { RestService } from 'src/app/services/rest.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { WellnessService } from 'src/app/services/wellness.service';

@Component({
  selector: 'vex-on-demand',
  templateUrl: './on-demand.component.html',
  styleUrls: ['./on-demand.component.scss']
})
export class OnDemandComponent implements OnInit {
  banner;
  categories = [];
  subCategories = [];
  currentBannerSlide: number = 0;
  selectedCategory;

  constructor(public translate: TranslateService, public guiService: GuiService, private restService: RestService, private router: Router, public wellnessSrv: WellnessService) { }

  ngOnInit(): void {
    this.getCategories();
  }

  getCategories() {
    setTimeout(() => {
      this.guiService.showSpinner = true;
    }, 100);
    this.restService.postService('category', '/search?ALL_LANGUAGES=true', { page: 0, size: 50 }).subscribe((ris) => {
      for (let item of ris.items) {
        if (item.parentId) {
          this.subCategories.push(item)
        } else {
          item.name !== 'BANNER' ? this.categories.push(item) : this.banner = item;
        }
      }
      this.wellnessSrv.macroAreaBack ? this.openCategory(this.wellnessSrv.macroAreaBack) : null

      this.guiService.showSpinner = false;
    })
  }

  changeSlide(k) {
    this.currentBannerSlide = k
  }

  openCategory(item: any) {
    this.videoDateCheck()
    this.checkOnDemandEnabled()
    this.selectedCategory = item;
  }

  videoDateCheck() {
    const tmpSubCategories = JSON.parse(JSON.stringify(this.subCategories))
    for (let k = 0; k < tmpSubCategories.length; k++) {
      let category = tmpSubCategories[k];
      if (category.videos && category.videos.length > 0) {
        for (let i = 0; i < category.videos.length; i++) {
          let video = category.videos[i]
          const publishDate = moment(video.publishDate, 'unix').format('YYYY-MM-DD');
          const expireDate = video.expirationDate ? moment(video.expirationDate, 'unix').format('YYYY-MM-DD') : null;
          const today = moment().format('YYYY-MM-DD')

          if (moment(today).isSameOrAfter(publishDate) && expireDate && moment(today).isSameOrBefore(expireDate) || moment(today).isSameOrAfter(publishDate) && !expireDate) {
            console.debug('LA DATA DI PUBBLICAZIONE E VALIDA ')
          } else {
            console.debug('NOT VALID')
            let indexSubCategory = this.subCategories.findIndex(cat => cat.id === category.id);
            let indexToRemove = this.subCategories[indexSubCategory].videos.findIndex(vid => vid.name === video.name)

            this.subCategories[indexSubCategory].videos.splice(indexToRemove, 1)
          }
        }
      } else {
        let indexToRemove = this.subCategories.findIndex(cat => cat.id === category.id)
        this.subCategories.splice(indexToRemove, 1)
      }
    }

    for (let k = 0; k < this.categories.length; k++) {
      let category = this.categories[k];
      if (category.videos && category.videos.length > 0) {
        for (let i = 0; i < category.videos.length; i++) {
          let video = category.videos[i]
          const publishDate = moment(video.publishDate, 'unix').format('YYYY-MM-DD');
          const expireDate = video.expirationDate ? moment(video.expirationDate, 'unix').format('YYYY-MM-DD') : null;
          const today = moment().format('YYYY-MM-DD')

          if (moment(today).isSameOrAfter(publishDate) && expireDate && moment(today).isSameOrBefore(expireDate) || moment(today).isSameOrAfter(publishDate) && !expireDate) {
            console.debug('LA DATA DI PUBBLICAZIONE E VALIDA ')
          } else {
            console.debug('NOT VALID')
            let indexCategory = this.categories.findIndex(cat => cat.id === category.id);
            let indexToRemove = this.categories[indexCategory].videos.findIndex(vid => vid.name === video.name)

            this.categories[indexCategory].videos.splice(indexToRemove, 1)
          }
        }
      }
    }
  }

  checkOnDemandEnabled() {
    if (this.guiService.userLogged.masterData.functionalityPromotions) {
      const onDemandPromoObj = this.guiService.userLogged.masterData.functionalityPromotions.find(x => x.code === 'ONDEMAND');
      if (onDemandPromoObj && onDemandPromoObj.subscriptionIds && onDemandPromoObj.subscriptionIds.includes(this.guiService.userLogged.userPaymentInfo.subscriptionId)) {
        console.debug("IL PIANO E' COMPRESO")
        const registrationDate = moment(this.guiService.userLogged.user.registrationDate).format('YYYY-MM-DD');
        const toDate = moment(onDemandPromoObj.toDate, 'unix').format('YYYY-MM-DD');
        console.debug('reg:', registrationDate, 'toDate:', toDate)
        if (moment(registrationDate).isSameOrBefore(toDate)) {
          console.debug("LA REGISTRAZIONE E' PRIMA DEL TODATE")

          this.subCategories.forEach(category => {
            category.videos ?
              category.videos.forEach(video => {
                video.videoBlocked = false
              })
              : null
          })

          this.categories.forEach(category => {
            category.videos ?
              category.videos.forEach(video => {
                video.videoBlocked = false
              })
              : null
          })
        }
      }
    }
  }
  videoDetail(video, categoryName, areaBack) {
    this.wellnessSrv.videoSelected = video;
    this.wellnessSrv.categoryName = categoryName;
    this.wellnessSrv.macroAreaBack = areaBack;

    setTimeout(() => {
      this.router.navigate(['main/ondemand-video-detail'])
    }, 200);
  }

  backButton() {
    this.selectedCategory = null;
    this.wellnessSrv.macroAreaBack = null;
  }

  showMoreVideos(areaBack, categoryName, videos) {
    this.wellnessSrv.macroAreaBack = areaBack;
    this.wellnessSrv.categoryName = categoryName;
    /* ARRAY OF VIDEOS */
    this.wellnessSrv.videoSelected = videos;
    setTimeout(() => {
      this.router.navigate(['main/ondemand-video-list'])
    }, 200);
  }
}
