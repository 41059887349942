import { faRunning } from '@fortawesome/free-solid-svg-icons';
import { faHeartbeat } from '@fortawesome/free-solid-svg-icons';
import { GuiService } from 'src/app/services/gui.service';
import { Component, OnInit } from '@angular/core';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger60ms } from 'src/@vex/animations/stagger.animation';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'vex-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
  animations: [
    stagger60ms,
    fadeInUp400ms
  ]
})
export class InfoComponent implements OnInit {
  icon = {
    "faHeartbeat": faHeartbeat,
    "faRunning": faRunning
  };

  constructor(public guiService: GuiService, public translate: TranslateService) { }

  ngOnInit(): void {
    setTimeout(()=>this.guiService.showSpinner = false,0)
  }

}
