import { TranslateService } from '@ngx-translate/core';
import { GuiService } from 'src/app/services/gui.service';
import { Component, OnInit } from '@angular/core';
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation';
import { scaleIn400ms } from 'src/@vex/animations/scale-in.animation';
import { Link } from 'src/@vex/interfaces/link.interface';

@Component({
  selector: 'vex-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  animations: [
    scaleIn400ms,
    fadeInRight400ms
  ]
})
export class UserProfileComponent implements OnInit {
  links: Link[] = [
    {
      label: this.translate.instant('PROFILE_MENU.PROFILE'),
      route: './profile',
      routerLinkActiveOptions: { exact: true }
    },
    {
      label: this.translate.instant('SUB-P'),
      route: './sub'
    },
    {
      label: 'password',
      route: './reset-password'
    }
  ];

  user:any;

  constructor(public guiService: GuiService, public translate: TranslateService) { }

  ngOnInit(): void {
    setTimeout(()=>this.guiService.showSpinner = false,0)

    setTimeout(() => {
      this.user = this.guiService.userLogged
      console.debug(this.user)
    }, 1000);

  }

}
