import { RestService } from 'src/app/services/rest.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { catchError, map, Observable, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  baseURL = environment.baseURL;
  tkn = "";
  lang = "";
  baseOPT = {};

  constructor(private restService: RestService,public router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.tkn = this.restService.getTkn()
    // console.debug(this.tkn)
    this.tkn && this.tkn!="" ? null : this.tkn = sessionStorage.getItem("tkn");
    req = req.clone({
      setHeaders: {
        'Access-Control-Allow-Origin':'*',
        'Accept': 'application/json'
      },
    });

    let pathname = [];
    req.url ? pathname = req.url.split("/") : null;

    // console.debug("Pathname",pathname);

    if (pathname.indexOf("login")<0 && pathname.indexOf("i18n")<0 && pathname.indexOf("checkout-session-url")< 0 && pathname.indexOf("checkout-session-info")< 0 && pathname.indexOf("checkout-new")< 0 && pathname.indexOf("user-subscriptions")< 0) {

        let tkn = this.restService.getTkn();
        if(this.tkn == null){
          req = req.clone({
            headers: req.headers.delete('Authorization')
          })
        }
        else if(req.url.includes("register") != true){
          /*FIX MOMENTANEO */
          req = req.clone({
                  headers: req.headers.set('Authorization', 'Bearer '+this.tkn)
                });
        }
        if (pathname[5] && pathname[5].indexOf('delete') >= 0) {
            req = req.clone({
                headers: req.headers.set('Content-Type', 'text/html')
            });
        } else if(pathname[4] && pathname[4] == "import") {
            // nothing
        } else {
            req = req.clone({
                headers: req.headers.set('Content-Type', 'application/json; charset=utf-8')
            });
        }
    }

    return next.handle(req).pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // console.debug('event--->>>', event);
          }
          return event;
        }),catchError((error: HttpErrorResponse) =>{
          if(error.status == 403){
            console.debug('rifare login il token potrebbe non essere piu valido',error.status)
            this.router.navigate(['auth/login'])
          }
          return throwError(error)
        })
    );
  }
}
