<h1 mat-dialog-title>
  <span *ngIf="status == 'ACTIVE'">{{"USER-EDIT.SURE" | translate}}</span>
  <span *ngIf="status == 'PAUSED'">{{"USER-EDIT.PAUSE2" | translate}}</span>
</h1>
<div mat-dialog-content>
  <span *ngIf="status == 'ACTIVE'">{{"USER-EDIT.SURE2" | translate}}</span>
  <span *ngIf="status == 'PAUSED'">
    <mat-radio-group>
      <mat-radio-button class="radio" *ngFor="let selection of selections" [value]="selection.value" (change)="onChange($event)">
        {{ selection.key }}
      </mat-radio-button>
    </mat-radio-group>
  </span>
</div>
<div class="btn-group">
  <button mat-button mat-dialog-close (click)="close()">{{"USER-EDIT.CANCEL" | translate}}</button>
  <button mat-button cdkFocusInitial (click)="changeStatus(status)">{{"USER-EDIT.YES" | translate}}</button>
</div>
