import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PauseDialogComponent } from './../pause-dialog/pause-dialog.component';
import { RestService } from './../../services/rest.service';
import { GuiService } from 'src/app/services/gui.service';
import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import * as confetti from 'canvas-confetti';
import {ImageResize} from "../../class/image-resize";
declare var $: any;

@Component({
  selector: 'vex-workout-exe',
  templateUrl: './workout-exe.component.html',
  styleUrls: ['./workout-exe.component.scss']
})
export class WorkoutExeComponent implements OnInit {
  workout: any;
  countdown: any
  phaseNumber
  exerciseNumber
  filteredIds = {}
  filteredExs = []
  imgURL: string;
  interval: any;
  workoutCompleted: any;
  congrats: string;
  executionTimer:any;
  intervalTimer: any;
  lastWorkoutProgram = {}
  setCounter: any;
  showBgGradient = true;
  showGoodWorkoutMsg = true
  showPhaseName = false
  descriptionEsercizio: any;
  phaseDescription: any;
  eachSideView = 0

  alternativeExercise: any;
  progressBar= {
    steps:0,
    size: 0
  };
  collectionExs = {}
  setCompleted: boolean = false;


  constructor(public guiService: GuiService, public restService: RestService,public dialog: MatDialog, public translate: TranslateService, public router: Router, private renderer2: Renderer2, private elementRef: ElementRef) { }

  ngOnInit(): void {
    setTimeout(()=>this.guiService.showSpinner = true,0)
    this.countdown = 3;
    this.phaseNumber = 0
    this.exerciseNumber = 0
    this.workoutCompleted = false;
    this.setCounter = 1;

    this.imgURL = environment.baseURL.exercises;
    this.workout = this.guiService.selectedWorkout
    if(this.workout == undefined){
      this.router.navigate(['/main/home'])
    }
    console.debug('workout: ', this.workout)
    for(let phase of this.workout.phases){
      for(let ex of phase.exercises){
        this.filteredExs.push(ex.exerciseId)
        ex.restTimeSeconds === 0 ? ex.restTimeSeconds = 5 : null
      }
    }
    this.loadExerciseName(this.filteredExs)
    this.manageCountdown(this.countdown)

    this.getCurrentInfo()
    this.checkPhaseDescription()

    setTimeout(() => {
      if(this.workout.phases[this.phaseNumber].exercises[this.exerciseNumber].executionTimeSeconds > 0){
        this.manageCountExecution(this.workout.phases[this.phaseNumber].exercises[this.exerciseNumber].executionTimeSeconds)
      }
    }, 3000);

  }
  ngOnDestroy(): void {
    this.stopCountdown()
    this.stopExecution()
  }
  getCurrentInfo(){
    this.restService.getService('current_user', '').subscribe((ris)=>{
      let tmphistory = ris.lastWorkoutProgram.weekHistory ? JSON.parse(ris.lastWorkoutProgram.weekHistory) : [];
      if(sessionStorage.getItem('selectedProgramId') != ris.lastWorkoutProgram.workoutProgramId){
        tmphistory = [];
        for(let i=0;i<this.guiService.weekSelectedNumber; i++) {
          tmphistory.push({
            startWeek: null,
            days: [null,null,null,null,null,null,null]
          })
        };
        tmphistory.push({
          startWeek: moment().format('x'),
          days: [null,null,null,null,null,null,null]
        });
        tmphistory[this.guiService.weekSelectedNumber].days[this.guiService.currentDayWorkout] = moment().format('x');
        /* UTILIZZATO EXERCISEID PROVVISORIO CON ID ALLENAMENTO E NOME ALLENAMENTO */
        this.lastWorkoutProgram = {
          day: 1,
          week: this.guiService.weekSelectedNumber,
          workoutProgramId: sessionStorage.getItem('selectedProgramId'),
          exerciseId:  this.workout.id+'::'+this.workout.name,
          id: "string",
          weekHistory: tmphistory
        }
      }else{
        for(let i=0;i<=this.guiService.weekSelectedNumber; i++) {
          if(!tmphistory[i]) {
            tmphistory[i] = {
              startWeek: null,
              days: [null,null,null,null,null,null,null]
            }
          }
        };

        if(this.guiService.resetWeek){
          console.debug('RESET WEEK ',this.guiService.resetWeek)
          ris.lastWorkoutProgram.week = this.guiService.weekSelectedNumber
          ris.lastWorkoutProgram.day = 0
          for(let i = this.guiService.weekSelectedNumber; i < ris.lastWorkoutProgram.weekHistory.length; i++){
            if(tmphistory[i]){
              tmphistory[i].startWeek = null
              tmphistory[i].days = [null,null,null,null,null,null,null]
            }
            console.debug('indice',i, tmphistory[i])
          }
          this.guiService.resetWeek = false
        }
        if(JSON.stringify(tmphistory[this.guiService.weekSelectedNumber].days) === JSON.stringify([null,null,null,null,null,null,null])){
          tmphistory[this.guiService.weekSelectedNumber].startWeek = moment().format('x');
        }
        tmphistory[this.guiService.weekSelectedNumber].days[this.guiService.currentDayWorkout] = moment().format('x');
        /* UTILIZZATO EXERCISEID PROVVISORIO CON ID ALLENAMENTO E NOME ALLENAMENTO */
        if(ris.lastWorkoutProgram.week != this.guiService.weekSelectedNumber){
          ris.lastWorkoutProgram.day = 0
        }
        this.lastWorkoutProgram = {
        day: ris.lastWorkoutProgram.day >= 6 ? 0 : ris.lastWorkoutProgram.day+1,
        week: ris.lastWorkoutProgram.day >= 6 && ris.lastWorkoutProgram.week == this.guiService.weekSelectedNumber && ris.lastWorkoutProgram.week < this.guiService.selectedProgramTotalWeeks ? ris.lastWorkoutProgram.week+1:this.guiService.weekSelectedNumber,
        workoutProgramId: ris.lastWorkoutProgram.workoutProgramId,
        exerciseId: this.workout.id+'::'+this.workout.name,
        id: "string",
        weekHistory: tmphistory
      }
      }
    })
  }

  loadExerciseName(baseIndex) {
    let param = {
      "filterAnd": [
        {
          "field": "id",
          "operator": "in",
          "value": baseIndex.toString()
        }
      ],
      "page": 0,
      "size": 200
    }
    this.restService.postService('exercises', '/search', param).subscribe((ris) => {
      let tmpAltIds = []
      for (let wId of baseIndex) {
        this.filteredIds[wId] = ris.items.find(x => x.id === wId).name
        this.collectionExs[wId] = ris.items.find(x => x.id === wId)
        if (!this.collectionExs[ris.items.find(x => x.id === wId).exerciseAlternativeId]) {
          tmpAltIds.push(ris.items.find(x => x.id === wId).exerciseAlternativeId)
        }
      }
      this.loadAlternativeExercise(tmpAltIds)
      this.guiService.showSpinner = false
    })
  }

  loadAlternativeExercise(baseIndex) {
    let param = {
      "filterAnd": [
        {
          "field": "id",
          "operator": "in",
          "value": baseIndex.toString()
        }
      ],
      "page": 0,
      "size": 200
    }
    this.restService.postService('exercises', '/search', param).subscribe((ris) => {
      for (let wId of baseIndex) {
        this.collectionExs[wId] = ris.items.find(x => x.id === wId)
      }
      this.guiService.showSpinner = false
    })
  }

nextExercise(a){
  this.stopCountdown()
  this.showGoodWorkoutMsg = false
  this.setCompleted = false;
  if(this.workout.phases[this.phaseNumber].exercises[this.exerciseNumber].restTimeSeconds > 0){
    this.showBgGradient = true
  }else{
    this.showBgGradient = false
  }
  clearInterval(this.intervalTimer)
  if(this.workout.phases.length-1 == this.phaseNumber){
    console.debug('arrivato alla fine delle fasi')
      if(this.workout.phases[this.workout.phases.length-1].exercises.length-1 == this.exerciseNumber && this.setCounter == this.workout.phases[this.phaseNumber].repetitions){
        console.debug('allenamento completato')
        this.surprise();
        this.workoutCompleted = true
        this.congratsMsg()
        var sound = new Audio("../../../assets/sound/beep_vecchio.wav");
        sound.play()
        console.debug("LastWorkoutProgram",this.lastWorkoutProgram);

        this.lastWorkoutProgram['weekHistory'] = JSON.stringify(this.lastWorkoutProgram['weekHistory']);
        this.restService.postService('user_workout_programs','', this.lastWorkoutProgram).subscribe((ris)=>{
          console.debug(ris)
        })

      }
    }
  if(this.exerciseNumber < this.workout.phases[this.phaseNumber].exercises.length-1){
    this.manageCountdown(this.workout.phases[this.phaseNumber].exercises[this.exerciseNumber].restTimeSeconds)
    this.exerciseNumber = this.exerciseNumber + a
    if(this.workout.phases[this.phaseNumber].exercises[this.exerciseNumber].executionTimeSeconds != 0){
      this.manageCountExecution(this.workout.phases[this.phaseNumber].exercises[this.exerciseNumber].executionTimeSeconds)
    }

  }else{
    if(this.phaseNumber < this.workout.phases.length-1 && this.setCounter == this.workout.phases[this.phaseNumber].repetitions){
      console.debug('serie finite e resettato setcounter a 1')
      this.showPhaseName = true
      setTimeout(() => {
        this.showPhaseName = false
      }, 5000);
      this.setCounter = 1
      this.manageCountdown(this.workout.phases[this.phaseNumber].exercises[this.exerciseNumber].restTimeSeconds)
      this.phaseNumber = this.phaseNumber + a
      this.exerciseNumber = 0
      if(this.workout.phases[this.phaseNumber].exercises[this.exerciseNumber].executionTimeSeconds != 0){
        let tmpExecutionTimeSeconds = this.showPhaseName = true ? this.workout.phases[this.phaseNumber].exercises[this.exerciseNumber].executionTimeSeconds + 5 : this.workout.phases[this.phaseNumber].exercises[this.exerciseNumber].executionTimeSeconds;
        this.manageCountExecution(tmpExecutionTimeSeconds)
    }
    } else if(this.exerciseNumber == this.workout.phases[this.phaseNumber].exercises.length-1 && this.setCounter < this.workout.phases[this.phaseNumber].repetitions){
      console.debug('serie terminata')
      console.debug('parte il rest della serie')
      if(this.workout.phases[this.phaseNumber].restTimeSeconds > 0){
        this.showBgGradient = true
        this.setCompleted = true;
      }else{
        this.showBgGradient = false
        this.setCompleted = false;
      }
      this.manageCountdown(this.workout.phases[this.phaseNumber].restTimeSeconds)
      this.exerciseNumber = 0
      this.setCounter++
      if(this.workout.phases[this.phaseNumber].exercises[this.exerciseNumber].executionTimeSeconds != 0){
        this.manageCountExecution(this.workout.phases[this.phaseNumber].exercises[this.exerciseNumber].executionTimeSeconds)
      }
    }

  }
  this.checkPhaseDescription()
}
previousExercise(a){
  this.stopCountdown()
  clearInterval(this.intervalTimer)
  console.debug('ex number',this.exerciseNumber, 'fase', this.phaseNumber)
  if(this.exerciseNumber != 0){
    this.exerciseNumber = this.exerciseNumber - a

    if(this.workout.phases[this.phaseNumber].exercises[this.exerciseNumber].executionTimeSeconds != 0){
      this.manageCountExecution(this.workout.phases[this.phaseNumber].exercises[this.exerciseNumber].executionTimeSeconds)
    }
  }else if(this.exerciseNumber == 0 && this.setCounter == 1){
      if(this.phaseNumber != 0){
        this.phaseNumber = this.phaseNumber - a;
        this.exerciseNumber = this.workout.phases[this.phaseNumber].exercises.length-1;
        this.setCounter = this.workout.phases[this.phaseNumber].repetitions;
          if(this.workout.phases[this.phaseNumber].exercises[this.exerciseNumber].executionTimeSeconds != 0){
            console.debug('ce la faccio')
            this.manageCountExecution(this.workout.phases[this.phaseNumber].exercises[this.exerciseNumber].executionTimeSeconds)
          }
      }
  }else if(this.exerciseNumber == 0 && this.setCounter > 1){
    this.setCounter--
    this.exerciseNumber = this.workout.phases[this.phaseNumber].exercises.length-1;
    if(this.workout.phases[this.phaseNumber].exercises[this.exerciseNumber].executionTimeSeconds != 0){
      this.manageCountExecution(this.workout.phases[this.phaseNumber].exercises[this.exerciseNumber].executionTimeSeconds)
    }
  }else{
    this.exerciseNumber--
  }
  this.checkPhaseDescription()
}

pauseExercise(){
  this.stopExecution()
  this.dialog.open(PauseDialogComponent, {
    width: '300px',
    panelClass: 'pause-dialog',
    disableClose: true,
    hasBackdrop: true
  }).afterClosed().subscribe((ris)=>{
    if(ris != 'true'){
      this.router.navigate(['/main/home'])
    }
    this.manageCountExecution(this.executionTimer)
  })
}


manageCountdown(number) {
  var sound = new Audio("../../../assets/sound/beep.mp3");
  clearInterval(this.interval);
  this.countdown = number > 0 ? number : -1;
  let count = 0;
  this.progressBar.size = this.countdown
  if (this.countdown == 3) {
    sound.play()
    count = 0;
  }
  this.interval = setInterval(() => {
    count == 0 && this.countdown == 3 ? null : this.countdown--;
    count++;
    this.progressBar.steps++
    console.debug(this.progressBar)
    if (this.countdown <= -1) {
      clearInterval(this.interval)
      this.progressBar.size = 0
      this.progressBar.steps = 0
    }
  }, 1000);

}

stopCountdown(){
  clearInterval(this.interval)
  this.progressBar.size = 0
  this.progressBar.steps = 0
}
skipRest(){
  this.countdown = -1
  this.stopCountdown()
}
manageCountExecution(number){
  this.executionTimer = number
  var singleBeep = new Audio("../../../assets/sound/beep_vecchio.wav");
  this.intervalTimer = setInterval(() => {
    if(this.countdown <= -1){
      this.executionTimer--
      console.debug('exe timer',this.executionTimer)
    }
    if(this.executionTimer <= -1){
      clearInterval(this.intervalTimer)
      if(this.workout.phases[this.phaseNumber].exercises[this.exerciseNumber].eachSideFlag == false){
        singleBeep.play()
        this.nextExercise(1)
      }else if(this.workout.phases[this.phaseNumber].exercises[this.exerciseNumber].eachSideFlag == true && this.eachSideView == 0){
        singleBeep.play()
        this.eachSideView = 1
        this.manageCountExecution(this.workout.phases[this.phaseNumber].exercises[this.exerciseNumber].executionTimeSeconds)
      }else if(this.eachSideView == 1){
        singleBeep.play()
        this.eachSideView = 0
        this.nextExercise(1)
      }
    }
    }, 1000);
}
stopExecution(){
  clearInterval(this.intervalTimer)
}
congratsMsg(){
  this.congrats = this.translate.instant("CONGRATS_MSG."+Math.floor(Math.random()*10))
}

goHome(){
  this.router.navigate(['main/home'])
}

public surprise(): void {

  const canvas = this.renderer2.createElement('canvas');
  canvas.setAttribute("id","confetti-canvas");

  this.renderer2.appendChild(this.elementRef.nativeElement, canvas);

  const myConfetti = confetti.create(canvas, {
    resize: true
  });

  myConfetti();
  setTimeout(()=>{
    document.getElementById("confetti-canvas").remove();
  }, 2000);
}

exDesc(exercise) {
  if (this.collectionExs[exercise.exerciseId]) {
    let ris = this.collectionExs[exercise.exerciseId]
    console.debug(ris)
    if (ris.equipmentsIds) {
      this.guiService.loadEquipmentNames(ris.equipmentsIds)
      this.guiService.getEquipImgs(ris.equipmentsIds)
    }
    ris.exerciseAlternativeId ? this.getAlternativeEx(ris.exerciseAlternativeId) : null
    ris.muscleGroups = ris.muscleGroups.sort()
    this.descriptionEsercizio = ris
    //console.debug(ris)
    setTimeout(() => {
      const resizeImg = new ImageResize({
        width: 857,
        height: 906,
        element: '#sagoma1'
      });
      setTimeout(() => {
        this.muscle(ris)
      }, 500);

    }, 100);
  } else {
    this.restService.getService('exercises', '/' + exercise.exerciseId).subscribe((ris) => {
      if (ris.equipmentsIds) {
        this.guiService.loadEquipmentNames(ris.equipmentsIds)
        this.guiService.getEquipImgs(ris.equipmentsIds)
      }
      ris.exerciseAlternativeId ? this.getAlternativeEx(ris.exerciseAlternativeId) : null
      ris.muscleGroups = ris.muscleGroups.sort()
      this.descriptionEsercizio = ris
      //console.debug(ris)
      setTimeout(() => {
        const resizeImg = new ImageResize({
          width: 857,
          height: 906,
          element: '#sagoma1'
        });
        setTimeout(() => {
          this.muscle(ris)
        }, 500);

      }, 100);
    })
  }


}

muscle(item){
  $('.map').maphilight({
    fade: false,
    fillColor: "db3687",
    fillOpacity: '1',
    strokeColor: '6826D0'
});
  let muscleGroup = item.muscleGroups;
  for (var k in muscleGroup) {
    $('.'+muscleGroup[k]).mouseover();
  }
}

closeEx(){
  this.descriptionEsercizio = null
}

checkPhaseDescription(){
  console.debug(this.workout.phases[this.phaseNumber].workoutPhaseTypeId)
  if(this.workout.phases[this.phaseNumber].workoutPhaseTypeId[this.translate.currentLang.toLocaleUpperCase()] != null){
    this.phaseDescription = this.workout.phases[this.phaseNumber].workoutPhaseTypeId[this.translate.currentLang.toLocaleUpperCase()]
  }else{
    this.phaseDescription = null
  }
}

skipPhasePresentation() {
  clearInterval(this.intervalTimer);
  if (this.workout.phases[this.phaseNumber].exercises[this.exerciseNumber].executionTimeSeconds > 0) {
    this.manageCountExecution(this.workout.phases[this.phaseNumber].exercises[this.exerciseNumber].executionTimeSeconds);
    this.showPhaseName = false;
  } else {
    this.showPhaseName = false;
  }
}

getAlternativeEx(idExercise) {
  this.alternativeExercise = null
  if (this.collectionExs[idExercise]) {
    this.alternativeExercise = this.collectionExs[idExercise]
    console.debug(this.collectionExs[idExercise])
  }else{
    this.restService.getService('exercises', '/' + idExercise).subscribe((ris) => {
      this.alternativeExercise = ris
      console.debug('alternative Exercise OBJ', this.alternativeExercise)
    })
  }
}

swapExercise(exerciseToSwap,closeModal?){
  console.debug('swap',this.workout.phases[this.phaseNumber].exercises[this.exerciseNumber])
  console.debug('to swap',exerciseToSwap)
  this.workout.phases[this.phaseNumber].exercises[this.exerciseNumber].exerciseId = exerciseToSwap.id
  console.debug('after',this.workout.phases[this.phaseNumber].exercises[this.exerciseNumber])

  if(this.workout.phases[this.phaseNumber].exercises[this.exerciseNumber].executionTimeSeconds > 0){
    clearInterval(this.intervalTimer)
    this.manageCountExecution(this.workout.phases[this.phaseNumber].exercises[this.exerciseNumber].executionTimeSeconds)
  }
  closeModal ? this.closeEx() : null
}

}
