import { RestService } from './../../../services/rest.service';
import { GuiService } from './../../../services/gui.service';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'vex-user-new',
  templateUrl: './user-new.component.html',
  styleUrls: ['./user-new.component.scss']
})
export class UserNewComponent implements OnInit {
  form: UntypedFormGroup

  constructor(public translate: TranslateService,private fb: UntypedFormBuilder, public guiService: GuiService, private restService: RestService,private dialogRef: MatDialogRef<UserNewComponent>) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      name: [null, Validators.required],
      lastName: [null, Validators.required],
      email: [null, Validators.required],
      birthdate: [null, Validators.required],
      subscriptionType: [null],
      roles: [null, Validators.required],
      password: [null, Validators.required],
      confirmPassword: [null, Validators.required]
    })
  }

  close(){
    this.dialogRef.close()
  }

  create(){
    let newUser = {}
    newUser = {
      name: this.form.value.name,
      lastName: this.form.value.lastName,
      email: this.form.value.email,
      password: this.form.value.password,
      subscriptionType: this.form.value.subscriptionType,
      birthdate: this.form.value.birthdate,
      roles: [this.form.value.roles]
    }
    console.debug(newUser)
    this.restService.postService('users','/create', newUser).subscribe((ris)=>{
      this.guiService.openSnackBar(this.translate.instant('GLOBAL.NEW_SUCCESS'), 'ok!')
      this.dialogRef.close()
    })
  }

}
