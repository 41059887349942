<div class="container">
  <p *ngIf="translate.currentLang == 'it'">
    Procedendo la trasazione verrà gestita da PayPal
  </p>
  <p *ngIf="translate.currentLang == 'en'">
    Proceeding the transaction will be managed by PayPal
  </p>
  <div id="paypal-button-container"></div>
  <p class="close-btn" mat-dialog-close>{{"DIALOG_CONFIRM.CLOSE" | translate}}</p>
</div>

