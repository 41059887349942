<div class="container">
  <form [formGroup]="form">

        <div  class="card flex-auto">
          <div class="px-6 py-4 border-b flex items-center header-title">
            <h2 class="title m-0">{{"NEW-SUB.EDIT_PLAN" | translate}}</h2>

            <div class="flex-end">
              <button [matMenuTriggerFor]="languageFormMenu" mat-icon-button type="button" align="end">
                <mat-icon svgIcon="{{iconFormFlag}}"></mat-icon>
              </button>
              <mat-menu #languageFormMenu="matMenu" overlapTrigger="false" >
                <button mat-menu-item (click)="changeFormLanguage('it')">
                  <mat-icon svgIcon="flag:italy"></mat-icon>
                  <span>Italiano</span>
                </button>
                <button mat-menu-item (click)="changeFormLanguage('en')">
                  <mat-icon svgIcon="flag:united-states"></mat-icon>
                  <span>English</span>
                </button>
              </mat-menu>
            </div>

          </div>

          <div class="px-6 py-4 flex flex-col">

            <div class="card px-6 py-4 flex flex-col subCards" *ngIf="formLang=='it'">


              <mat-form-field>
                <mat-label>{{"NEW-SUB.NAME" | translate}} {{"GLOBAL.ITA" | translate}}</mat-label>
                <input matInput formControlName="nome_it" required>
              </mat-form-field>


              <div class="flex flex-col sm:flex-row sm:gap-4">
                <mat-form-field class="flex-auto">
                  <mat-label>{{"NEW-SUB.VALID_FROM" | translate}}</mat-label>
                    <input [matDatepicker]="datepickerRef" matInput formControlName="valda" required>
                    <mat-datepicker-toggle [for]="datepickerRef" matSuffix></mat-datepicker-toggle>
                    <mat-datepicker #datepickerRef></mat-datepicker>
                </mat-form-field>
                <mat-form-field class="flex-auto">
                  <mat-label>{{"NEW-SUB.VALID_TO" | translate}}</mat-label>
                    <input [matDatepicker]="datepickerRef2" matInput formControlName="vala">
                    <mat-datepicker-toggle [for]="datepickerRef2" matSuffix></mat-datepicker-toggle>
                    <mat-datepicker #datepickerRef2></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="flex flex-col sm:flex-row sm:gap-4">
              <mat-form-field class="flex-auto">
                <mat-label>{{"NEW-SUB.FREE" | translate}}</mat-label>
                <input matInput formControlName="giornigratis" type="number">
              </mat-form-field>
              <mat-form-field class="flex-auto">
                <mat-label>{{"NEW-SUB.TYPE" | translate}}</mat-label>
                <mat-select formControlName="subType">
                  <mat-option value="GOLD">GOLD</mat-option>
                  <mat-option value="DIAMOND">DIAMOND</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

              <div class="flex flex-col sm:flex-row sm:gap-4">
                <mat-form-field class="flex-auto">
                  <mat-label>{{"NEW-SUB.DURATION" | translate}}</mat-label>
                  <mat-select formControlName="durata">
                    <mat-option></mat-option>
                    <mat-option [value]="option.key" *ngFor="let option of durate">{{ option.description }}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="flex-auto">
                  <mat-label>{{"NEW-SUB.PRICE" | translate}}</mat-label>
                  <input matInput formControlName="prezzo" type="number">
                  <mat-icon matSuffix svgIcon="mat:attach_money"></mat-icon>
                </mat-form-field>
              </div>

                <div>
                  <h2 class="title">{{"NEW-SUB.DESC" | translate}} {{"GLOBAL.ITA" | translate}}</h2>
                  <quill-editor
                   formControlName="desc_it"
                    [styles]="{ flex: '1 1 auto' }"></quill-editor>
                </div>
            </div>


            <!-- EN FORM -->
            <div class="card px-6 py-4 flex flex-col subCards" *ngIf="formLang=='en'">

              <mat-form-field>
                <mat-label>{{"NEW-SUB.NAME" | translate}} {{"GLOBAL.ENG" | translate}}</mat-label>
                <input matInput formControlName="nome_en" required>
              </mat-form-field>


              <div class="flex flex-col sm:flex-row sm:gap-4">
                <mat-form-field class="flex-auto">
                  <mat-label>{{"NEW-SUB.VALID_FROM" | translate}}</mat-label>
                    <input [matDatepicker]="datepickerRef" matInput formControlName="valda" required>
                    <mat-datepicker-toggle [for]="datepickerRef" matSuffix></mat-datepicker-toggle>
                    <mat-datepicker #datepickerRef></mat-datepicker>
                </mat-form-field>
                <mat-form-field class="flex-auto">
                  <mat-label>{{"NEW-SUB.VALID_TO" | translate}}</mat-label>
                    <input [matDatepicker]="datepickerRef2" matInput formControlName="vala">
                    <mat-datepicker-toggle [for]="datepickerRef2" matSuffix></mat-datepicker-toggle>
                    <mat-datepicker #datepickerRef2></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="flex flex-col sm:flex-row sm:gap-4">
                <mat-form-field class="flex-auto">
                <mat-label>{{"NEW-SUB.FREE" | translate}}</mat-label>
                <input matInput formControlName="giornigratis" type="number">
              </mat-form-field>
              <mat-form-field class="flex-auto">
                <mat-label>{{"NEW-SUB.TYPE" | translate}}</mat-label>
                <mat-select formControlName="subType">
                  <mat-option value="GOLD">GOLD</mat-option>
                  <mat-option value="DIAMOND">DIAMOND</mat-option>
                </mat-select>
              </mat-form-field>
              </div>


              <div class="flex flex-col sm:flex-row sm:gap-4">
                <mat-form-field class="flex-auto">
                  <mat-label>{{"NEW-SUB.DURATION" | translate}}</mat-label>
                  <mat-select formControlName="durata">
                    <mat-option></mat-option>
                    <mat-option [value]="option.key" *ngFor="let option of durate">{{ option.description }}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="flex-auto">
                  <mat-label>{{"NEW-SUB.PRICE" | translate}}</mat-label>
                  <input matInput formControlName="prezzo" type="number">
                  <mat-icon matSuffix svgIcon="mat:attach_money"></mat-icon>
                </mat-form-field>
              </div>

                <div>
                  <h2 class="title">{{"NEW-SUB.DESC" | translate}} {{"GLOBAL.ENG" | translate}}</h2>
                  <quill-editor
                   formControlName="desc_en"
                    [styles]="{ flex: '1 1 auto' }"></quill-editor>
                </div>
            </div>


            <div class="bottoni">
              <button (click)="confirmClose()" class="m-4" >{{"GLOBAL.CLOSE" | translate}}</button>
              <button mat-raised-button [disabled]="form.invalid" (click)="submit(form)" class="m-4 bottone-viola">{{"GLOBAL.EDIT" | translate}}</button>
            </div>
          </div>
        </div>
  </form>

</div>
