
<div class="container">
  <!-- <button class="close"><mat-icon svgIcon="mat:close" (click)="closeDialog()"></mat-icon></button> -->
  <img [src]="environment+'/'+program.id+'/'+'images/main'" alt="" class="program">
  <div class="week">
    <h3><span><fa-icon [icon]="icon.faCalendar"></fa-icon><span class="first">{{"WELCOME_DETAIL.WEEK_N" | translate}}</span></span> <span class="color">{{program.numberOfWeeks}}</span></h3>
    <h3><span><mat-icon svgIcon="mat:flash_on"></mat-icon><span class="first">{{"WELCOME_DETAIL.WORKOUT_W" | translate}}</span></span> <span class="color">{{program.weeklyWorkouts}}</span></h3>
    <h3><span><mat-icon svgIcon="mat:timer"></mat-icon><span class="first">{{"WELCOME_DETAIL.WORKOUT_TIME" | translate}}</span></span> <span class="color">{{program.workoutDurationInMinutes}}</span></h3>
  </div>
  <div class="equipment">
    <h2 class="color">
      {{"WELCOME_DETAIL.EQUIP" | translate}}
    </h2>
    <div class="equipo-container">
      <div *ngFor="let equip of program.equipmentIds" class="equipo">
        <img [src]="'assets/img/equipsIcons/'+guiService.filteredIds[equip]+'.svg'" class="svg">
        <h3>{{filteredIds[equip]}}</h3>
      </div>
    </div>
  </div>
  <div class="about">
    <h2>{{"WELCOME_DETAIL.ABOUT" | translate}}</h2>

    <p>
      <span class="color program-name">{{program.name}}</span>
      {{program.description}}</p>
  </div>
  <div class="actions flex items-center justify-end gap-2 mt-4 bottoni">
    <button mat-button (click)="closeDialog()" color="dark" class="m-4">{{"GLOBAL.CLOSE" | translate}}</button>
    <button mat-raised-button class="bottone-viola" (click)="evaluateSelectedProgram(program)">{{"WELCOME_DETAIL.WORK" | translate}}</button>
  </div>
</div>
