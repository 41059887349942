<div class="contenitore">
  <div class="sub-info">
    <div class="sub-info-inner">
      <div class="box total">
        <div class="border-b py-2 px-4 flex items-center">
          <h1>Attivi</h1>
        </div>
        <div class="datas">
          <h1 *ngIf="actives" [@myAnimation]>{{actives}}
            <span>
              Attivi
              <mat-icon svgIcon="mat:expand_more" *ngIf="!expandToggle || expandToggle == null" (click)="expand(true)"></mat-icon>
              <mat-icon svgIcon="mat:expand_less" *ngIf="expandToggle" (click)="expand(false)"></mat-icon>
            </span>
          </h1>
          <div *ngIf="expandToggle" class="sub-datas" [@myAnimation]>
            <mat-spinner [diameter]="70" *ngIf="!admins && !ambassadors && !nutritionists"></mat-spinner>
            <h1 *ngIf="admins && ambassadors && nutritionists" [@myAnimation]>
              {{calcActiveUsers()}}
              <span>Users</span>
            </h1>
            <h1 *ngIf="ambassadors" [@myAnimation]>
              {{ambassadors}}
              <span>Ambassadors</span>
            </h1>
            <h1 *ngIf="admins" [@myAnimation]>
              {{admins}}
              <span>Admin</span>
            </h1>
            <h1 *ngIf="nutritionists" [@myAnimation]>
              {{nutritionists}}
              <span>Nutritionists</span>
            </h1>
          </div>
        </div>
      </div>
      <div class="box user">
        <div class="border-b py-2 px-4 flex items-center">
          <h1>Utenti</h1>
        </div>
        <div class="datas">
          <h1 *ngIf="allUsers" [@myAnimation]>
            {{allUsers}}
            <span>Utenti
              <mat-icon svgIcon="mat:expand_more" *ngIf="!expandeToggleAllUsers || expandeToggleAllUsers == null" (click)="expandAllUsers(true)"></mat-icon>
              <mat-icon svgIcon="mat:expand_less" *ngIf="expandeToggleAllUsers" (click)="expandAllUsers(false)"></mat-icon>
            </span>
          </h1>
          <div *ngIf="expandeToggleAllUsers" class="sub-datas" [@myAnimation]>
            <mat-spinner [diameter]="70" *ngIf="!actives && !custCanceled && !suspended && !expired"></mat-spinner>
            <h1 *ngIf="actives" [@myAnimation]>
              {{actives}}
              <span>Actives</span>
            </h1>
            <h1 *ngIf="paused" [@myAnimation]>
              {{paused}}
              <span>Paused</span>
            </h1>
            <h1 *ngIf="actives && paused" [@myAnimation]>
              {{!custCanceled && !suspended && !expired ? 0 : calcNA()}}
              <span>N/A</span>
            </h1>
            <h1 *ngIf="custCanceled" [@myAnimation]>
              {{custCanceled}}
              <span>Cust canceled</span>
            </h1>
            <h1 *ngIf="suspended" [@myAnimation]>
              {{suspended}}
              <span>Suspended</span>
            </h1>
            <h1 *ngIf="expired" [@myAnimation]>
              {{expired}}
              <span>Expired</span>
            </h1>
          </div>
        </div>
      </div>
      <div class="box gold">
        <div class="border-b py-2 px-4 flex items-center">
          <h1>Abbonamenti</h1>
        </div>
        <div class="datas">
          <h1 *ngIf="gold" [@myAnimation]>{{gold}} <span>Gold</span></h1>
          <h1 *ngIf="diamond" [@myAnimation]>{{diamond}} <span>Diamond</span></h1>
        </div>
      </div>
    </div>
  </div>

</div>
