import { GuiService } from 'src/app/services/gui.service';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subject,takeUntil } from 'rxjs';
import { RestService } from 'src/app/services/rest.service';
import { ExerciseComponent } from '../exercise.component';
import {MatSelectModule} from '@angular/material/select';
import { CloseConfirmComponent } from '../../close-confirm/close-confirm.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'vex-new-exercise',
  templateUrl: './new-exercise.component.html',
  styleUrls: ['./new-exercise.component.scss',
  '../../../../../node_modules/quill/dist/quill.snow.css',
  '../../../../@vex/styles/partials/plugins/quill/_quill.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NewExerciseComponent implements OnInit {
  typs: any[];
  muscle: any [];
  level: any[] = [
    {key: 'EASY', description: this.translate.instant("EXERCISE-LIST.EASY")},
    {key: 'INTERMEDIATE', description: this.translate.instant("EXERCISE-LIST.INTERMEDIATE")},
    {key: 'EXPERT', description: this.translate.instant("EXERCISE-LIST.EXPERT")}
  ]
  form: UntypedFormGroup;
  formLang: any;
  iconFormFlag: any;
  flags = {
    en: 'flag:united-states',
    it: 'flag:italy'
  };

  exercises: any;
  esercizio: any;
  imgSelected: string;
  muscolo = new FormControl('')
  attrezzatura: any;
  imgURL: string;

  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<ExerciseComponent>,
    private translate: TranslateService,
    private restService: RestService,
    public guiService: GuiService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.imgURL = environment.baseURL.exercises;
    this.populateSelects()
    this.changeFormLanguage(this.translate.currentLang);

    this.form = this.fb.group({
      nome_it:[null, Validators.required],
      nome_en:[null, Validators.required],
      tipo: [null, Validators.required],
      muscolo: [null, Validators.required],
      difficolta: [null, Validators.required],
      alternative: [null],
      desc_it: [null],
      desc_en: [null],
      attrezzatura: [null]
    })

  }

  submit(){
    setTimeout(()=>this.guiService.showSpinner = true,0);
    let newEx = {
      i18nName: {
        "EN": this.form.value.nome_en,
        "IT": this.form.value.nome_it
    },
    i18nDescription: {
        "EN": this.form.value.desc_en,
        "IT": this.form.value.desc_it
    },
    difficulty: this.form.value.difficolta,
    type: this.form.value.tipo,
    muscleGroups: this.form.value.muscolo,
    name: this.form.value.nome_en,
    description: this.form.value.desc_en,
    exerciseAlternativeId: this.form.value.alternative ? this.form.value.alternative : null,
    image: this.imgSelected,
    imageFileName: this.imgSelected ? this.form.value.nome_en : null,
    equipmentIds: this.form.value.attrezzatura
    }

    this.restService.postService('exercises', '?ALL_LANGUAGES=true', newEx).subscribe((ris) => {
      console.debug(ris)
       this.dialogRef.close('done')
       this.guiService.openSnackBar(ris.name + this.translate.instant('GLOBAL.NEW_SUCCESS'), this.translate.instant('GLOBAL.CLOSE'))
     })

  }

  populateSelects(){
    this.restService.getService('exercise_type', '').subscribe((ris)=>{
      this.typs = ris
    })
    this.restService.getService('exercise_muscle', '').subscribe((ris)=>{
      this.muscle = ris
    })
    // this.restService.getService('exercise_training', '').subscribe((ris)=>{
    //   this.level = ris
    // })
    this.restService.postService('equipments', '', {page: 0, size: 0}).subscribe((ris)=>{
      this.attrezzatura = ris.items
    })
  }

  searchAltExercise() {
    console.debug('search open')
    if(this.form.value.alternative && this.form.value.alternative.length > 3){

    let param = {
      "filterAnd": [
        {
        "field": "name",
        "operator": "like",
        "value": this.form.value.alternative
        }
        ],
      "page": 0,
      "size": 100
    }
    this.restService.postService('exercises_search', '', param).subscribe((ris)=>{
      this.exercises = ris["items"]
    })
  }else if(this.form.value.alternative && this.form.value.alternative.length < 3){
    this.exercises = null
  }
  }

  changeFormLanguage(lang) {
    this.iconFormFlag = this.flags[lang];
    this.formLang = lang;
}

  onFileSelected(event) {
    this.guiService.convertFile(event.target.files[0]).subscribe(base64 => {
      console.debug("BASE64",base64);
      this.imgSelected = base64;
    });
  }

  confirmClose(){
    this.dialog.open(CloseConfirmComponent,{
      disableClose: true,
      hasBackdrop: true,
      panelClass: 'close-dialog-modalbox'
    }).afterClosed().subscribe((ris)=>{
      if(ris == 'true'){
        this.dialogRef.close()
      }
    })
  }
}
