<div class="container mt-4">
  <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">
    <h2 class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l hidden sm:block flex-none">
      <span *ngIf="selection.isEmpty()">{{"WORKOUTS.LIST" | translate}}</span>
    </h2>
    <div
      class="rounded-full border px-4 max-w-[300px] flex-auto flex items-center border border-gray-300">
      <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon>
      <input [formControl]="searchCtrl" (keyup)="whiteStartSpace()"  class="px-4 py-2 border-0 outline-none w-full bg-transparent" placeholder="{{'GLOBAL.SEARCH' | translate}}" type="search" />
    </div>

    <button class="ml-4 flex-none ltr:mr-4 ltr:border-r" color="primary" mat-mini-fab type="button" (click)="resetPage()">
      <mat-icon svgIcon="mat:search"></mat-icon>
    </button>
    <button (click)="reload()" class="ml-4 flex-none blue-bottone" color="primary" mat-mini-fab matTooltip="reload" type="button" >
      <mat-icon svgIcon="mat:autorenew"></mat-icon>
    </button>
    <button (click)="createWorkout()" class="ml-4 flex-none blue-bottone" color="primary" mat-mini-fab matTooltip="Add Workout" type="button" >
      <mat-icon svgIcon="mat:add"></mat-icon>
    </button>
  </div>

  <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center bg2">
    <div class="flex items-center filter-bar">
      <nav class="vex-tabs border-0 flex-auto cerca" mat-tab-nav-bar>
        <mat-form-field class="nut-form">
          <mat-label class="label-light">{{"WORKOUTS.WEEK" | translate}}</mat-label>
          <input matInput [formControl]="searchCtrlWeek" type="number">
        </mat-form-field>
        <mat-form-field class="nut-form">
          <mat-label class="label-light">{{"WORKOUTS.PROGRAM" | translate}}</mat-label>
          <mat-select [formControl]="searchCtrlProgram">
            <mat-option [value]="program.id" *ngFor="let program of programs">{{program.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </nav>

    </div>
  </div>

  <table mat-table [dataSource]="dataSource" *ngIf="workouts" matSort>

    <ng-container matColumnDef="vuota" >
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef=""></td>
    </ng-container>

    <ng-container matColumnDef="nome">
      <th mat-header-cell *matHeaderCellDef mat-sort-header (click)="sortParam('name', asc == true ? false : true)">{{"WORKOUTS.NAME" | translate}}</th>
      <td mat-cell *matCellDef="let workout">{{ workout.name }}</td>
    </ng-container>

    <ng-container matColumnDef="programma">
      <th mat-header-cell *matHeaderCellDef mat-sort-header (click)="sortParam('workoutProgramId', asc == true ? false : true)">{{"NEW-WORKOUT.PROGRAMS" | translate}}</th>
      <td mat-cell *matCellDef="let workout"><span class="wName">{{programsNames[workout.workoutProgramId]}}</span></td>
    </ng-container>

    <ng-container matColumnDef="tags">
      <th mat-header-cell *matHeaderCellDef mat-sort-header (click)="sortParam('tags', asc == true ? false : true)">{{"WORKOUTS.TAGS" | translate}}</th>
      <td mat-cell *matCellDef="let workout"><span class="wName">{{workout.tags}}</span></td>
    </ng-container>

    <!-- <ng-container matColumnDef="creazione">
      <th mat-header-cell *matHeaderCellDef>{{"WORKOUTS.DATE" | translate}}</th>
      <td mat-cell *matCellDef="let workout">
        {{workout.created | date: 'mediumDate' }}
      </td>
    </ng-container> -->
    <!-- Action Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row" class="w-10 text-secondary" >
        <button (click)="$event.stopPropagation()"
                [matMenuTriggerData]="{ customer: row }"
                [matMenuTriggerFor]="actionsMenu"
                mat-icon-button
                type="button">
          <mat-icon svgIcon="mat:more_horiz"></mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>


  </table>
  <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize" [length]="lunghezza" (page)="onPageChanged($event)" class="sticky left-0"></mat-paginator>

</div>

<mat-menu #actionsMenu="matMenu" xPosition="before" yPosition="below">
  <ng-template let-customer="customer" matMenuContent>
    <button  mat-menu-item (click)="editWorkout(customer)">
      <mat-icon svgIcon="mat:edit"></mat-icon>
      <span>{{'GLOBAL.EDIT' | translate}}</span>
    </button>
    <button  mat-menu-item (click)="deleteWorkout(customer.id)">
      <mat-icon svgIcon="mat:delete"></mat-icon>
      <span>{{'GLOBAL.DELETE' | translate}}</span>
    </button>
  </ng-template>
</mat-menu>
