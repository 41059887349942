<div class="container">
  <div class="px-6 py-4 border-b flex items-center header-title">
    <h2 class="title m-0">{{"USER-NUTRITION.MY_SURVEY" | translate}}</h2>
  </div>

  <div *ngIf="mySurvey.surveyType == 'questionario'">
    <form [formGroup]="questFormGroup">
      <div class="flex flex-col sm:flex-row gap-2 sm:gap-6">
        <mat-form-field appearance="fill" class="flex-auto">
          <mat-label>{{"USER-NUTRITION.SURVEY_C.NAME" | translate}}</mat-label>
          <input matInput formControlName="nome" readonly>
        </mat-form-field>
        <mat-form-field appearance="fill" class="flex-auto">
          <mat-label>{{"USER-NUTRITION.SURVEY_C.SURNAME" | translate}}</mat-label>
          <input matInput formControlName="cognome" readonly>
        </mat-form-field>
      </div>
      <div class="flex flex-col sm:flex-row gap-2 sm:gap-6">
      <mat-form-field appearance="fill">
        <mat-label>{{"USER-NUTRITION.SURVEY_C.AGE" | translate}}</mat-label>
        <input matInput formControlName="eta" type="number" readonly>
      </mat-form-field>
      <mat-form-field appearance="fill" class="flex-auto">
        <mat-label>{{"USER-NUTRITION.SURVEY_C."+unit+".HEIGHT" | translate}}</mat-label>
        <input matInput  formControlName="altezza" type="number" readonly>
      </mat-form-field>
      <mat-form-field appearance="fill" class="flex-auto">
        <mat-label>{{"USER-NUTRITION.SURVEY_C."+unit+".WEIGHT" | translate}}</mat-label>
        <input matInput  formControlName="peso" type="number" readonly>
      </mat-form-field>
      </div>
      <div class="flex flex-col sm:flex-row gap-2 sm:gap-6">
        <mat-form-field appearance="fill" class="flex-auto">
          <mat-label>{{"USER-NUTRITION.SURVEY_C."+unit+".WAIST" | translate}}</mat-label>
          <input matInput  formControlName="vita" type="number" readonly>
        </mat-form-field>
        <mat-form-field appearance="fill" class="flex-auto">
          <mat-label>{{"USER-NUTRITION.SURVEY_C."+unit+".FLANKS" | translate}}</mat-label>
          <input matInput  formControlName="fianchi" type="number" readonly>
        </mat-form-field>
      </div>
      <div class="flex flex-col sm:flex-row gap-2 sm:gap-6">
        <mat-form-field appearance="fill" class="flex-auto">
          <mat-label>{{"USER-NUTRITION.SURVEY_C."+unit+".THIGH_DX" | translate}}</mat-label>
          <input matInput formControlName="coscia_dx" type="number" readonly>
        </mat-form-field>
        <mat-form-field appearance="fill" class="flex-auto">
          <mat-label>{{"USER-NUTRITION.SURVEY_C."+unit+".THIGH_SX" | translate}}</mat-label>
          <input matInput formControlName="coscia_sx" type="number" readonly>
        </mat-form-field>
      </div>
      <div class="flex flex-col sm:flex-row gap-2 sm:gap-6">
        <mat-form-field appearance="fill" class="flex-auto">
          <mat-label>{{"USER-NUTRITION.SURVEY_C."+unit+".ARM_DX" | translate}}</mat-label>
          <input matInput formControlName="braccio_dx" type="number" readonly>
        </mat-form-field>
        <mat-form-field appearance="fill" class="flex-auto">
          <mat-label>{{"USER-NUTRITION.SURVEY_C."+unit+".ARM_SX" | translate}}</mat-label>
          <input matInput formControlName="braccio_sx" type="number" readonly>
        </mat-form-field>
      </div>

      <div class="flex flex-col sm:flex-row gap-2 sm:gap-6">
        <mat-form-field appearance="fill" class="flex-auto">
          <mat-label>{{"USER-NUTRITION.SURVEY_C.JOB" | translate}}</mat-label>
          <input matInput formControlName="attivita" readonly>
        </mat-form-field>
        <mat-form-field appearance="fill" class="flex-auto">
          <mat-label>{{"USER-NUTRITION.SURVEY_C.LIFE" | translate}}</mat-label>
          <input matInput formControlName="stile" readonly>
        </mat-form-field>
      </div>
      <div class="flex flex-col sm:flex-row gap-2 sm:gap-6" *ngIf="mySurvey.periodFrequency != '' && mySurvey.period != ''">
        <mat-form-field appearance="fill" class="flex-auto">
          <mat-label>{{"USER-NUTRITION.SURVEY_C.CYCLE" | translate}}</mat-label>
          <input matInput formControlName="ciclo" readonly>
        </mat-form-field>
      <mat-form-field appearance="fill" class="flex-auto">
        <mat-label>{{"USER-NUTRITION.SURVEY_C.CYCLE_FREQ" | translate}}</mat-label>
        <input matInput formControlName="frequenza_ciclo" readonly>
      </mat-form-field>
      </div>

      <div>
        <mat-form-field appearance="fill" class="flex-auto w-100" *ngIf="mySurvey.pathologies != ''">
          <mat-label>{{"USER-NUTRITION.SURVEY_C.PATOLOGY" | translate}}</mat-label>
          <input matInput formControlName="patologie" readonly>
        </mat-form-field>
      </div>
      <mat-form-field appearance="fill" class="flex-auto w-100" *ngIf="mySurvey.therapy != ''">
        <mat-label>{{"USER-NUTRITION.SURVEY_C.TERAPY" | translate}}</mat-label>
        <input matInput formControlName="terapia" readonly>
      </mat-form-field>
      <mat-form-field appearance="fill" class="flex-auto w-100" *ngIf="mySurvey.allergies != ''">
        <mat-label>{{"USER-NUTRITION.SURVEY_C.ALLERGY" | translate}}</mat-label>
        <input matInput formControlName="allergie" readonly>
      </mat-form-field>

      <mat-form-field appearance="fill" class="flex-auto w-100">
        <mat-label>Note</mat-label>
        <textarea cols="30" rows="4" matInput formControlName="consideration"></textarea>
      </mat-form-field>

       <div class="foods">
        <div class="food w-50">
          <h2>{{"USER-NUTRITION.SURVEY_C.FOOD_LOVE2" | translate}}</h2>
          <mat-chip-list *ngFor="let food of mySurvey.belovedFood">
            <mat-chip class="chip">
              {{food}}
            </mat-chip>
          </mat-chip-list>
        </div>
        <div class="food w-50">
          <h2>{{"USER-NUTRITION.SURVEY_C.FOOD_HATE2" | translate}}</h2>
          <mat-chip-list *ngFor="let food of mySurvey.hatedFood">
            <mat-chip class="chip">
              {{food}}
            </mat-chip>
          </mat-chip-list>
        </div>

       </div>
       <div class="bottoni">
        <button class="m-4 bottone-viola" (click)="closeDialog()" mat-button-base>{{"GLOBAL.CLOSE" | translate}}</button>
        <button class="m-4 bottone-viola" mat-button-base *ngIf="guiService.showDownloadSurveyButton == true" (click)="downloadSurveyAsPDF()"><fa-icon [icon]="icon.download"></fa-icon></button>
      </div>
    </form>
  </div>

  <div *ngIf="mySurvey.surveyType == 'follow-up'">
    <form [formGroup]="surveyFormGroup">
      <div class="flex flex-col sm:flex-row gap-2 sm:gap-6">
        <mat-form-field appearance="fill" class="flex-auto">
          <mat-label>{{"USER-NUTRITION.SURVEY_C."+unit+".WEIGHT" | translate}}</mat-label>
          <input matInput formControlName="peso" type="number" readonly>
        </mat-form-field>
        <mat-form-field appearance="fill" class="flex-auto">
          <mat-label>{{"USER-NUTRITION.SURVEY_C."+unit+".WAIST" | translate}}</mat-label>
          <input matInput formControlName="vita" type="number" readonly>
        </mat-form-field>
        <mat-form-field appearance="fill" class="flex-auto">
          <mat-label>{{"USER-NUTRITION.SURVEY_C."+unit+".FLANKS" | translate}}</mat-label>
          <input matInput formControlName="fianchi" type="number" readonly>
        </mat-form-field>
      </div>
      <div class="flex flex-col sm:flex-row gap-2 sm:gap-6" >
        <mat-form-field appearance="fill" class="flex-auto">
          <mat-label>{{"USER-NUTRITION.SURVEY_C."+unit+".THIGH_DX" | translate}}</mat-label>
          <input matInput formControlName="coscia_dx" type="number" readonly>
        </mat-form-field>
        <mat-form-field appearance="fill" class="flex-auto">
          <mat-label>{{"USER-NUTRITION.SURVEY_C."+unit+".THIGH_SX" | translate}}</mat-label>
          <input matInput formControlName="coscia_sx" type="number" readonly>
        </mat-form-field>
      </div>
      <div class="flex flex-col sm:flex-row gap-2 sm:gap-6">
        <mat-form-field appearance="fill" class="flex-auto">
          <mat-label>{{"USER-NUTRITION.SURVEY_C."+unit+".ARM_DX" | translate}}</mat-label>
          <input matInput formControlName="braccio_dx" type="number" readonly>
        </mat-form-field>
        <mat-form-field appearance="fill" class="flex-auto">
          <mat-label>{{"USER-NUTRITION.SURVEY_C."+unit+".ARM_SX" | translate}}</mat-label>
          <input matInput formControlName="braccio_sx" type="number" readonly>
        </mat-form-field>
      </div>

      <mat-form-field appearance="fill" class="flex-auto w-100">
        <mat-label>{{"USER-NUTRITION.SURVEY_C.LIKE" | translate}}</mat-label>
        <input matInput formControlName="like" readonly>
      </mat-form-field>
      <mat-form-field appearance="fill" class="flex-auto w-100">
        <mat-label>{{"USER-NUTRITION.SURVEY_C.DISLIKE" | translate}}</mat-label>
        <input matInput formControlName="dislike" readonly>
      </mat-form-field>


      <mat-form-field appearance="fill" class="flex-auto w-100">
        <mat-label>{{"USER-NUTRITION.SURVEY_C.NEW_FOOD" | translate}}</mat-label>
        <input matInput formControlName="would" readonly>
      </mat-form-field>
      <mat-form-field appearance="fill" class="flex-auto w-100">
        <mat-label>{{"USER-NUTRITION.SURVEY_C.CONSIDERATION" | translate}}</mat-label>
        <input matInput formControlName="consideration" readonly>
      </mat-form-field>

    <div class="bottoni">
      <button class="m-4 bottone-viola" (click)="closeDialog()" mat-button-base>{{"GLOBAL.CLOSE" | translate}}</button>
      <button class="m-4 bottone-viola" mat-button-base *ngIf="guiService.showDownloadSurveyButton == true" (click)="downloadSurveyAsPDF()"><fa-icon [icon]="icon.download"></fa-icon></button>
     </div>
    </form>
  </div>
</div>

<div class="toDownload" id="pdfTable" #pdfTable>
  <h1>{{"USER-NUTRITION.SURVEY_C.NAME" | translate}}:</h1>
  <p>{{mySurvey.name}}</p>
  <h1>{{"USER-NUTRITION.SURVEY_C.SURNAME" | translate}}</h1>
  <p>{{mySurvey.surname}}</p>
  <h1>{{"USER-NUTRITION.SURVEY_C.AGE" | translate}}</h1>
  <p>{{mySurvey.age}}</p>
  <h1>{{"USER-NUTRITION.SURVEY_C."+unit+".HEIGHT" | translate}}</h1>
  <p>{{mySurvey.height}}</p>
  <h1>{{"USER-NUTRITION.SURVEY_C."+unit+".WEIGHT" | translate}}</h1>
  <p>{{mySurvey.weight}}</p>
  <h1>{{"USER-NUTRITION.SURVEY_C."+unit+".WAIST" | translate}}</h1>
  <p>{{mySurvey.weistline}}</p>
  <h1>{{"USER-NUTRITION.SURVEY_C."+unit+".FLANKS" | translate}}</h1>
  <p>{{mySurvey.hip}}</p>
  <h1>{{"USER-NUTRITION.SURVEY_C."+unit+".THIGH_DX" | translate}}</h1>
  <p>{{mySurvey.rightThigh}}</p>
  <h1>{{"USER-NUTRITION.SURVEY_C."+unit+".THIGH_SX" | translate}}</h1>
  <p>{{mySurvey.leftThigh}}</p>
  <h1>{{"USER-NUTRITION.SURVEY_C."+unit+".ARM_DX" | translate}}</h1>
  <p>{{mySurvey.rightArm}}</p>
  <h1>{{"USER-NUTRITION.SURVEY_C."+unit+".ARM_SX" | translate}}</h1>
  <p>{{mySurvey.leftArm}}</p>
  <h1>{{"USER-NUTRITION.SURVEY_C.JOB" | translate}}</h1>
  <p>{{mySurvey.activity}}</p>
  <h1>{{"USER-NUTRITION.SURVEY_C.LIFE" | translate}}</h1>
  <p>{{mySurvey.lifeStyle}}</p>
  <h1>{{"USER-NUTRITION.SURVEY_C.CYCLE" | translate}}</h1>
  <p>{{mySurvey.period}}</p>
  <h1>{{"USER-NUTRITION.SURVEY_C.CYCLE_FREQ" | translate}}</h1>
  <p>{{mySurvey.periodFrequency}}</p>
  <h1>{{"USER-NUTRITION.SURVEY_C.PATOLOGY" | translate}}</h1>
  <p>{{mySurvey.pathologies}}</p>
  <h1>{{"USER-NUTRITION.SURVEY_C.TERAPY" | translate}}</h1>
  <p>{{mySurvey.therapy}}</p>
  <h1>{{"USER-NUTRITION.SURVEY_C.ALLERGY" | translate}}</h1>
  <p>{{mySurvey.allergies}}</p>
  <h1>{{"USER-NUTRITION.SURVEY_C.FOOD_LOVE2" | translate}}</h1>
  <ul *ngFor="let food of mySurvey.belovedFood">
    <li>{{food}}</li>
  </ul>
  <h1>{{"USER-NUTRITION.SURVEY_C.FOOD_HATE2" | translate}}</h1>
  <ul *ngFor="let food of mySurvey.hatedFood">
    <li>{{food}}</li>
  </ul>
</div>

<div class="toDownload" id="pdfTable1" #pdfTable1>
  <h1>{{"USER-NUTRITION.SURVEY_C."+unit+".WEIGHT" | translate}}</h1>
  <p>{{mySurvey.weight}}</p>
  <h1>{{"USER-NUTRITION.SURVEY_C."+unit+".WAIST" | translate}}</h1>
  <p>{{mySurvey.weistline}}</p>
  <h1>{{"USER-NUTRITION.SURVEY_C."+unit+".FLANKS" | translate}}</h1>
  <p>{{mySurvey.hip}}</p>
  <h1>{{"USER-NUTRITION.SURVEY_C."+unit+".THIGH_DX" | translate}}</h1>
  <p>{{mySurvey.rightThigh}}</p>
  <h1>{{"USER-NUTRITION.SURVEY_C."+unit+".THIGH_SX" | translate}}</h1>
  <p>{{mySurvey.leftThigh}}</p>
  <h1>{{"USER-NUTRITION.SURVEY_C."+unit+".ARM_DX" | translate}}</h1>
  <p>{{mySurvey.rightArm}}</p>
  <h1>{{"USER-NUTRITION.SURVEY_C."+unit+".ARM_SX" | translate}}</h1>
  <p>{{mySurvey.leftArm}}</p>
  <h1>{{"USER-NUTRITION.SURVEY_C.LIKE" | translate}}</h1>
  <p>{{mySurvey.like}}</p>
  <h1>{{"USER-NUTRITION.SURVEY_C.DISLIKE" | translate}}</h1>
  <p>{{mySurvey.dislike}}</p>
  <h1>{{"USER-NUTRITION.SURVEY_C.NEW_FOOD" | translate}}</h1>
  <p>{{mySurvey.would}}</p>
  <h1>{{"USER-NUTRITION.SURVEY_C.CONSIDERATION" | translate}}</h1>
  <p>{{mySurvey.consideration}}</p>
</div>
