<div @fadeInUp class="card overflow-hidden" id="box">
  <mat-vertical-stepper #verticalStepper="matVerticalStepper" [linear]="true">
    <ng-template matStepperIcon="edit">
      <mat-icon svgIcon="mat:done_all"></mat-icon>
    </ng-template>

    <ng-template matStepperIcon="done">
      <mat-icon svgIcon="mat:done_all"></mat-icon>
    </ng-template>

    <mat-step [stepControl]="verticalPlanFormGroup">
      <form [formGroup]="verticalPlanFormGroup">
        <ng-template matStepLabel>
          <span class="custom-step-label">{{"NEW-WORKOUT.NEW" | translate}}</span>
          <div class="flex-end">
            <button [matMenuTriggerFor]="languageFormMenu" mat-icon-button type="button" align="end">
              <mat-icon svgIcon="{{iconFormFlag}}"></mat-icon>
            </button>
            <mat-menu #languageFormMenu="matMenu" overlapTrigger="false" >
              <button mat-menu-item (click)="changeFormLanguage('it')">
                <mat-icon svgIcon="flag:italy"></mat-icon>
                <span>Italiano</span>
              </button>
              <button mat-menu-item (click)="changeFormLanguage('en')">
                <mat-icon svgIcon="flag:united-states"></mat-icon>
                <span>English</span>
              </button>
            </mat-menu>
          </div>
        </ng-template>

        <div class="title m-0">{{"NEW-WORKOUT.CREATE" | translate}}</div>

        <div *ngIf="formLang=='it'">
            <mat-form-field class="flex flex-col gap-4 mt-4">
              <mat-label>{{"NEW-WORKOUT.W_NAME" | translate}} {{"GLOBAL.ITA" | translate}}</mat-label>
              <input matInput requred formControlName="nome_it">
            </mat-form-field>

            <mat-form-field class="flex flex-col gap-4 mt-4">
              <mat-label>{{"NEW-WORKOUT.PROGRAMS" | translate}}</mat-label>
              <mat-select formControlName="programs">
                <mat-option [value]="program.id" *ngFor="let program of programs">{{ program.name }}</mat-option>
              </mat-select>
            </mat-form-field>

          <mat-form-field class="flex flex-col gap-4 mt-4">
            <mat-label>{{"NEW-WORKOUT.DESC" | translate}} {{"GLOBAL.ITA" | translate}}</mat-label>
            <textarea cols="30" rows="3" matInput formControlName="desc_it"></textarea>
          </mat-form-field>
        </div>
        <div *ngIf="formLang=='en'">
          <mat-form-field class="flex flex-col gap-4 mt-4">
            <mat-label>{{"NEW-WORKOUT.W_NAME" | translate}} {{"GLOBAL.ENG" | translate}}</mat-label>
            <input matInput required formControlName="nome_en">
          </mat-form-field>

          <mat-form-field class="flex flex-col gap-4 mt-4">
            <mat-label>{{"NEW-WORKOUT.PROGRAMS" | translate}}</mat-label>
            <mat-select formControlName="programs">
              <mat-option [value]="program.id" *ngFor="let program of programs">{{ program.name }}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="flex flex-col gap-4 mt-4">
            <mat-label>{{"NEW-WORKOUT.DESC" | translate}} {{"GLOBAL.ENG" | translate}}</mat-label>
            <textarea cols="30" rows="3" matInput formControlName="desc_en"></textarea>
          </mat-form-field>
        </div>

        <div class="w-100">
          <mat-form-field>
            <mat-label>{{"NEW-WORKOUT.W_NUMBER" | translate}}</mat-label>
            <input (keypress)="validateNo($event)" matInput formControlName="week_number">
            <button mat-button matSuffix mat-stroked-button (click)="addWeekToChip(verticalPlanFormGroup.value.week_number)">
              <mat-icon svgIcon="mat:add"></mat-icon>
            </button>
          </mat-form-field>

        </div>
        <div class="w-100">
          <mat-chip-listbox *ngFor="let w of week_number; let i = index">
              <mat-chip class="chip_week">{{w}}
                <button matChipRemove (click)="removeWeekFromChip(i)">
                  <mat-icon svgIcon="mat:cancel"></mat-icon>
                </button>
              </mat-chip>
          </mat-chip-listbox>
        </div>

        <input formControlName="file" (change)="onFileSelected($event)" type="file" />

        <div class="actions flex items-center justify-end gap-2 bottoni">
          <button mat-button (click)="confirmClose()"  color="dark">{{"GLOBAL.CLOSE" | translate}}</button>
          <button  class="bottone-viola" mat-raised-button matStepperNext>
            {{"NEW-WORKOUT.NEXT" | translate}}
          </button>
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="verticalSelectExerciseFormGroup">
      <form [formGroup]="verticalSelectExerciseFormGroup">
        <ng-template matStepLabel><span class="custom-step-label">{{"NEW-WORKOUT.SELECT_EX" | translate}}</span></ng-template>


        <div class="sfondodiv">

          <div class="left-col w30">
            <div *ngFor="let phase of phases; let i = index" class="text-center relative card carta-flexata mb-1"
                 matRipple
                 [class.selected]="i === selectedPhase">
              <span class="phase-counter">
                {{i + 1}}
                <a class="phase-remove" (click)="removePhase(i)">
                  <fa-icon [icon]="icon.faClose" class="icon large-icon" size="1x"></fa-icon>
                </a>
              </span>
              <a [attr.class]="'phase_'+i" (click)="selectPhase(i)">
                <div>
                  <div>
                      <span *ngIf="formLang=='it'">
                        <mat-form-field appearance="legacy">
                          <mat-label>{{"NEW-WORKOUT.NAME" | translate}} {{"GLOBAL.ITA" | translate}}</mat-label>
                          <input  matInput formControlName="name_it_{{i}}">
                        </mat-form-field>
                      </span>
                    <span *ngIf="formLang=='en'">
                        <mat-form-field appearance="legacy">
                          <mat-label>{{"NEW-WORKOUT.NAME" | translate}} {{"GLOBAL.ENG" | translate}}</mat-label>
                          <input  matInput formControlName="name_en_{{i}}">
                        </mat-form-field>
                      </span>
                  </div>
                </div>

                <div>
                  <div class="w50">
                    <mat-form-field appearance="legacy">
                      <mat-label>{{"NEW-WORKOUT.SERIES" | translate}}</mat-label>
                      <input matInput (keypress)="validateNo($event)" formControlName="series_{{i}}">
                    </mat-form-field>
                  </div>
                  <div class="w50">
                    <mat-form-field appearance="legacy">
                      <mat-label>{{"NEW-WORKOUT.REST" | translate}}</mat-label>
                      <input matInput (keypress)="validateNo($event)" formControlName="series_rest_{{i}}">
                    </mat-form-field>
                  </div>
                </div>

                <div>
                  <span *ngIf="formLang=='en'">
                    <mat-form-field appearance="legacy" class="">
                      <mat-label>{{"NEW-WORKOUT.DESC_2" | translate}}</mat-label>
                      <textarea matInput formControlName="type_en_{{i}}"> </textarea>
                    </mat-form-field>
                  </span>
                  <span *ngIf="formLang=='it'">
                    <mat-form-field appearance="legacy" class="">
                      <mat-label>{{"NEW-WORKOUT.DESC_2" | translate}}</mat-label>
                      <textarea matInput formControlName="type_it_{{i}}"> </textarea>
                    </mat-form-field>
                  </span>
                </div>

                <div class="p-2 pt-0 chip-wrapper">
                  <mat-chip-list cdkDropList (cdkDropListDropped)="drop($event, i)">
                    <mat-chip *ngFor="let item of phase.exercises; let e = index" [cdkDragData]="item" cdkDrag>
                      {{item.name}}
                      <button (click)="removeExerciseFromPhase(i,e)">
                        <fa-icon [icon]="icon.faClose" class="icon large-icon" size="2x"></fa-icon>
                      </button>
                    </mat-chip>
                  </mat-chip-list>
                </div>
              </a>
            </div>
            <button mat-raised-button color="primary" (click)="addPhase()" class="mt-2 w100 bottone-viola">{{"NEW-WORKOUT.ADD_PHASE" | translate}}</button>

          </div>
          <div class="right-col w60">
            <div
              class="search-ex ml-4 rounded-full border px-4 max-w-[300px] flex-auto flex items-center border border-gray-300">
              <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon>
              <input [formControl]="searchCtrl" (keyup)="searchAltExercise()" (search)="searchAltExercise()" class="px-4 py-2 border-0 outline-none w-full bg-transparent" placeholder="{{'GLOBAL.SEARCH'| translate}}"
                     type="search"/>
            </div>
            <div class="p-gutter grid grid-cols-1 sm:grid-cols-3 md:grid-cols-5 gap-1 container">
              <div *ngFor="let ex of exercises" class="text-center relative card carta-flexata" matRipple>
                <div class="box-wrapper" [style.background-image]="'url('+imgURL+'/'+ex.id+'/images/main)'">
                  <div class="bg-app-bar flex items-center justify-around barra-rosa">
                    <h2 class="title">{{ex.name}}</h2>
                  </div>
                  <button (click)="addExerciseFromPhase(ex)" aria-label="Add">
                    <fa-icon [icon]="icon.faAdd" class="icon large-icon" size="5x"></fa-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="actions flex items-center justify-end gap-2 mt-4 bottoni">
          <button color="dark" mat-button matStepperPrevious type="button">{{"NEW-WORKOUT.BACK" | translate}}</button>
          <button class="bottone-viola" mat-raised-button matStepperNext  (click)="setPhasesparam()">
            {{"NEW-WORKOUT.NEXT" | translate}}
          </button>
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="verticalSetExerciseFormGroup">
      <form [formGroup]="verticalSetExerciseFormGroup">
      <ng-template matStepLabel><span class="custom-step-label">{{"NEW-WORKOUT.CONFIG_PROG" | translate}}</span></ng-template>

      <div class="">

        <div *ngFor="let phase of phases; let i = index" class="relative card carta-flexata ml-4 mb-1" matRipple
             [class.selected]="i === selectedPhase">
          <span class="phase-counter">{{i + 1}}</span>
          <a [attr.class]="'phase_'+i" (click)="selectPhase(i)">
            <h2 class="pl-10" *ngIf="typs">{{phase.name}}</h2>
            <div class="p-2 exercise-wrapper">
              <div class="phase-row">
                  <span *ngFor="let item of phase.exercises; let e = index">
                    <mat-card class="exercise-card ">
                      <mat-card-header>
                        <mat-card-title>{{item.name}}</mat-card-title>
                      </mat-card-header>
                      <mat-card-content>
                        <p>
                          <mat-form-field appearance="legacy">
                            <mat-label>{{"NEW-WORKOUT.REPS" | translate}}</mat-label>
                            <input matInput (keypress)="validateNo($event)" placeholder="Placeholder" [formControl]="verticalSetExerciseFormGroup.controls['reps_'+i+'_'+e]">
                            <mat-icon matSuffix svgIcon="mat:repeat"></mat-icon>
                            <mat-hint>{{"NEW-WORKOUT.R_DESC" | translate}}</mat-hint>
                          </mat-form-field>
                        </p>
                        <p>
                          <mat-checkbox class="per-lato-field" [formControl]="verticalSetExerciseFormGroup.controls['side_'+i+'_'+e]">{{"NEW-WORKOUT.SIDE" | translate}}</mat-checkbox>
                        </p>
                        <p>
                          <mat-form-field appearance="legacy">
                            <mat-label>{{"NEW-WORKOUT.TIME" | translate}} (sec)</mat-label>
                            <input matInput (keypress)="validateNo($event)" placeholder="Placeholder" [formControl]="verticalSetExerciseFormGroup.controls['time_'+i+'_'+e]">
                            <mat-icon matSuffix svgIcon="mat:timer"></mat-icon>
                            <mat-hint>{{"NEW-WORKOUT.T_DESC" | translate}}</mat-hint>
                          </mat-form-field>
                        </p>
                      </mat-card-content>
                    </mat-card>
                    <span class="rest-space" *ngIf="e<phase.exercises.length-1">
                      <mat-form-field appearance="outline">
                        <mat-label>{{"NEW-WORKOUT.TIME" | translate}} (sec)</mat-label>
                        <input matInput (keypress)="validateNo($event)" placeholder="Placeholder" [formControl]="verticalSetExerciseFormGroup.controls['rest_'+i+'_'+e]">
                        <mat-icon matSuffix svgIcon="mat:timer"></mat-icon>
                        <mat-hint>{{"NEW-WORKOUT.REST_DESC" | translate}}</mat-hint>
                      </mat-form-field>
                    </span>
                  </span>
              </div>

            </div>
          </a>
        </div>

      </div>

      <div class="actions flex items-center justify-end gap-2 mt-4 bottoni">

        <button color="dark" mat-button matStepperPrevious type="button">{{"NEW-WORKOUT.BACK" | translate}}</button>
        <button (click)="submit()" [disabled]="verticalSetExerciseFormGroup.invalid" [disabled]="showMiniSpinner == true" class="bottone-viola" mat-raised-button
                matStepperNext>
                <span *ngIf="showMiniSpinner == false">{{"NEW-WORKOUT.CREATE" | translate}}</span>
                <span *ngIf="showMiniSpinner == true">
                  <mat-icon><mat-spinner color="warn" diameter="20">
                  </mat-spinner></mat-icon>
                </span>
        </button>
      </div>
    </form>
    </mat-step>

  </mat-vertical-stepper>
</div>
